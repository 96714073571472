import React, { useState } from 'react';

import { Theme } from 'helpers/theme';
import { createUseStyles, useTheme } from 'react-jss';
import { useRouter } from 'found';
import ModuleMp3 from 'components/Misc/ModuleMp3';
import Page from 'components/Page';
import PageTitle from 'components/PageTitle';
import { createFragmentContainer, graphql } from 'react-relay';
import { Module_myActiveCourse } from './__generated__/Module_myActiveCourse.graphql';
import { Module_module } from './__generated__/Module_module.graphql';
import CourseSyllabusCardFrag from 'components/Overview/CourseSyllabusCard/CourseSyllabusCardFrag';
import SelectButton from 'components/core/Input/SelectButton';
import Spacer from 'components/core/Spacers/Spacer';
import Button from 'components/core/Input/Button';
import { goToNextURL } from 'views/helpers';

import ReactPlayer from 'react-player';
import Transcript from 'components/Misc/Transcript';
import Video from 'components/Video/Video';
import { Module_user } from './__generated__/Module_user.graphql';
import { useSideModalDispatch } from 'views/SideModalProvider';

const useStyles = createUseStyles((theme: Theme) => ({
  moduleRoot: {
    width: '100%'
  },
  mp3: {
    background: 'white',
    height: 111,
    padding: '0px 42px',
    borderBottom: `1px solid ${theme.colors.borderGrey}`
  },
  contentHolder: {
    width: '100%',
    display: 'flex',
    position: 'relative',
    justifyContent: 'center'
  },
  content: {
    display: 'grid',
    gridGap: 50,
    maxWidth: 1040,
    gridTemplateColumns: '2fr 1fr'
  },
  nextQuestionWrap: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: 150,
    background: 'white',
    border: `1px solid ${theme.colors.borderGrey}`,
    borderRadius: 6
  },
  bodyText: {
    width: 654,
    whiteSpace: 'pre-wrap'
  },
  videoContainer: {
    cursor: 'pointer'
  }
}));

type Props = {
  myActiveCourse: Module_myActiveCourse;
  module?: Module_module;
  user: Module_user;
};

const Module = ({ myActiveCourse, module, user }: Props) => {
  const theme = useTheme();
  const classes = useStyles({ theme });
  const { router, match } = useRouter();
  const { courseID, moduleUUID } = match.params;

  const selectOptions = ['Description', 'Transcript'];
  const [selectedOption, setSelectedOption] = useState(selectOptions[0]);
  const [playingVideo, setPlayingVideo] = useState(false);
  const [canContinue, setCanContinue] = useState(!(module?.videoURL ?? false));

  const dispatch = useSideModalDispatch();

  if (!myActiveCourse?.course)
    return <div>Unable to get course for module</div>;
  return (
    <Page noPadding>
      <div className={classes.moduleRoot}>
        {module?.voiceoverURL && (
          <ModuleMp3
            name={module?.name ?? ''}
            subTitle=""
            mp3Url={module?.voiceoverURL ?? ''}
            className={classes.mp3}
          />
        )}
        <Spacer vertical spacing={3} />
        <div className={classes.contentHolder}>
          <div className={classes.content}>
            <div>
              <PageTitle title={module?.name ?? ''} />
              <Spacer vertical spacing={3} />
              <div
                onClick={() => {
                  setPlayingVideo(!playingVideo);
                }}
                className={classes.videoContainer}
              >
                {module?.videoURL && (
                  // <ReactPlayer
                  //   url={module.videoURL}
                  //   width="100%"
                  //   height="100%"
                  //   playing={playingVideo}
                  //   onEnded={() => setCanContinue(true)}
                  //   controls
                  // />
                  <Video
                    src={module.videoURL}
                    onEnded={() => setCanContinue(true)}
                  />
                )}
              </div>
              <Spacer vertical spacing={3} />
              <SelectButton
                options={selectOptions}
                selected={selectedOption}
                onClick={(option: string) => {
                  setSelectedOption(option);
                }}
              />
              <Spacer vertical spacing={3} />
              <div>
                <pre className={classes.bodyText}>
                  {selectedOption == selectOptions[0] ? (
                    module?.description
                  ) : (
                    <Transcript transcript={module?.transcript} />
                  )}
                </pre>
              </div>
              <Spacer vertical spacing={3} />
              <div className={classes.nextQuestionWrap}>
                <Button
                  title={'Continue'}
                  padding="large"
                  disabled={!canContinue}
                  onClick={() => {
                    if (myActiveCourse?.course === null) return;

                    const url = goToNextURL(
                      parseInt(courseID),
                      myActiveCourse.course.syllabus,
                      module?.uuid,
                      () =>
                        dispatch({
                          modal: 'complete',
                          firstName: user.firstName,
                          courseName: myActiveCourse.course?.name ?? '',
                          onClose: () => {
                            router.push('/app/my-courses');
                          }
                        })
                    );
                    if (url) {
                      router.push(url);
                    }
                  }}
                />
              </div>
              <Spacer vertical spacing={3} />
            </div>
            <div>
              <CourseSyllabusCardFrag
                current={module?.uuid ?? ''}
                course={myActiveCourse?.course}
                upTo={myActiveCourse?.upTo ?? undefined}
                completePercent={myActiveCourse?.progress?.percent}
              />
            </div>
          </div>
        </div>
      </div>
    </Page>
  );
};

export default createFragmentContainer(Module, {
  myActiveCourse: graphql`
    fragment Module_myActiveCourse on MyCourse {
      progress {
        percent
      }
      course {
        name
        syllabus {
          name
          type
          uuid
          ... on Module {
            syllabus {
              name
              uuid
              type
            }
          }
        }
        ...CourseSyllabusCardFrag_course
      }
      upTo
    }
  `,
  module: graphql`
    fragment Module_module on Module {
      name
      uuid
      voiceoverURL
      description
      transcript
      videoURL
    }
  `,
  user: graphql`
    fragment Module_user on User {
      firstName
      lastName
    }
  `
});
