export var practiceAreaOptions = [
  {
    title: 'Administrative and Public'
  },
  {
    title: 'Advertising & marketing'
  },
  {
    title: 'Advocacy'
  },
  {
    title: 'Agricultural'
  },
  {
    title: 'Aviation'
  },
  {
    title: 'Banking'
  },
  {
    title: 'Business affairs'
  },
  {
    title: 'Capital markets'
  },
  {
    title: 'Chancery'
  },
  {
    title: 'Charity'
  },
  {
    title: 'Corporate'
  },
  {
    title: 'Children'
  },
  {
    title: 'Civil liberties and human rights'
  },
  {
    title: 'Civil Litigation'
  },
  {
    title: 'Clinical negligence'
  },
  {
    title: 'Commercial'
  },
  {
    title: 'Commercial litigation'
  },
  {
    title: 'Commercial property'
  },
  {
    title: 'Company'
  },
  {
    title: 'Competition'
  },
  {
    title: 'Computer and IT'
  },
  {
    title: 'Construction and civil engineering'
  },
  {
    title: 'Consumer problems'
  },
  {
    title: 'Conveyancing residential'
  },
  {
    title: 'Corporate finance'
  },
  {
    title: 'Criminal Litigation'
  },
  {
    title: 'Criminal'
  },
  {
    title: 'Debt and money advice'
  },
  {
    title: 'Defamation'
  },
  {
    title: 'Dispute resolution'
  },
  {
    title: 'Education'
  },
  {
    title: 'Employment'
  },
  {
    title: 'Energy (including Oil and Gas)'
  },
  {
    title: 'Environmental'
  },
  {
    title: 'Extradition'
  },
  {
    title: 'European Community'
  },
  {
    title: 'Family'
  },
  {
    title: 'Financial and investment services'
  },
  {
    title: 'Fraud/ Business crime'
  },
  {
    title: 'FSA regulatory'
  },
  {
    title: 'General corporate'
  },
  {
    title: 'Immigration'
  },
  {
    title: 'Insolvency and bankruptcy'
  },
  {
    title: 'Insurance and Reinsurance'
  },
  {
    title: 'Intellectual Property'
  },
  {
    title: 'International (non EU)'
  },
  {
    title: 'Investment funds'
  },
  {
    title: 'Landlord and tenant'
  },
  {
    title: 'Licensing'
  },
  {
    title: 'Litigation'
  },
  {
    title: 'Local Government'
  },
  {
    title: 'Magisterial'
  },
  {
    title: 'Media and Entertainment'
  },
  {
    title: 'Media, Comms. and Technology'
  },
  {
    title: 'Mediation'
  },
  {
    title: 'Mental health and incapacity'
  },
  {
    title: 'Mergers and acquisitions'
  },
  {
    title: 'Military'
  },
  {
    title: 'Outsourcing'
  },
  {
    title: 'Pensions'
  },
  {
    title: 'Personal Injury'
  },
  {
    title: 'Planning'
  },
  {
    title: 'Private equity'
  },
  {
    title: 'Privacy/data protection'
  },
  {
    title: 'Professional negligence'
  },
  {
    title: 'Projects/project finance'
  },
  {
    title: 'Property'
  },
  {
    title: 'Regulatory and compliance'
  },
  {
    title: 'Shipping and Airways'
  },
  {
    title: 'Sports'
  },
  {
    title: 'Tax and Financial Planning'
  },
  {
    title: 'Trade and Export Finance '
  },
  {
    title: 'Transport, road and rail'
  },
  {
    title: 'Travel and tourism'
  },
  {
    title: 'Trusts'
  },
  {
    title: 'Welfare'
  },
  {
    title: 'Wills and Probate'
  },
  {
    title: 'Private client'
  },
  {
    title: 'Risk and Compliance'
  }
].map((x, i) => ({
  id: i + 1,
  title: x.title
})).sort((a, b) => a.title.localeCompare(b.title));
