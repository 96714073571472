/* tslint:disable */
/* eslint-disable */

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type CandidateType = "level1" | "level2" | "level3" | "%future added value";
export type CourseStatus = "complete" | "failed" | "incomplete" | "%future added value";
export type Dashboard_candidate = {
    readonly firstName: string;
    readonly skillGroupType: string | null;
    readonly skillGroupTypeConfirmed: boolean;
    readonly chatHistory: string | null;
    readonly candidateType: CandidateType;
    readonly areaProgress: ReadonlyArray<{
        readonly practiceArea: {
            readonly id: string;
        };
        readonly practiceSkillsDone: ReadonlyArray<{
            readonly id: string;
        }> | null;
        readonly practiceSkillsRemaining: ReadonlyArray<{
            readonly id: string;
        }> | null;
    }> | null;
    readonly myCourses: ReadonlyArray<{
        readonly progress: {
            readonly completed: number;
        } | null;
        readonly status: CourseStatus;
        readonly course: {
            readonly " $fragmentRefs": FragmentRefs<"CourseCard_course">;
        } | null;
    }> | null;
    readonly " $fragmentRefs": FragmentRefs<"PlacementGraph_candidate" | "Pathway_candidate" | "Roadmap_candidate" | "RobertChat_candidate">;
    readonly " $refType": "Dashboard_candidate";
};
export type Dashboard_candidate$data = Dashboard_candidate;
export type Dashboard_candidate$key = {
    readonly " $data"?: Dashboard_candidate$data;
    readonly " $fragmentRefs": FragmentRefs<"Dashboard_candidate">;
};



const node: ReaderFragment = (function () {
    var v0 = [
        ({
            "kind": "ScalarField",
            "alias": null,
            "name": "id",
            "args": null,
            "storageKey": null
        } as any)
    ];
    return {
        "kind": "Fragment",
        "name": "Dashboard_candidate",
        "type": "Candidate",
        "metadata": null,
        "argumentDefinitions": [],
        "selections": [
            {
                "kind": "ScalarField",
                "alias": null,
                "name": "firstName",
                "args": null,
                "storageKey": null
            },
            {
                "kind": "ScalarField",
                "alias": null,
                "name": "skillGroupType",
                "args": null,
                "storageKey": null
            },
            {
                "kind": "ScalarField",
                "alias": null,
                "name": "skillGroupTypeConfirmed",
                "args": null,
                "storageKey": null
            },
            {
                "kind": "ScalarField",
                "alias": null,
                "name": "chatHistory",
                "args": null,
                "storageKey": null
            },
            {
                "kind": "ScalarField",
                "alias": null,
                "name": "candidateType",
                "args": null,
                "storageKey": null
            },
            {
                "kind": "LinkedField",
                "alias": null,
                "name": "areaProgress",
                "storageKey": null,
                "args": null,
                "concreteType": "PracticeAreaProgress",
                "plural": true,
                "selections": [
                    {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "practiceArea",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "PracticeArea",
                        "plural": false,
                        "selections": (v0 /*: any*/)
                    },
                    {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "practiceSkillsDone",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "PracticeSkill",
                        "plural": true,
                        "selections": (v0 /*: any*/)
                    },
                    {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "practiceSkillsRemaining",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "PracticeSkill",
                        "plural": true,
                        "selections": (v0 /*: any*/)
                    }
                ]
            },
            {
                "kind": "LinkedField",
                "alias": null,
                "name": "myCourses",
                "storageKey": null,
                "args": null,
                "concreteType": "MyCourse",
                "plural": true,
                "selections": [
                    {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "progress",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "Progress",
                        "plural": false,
                        "selections": [
                            {
                                "kind": "ScalarField",
                                "alias": null,
                                "name": "completed",
                                "args": null,
                                "storageKey": null
                            }
                        ]
                    },
                    {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "status",
                        "args": null,
                        "storageKey": null
                    },
                    {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "course",
                        "storageKey": null,
                        "args": null,
                        "concreteType": null,
                        "plural": false,
                        "selections": [
                            {
                                "kind": "FragmentSpread",
                                "name": "CourseCard_course",
                                "args": null
                            }
                        ]
                    }
                ]
            },
            {
                "kind": "FragmentSpread",
                "name": "PlacementGraph_candidate",
                "args": null
            },
            {
                "kind": "FragmentSpread",
                "name": "Pathway_candidate",
                "args": null
            },
            {
                "kind": "FragmentSpread",
                "name": "Roadmap_candidate",
                "args": null
            },
            {
                "kind": "FragmentSpread",
                "name": "RobertChat_candidate",
                "args": null
            }
        ]
    } as any;
})();
(node as any).hash = '4ef22246cc20a8dd7e5285fc993ac3b1';
export default node;
