import * as React from 'react';
import Page from 'components/Page';
import { createFragmentContainer, graphql } from 'react-relay';
import { Tutorials_user } from './__generated__/Tutorials_user.graphql';
import { useRouter } from 'found';
import styled from 'styled-components';
import PageTitle from 'components/PageTitle';
import theme from 'helpers/theme';
import { tutorials } from 'helpers/tutorials';
import VideoCard from 'components/VideoCard';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;

const SubTitle = styled.span`
  font-weight: 300;
  font-size: 14px;
  color: ${theme.colors.textGrey};
  max-width: 500px;
  margin-top: 15px;
`;

const TutorialHolder = styled.div`
  display: grid;
  grid-gap: 26px;
  margin: ${theme.spacing(3)}px 0;
  grid-template-columns: repeat(auto-fit, 298px);
`;

type Props = {
  user?: Tutorials_user;
};

function Tutorials({ user }: Props){
  const { router } = useRouter();

  return (
    <Page>
      <Container>
        <PageTitle title="How To Tutorials"/>
        <SubTitle>
          {user?.firstName}, here you can see the tutorials to help you use pathways.
        </SubTitle>
        <TutorialHolder>
          {tutorials.map(t => (
            <VideoCard
              key={t.id}
              title={t.title}
              thumbnail={t.thumbnail}
              onClick={() => router.push(`/app/tutorial/${t.id}`)}
            />
          ))}
        </TutorialHolder>
      </Container>
    </Page>
  );
}

export default createFragmentContainer(Tutorials, {
  user: graphql`
    fragment Tutorials_user on User {
      firstName
    }
  `
});