import React from 'react';
import styled from 'styled-components';
import { animated } from 'react-spring/renderprops';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export type AlertType = 'warning' | 'error' | 'success';

const Container = styled(animated.div)`
  background: #1dbea5;
  padding: 9px;
  border-radius: 5px;
  z-index: 100000;
  box-shadow: 0px 3px 9px #00000021;
  color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 5px;
`;

const Text = styled.div`
  font-size: 14px;
  font-weight: 500;
  margin-left: 16px;
`;

const CloseButton = styled.div`
  padding: 3px 7px;
  border-radius: 5px;
  transition: 0.2s all;
  cursor: pointer;
  &:hover {
    background: #ffffff2e;
  }
`;

const ALERT_TYPES = {
  success: {
    background: '#25bf72',
  },
  warning: {
    background: 'rgb(255 183 44)',
  },
  error: {
    background: 'rgb(255 84 84)',
  },
};

type Props = {
  style?: any;
  text?: string;
  alertType?: AlertType;
  onClose?: () => void;
};

export function Toast({ style, text, onClose, alertType = 'success' }: Props) {
  return (
    <Container style={{ ...style, ...ALERT_TYPES[alertType] }}>
      <Text>{text}</Text>
      <CloseButton onClick={onClose}>
        <FontAwesomeIcon icon={faTimes} />
      </CloseButton>
    </Container>
  );
}
