import React, { useState, ChangeEvent } from 'react';
import { createUseStyles } from 'react-jss';
import classNames from 'classnames';

const styles = createUseStyles({
  defaultStyles: {
    border: 'none',
    outline: 'none',
    flex: 1,
  },
});

export type InputTypes =
  | 'text'
  | 'password'
  | 'email'
  | 'search'
  | 'tel'
  | 'number';
type Props = {
  type: InputTypes;
  className?: string;
  placeholder?: string;
  onChange: (text: string) => string | Promise<void> | void; // Function given changed text and should return what to update it to
  onFocus?: () => void;
  onBlur?: () => void;
  value?: string;
  disabled?: boolean;
  autocomplete?: string;
  autoFocus?: boolean;
};

const CoreInput = React.forwardRef(
  (
    {
      type,
      className,
      placeholder = '',
      onChange,
      onFocus,
      onBlur,
      value,
      disabled,
      autocomplete,
      autoFocus
    }: Props,
    ref: React.Ref<HTMLInputElement>,
  ) => {
    const classes = styles();

    const updateInput = (event: ChangeEvent<HTMLInputElement>) => {
      onChange(event.target.value);
    };

    return (
      <input
        type={autocomplete == "off" ? "text" : type}
        name={"coreinput" + Math.random()}
        className={classNames(className, classes.defaultStyles)}
        value={value}
        placeholder={placeholder}
        onChange={updateInput}
        ref={ref}
        onFocus={onFocus}
        onBlur={onBlur}
        disabled={disabled}
        autoComplete={autocomplete}
        autoFocus={autoFocus}
      />
    );
  },
);

export default CoreInput;
