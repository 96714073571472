import React from 'react';
import { Category } from '../FancyDropdown';
import styled from 'styled-components';
import Spacer from '../../Spacers/Spacer';
import Item from './Item';

const CategoryItem = styled.div``;

const Header = styled.div`
  font-weight: 700;
  font-size: 14px;
`;

const DropdownOption = styled.div`
  display: flex;
  padding: 7px 1px;
  align-items: center;
  border-radius: 6px;
  cursor: pointer;
  &:hover {
    background: #f8f9fb;
  }
`;

const OptionTextContainer = styled.div`
  display: flex;
  margin-left: 10px;
`;

const OptionText = styled.div<{ color?: string }>`
  font-size: 13px;
  font-weight: 700;
  color: ${(props) => props.color};
`;

type Props = {
  category: Category;
  onChange: (selected: string[]) => void;
  selected: string[]; // list of values
}

const FancyItem = ({
  category,
  onChange,
  selected,
}: Props) => (
  <Item>
    <CategoryItem>
      <Header>{category.name}</Header>
      <Spacer vertical spacing={1} />
      {category.options.map((option, i) => (
        <DropdownOption
          key={i}
          onClick={() => {
            if (selected.includes(option.value)) {
              onChange([
                ...selected.filter((item) => item != option.value)
              ]);
              return;
            }

            onChange([...selected, option.value]);
          }}
          style={
            selected.find((item) => item == option.value) ?
            {
              fontWeight: 'bold',
              backgroundColor: '#EEF3FF'
            }: {}
          }
        >
          <OptionTextContainer>
            <OptionText color={option.color}>
              {option.name}
            </OptionText>
          </OptionTextContainer>
        </DropdownOption>
      ))}
    </CategoryItem>
    {/* <Spacer vertical spacing={1} /> */}
  </Item>
);

export default FancyItem;