import React, { useCallback, useEffect, useState } from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import {
    addResponseMessage,
    addUserMessage,
    dropMessages,
    deleteMessages,
    isWidgetOpened,
    toggleWidget,
    isChatFinished,
    setChatFinished,
    Widget,
    setBadgeCount
} from 'components/RobertChat';
import { useToastDispatch } from 'components/Toast/ToastProvider';
import { makeMutation } from 'api/helpers';
import { CreateRobertQuestion } from 'api/mutations';
import { RecordSourceSelectorProxy } from 'relay-runtime';
import { RobertChat_candidate } from './__generated__/RobertChat_candidate.graphql';
import logo from 'assets/ai_avatar.png';

type Props = {
    candidate: RobertChat_candidate;
    onChatConfirmed: any;
};

function RobertChat({ candidate, onChatConfirmed }: Props) {
    const [messages, setMessages] = useState<Array<string>>([]);
    const dispatch = useToastDispatch();

    useEffect(() => {
        if (candidate.chatHistory != null && candidate.chatHistory?.length > 0) {
            // restore chat history
            resetRobertChat();
            const arr = candidate.chatHistory.split(",")

            setMessages(arr);

            // added first message manually
            addResponseMessage("Are you a qualified Solicitor?", "1", []);
            addUserMessage(["Yes", "No"][arr[1]])

            restoreChatHistory(arr, 0);
        } else {
            initialize();
            if (!isWidgetOpened() && !candidate.skillGroupTypeConfirmed) {
                toggleWidget();
            }
        }
    }, []);

    const restoreChatHistory = (arr: Array<string>, index: number) => {
        if (arr[index] === "" || arr[index] == null) return;

        const questionId = arr[index];
        const answerId = parseInt(arr[index + 1]);
        const chatHistory = "";
        makeMutation(
            CreateRobertQuestion,
            {
                questionId, answerId, chatHistory,
            },
            (store: RecordSourceSelectorProxy<{}>, data: any) => {

                const robertQuestion = data.createRobertQuestion;
                if (!robertQuestion) {
                    console.error('could not find question', data.createRobertQuestion);
                    return;
                }

                if (robertQuestion.id == "12-0") {
                    let msgs = robertQuestion.question.split("/");
                    addResponseMessage(msgs[0]);
                    addResponseMessage(msgs[1], robertQuestion.id, []);
                } else {
                    addResponseMessage(robertQuestion.question, robertQuestion.id, []);
                }
                if (robertQuestion.answers == null) {
                    if (candidate.skillGroupType == "Undecided") {
                        setChatFinished(2);
                    } else {
                        setChatFinished(1);
                    }
                    return;
                }
                const answer = parseInt(arr[index + 3]);
                addUserMessage(robertQuestion.answers[answer]);
                restoreChatHistory(arr, index + 2);
            }
        );
    }

    const handleCandidateAnswer = (questionId: string, answerId: number, chatHistory: string) => {
        let skillGroupType = "";
        if (questionId == "2") {
            if (answerId == 0)
                skillGroupType = "TC";
            if (answerId == 1)
                skillGroupType = "SQE";
            if (answerId == 2)
                skillGroupType = "Undecided";
        }

        if (questionId == "100") {
            if (answerId == 0) { // Yes
                initialize();
            } else if (answerId == 1) { // No
                if (messages.length == 1) {
                    initialize();
                } else {
                    var qId = messages[messages.length - 3];
                    var aId = messages[messages.length - 2];

                    deleteMessages(2);
                    messages.splice(messages.length - 2, 2);
                    handleCandidateAnswer(qId, Number(aId), "");
                }
            }
            return
        }
        // request AI question start
        makeMutation(
            CreateRobertQuestion,
            {
                questionId, answerId, chatHistory,
            },
            (store: RecordSourceSelectorProxy<{}>, data: any) => {
                const root = store.get('client:root');

                if (!root) {
                    console.error('could not get root');
                    return;
                }
                const user = root.getLinkedRecord('candidate');
                if (!user) {
                    console.log('Could not get user');
                    return;
                }

                const robertQuestion = data.createRobertQuestion;
                if (!robertQuestion) {
                    console.error('could not find question', data.createRobertQuestion);
                    return;
                }

                if (robertQuestion.question == "Confirmed") {
                    onChatConfirmed(true);
                    return;
                }

                if (questionId != "0") {
                    messages.push(String(answerId));
                }

                if (robertQuestion.answers == null) {
                    if (skillGroupType == "Undecided") {
                        setChatFinished(2);
                    } else {
                        setChatFinished(1);
                    }
                }
                if (robertQuestion.id == "12-0") {
                    let msgs = robertQuestion.question.split("/");
                    addResponseMessage(msgs[0]);
                    addResponseMessage(msgs[1], robertQuestion.id, robertQuestion.answers);
                } else {
                    addResponseMessage(robertQuestion.question, robertQuestion.id, robertQuestion.answers);
                }
                messages.push(robertQuestion.id);
            }
        );
    }

    const resetRobertChat = () => {
        dropMessages();
        messages.splice(0);
        setChatFinished(0);

        addResponseMessage('Hi ' + candidate.firstName + ', just a few questions before we start.');
    }

    const initialize = () => {
        resetRobertChat();
        handleCandidateAnswer("0", 0, "");
        onChatConfirmed(false);
    }

    const handleNewUserMessage = (newMessage: string) => {
        // Now send the message throught the backend API
        if (newMessage == "Back") {
            if (messages.length >= 5) {
                console.log(messages)
                var questionId = messages[messages.length - 5];
                var answerId = messages[messages.length - 4];
                if (messages[messages.length - 1] == "12-0") {
                    deleteMessages(4);
                } else {
                    deleteMessages(3);
                }
                messages.splice(messages.length - 4, 4);
                handleCandidateAnswer(questionId, Number(answerId), "");
                setChatFinished(0);
            } else if (messages.length > 1) {
                initialize();
            }
        }

        if (newMessage == "Restart") {
            addResponseMessage('You are about to change your qualification route. Please be aware your account will be reset. Are you sure you want to make this change?', "100", ["Yes", "No"]);
        }

        if (newMessage == "Confirm") {
            console.log("Confirmed:", newMessage, messages.toString())
            handleCandidateAnswer(newMessage, 0, messages.toString());
            toggleWidget();

            // refresh page
            setTimeout(() => window.location.reload(), 1800);
        }
    };

    return (
        <Widget
            handleNewUserMessage={handleNewUserMessage}
            profileAvatar={logo}
            handleCandidateAnswer={handleCandidateAnswer}
        />
    );
}

export default createFragmentContainer(RobertChat, {
    candidate: graphql`
        fragment RobertChat_candidate on Candidate {
            firstName
            skillGroupType
            skillGroupTypeConfirmed
            hiddenQualifications
            chatHistory
        }
    `
});