import * as React from 'react';
import { createUseStyles, useTheme } from 'react-jss';
import { Theme } from 'helpers/theme';
import classnames from 'classnames';

const useStyles = createUseStyles((theme: Theme) => ({
  headingRoot: {
    fontWeight: (props: { font: Font }) => props.font.weight,
    fontSize: (props: { font: Font }) => props.font.size,
    color: theme.colors.primaryBlack,
    marginTop: 0
  },
  headingRootNormal: {
    fontWeight: (props: { font: Font }) => props.font.weight,
    fontSize: (props: { font: Font }) => props.font.size,
    color: theme.colors.primaryBlack,
    marginTop: '5px',
    marginBottom: '5px'
  }
}));

type Props = {
  text: string;
  size: 'large' | 'medium';
  className?: string;
  normal?: boolean;
  [other: string]: any;
};

type Font = {
  size: number;
  weight: number;
};

const fonts: {
  [key: string]: Font;
} = {
  large: {
    size: 41,
    weight: 700
  },
  medium: {
    size: 20,
    weight: 600
  },
  small: {
    size: 15,
    weight: 600
  }
};

function Heading({ text, size, className , normal = false, ...props }: Props) {
  const theme = useTheme();

  const font = fonts[normal ? "small" :size];
  const classes = useStyles({ font, theme });

  return <h1 className={classnames(normal ? classes.headingRootNormal : classes.headingRoot, className)} {...props}>{text}</h1>;
}

export default Heading;
