import { DropdownOption } from 'components/core/Input/Dropdown/Dropdown';
import { Placement } from 'components/PlacementCard';
import SideModal from 'components/SideModal/SideModal';
import Tabs from 'components/SideModal/Tabs';
import React from 'react';
import Content, { State } from './Content';

type Props = {
  isOpen: boolean;
  candidateID: string;
  isCandidateTC: boolean;
  clients?: { id: string; title: string }[];
  onClose: () => void;
  prefillState?: Placement;
};

function PlacementModal({
  isOpen,
  candidateID,
  clients,
  onClose,
  prefillState,
  isCandidateTC
}: Props) {
  var selectedClient: DropdownOption | undefined = undefined;
  if (prefillState?.clientName) {
    clients?.forEach((client) => {
      if (client.title == prefillState?.clientName) {
        selectedClient = { id: client.id, title: client.title };
      }
    });
  }
  console.log(prefillState?.daysMissed);
  const initialState: State = {
    candidateID,
    isCandidateTC,
    saving: false,
    orgSRANumber: prefillState?.orgSRANumber || '',
    supervisorName: prefillState?.supervisorName || '',
    supervisorEmail: prefillState?.supervisorEmail || '',
    email: prefillState?.email || '',
    fullTime: prefillState?.fullTime ?? true,
    partTimeDays: prefillState?.partTimeDays,
    editing: prefillState !== undefined,
    ...prefillState,
    daysMissed: prefillState?.daysMissed ?? 0,
    qweDocURL: prefillState?.qweDocURL
  };

  return (
    <SideModal
      isOpen={isOpen}
      title={`${prefillState ? 'Edit' : 'Create'} Placement`}
      closeModal={onClose}
    >
      <Tabs
        content={[
          {
            key: `${prefillState ? 'Edit' : 'Create'} Placement`,
            component: Content
          }
        ]}
        closeModal={onClose}
        initialState={initialState}
      />
    </SideModal>
  );
}

export default PlacementModal;
