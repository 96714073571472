import * as React from 'react';
import { createUseStyles } from 'react-jss';
import { Theme } from 'helpers/theme';

const useStyles = createUseStyles((theme: Theme) => ({
  container: {
    overflow: 'hidden',
  },
  submit: {
    border: 'none',
    borderRadius: [0, 0, theme.buttonBorderRadius, theme.buttonBorderRadius],
    width: '100%',
    height: 50,
    color: 'white',
    fontSize: 20,
    fontWeight: 800,
    background: '#f37a21',
    transition: 'margin 0.1s ease',
    '&::-moz-focus-inner': {
      border: 0
    },
    cursor: 'pointer'
  },
  disabled: {
    pointerEvents: 'none',
    opacity: '0.5',
    background: '#CCC',
    width: '100%',
    color: 'white',
    transition: 'margin 0.1s ease',
    '&::-moz-focus-inner': {
      border: 0
    },
    cursor: 'pointer'
  }
}));

type Props = {
  text: string;
  disabled?: boolean;
  updateClass?: string;
};

function FancyButton({
  text,
  disabled,
  updateClass,
  ...props
}: Props & React.PropsWithoutRef<JSX.IntrinsicElements['input']>) {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <input {...props} type="submit" value={text} className={disabled ? classes.disabled : updateClass || classes.submit} />
    </div>
  );
}

export default FancyButton;
