import React, { useCallback, useState } from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import { SignoffGenerator_node } from './__generated__/SignoffGenerator_node.graphql';
import styled from 'styled-components';
import Spacer from 'components/core/Spacers/Spacer';
import moment from 'moment';
import { dropDownContentiousOptions } from 'views/Entry/Entry';
import theme from 'helpers/theme';
import Heading from 'components/core/Heading';
import { subSkillToCompetencies } from 'helpers/practiceSubSkills';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload } from '@fortawesome/free-solid-svg-icons';
import { Competency } from 'views/Entry/DropdownCompetencyQueryRenderer';
import { practiceAreaOptions } from 'helpers/practiceAreaOptions';

type Props = {
  node: SignoffGenerator_node;
};

const Container = styled.div`
  margin: 0;
  color: #383636;
`;

const Entry = styled.div`
`;

const Page = styled.div`
  display: flex;
  flex: 1;
  width: 8.5in;
  padding: 20px;
  box-sizing: border-box;
  page-break-inside: avoid;
  page-break-after: always;
  border-top: 6px solid #f27b21;
  flex-direction: column;
  background: white;
`;

const LogoContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Logo = styled.img`
  width: 200px;
`;

const BarItems = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const DateContainer = styled.div``;

const Date = styled.div`
  font-size: 14px; 
  font-weight: 700;
`;

const DateAndContentiousContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const Contentious = styled.div`
  font-size: 13px; 
  font-weight: 700;
`;
const ContentiousValue = styled.h1`
  font-size: 14px; 
  font-weight: 700;
  margin: 4px
`;
const TaskBy = styled.div` 
    font-size: 13px; 
    font-weight: 700;
`;
const TaskByName = styled.h1`
    font-size: 14px; 
    font-weight: 700;
    padding-left: 10px
`;

const Label = styled.div`
   
  font-size: 12px;
  text-transform: uppercase;
  font-weight: 800;
`;

const CandidateName = styled.div`
  font-size: 22px;
  font-weight: 700;
  color: #f27b21;
`;

const EntryName = styled.h1` ; 
  font-weight: 700;
  font-size: 13px;
`;

const EntryNameValue = styled.h1`
  font-size: 14px;
  font-weight: 700;
  padding-left: 10px
`;
const EntryItem = styled.div`
  background: white; 
  padding-bottom: ${theme.spacing(2)}px;  
`;
const EntryText = styled.p`
color: rgb(115, 121, 136);
  font-size: 11px;
  display: inline;
  white-space: pre-wrap;
  word-break: break-word;
  page-break-inside: auto;
`;

const Subheading = styled.h2`
  font-size: 16px;
  margin-bottom: ${theme.spacing(0)}px;
  page-break-inside: avoid;
`;

const AttachedDocument = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  & > h2 {
    font-size: 16px;
  }
  margin-top: ${theme.spacing(2)}px;
`;

const Text = styled.p`
  font-size: 13px;
  display: inline;
  white-space: pre-wrap;
  word-break: break-word;
  page-break-inside: auto;
`;

const Subskills = styled.div`
page-break-inside: avoid;
`;

const SubskillHeading = styled.div`
  font-size: 14px;
  color: #818794;
`;

const CompetencyContainer = styled.ul`
  list-style: none;
  list-style-position: inside;
  margin: ${theme.spacing(0)}px 0;
  padding: 0;
`;

const CompetencyItem = styled.li`
page-break-inside: avoid;
`;

const SkillContainer = styled.ul`
  list-style-position: inside;
  margin: ${theme.spacing(1)}px 0;
  padding-left: ${theme.spacing(2)}px;
`;

const SkillItem = styled.li`
  & > span {
    font-size: ${theme.fontSizes.default}px;
  }
`;

const SubskillContainer = styled.div`
  page-break-inside: avoid;
  flex-wrap: wrap;
`;

const SubskillItem = styled.div`
  font-size: 11px;
  background: white;
  color: rgb(115, 121, 136);
  padding: 5px 11px;
  border-radius: 6px;
  box-shadow: 0px 1px 3px #0000000f;
  border: 1px solid #ededed;
  margin: 6px;
  page-break-inside: avoid;
`;

const SubskillNormalItem = styled.div`
  font-size: 11px;
  background: white; 
  padding: 2px 11px;
  padding-right: 2px;
  border-radius: 6px;   
  page-break-inside: avoid;
  
`;
const PracticeSubItem = styled.div`
  font-size: 11px;
  background: white;
  color: rgb(115, 121, 136);
  padding: 5px 11px;
  border-radius: 6px;
  box-shadow: 0px 1px 3px #0000000f;
  border: 1px solid #ededed;
  margin: 6px;
  page-break-inside: avoid;
`;

const PracticeItem = styled.li`
  & > span {
    font-size: ${theme.fontSizes.default}px;
  }
`;
const ex = `The Journals, comprising most of Paul Blackburn’s final poems, is a milestone in the history of literary innovation, beyond the open field poetry of the BLACK MOUNTAIN SCHOOL and, earlier, vers libre (see PROSODY AND FREE VERSE) of IMAGISM. Blackburn wanted to create open-ended occasions out of ordinary, everyday experiences, and thereby to shape a form appearing to be, paradoxically, formless. He rejected traditional poetry’s point of view that saw certain historical events as grand or monumental and others as inconsequential and that, accordingly, insisted on poetry that followed rhetorical principles of argument first established in classical times and later reaffirmed in the Renaissance. He replaced syllogism with juxtaposition or contingency, logical deduction and inference with the "logic" of experience, such as what a person sees or hears; and he relied on nouns and verbs, not adjectives and adverbs, on metonyms, not metaphors or symbols. Likewise, Blackburn avoided standard meters and employed irregular spacing of words, characteristic of Ezra POUND, Charles OLSON and others. His demonstration that words could be used for their visual effects, often in conjunction with graphics, influenced later visual poetry such as Armand SCHWERNER’s THE TABLETS. Robert Buckeye notes how Blackburn’s "use of juxtaposition […] equalizes the elements of the poem: one thing, no matter how different, is just next to another" (157). Blackburn’s visuality might also have been influenced by downtown New York City painting during a period in the 1950s and 1960s when poets later to be grouped in various schools—BEAT, Black Mountain, and NEW YORK—were intermingling with each other, with painters and musicians as well.

Two other poets to be associated with The Journals are WILLIAM CARLOS WILLIAMS and Robert CREELEY. In its attention to the details of daily life, Blackburn’s poetry embraces and extends Williams’s renowned dictum, "no ideas but in things," a plea to ground poetry in concrete images, and provides another version of the precision celebrated in Creeley’s lines, "and and becomes // just so" in his homage to Williams ("For W.C.W"). Yet Blackburn aims for a new kind of poetry, as if the poem were a painting that refuses its frame; his is not simply the collage technique Pound made famous in THE CANTOS. Blackburn’s work can also be thought of within the context of CONFESSIONAL POETRY—the poet’s life available for viewing, the doors of his home flung open. In this regard, these poems are similar to the work of the New York school, especially Frank O’HARA ’s. As Peter Baker has commented, "So little [may] apparently [be] going on in [a Blackburn] poem that it may seem beneath our notice" (44); still, the experience of reading it is palpable as momentary occurrences become events and then rituals, "what O’Hara called his ‘I do this, I do that’ poems […]." Creating the impression of relaxed candor, Blackburn is able, ultimately, "to resist the pull toward transcendence that the Romantic and American post-Romantic traditions have forced on several generations of poets, readers and interpreters" (52).

On the other hand, this apparent spontaneity is hard won. His poems, Gilbert Sorrentino observes, "are journals only in that they purport to follow the events of the last four years of the poet’s life, but the selection of the important elements out of the sea of experience […] is rigorously formal" (103). Each poem, as if tossed off in a diary, has actually been worked by Blackburn to great effect. "AUG/22 . Berkeley Marina," for instance, begins with a simply contrastive observation: the day is cold and the sun is bright, the strong

wind holding the flags out
 
flap/flap
as the poet’s eye alights on the legs of his wife while she is exercising on the deck of their swaying boat. The couple is perfectly composed, recalling "the 3 graces & the 4 dignities" of ancient Chinese philosophy, which Blackburn sets out on the page as two lists placed beside one another, each encased within a simply drawn rectangle: "grace of word, / of deed, / grace of thought," and, "standing // sitting / walking // & lying down." How are these to be read? There is to be no prescribed method. What is important, however, are the two people "at peace" with the world. In this poem language, in and of itself, and phenomena, the world taken in by the poet, seamlessly merge as one through the graphics on the page.
Another key aspect of The Journals is its examination of dying. Blackburn learns that he has terminal cancer. His characteristic frankness becomes especially memorable when chronicling physical deterioration as the world begins to slip away. He is direct and graphic. "27. VI. 71," for example, records a morning’s thoughts, beginning with the exclamation "sundaysundaysundaysundaysunday," and then observing the essential elements of the day: "empty walks," a "single bird," a "blue sky." The enumeration leads to a crisis, "EMPTY AND ALIVE" repeated three times going down the page. Blackburn notes his simplest acts—fastening his belt, washing, writing in his diary—made difficult by pain. Yet this pain, in an apparent contradiction, makes the present vibrant. He notices "the promise of death" in the daylight spilling across the objects in his room; with the "window open, the day comes in, o fade the carcinoma […]." This blending of diction is remarkable—the contemporaneity of " carcinoma" juxtaposed with the Romantic apostrophe "o fade." Finally there is a bitter turn as he struggles to slip out of death’s trap, when he parodies the song "The Girl on the Flying Trapeze": the cancer "floats thru the blood / with the greatest of ease . the pain goes and comes again […]."

In The Journals no other but the present moment exists, fleeting yet permanent. The typical Blackburn poem, Baker has noted, "stands outside of time while foregrounding time itself" (45), an effect resulting from The Journals’ formless formality that was a breakthrough in new verse possibilities. Blackburn’s contribution is utterly original, as well as a stage in the evolution of experimental poetry. "We hear the echo in Blackburn," Joseph Conte writes, "of Olson’s statement in ‘Projective Verse’: ‘One perception must immediately and directly lead to a further perception’. The poetry of process is opposed to the notion of progress, and in Blackburn […] we hear a denial of telos, closure, or climax—‘any sense of an ending’. Each arrival signals a new departure" (48-49). Gloriously unrestricted, seemingly at loose ends, The Journals created, for later poetry, a new aesthetic sense of what a poetic statement could be. This posthumous work culminated the poetic project that had consumed Blackburn throughout his adult life, representing the ultimate refinement of his technique and the distillation of his vision.`;

function SignoffGenerator({ node }: Props) {
  const candidate = node.candidate;
  let isLarge = false 
  let total = 0

  let skills: {
    [id: string]: {
      size: number;
      comps: Competency[];
    }
  } = {};
  let areas: { [id: string]: { id: number; title: string; }[] } = {};
  node.entries?.edges?.forEach(entry => {
    if (entry) {
      const comps = subSkillToCompetencies(entry.practiceSubSkills);
      const size = comps.length > 0 ? comps.length + comps.
        map(c =>
          c.skills.length + c.skills.
            map(s => s.options.length).reduce((a, b) => a + b)
        ).reduce((a, b) => a + b) : 0;
      skills[entry.id] = {
        comps,
        size
      };
      total += size
      if (total >= 90) {
        isLarge = true
      }

      areas[entry.id] = practiceAreaOptions.filter(p => entry.practiceAreas?.includes(p.id));
    }
  });
  let nodeEntries = [...(node.entries?.edges || [])].sort((a, b) => {
    let aDate = moment(a?.startDate).unix()
    let bDate = moment(b?.startDate).unix()

    return aDate > bDate ? 1 : -1
  })

  const renderSkill = useCallback((comp: Competency, type: number) => {
    return <CompetencyItem key={comp.id}>
      <Heading text={comp.title} size="medium" normal={isLarge} style={{ fontWeight: 'bold' }} />
      <SkillContainer style={{
        listStyle: type == 1 ? "none" : "circle",
        margin: type == 1 ?  "0px" :"10px 0px"
      }}>
        {comp.skills.map(skill => (
          type == 1 ?
            <SkillItem key={skill.name}>
              <span>{skill.name}</span>
              <SubskillContainer>
                {skill.options.map(subskill => (
                  <SubskillNormalItem key={subskill.value}>{subskill.name}</SubskillNormalItem>
                ))}
              </SubskillContainer>
            </SkillItem>

            : <SkillItem key={skill.name}>
              <span>{skill.name}</span>
              <Spacer vertical spacing={1} />
              <SubskillContainer>
                {skill.options.map(subskill => (
                  <SubskillItem key={subskill.value}>{subskill.name}</SubskillItem>
                ))}
              </SubskillContainer>
            </SkillItem>
        ))}
      </SkillContainer>
    </CompetencyItem>
  }, [])

  const getSkills = useCallback((skillEntry: {
    size: number;
    comps: Competency[];
  } | null | undefined): Competency[] | null | undefined => {
    /* let comps: Competency[] = [

        {
          id: "0",
          title: "asd ajkdhjkas hdkjashd jkahdkjas hdkjashd ",
          skills: [
            {
              name: "skills bane ",
              options: [
                {
                  name: "skills bane options",
                  value: "skills bane options",
                  color: "red"
                }
              ]

            }
          ]
        }
      ]
  */
    return skillEntry?.comps
  }, [])

  const renderCompetency = useCallback((skills: {
    [id: string]: {
      size: number;
      comps: Competency[];
    }
  }, id) => { 
    let items = getSkills(skills[id]) || []
    return <CompetencyContainer>
      {items.map(comp => (
        renderSkill(comp, isLarge ? 1: 0)
      ))}
    </CompetencyContainer>
  }, [])
  return (
    <Container>
      {nodeEntries.map(entry => (
        <Entry key={entry?.id}>
          <Page>
            <LogoContainer>
              <Logo src={require(`../../assets/logo/accutrainee-logo-pad.png`)} />
              <div>
                <Label>Candidate:</Label>
                <CandidateName>{`${candidate?.firstName} ${candidate?.lastName}`}</CandidateName>
              </div>
            </LogoContainer>
            <Spacer vertical spacing={2} />
            <BarItems>
              <EntryName>
                Entry title
                <EntryNameValue>

                  {entry?.title}
                </EntryNameValue>
              </EntryName>
              {
                entry?.taskedBy ? <TaskBy>
                  Task by
                  <TaskByName>
                    {entry.taskedBy}
                  </TaskByName>
                </TaskBy> : null

              }
              <DateAndContentiousContainer>
                <DateContainer>
                  <Date>
                    {moment(entry?.startDate).format('DD/MM/YYYY')} to{' '}
                    {moment(entry?.endDate).format('DD/MM/YYYY')}
                  </Date>
                </DateContainer>
                <ContentiousValue>
                  <Contentious>
                    {entry?.contentious === 'both'
                      ? 'Contentious/Non Contentious'
                      : dropDownContentiousOptions.find(d => d.id === entry?.contentious)?.title
                    }
                  </Contentious>
                </ContentiousValue>
              </DateAndContentiousContainer>
            </BarItems>
            <Spacer vertical spacing={2} />
            {!!entry?.practiceAreas && (
              <>
                <PracticeItem>
                  <span>Practice Areas:</span>
                  <Spacer vertical spacing={1} />
                  {areas[entry.id].map((area) => (
                    isLarge ? <SubskillNormalItem key={area.id}>{area.title}</SubskillNormalItem> :
                     <PracticeSubItem key={area.id}>{area.title}</PracticeSubItem>
                  ))}
                </PracticeItem>
                <Spacer vertical spacing={2} />
              </>
            )} 
            <EntryItem style={{paddingBottom: isLarge ? 0 : 20}}>
              <Subheading>Details of the task/work completed</Subheading>
              <EntryText>{entry?.experience}</EntryText>
            </EntryItem>
            <Spacer vertical spacing={2} />
            <EntryItem style={{paddingBottom: isLarge ? 0 : 20}}>
              <Subheading>Reflections/What did I learn?</Subheading>
              <EntryText>{entry?.learnt}</EntryText>
            </EntryItem>            
            <Spacer vertical spacing={2} />
            <EntryItem style={{paddingBottom: isLarge ? 0 : 20}}>
              <Subheading>How can I improve?</Subheading>
              <EntryText>{entry?.moreExperience}</EntryText>
            </EntryItem>
            <Spacer vertical spacing={2} />
            {entry?.fileUrl && (
              <AttachedDocument>
                <h2>Attached Document</h2>
                <Spacer horizontal spacing={1} />
                <a href={entry.fileUrl}>
                  <FontAwesomeIcon icon={faDownload} />
                </a>
              </AttachedDocument>
            )}
            {
                  isLarge ?  <Spacer vertical spacing={1} /> :   <Spacer vertical spacing={2} />
            }          
            {entry?.practiceSubSkills && (
              <Subskills>
                <Label>Skills:</Label>
                <Spacer vertical spacing={1} />
                {renderCompetency(skills, entry.id)}
              </Subskills>
            )}
          </Page>
        </Entry>
      ))}
    </Container>
  );
}

export default createFragmentContainer(SignoffGenerator, {
  node: graphql`
    fragment SignoffGenerator_node on SignoffRequest {
      candidate {
        firstName
        lastName
      }
      entries {
        edges {
          id
          title
          startDate
          endDate
          experience
          evidence
          learnt
          moreExperience
          taskedBy
          contentious
          needMoreExperience
          fileUrl
          practiceAreas
          practiceSubSkills {
            practiceSkillGroup {
              practiceSkill {
                id
                name
              }
              name
            }
            id
            name
          }
        }
      }
    }
  `
});
