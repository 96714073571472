/* tslint:disable */
/* eslint-disable */
/* @relayHash 7189ca53c3d2ec7c21e6837f5f319f5b */

import { ConcreteRequest } from "relay-runtime";
export type helpersSignoffPDFQueryVariables = {
    id: string;
};
export type helpersSignoffPDFQueryResponse = {
    readonly signoffPDF: string | null;
};
export type helpersSignoffPDFQuery = {
    readonly response: helpersSignoffPDFQueryResponse;
    readonly variables: helpersSignoffPDFQueryVariables;
};



/*
query helpersSignoffPDFQuery(
  $id: ID!
) {
  signoffPDF(id: $id)
}
*/

const node: ConcreteRequest = (function () {
    var v0 = [
        ({
            "kind": "LocalArgument",
            "name": "id",
            "type": "ID!",
            "defaultValue": null
        } as any)
    ], v1 = [
        ({
            "kind": "ScalarField",
            "alias": null,
            "name": "signoffPDF",
            "args": [
                {
                    "kind": "Variable",
                    "name": "id",
                    "variableName": "id"
                }
            ],
            "storageKey": null
        } as any)
    ];
    return {
        "kind": "Request",
        "fragment": {
            "kind": "Fragment",
            "name": "helpersSignoffPDFQuery",
            "type": "Query",
            "metadata": null,
            "argumentDefinitions": (v0 /*: any*/),
            "selections": (v1 /*: any*/)
        },
        "operation": {
            "kind": "Operation",
            "name": "helpersSignoffPDFQuery",
            "argumentDefinitions": (v0 /*: any*/),
            "selections": (v1 /*: any*/)
        },
        "params": {
            "operationKind": "query",
            "name": "helpersSignoffPDFQuery",
            "id": null,
            "text": "query helpersSignoffPDFQuery(\n  $id: ID!\n) {\n  signoffPDF(id: $id)\n}\n",
            "metadata": {}
        }
    } as any;
})();
(node as any).hash = 'a2d92bb466db06a957860cd440c49ec6';
export default node;
