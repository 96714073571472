import * as React from 'react';
import classNames from 'classnames';
import Card from '../../components/core/Cards/Card';
import FancyInput from './FancyInput';
import FancyButton from './FancyButton';
import { createUseStyles, useTheme } from 'react-jss';
import { Theme } from 'helpers/theme';
import { ReactComponent as Logo } from '../../assets/logo/accutrainee-logo.svg';
import Spacer from 'components/core/Spacers/Spacer';

const useStyles = createUseStyles((theme: Theme) => ({
  loginDialogueRoot: {},
  root: {
    display: 'flex',
    width: 378,
    flexDirection: 'column',
    background: 'white'
  },
  logoContainer: {
    padding: [30, 0, 20]
  },
  logo: {
    height: 70
  },
  heading: {
    fontWeight: 800,
    color: theme.colors.primaryBlack
  },
  subheading: {
    color: theme.colors.textGrey,
    fontWeight: 300,
    fontSize: 15,
    marginTop: 0,
    marginBottom: theme.spacing(2)
  },
  link: {
    margin: '22px 0 19px 0',
    textAlign: 'center',
    color: theme.colors.textOrange,
    fontSize: theme.fontSizes.small
  },
  errMessage: {
    color: '#43454a',
    fontWeight: 200,
    fontSize: 15,
    textAlign: 'center',
    margin: 3
  },
  links: {
    marginTop: 8,
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%'
  },
  linkText: {
    fontSize: 15,
    letterSpacing: '-0.38',
    lineHeight: '25px',
    color: theme.colors.textOrange,
    textDecoration: 'none'
  },
  helpIcon: {
    cursor: 'pointer',
    color: theme.colors.textOrange,
    fontWeight: 'bold',
    fontSize: 21,
    lineHeight: `25px`,
    width: 32,
    height: 32,
    border: `1px solid rgba(16,129,170,0.17)`,
    marginRight: 14.5,
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 50
  },
  helpTooltipContainer: {
    position: 'absolute',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    filter: 'drop-shadow(5px 5px 2px rgba(0,0,0,0.15))'
  },
  helpTooltip: {
    position: 'relative',
    right: 50,
    width: '275px',
    borderRadius: '5px',
    padding: '15px',
    backgroundColor: theme.colors.primaryWhite,
    fontSize: theme.fontSizes.small,
    fontWeight: '400',
    color: theme.colors.textGrey,
    lineHeight: '15px',
    textAlign: 'center'
  },
  triangle: {
    zIndex: 1,
    position: 'relative',
    width: 0,
    height: 0,
    borderLeft: '10px solid transparent',
    borderRight: '10px solid transparent',
    borderTop: `20px solid ${theme.colors.primaryWhite}`,
    marginBottom: '130px'
  },
  smallLink: {
    color: theme.colors.textOrange
  },
  white: {
    color: theme.colors.primaryWhite
  },
  whiteBorder: {
    color: theme.colors.primaryWhite,
    border: `1px solid rgba(255,255,255,0.4)`
  },
  terms: {
    color: theme.colors.textGrey,
    fontSize: 12,
    marginTop: 0,
    marginBottom: theme.spacing(1)
  },
}));

type Props = {
  isManager?: boolean;
  onSubmit: (
    email: string,
    password: string,
    errorCallback: (err: string) => void
  ) => void;
  userIDLabel?: string;
};

function LoginDialogue({ onSubmit, isManager, userIDLabel = 'Email' }: Props) {
  const theme = useTheme();
  const classes = useStyles({ theme });

  const [hover, setHover] = React.useState(false);
  const [email, setEmail] = React.useState('');
  const [password, setPassword] = React.useState('');
  const [error, setError] = React.useState('');
  const onLogin = () => {
    onSubmit(email, password, (err) => {
      setError(err);
    });
  };
  return (
    <div className={classes.loginDialogueRoot}>
      <Card padding="medium" className={classes.root}>
        <div className={classes.logoContainer}>
          <Logo className={classes.logo} />
        </div>
        <h1 className={classes.heading}>Login to Pathways</h1>
        <p className={classes.subheading}>
          Please enter your login details to proceed
        </p>
        <p className={classes.errMessage}>{error}</p>
        <form
          onSubmit={(evt) => {
            evt.preventDefault();
            onLogin();
          }}
        >
          <FancyInput
            label={userIDLabel}
            labelColor={'#f37a21'}
            type={'text'}
            onChange={setEmail}
          />
          <FancyInput
            label="Password"
            labelColor={'#f37a21'}
            type={'password'}
            onChange={setPassword}
          />
          <FancyButton text="Login to Pathways" onClick={onLogin} />
        </form>
        <Spacer vertical spacing={1} />
        <p className={classes.terms}>
          By clicking Submit you agree to the Website{' '}
          <a href="https://accutrainee.com/terms-of-use" target={'_blank'}>Terms of Use</a>{' '}and{' '}
          <a href="https://accutrainee.com/privacy-policy" target={'_blank'}>Privacy Policy</a>
        </p>
      </Card>
      <div className={classes.links}>
        <a
          href={'mailto:admin@accutrainee.com'}
          className={classNames(classes.linkText, isManager && classes.white)}
        >
          <span
            className={classNames(
              classes.helpIcon,
              isManager && classes.whiteBorder
            )}
            onMouseEnter={() => setHover(true)}
            onMouseLeave={() => setHover(false)}
          >
            ?
            {hover && (
              <div className={classes.helpTooltipContainer}>
                <div className={classes.helpTooltip}>
                  <strong>Struggling to login?</strong> Don't worry, our
                  friendly team are here to help you recover your login details.
                  Feel free to contact{' '}
                  <span className={classes.smallLink}>
                      admin@accutrainee.com
                  </span>{' '}
                  for help
                </div>
                <div className={classes.triangle} />
              </div>
            )}
          </span>
          Need Help?
        </a>
        <a
          href="/password"
          className={classNames(classes.linkText, isManager && classes.white)}
        >
          Forgotten password?
        </a>
      </div>
    </div>
  );
}

export default LoginDialogue;
