/* tslint:disable */
/* eslint-disable */

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type UserType = "candidate" | "individual" | "manager" | "%future added value";
export type LoginPage_user = {
    readonly type: UserType;
    readonly firstName: string;
    readonly lastName: string;
    readonly " $refType": "LoginPage_user";
};
export type LoginPage_user$data = LoginPage_user;
export type LoginPage_user$key = {
    readonly " $data"?: LoginPage_user$data;
    readonly " $fragmentRefs": FragmentRefs<"LoginPage_user">;
};



const node: ReaderFragment = ({
    "kind": "Fragment",
    "name": "LoginPage_user",
    "type": "User",
    "metadata": null,
    "argumentDefinitions": [],
    "selections": [
        {
            "kind": "ScalarField",
            "alias": null,
            "name": "type",
            "args": null,
            "storageKey": null
        },
        {
            "kind": "ScalarField",
            "alias": null,
            "name": "firstName",
            "args": null,
            "storageKey": null
        },
        {
            "kind": "ScalarField",
            "alias": null,
            "name": "lastName",
            "args": null,
            "storageKey": null
        }
    ]
} as any);
(node as any).hash = '09d816bde2107b6aaaa1302698fb6d44';
export default node;
