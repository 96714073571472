import * as React from 'react';
import Page from 'components/Page';
import { tutorials } from 'helpers/tutorials';
import styled from 'styled-components';
import Heading from 'components/core/Heading';
import Video from 'components/Video/Video';
import Spacer from 'components/core/Spacers/Spacer';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;

type Props = {
  id: number | string;
};

function Tutorial({ id }: Props){
  const tutorial = tutorials.find(f => `${f.id}` === id);

  if (!tutorial) return <div>Unable to find tutorial</div>;

  return (
    <Page>
      <Container>
        <Video
          src={tutorial.videoLink}
          enableControls={true}
        />
        <Spacer vertical spacing={2} />
        <Heading text={tutorial.title} size="medium"/>
      </Container>
    </Page>
  );
}

export default Tutorial;