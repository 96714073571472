/* tslint:disable */
/* eslint-disable */

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type QWE_clients = {
    readonly edges: ReadonlyArray<{
        readonly id: string;
        readonly name: string;
    } | null> | null;
    readonly " $refType": "QWE_clients";
};
export type QWE_clients$data = QWE_clients;
export type QWE_clients$key = {
    readonly " $data"?: QWE_clients$data;
    readonly " $fragmentRefs": FragmentRefs<"QWE_clients">;
};



const node: ReaderFragment = ({
    "kind": "Fragment",
    "name": "QWE_clients",
    "type": "ClientPage",
    "metadata": null,
    "argumentDefinitions": [],
    "selections": [
        {
            "kind": "LinkedField",
            "alias": null,
            "name": "edges",
            "storageKey": null,
            "args": null,
            "concreteType": "Client",
            "plural": true,
            "selections": [
                {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "id",
                    "args": null,
                    "storageKey": null
                },
                {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "name",
                    "args": null,
                    "storageKey": null
                }
            ]
        }
    ]
} as any);
(node as any).hash = '8de06492276e0b9ec68875359e404914';
export default node;
