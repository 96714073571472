/* tslint:disable */
/* eslint-disable */
/* @relayHash 19a20eaa0cd2428815538dfe2596d6d0 */

import { ConcreteRequest } from "relay-runtime";
export type mutationsDeletePlacementMutationVariables = {
    id: string;
};
export type mutationsDeletePlacementMutationResponse = {
    readonly deletePlacement: boolean;
};
export type mutationsDeletePlacementMutation = {
    readonly response: mutationsDeletePlacementMutationResponse;
    readonly variables: mutationsDeletePlacementMutationVariables;
};



/*
mutation mutationsDeletePlacementMutation(
  $id: ID!
) {
  deletePlacement(input: {id: $id})
}
*/

const node: ConcreteRequest = (function () {
    var v0 = [
        ({
            "kind": "LocalArgument",
            "name": "id",
            "type": "ID!",
            "defaultValue": null
        } as any)
    ], v1 = [
        ({
            "kind": "ScalarField",
            "alias": null,
            "name": "deletePlacement",
            "args": [
                {
                    "kind": "ObjectValue",
                    "name": "input",
                    "fields": [
                        {
                            "kind": "Variable",
                            "name": "id",
                            "variableName": "id"
                        }
                    ]
                }
            ],
            "storageKey": null
        } as any)
    ];
    return {
        "kind": "Request",
        "fragment": {
            "kind": "Fragment",
            "name": "mutationsDeletePlacementMutation",
            "type": "Mutation",
            "metadata": null,
            "argumentDefinitions": (v0 /*: any*/),
            "selections": (v1 /*: any*/)
        },
        "operation": {
            "kind": "Operation",
            "name": "mutationsDeletePlacementMutation",
            "argumentDefinitions": (v0 /*: any*/),
            "selections": (v1 /*: any*/)
        },
        "params": {
            "operationKind": "mutation",
            "name": "mutationsDeletePlacementMutation",
            "id": null,
            "text": "mutation mutationsDeletePlacementMutation(\n  $id: ID!\n) {\n  deletePlacement(input: {id: $id})\n}\n",
            "metadata": {}
        }
    } as any;
})();
(node as any).hash = '017fb70f988a393db4751bed4d22acc3';
export default node;
