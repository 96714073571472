import React from 'react';
import styled from 'styled-components';

const Container = styled.li`
  height: 1px;
  background-color: EBECF0;
  pointer-events: none;
  padding: 0;
  margin: 4px 0; 
`;

const Divider = () => (
  <Container
    role='separator'
  />
);

export default Divider;
