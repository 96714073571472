import * as React from 'react';
import { createUseStyles } from 'react-jss';
import { Theme } from 'helpers/theme';
import Icon from 'components/core/Icon';
import CoreInput from 'components/core/Input/CoreInput';
import PaymentForm from 'components/SideModal/PaymentForm';
import PaymentSuccess from 'components/SideModal/PaymentSuccess';
import Button from 'components/core/Input/Button';
import { OnPurchase } from 'components/SideModal/PaymentForm/PaymentForm';
import environment, { FetchError } from 'api/environment';
import { commitMutation, graphql } from 'react-relay';
import { GraphError } from 'types/general';
import { Payment_PurchaseMutationResponse } from './__generated__/Payment_PurchaseMutation.graphql';

const useStyles = createUseStyles((theme: Theme) => ({
  paymentRoot: {},
  paymentItems: {
    marginBottom: 25,
    '& table': {
      width: '100%'
    },
    '& thead': {
      '& td': {
        padding: [10, 20],
        borderBottom: `1px solid ${theme.colors.borderGrey}`,
        '&:first-child': {
          paddingLeft: 0
        },
        '&:last-child': {
          paddingRight: 0
        }
      }
    },
    '& tbody': {
      '& td': {
        padding: [16, 20],
        borderBottom: `1px dashed #E9EBEB`,
        '&:first-child': {
          paddingLeft: 0
        },
        '&:last-child': {
          paddingRight: 0
        }
      }
    }
  },
  tableHeadText: {
    fontSize: theme.fontSizes.tiny,
    color: theme.colors.secondaryBlack,
    letterSpacing: -0.28,
    textTransform: 'uppercase'
  },
  productText: {
    fontSize: theme.fontSizes.default,
    letterSpacing: -0.35,
    color: theme.colors.secondaryBlack,
    display: 'block',
    fontWeight: 300
  },
  productSku: {
    display: 'block',
    fontSize: theme.fontSizes.default,
    letterSpacing: -0.35,
    color: '#CCCDCD'
  },
  summaryBox: {
    display: 'flex',
    justifyContent: 'space-between',
    backgroundColor: theme.colors.backgroundGrey,
    border: `1px solid #E9EBEB`,
    borderRadius: 4,
    padding: [20, 25]
  },
  summaryBoxRight: {
    flex: 1
  },
  cardInfo: {
    marginBottom: 42
  },
  summaryHeading: {
    fontSize: theme.fontSizes.default,
    fontWeight: 900,
    letterSpacing: -0.35,
    color: theme.colors.primaryBlack,
    lineHeight: '41px',
    margin: 0
  },
  inputWrapper: {
    marginTop: 11,
    backgroundColor: theme.colors.primaryWhite,
    borderRadius: 4,
    border: `1px solid ${theme.colors.borderGrey}`,
    padding: [8, 14],
    '& input': {
      width: '100%',
      height: '100%',
      padding: 0,
      border: 'none',
      fontSize: theme.fontSizes.default,
      color: theme.colors.secondaryBlack,
      lineHeight: `20px`,
      letterSpacing: -0.35
    }
  },
  summaryTable: {
    width: '100%'
  },
  summaryText: {
    color: theme.colors.secondaryBlack,
    fontSize: 15,
    lineHeight: `29px`
  },
  totalDueText: {
    color: '#f37a21',
    fontSize: 15,
    fontWeight: 800,
    lineHeight: `29px`
  },
  smallHeading: {
    fontSize: theme.fontSizes.smallHeading,
    color: theme.colors.primaryBlack,
    fontWeight: 900,
    letterSpacing: -0.6,
    marginBottom: 25,
    lineHeight: `41px`
  },
  cardContent: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  cardRight: {
    flex: 2,
    textAlign: 'right'
  },
  cardRightImage: {
    display: 'inline-block'
  },
  cardLeft: {
    fontSize: theme.fontSizes.tiny,
    color: theme.colors.secondaryBlack,
    lineHeight: `16px`,
    letterSpacing: -0.28,
    flex: 3
  },
  stripeImage: {
    width: 164,
    marginBottom: 14,
    height: 45,
    '& img': {
      width: '100% !important',
      height: 'auto !important'
    }
  },
  paymentImage: {
    width: 196,
    height: 36,
    '& img': {
      width: '100% !important',
      height: 'auto !important'
    }
  },
  breakLine: {
    height: 1,
    width: '100%',
    borderBottom: `1px dashed #E9EBEB`,
    marginTop: 24,
    marginBottom: 42
  },
  paymentPlace: {
    border: `1px solid #E9EBEB`,
    borderRadius: 4,
    backgroundColor: theme.colors.backgroundGrey,
    padding: [14.5, 17.5, 43.5, 20.5],
    marginBottom: 45
  },
  placeHeading: {
    fontSize: theme.fontSizes.default,
    color: theme.colors.primaryBlack,
    fontWeight: 900,
    letterSpacing: -0.35,
    lineHeight: '41px',
    margin: 0
  }
}));

export type Course = {
  id: number;
  instanceUUID?: string;
  name: string;
  sku: string;
  price: number;
};

const mutation = graphql`
  mutation Payment_PurchaseMutation(
    $courses: [Int!]
    $classroomInstances: [UUID!]
    $users: [UUID!]
    $extraEmail: String
  ) {
    purchaseCourses(
      input: {
        onlineCourses: $courses
        classroomInstances: $classroomInstances
        users: $users
        extraInvoiceEmail: $extraEmail
        acceptedTerms: true
        backgroundCheckConfirm: true
      }
    ) {
      transactionComplete
      stripeClientSecret
    }
  }
`;

type PurchaseCallback = (
  response: Payment_PurchaseMutationResponse,
  error?: GraphError
) => void;

const PurchaseCourses = (
  courses: number[] | undefined,
  classroomInstances: string[] | undefined,
  users: string[] | undefined,
  extraEmail: string,
  callback?: PurchaseCallback
) => {
  const variables = {
    classroomInstances,
    courses,
    users,
    extraEmail
  };

  if (!users) {
    if (callback)
      callback(
        { purchaseCourses: null },
        {
          message: 'No users given, cannot purchase course without users',
          path: []
        }
      );
  }

  if (!courses && !classroomInstances) {
    if (callback)
      callback(
        { purchaseCourses: null },
        { message: 'Please select at least one course to purchase', path: [] }
      );
  }

  commitMutation(environment, {
    mutation,
    variables,
    onCompleted: async (
      response: Payment_PurchaseMutationResponse,
      errors: GraphError[]
    ) => {
      if (errors) {
        // Display error
        if (callback) {
          callback(response, errors[0]);
        }
        return;
      }

      if (callback) {
        callback(response, undefined);
      }
      console.log('Response received from server.', response, errors);
    },
    onError: (err: FetchError) => {
      if (callback)
        callback(
          { purchaseCourses: null },
          {
            message: err.message,
            path: [],
            extensions: {
              type: err?.type === false || err?.type === true ? '' : err?.type,
              message: err.message
            }
          }
        );
    }
  });
};

export default function Payment({
  courses,
  userUUIDs,
  isContract,
  onSuccess,
  onError
}: {
  courses: Course[];
  userUUIDs?: string[];
  isContract: boolean;
  onSuccess: () => void;
  onError: (error: GraphError) => void;
}) {
  const classes = useStyles();
  const coursePrice = courses
    .map(({ price }: { price: number }) => price)
    .reduce(
      (prevValue: number, currentValue: number) => prevValue + currentValue,
      0
    );
  const [email, setEmail] = React.useState('');

  const onPurchase = (callback?: PurchaseCallback) =>
    PurchaseCourses(
      courses
        .filter((course: Course) => !course.instanceUUID)
        .map((course: Course) => course.id),
      courses
        .filter((course: Course) => !!course.instanceUUID)
        .map((course: Course) => course.instanceUUID ?? ''),
      userUUIDs,
      email,
      callback
    );

  const subTotal = userUUIDs
    ? coursePrice * (userUUIDs.length || 1)
    : coursePrice;
  const vat = Math.round(subTotal * (20 / 100) * 100) / 100;
  const totalDue = subTotal + vat;
  const requiresPayment = !isContract && totalDue > 0;
  return (
    <>
      <div className={classes.paymentRoot}>
        <div className={classes.paymentItems}>
          <table>
            <thead>
              <tr>
                <td colSpan={2}>
                  <span className={classes.tableHeadText}>PRODUCT</span>
                </td>
                <td colSpan={1}>
                  <span className={classes.tableHeadText}>QUANTITY</span>
                </td>
                <td colSpan={1}>
                  <span className={classes.tableHeadText}>PRICE</span>
                </td>
                <td colSpan={1}>
                  <span className={classes.tableHeadText}>TOTAL</span>
                </td>
              </tr>
            </thead>
            <tbody>
              {courses.map(({ name, sku, price }, index: string | number) => (
                <tr key={index}>
                  <td colSpan={2}>
                    <span className={classes.productText}>
                      <strong>{userUUIDs ? userUUIDs.length || 1 : 1}x </strong>
                      {name}
                    </span>
                    {/*<span className={classes.productSku}>SKU: {sku}</span>*/}
                  </td>
                  <td colSpan={1}>
                    <span className={classes.productText}>
                      {userUUIDs ? userUUIDs.length || 1 : 1}
                    </span>
                  </td>
                  <td colSpan={1}>
                    <span className={classes.productText}>
                      £{price.toFixed(2)}
                    </span>
                  </td>
                  <td colSpan={1}>
                    <span className={classes.productText}>
                      £{subTotal.toFixed(2)}
                    </span>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div className={classes.summaryBox}>
          <div className={classes.summaryBoxRight}>
            <p className={classes.summaryHeading}>Order Summary</p>
            <table className={classes.summaryTable}>
              <tbody>
                <tr>
                  <td>
                    <span className={classes.summaryText}>Sub Total:</span>
                  </td>
                  <td>
                    <span className={classes.summaryText}>
                      £{subTotal.toFixed(2)}
                    </span>
                  </td>
                </tr>
                <tr>
                  <td>
                    <span className={classes.summaryText}>VAT (20%):</span>
                  </td>
                  <td>
                    <span className={classes.summaryText}>
                      £{vat.toFixed(2)}
                    </span>
                  </td>
                </tr>
                <tr>
                  <td>
                    <span className={classes.totalDueText}>Total Due:</span>
                  </td>
                  <td>
                    <span className={classes.totalDueText}>
                      £{totalDue.toFixed(2)}
                    </span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div className={classes.breakLine} id="PaymentForm" />{' '}
        {requiresPayment && (
          <>
            <div className={classes.cardInfo}>
              <h1 className={classes.smallHeading}>Pay by Card</h1>
              <div className={classes.cardContent}>
                <div className={classes.cardLeft}>
                  Pay securely by debit/credit card. All payments are handled
                  securely using Stripe.
                </div>
                <div className={classes.cardRight}>
                  <div className={classes.cardRightImage}>
                    <div className={classes.stripeImage}>
                      <Icon name="Stripe" />
                    </div>
                    <div className={classes.paymentImage}>
                      <Icon name="Payments_Method" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
        <PaymentForm
          key={courses.map((course) => course.id).join(',')}
          onPurchase={onPurchase}
          onSuccess={onSuccess}
          onError={onError}
          requiresPayment={requiresPayment}
        />
      </div>
    </>
  );
}
