/* tslint:disable */
/* eslint-disable */
/* @relayHash c676acc2883a2ba6463d57d7c46c827a */

import { ConcreteRequest } from "relay-runtime";
export type helpersCourseQueryVariables = {
    id: number;
};
export type helpersCourseQueryResponse = {
    readonly course: {
        readonly ident: number;
        readonly name: string;
        readonly bannerImageURL: string | null;
        readonly introduction: string | null;
        readonly price: number;
        readonly specificTerms: string | null;
    } | null;
};
export type helpersCourseQuery = {
    readonly response: helpersCourseQueryResponse;
    readonly variables: helpersCourseQueryVariables;
};



/*
query helpersCourseQuery(
  $id: Int!
) {
  course(id: $id) {
    __typename
    ident: id
    name
    bannerImageURL
    introduction
    price
    specificTerms
  }
}
*/

const node: ConcreteRequest = (function () {
    var v0 = [
        ({
            "kind": "LocalArgument",
            "name": "id",
            "type": "Int!",
            "defaultValue": null
        } as any)
    ], v1 = [
        ({
            "kind": "Variable",
            "name": "id",
            "variableName": "id"
        } as any)
    ], v2 = ({
        "kind": "ScalarField",
        "alias": "ident",
        "name": "id",
        "args": null,
        "storageKey": null
    } as any), v3 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "name",
        "args": null,
        "storageKey": null
    } as any), v4 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "bannerImageURL",
        "args": null,
        "storageKey": null
    } as any), v5 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "introduction",
        "args": null,
        "storageKey": null
    } as any), v6 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "price",
        "args": null,
        "storageKey": null
    } as any), v7 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "specificTerms",
        "args": null,
        "storageKey": null
    } as any);
    return {
        "kind": "Request",
        "fragment": {
            "kind": "Fragment",
            "name": "helpersCourseQuery",
            "type": "Query",
            "metadata": null,
            "argumentDefinitions": (v0 /*: any*/),
            "selections": [
                {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "course",
                    "storageKey": null,
                    "args": (v1 /*: any*/),
                    "concreteType": null,
                    "plural": false,
                    "selections": [
                        (v2 /*: any*/),
                        (v3 /*: any*/),
                        (v4 /*: any*/),
                        (v5 /*: any*/),
                        (v6 /*: any*/),
                        (v7 /*: any*/)
                    ]
                }
            ]
        },
        "operation": {
            "kind": "Operation",
            "name": "helpersCourseQuery",
            "argumentDefinitions": (v0 /*: any*/),
            "selections": [
                {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "course",
                    "storageKey": null,
                    "args": (v1 /*: any*/),
                    "concreteType": null,
                    "plural": false,
                    "selections": [
                        {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "__typename",
                            "args": null,
                            "storageKey": null
                        },
                        (v2 /*: any*/),
                        (v3 /*: any*/),
                        (v4 /*: any*/),
                        (v5 /*: any*/),
                        (v6 /*: any*/),
                        (v7 /*: any*/)
                    ]
                }
            ]
        },
        "params": {
            "operationKind": "query",
            "name": "helpersCourseQuery",
            "id": null,
            "text": "query helpersCourseQuery(\n  $id: Int!\n) {\n  course(id: $id) {\n    __typename\n    ident: id\n    name\n    bannerImageURL\n    introduction\n    price\n    specificTerms\n  }\n}\n",
            "metadata": {}
        }
    } as any;
})();
(node as any).hash = '09de23981a04845f94c3e6e41a2953be';
export default node;
