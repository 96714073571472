import React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import styled from 'styled-components';
import { CourseCard_course } from './__generated__/CourseCard_course.graphql';
import { buildURL } from 'react-imgix';
import Button from 'components/core/Input/ButtonBase';
import Spacer from 'components/core/Spacers/Spacer';
import { useSideModalDispatch } from 'views/SideModalProvider';
import { useRouter } from 'found';
import theme from 'helpers/theme';
import CourseCompletion from 'components/core/CourseCompletion';
import moment from 'moment';

const Container = styled.div`
  background: white;
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  box-shadow: 0px 5px 15px #0000000f;
  width: 300px;
  overflow: hidden;
  position: relative;
  height: max-content;
  max-height: 120%;
`;

const Content = styled.div<{ hasProgress: boolean }>`
  padding: 22px;
  padding-bottom: ${(props) => (props.hasProgress ? 0 : 22)};
  display: flex;
  flex-direction: column;
`;

const BackgroundImage = styled.div<{ src: string }>`
  display: flex;
  align-items: center;
  cursor: pointer;
  height: 150px;
  background-image: url(${(props) => props.src});
  background-size: cover;
  background-position: center;
  position: relative;
`;

const Title = styled.div`
  margin-top: 60px;
  margin-left: 22px;
  margin-right: 22px;
  color: white;
  font-weight: 700;
  font-size: 20px;
  overflow: hidden;
  height: 150px;
`;

const CategoryTag = styled.div<{ color: string }>`
  position: absolute;
  top: 0;
  left: 0;
  background: ${(props) => props.color};
  padding: 6px 22px;
  border-bottom-right-radius: 8px;
  color: white;
  font-size: 12px;
  font-weight: 700;
`;

const Description = styled.div`
  font-size: 14px;
  font-weight: 300;
  height: 100px;
  overflow: hidden;
  position: relative;
`;

const DescriptionContainer = styled.div`
  position: relative;
`;

const MoreLessDescription = styled.label`
  position: absolute;
  top: 100%;
  right: 0px;
  color: #f37a21;
  text-decoration: solid underline #f37a21 1px;
  cursor: pointer;
  &::after {
    content: "Show More";
  }
`;

const DescriptionOverflow = styled.div`
  position: absolute;
  bottom: 0px;
  left: 0px;
  width: 100%;
  height: 50%;
  background: linear-gradient(0deg, white, #ffffffb5, #ffffff00);
`;
const HiddenCheckboxDescription = styled.input`
  display: none;
  &:checked ~ div {
    height: 100%;
    ${DescriptionOverflow} {
      background: #ffffff00;
    }
  }
  &:checked + ${MoreLessDescription}:after {
    content: "Show Less";
  }
`;


const CertButton = styled.a`
  background-color: #f37a21;
  color: white;
  text-decoration: none;
  border-radius: 6px;
  padding: 9px;
  font-size: 12px;
  font-weight: 400;
  justify-content: center;
  align-items: center;
  display: flex;
  cursor: pointer;
`;

const Progress = styled.div`
  color: ${theme.colors.primaryBlack};
  margin: 15px;
  font-size: ${theme.fontSizes.tiny}px;
`;

const Footer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0 ${theme.spacing(2)}px;
  background-color: ${theme.colors.backgroundGrey};
  border: 1px solid ${theme.colors.borderGrey};
  border-width: 1px 0 0 0;
  border-radius: 0 0 ${theme.primaryBorderRadius}px
    ${theme.primaryBorderRadius}px;
`;

const CompletedAt = styled.span`
  text-align: center;
  font-weight: bold;
  margin: ${theme.spacing(0)}px 0;
  width: 100%;
  font-size: ${theme.fontSizes.default}px;
`;

type Props = {
  course: CourseCard_course;
  isTaking?: boolean;
  certificateURL?: string | null;
  progress?: number;
  completedAt?: string | null;
};

function CourseCard({ course, isTaking, certificateURL, progress, completedAt }: Props) {
  const dispatch = useSideModalDispatch();
  const { router } = useRouter();

  const descriptionLength = course?.excerpt?.length;
  const onClick = () =>
    isTaking
      ? certificateURL
        ? () => {}
        : router.push(`/app/courses/${course?.ident}`)
      : dispatch({ modal: 'singleuser', courseId: course.ident });

  return (
    <Container
      onClick={() => isTaking && !certificateURL 
        ? router.push(`/app/courses/${course?.ident}`)
        : () => {}
      }
    >
      <BackgroundImage
        onClick={()=>{}}
        src={
          course?.bannerImageURL
            ? buildURL(course.bannerImageURL, { w: 300, h: 150 })
            : require(`../../assets/defaultImage.svg`)
        }
      >
        <div style={{ position: 'absolute', top: 0, left: 0, right: 0, bottom: 0, zIndex: 1, backgroundColor: 'rgba(0,0,0,0.3)' }} />
        <div style={{ zIndex: 10 }}>
          <CategoryTag color={course.category?.color ?? '#f37a21'}>
            {course?.category?.name}
          </CategoryTag>
          <Title>
            {course.name.length < 90
              ? course.name
              : `${course.name.slice(0, course.name[88] == ' ' ? 87 : 88)}...`}
          </Title>
        </div>
      </BackgroundImage>
      <Content hasProgress={progress != undefined}>
        {descriptionLength != undefined && descriptionLength > 200 ? 
          <DescriptionContainer>
            <HiddenCheckboxDescription id={`desc-${course.ident}`} type="checkbox"/>
            <MoreLessDescription htmlFor={`desc-${course.ident}`}/>
            <Description>
              {course.excerpt}
              <DescriptionOverflow />
            </Description> 
          </DescriptionContainer>
        : 
          <Description>
            {course.excerpt}
          </Description>
        }
        <Spacer vertical spacing={1} />
        <Spacer vertical spacing={2} />
        {progress == undefined &&
          (!certificateURL ? (
            <>
              <Button archetype="submit" onClick={() => {
                if (!isTaking && course.externalLink){
                  window.open(course.externalLink, '_blank');
                  return;
                }
                onClick();
              }}>
                {!isTaking && course.externalLink ? 
                  'More Information'
                  : `${isTaking ? 'Take' : 'Book'} Course` 
                }
              </Button>
            </>
          ) : (
            <CertButton href={certificateURL}>
              {`Download Certificate`}
            </CertButton>
          ))}
      </Content>
      {progress != undefined && (
        <Footer>
          <Progress>PROGRESS</Progress>
          <CourseCompletion
            complete={progress}
            total={100}
            width={125}
            fraction={false}
          />
        </Footer>
      )}
      {!progress && completedAt && (
        <Footer>
          <CompletedAt>Completed: {moment(completedAt).format('DD/MM/YYYY')}</CompletedAt>
        </Footer>
      )}
    </Container>
  );
}

export default createFragmentContainer(CourseCard, {
  course: graphql`
    fragment CourseCard_course on Course {
      ident: id
      name
      bannerImageURL
      externalLink
      excerpt
      category {
        color
        name
      }
    }
  `
});
