import React, { useRef } from 'react';
import { createUseStyles, useTheme } from 'react-jss';
import { Theme } from 'helpers/theme';
import Button from 'components/core/Input/ButtonBase';
import EasyInput from 'components/core/Input/EasyInput';
import Dropdown, { DropdownOption } from 'components/core/Input/Dropdown';
import { Body, Footer } from 'components/SideModal/Tabs';
import { useToastDispatch } from 'components/Toast/ToastProvider';
import { FileUpload, mutations_UpdateCandidateMutationResponse } from './__generated__/mutations_UpdateCandidateMutation.graphql';
import { UpdateCandidate } from './mutations';
import { CandidateInfo } from '.';
import styled from 'styled-components';
import UploadProfilePicture, { File } from './UploadProfilePicture';
import { Router, useRouter } from 'found';
import { commitMutation, graphql } from 'react-relay';
import environment from 'api/environment';
import { GraphError } from 'types/general';



const useStyles = createUseStyles((theme: Theme) => ({
  personalContainer: {
    display: 'flex',
    marginTop: theme.spacing(2),
    marginBottom: 35
  },
  heading: {
    marginBottom: theme.spacing(2),
    color: theme.colors.primaryBlack,
    fontSize: theme.fontSizes.heading,
    fontWeight: 800
  },
  profilePicContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '30%',
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: theme.spacing(3) //"5%"
  },
  editPicPencilIcon: {
    marginBottom: -30,
    marginRight: -65,
    zIndex: 15
  },
  editPhotoBtn: {
    marginTop: theme.spacing(1)
  },
  personalDetailContainer: {
    display: 'flex',
    flexDirection: 'column'
  },
  namesContainer: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: theme.spacing(2)
  },
  marginRight: {
    marginRight: theme.spacing(2)
  },
  marginBottom: {
    marginBottom: theme.spacing(2)
  },
  contactContainer: {
    display: 'flex',
    marginTop: theme.spacing(0),
    marginBottom: 35
  },
  emailInput: {
    marginRight: '7.5%',
    width: '40%'
  },
  telephoneInput: {
    width: '40%'
  },
  footerBtnsContainer: {
    display: 'flex'
  },
  submitBtn: {
    marginLeft: theme.spacing(2)
  },
  halfSize: {
    width: '50%'
  },
  input: {
    display: 'none'
  },
  dropdownWidth: {
    width: '168px'
  }
}));

export type State = {
  id: string;
  firstName?: string;
  lastName?: string;
  jobTitle?: string;
  email?: string;
  telephone?: string;
  profileImageUrl?: string;
  profileImage?: FileUpload;
  skillGroupType: string;
  tempSkillGroupType: string;
};

const SectionTitle = styled.div`
  color: #271507;
  font-size: 14px;
  margin-bottom: 10px;
`;

const MessageBox = styled.div`
  color: #271507;
  font-size: 14px;
  margin-bottom: 10px;
`;

const routes = [
  { id: "TC", title: "TC" },
  { id: "SQE", title: "SQE" }
]

type Props = {
  state: State;
  setState: (newState: object) => void;
  closeModal: () => void;
};


const mutation = graphql`
  mutation Content_ResetRequestMutation(
    $email: String!
    $role: UserType!
  ) {
    resetPasswordRequest(input: { email: $email, role: $role })
  }
`;

const AttemptRecovery = (router: Router, dispatch: any, setSent: (sent: boolean) => void) => (
  email: string,
  errorCallback: (err: string) => void
) => {
  // delegates cannot recover their password
  if (!email.includes('@')) {
    errorCallback('Contact your manager to reset password');
    return;
  }

  const variables = {
    email,
    role: 'candidate'
  };
  console.log(email);

  commitMutation(environment, {
    mutation,
    variables,
    onCompleted: (
      response: { delegateLogin: { token: string } },
      errors: GraphError[]
    ) => {
      if (errors) {
        // Display error
        errorCallback(`${errors[0]?.extensions?.message}`);
        return;
      }
      console.log('Response received from server.', response, errors);
      setSent(true);
      dispatch({
        text: 'A password change email has been successfully sent',
        alert: 'success',
        stayFor: 5000
      });
    },
    onError: (err) => console.error(err)
  });
};

function Content({ state, setState, closeModal }: Props) {
  const theme = useTheme();
  const classes = useStyles({ theme });
  const dispatch = useToastDispatch();

  const { router } = useRouter();
  const [sent, setSent] = React.useState(false);


  const onSubmit = AttemptRecovery(router, dispatch, setSent)
  const onRecover = () => {
    onSubmit(state.email || "", (err) => {
      console.log(err);
    });
  };
  const updateCandidate = (
    candidate: CandidateInfo,
    stateUpdate: (resp: CandidateInfo) => void
  ) =>
    UpdateCandidate(
      {
        id: candidate.id,
        firstName: candidate.firstName,
        lastName: candidate.lastName,
        jobTitle: candidate.jobTitle,
        email: candidate.email,
        skillGroupType: candidate.skillGroupType,
        telephone: candidate.telephone,
        profilePicture: candidate.profileImage
      },
      () => { },
      (resp: mutations_UpdateCandidateMutationResponse) => {
        stateUpdate({
          id: candidate.id,
          firstName: resp.updateCandidate?.firstName ?? '',
          lastName: resp.updateCandidate?.lastName ?? '',
          jobTitle: resp.updateCandidate?.jobTitle ?? '',
          email: resp.updateCandidate?.email ?? '',
          skillGroupType: resp.updateCandidate?.skillGroupType ?? '',
          telephone: resp.updateCandidate?.telephone ?? '',
          profileImageUrl: resp.updateCandidate?.profileImageUrl ?? '',
        });
      }
    );


  return (
    <>
      <Body>
        <span className={classes.heading}>Personal</span>
        <div className={classes.personalContainer}>
          <UploadProfilePicture
            title="Edit Photo"
            url={state.profileImageUrl}
            onUploaded={(file: File) => {
              setState((s: object) => ({
                ...s,
                profileImage: file.file,
                profileImageUrl: file.url,
              }));
            }}
            acceptType=".png, .jpg, .jpeg"
            onRemovedAvatar={()=>{
                setState((s: object) => ({
                  ...s,
                  profileImage: null,
                  profileImageUrl: null,
                }));
              }
            }
          />
          <div className={classes.personalDetailContainer}>
            <div className={classes.namesContainer}>
              <div className={classes.marginRight}>
                <EasyInput
                  label="First Name"
                  placeholder="To update please enter info on your Team Tailor account"
                  value={state.firstName}
                  disabled
                />
              </div>
              <div className={classes.marginRight}>
                <EasyInput
                  label="Last Name"
                  placeholder="To update please enter info on your Team Tailor account"
                  value={state.lastName}
                  disabled
                />
              </div>
            </div>
            <div className={classes.namesContainer}>
              <div className={classes.dropdownWidth}>
                <SectionTitle>Qualification Route</SectionTitle>
                <Dropdown
                  placeholder="Route"
                  options={routes ?? []}
                  setSelected={(drop) => {
                    dispatch({
                      text: 'You are about to change your qualification route. Please be aware your account will be reset. Are you sure you want to make this change?',
                      alert: 'error',
                      stayFor: 5000
                    });
                    if (state.tempSkillGroupType === undefined) {
                      setState((s: State) => ({ ...s, tempSkillGroupType: s.skillGroupType }));
                    }

                    setState((s: State) => ({ ...s, skillGroupType: drop.id }));
                  }}
                  selected={{ id: state.skillGroupType, title: state.skillGroupType }}
                />
              </div>
            </div>
          </div>
        </div>
        <span className={classes.heading}>Contact</span>
        <div className={classes.contactContainer}>
          <div className={classes.emailInput}>
            <EasyInput
              label="Email"
              type="email"
              placeholder="To update please enter info on your Team Tailor account"
              value={state.email}
              disabled
            />
          </div>
        </div>
        <div className={classes.contactContainer}>

          <form
            onSubmit={(evt) => {
              !sent && evt.preventDefault();
              !sent && onRecover();
            }}
          >
            <Button archetype="submit" disabled={sent}>Update password</Button>
          </form>
        </div>
      </Body>
      <Footer>
        <div />
        <div className={classes.footerBtnsContainer}>
          <Button archetype="default" onClick={() => closeModal()}>
            Cancel
          </Button>
          <Button
            archetype="submit"
            onClick={() => {
              const candidate = {
                id: state.id,
                firstName: state.firstName,
                lastName: state.lastName,
                jobTitle: state.jobTitle,
                email: state.email,
                skillGroupType: state.skillGroupType,
                telephone: state.telephone,
                profileImage: state.profileImage,
              };
              updateCandidate(candidate, (resp: CandidateInfo) => {
                setState((s: State) => ({
                  ...s,
                  firstName: resp.firstName,
                  lastName: resp.lastName,
                  jobTitle: resp.jobTitle,
                  email: resp.email,
                  skillGroupType: resp.skillGroupType,
                  telephone: resp.telephone,
                  profileImageUrl: resp.profileImageUrl
                }));

                closeModal();

                if (state.tempSkillGroupType && state.tempSkillGroupType !== resp.skillGroupType) {
                  window.location.reload();
                }
              }
              );
            }}
            className={classes.submitBtn}
          >
            Update
          </Button>
        </div>
      </Footer>
    </>
  );
}

export default Content;