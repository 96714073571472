import React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import { Page as _Page} from 'components/Pagination/Paginator';
import { SocialEvent_socialEvent } from './__generated__/SocialEvent_socialEvent.graphql';
import EventPage from 'components/EventPage';

type Props = {
  socialEvent: SocialEvent_socialEvent;
};

function SocialEvent({ socialEvent }: Props) {
  return (
   <div>
    {socialEvent && (
      <EventPage
        event={{
          ...socialEvent,
        }}
      />
    )}
    </div>
  );
}

export default createFragmentContainer(SocialEvent, {
  socialEvent: graphql`
    fragment SocialEvent_socialEvent on SocialEvent {
      id : id
      name
      title
      startDate
      endDate
      addressLine1
      addressLine2
      postCode
      bodyText
      imageURL
      userAttendanceStatus
  }
  `
});
