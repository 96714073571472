import React from 'react';
import moment from 'moment';
import { makeMutation } from 'api/helpers';
import { createFragmentContainer, graphql } from 'react-relay';
import { RecordSourceSelectorProxy } from 'relay-runtime';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen, faTrash } from '@fortawesome/free-solid-svg-icons';
import MediaQuery from 'react-responsive';
import styled from 'styled-components';
import Heading from 'components/core/Heading';
import Button from 'components/core/Input/ButtonBase';
import Spacer from 'components/core/Spacers/Spacer';
import Table from 'components/core/Table';
import ListSelector from 'components/ListSelector/ListSelector';
import Page from 'components/Page';
import { useRouter } from 'found';
import { Qualifications_candidate } from './__generated__/Qualifications_candidate.graphql';
import { qualificationOptions } from 'helpers/qualificationOptions';
import { DeleteQualification } from 'api/mutations';
import QualificationCard from 'components/QualificationCard';
import Text from 'components/core/Table/Text';
import DeletePopup from 'components/DeletePopup';
import { useSideModalDispatch } from '../../views/SideModalProvider';
import theme from 'helpers/theme';
import PageTitle from 'components/PageTitle';
import Tooltip from 'components/core/Tooltip';

type Props = {
  candidate: Qualifications_candidate;
};

const SubTitle = styled.div`
  font-weight: 300;
  font-size: 14px;
  color: ${theme.colors.textGrey};
  max-width: 500px;
  margin-top: 15px;
`;

const UploadContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const Actions = styled.div`
  display: flex;
`;

function Qualifications({ candidate }: Props) {
  const { match, router } = useRouter();
  const [isOpen, setIsOpen] = React.useState(false);
  const [qualToDelete, setQualToDelete] = React.useState({
    name: '',
    id: '',
    qualificationType: ''
  });
  const dispatch = useSideModalDispatch();

  const deleteQualification = async (id: string, qualificationType: string) => {
    try {
      await makeMutation(
        DeleteQualification,
        {
          id: id
        },
        (store: RecordSourceSelectorProxy<{}>) => {
          const root = store.get('client:root');
          if (!root) {
            console.error('could not get root');
            return;
          }
          const user = root.getLinkedRecord('candidate');
          if (!user) {
            console.log('Could not get user');
            return;
          }
          const qualifs = user.getLinkedRecords('qualifications', {
            qualificationType: qualificationType
          });
          if (!qualifs) {
            console.error('could not get qualifs');
            return;
          }
          const currentQualif = store.get(id);
          if (!currentQualif) {
            console.error('could not find qualif', id);
            return;
          }
          const deleteIndex = qualifs.indexOf(currentQualif);
          qualifs.splice(deleteIndex, 1);
          user.setLinkedRecords(qualifs, 'qualifications', {
            qualificationType: qualificationType
          });
        }
      );
    } catch (err) {
      console.log('Unable to delete qualification', err);
    }
    setIsOpen((prev) => !prev);
  };

  const tableColumns = [
    { headerName: 'NAME', field: 'name' },
    {
      headerName: 'Month and Year Completed',
      component: (item: any) => (
        <Text
          text={
            item.startDate ? moment(item.startDate).format('MM/YYYY') : '-'
          }
        />
      )
    },
    /*
    {
      headerName: 'COMPLETION DATE',
      component: (item: any) => (
        <Text
          text={item.endDate ? moment(item.endDate).format('DD/MM/YYYY') : '-'}
        />
      )
    },
    */
    { headerName: 'GRADE', field: 'grade' },
    {
      headerName: 'LINK TO RECORD',
      component: (item: any) => (
        <Button
          archetype="default"
          onClick={() => window.open(item.fileUrl, '_blank')}
        >
          Certificate
        </Button>
      )
    },
    {
      headerName: '',
      component: (item: any) => (
        <Actions>
          <FontAwesomeIcon
            icon={faPen}
            color={'#a4acb5'}
            style={{ cursor: 'pointer' }}
            onClick={() =>
              dispatch({
                modal: 'qualification',
                qualificationType: item.qualificationType,
                prefillQualification: item
              })
            }
          />
          <Spacer horizontal spacing={2} />
          <FontAwesomeIcon
            icon={faTrash}
            color={'#a4acb5'}
            style={{ cursor: 'pointer' }}
            onClick={() => {
              setQualToDelete(item);
              setIsOpen((prev) => !prev);
            }}
          />
        </Actions>
      )
    }
  ];
  return (
    <Page>
      <PageTitle title="Qualifications" />
      <SubTitle>
        Safely and securely store all your qualification documents in one
        convenient place. No more frantically running around trying to find that
        missing A Level certificate anymore!
      </SubTitle>
      <Spacer vertical spacing={4} />
      <ListSelector
        items={qualificationOptions.map((opt, i) => ({
          text: opt.text,
          value: opt.value,
          component: (
            <Tooltip
              element={<span>{opt.text}</span>}
              text={opt.tooltipText}
              position="top"
            />
          )
        }))}
        skillGroupType={candidate.skillGroupType}
        hiddenQualifications={candidate.hiddenQualifications}
        selected={match.params.qualType || qualificationOptions[0].value}
        onChange={(item) => {
          router.push(`/app/qualifications/${item.value}`);
        }}
      />
      <Spacer vertical spacing={2} />
      <UploadContainer>
        <Button
          archetype="submit"
          onClick={() =>
            dispatch({
              modal: 'qualification',
              qualificationType: match.params.qualType
            })
          }
        >
          Upload Document
        </Button>
      </UploadContainer>
      <Spacer vertical spacing={2} />
      <MediaQuery minWidth={1000}>
        <Table
          columns={tableColumns}
          rows={candidate.qualifications?.map((qual) => qual) ?? []}
        />
      </MediaQuery>
      <MediaQuery maxWidth={1000}>
        {candidate.qualifications?.map((qual) => (
          <div key={qual.id}>
            <QualificationCard
              qualification={{
                ...qual,
                startDate: qual.startDate || undefined,
                endDate: qual.endDate || undefined,
                grade: qual.grade || undefined
              }}
              onEdit={() =>
                dispatch({
                  modal: 'qualification',
                  qualificationType: qual.qualificationType,
                  prefillQualification: {
                    ...qual,
                    startDate: qual.startDate || undefined,
                    endDate: qual.endDate || undefined,
                    grade: qual.grade || undefined
                  }
                })
              }
              onDelete={() => {
                setQualToDelete(qual);
                setIsOpen((prev) => !prev);
              }}
            />
            <Spacer vertical spacing={2} />
          </div>
        ))}
      </MediaQuery>
      <DeletePopup
        open={isOpen}
        name={qualToDelete.name}
        onClose={() => setIsOpen((prev) => !prev)}
        onDelete={() =>
          deleteQualification(qualToDelete.id, qualToDelete.qualificationType)
        }
      />
    </Page>
  );
}

export default createFragmentContainer(Qualifications, {
  candidate: graphql`
    fragment Qualifications_candidate on Candidate
      @argumentDefinitions(qualType: { type: "QualificationType!" }) {
        skillGroupType        
        hiddenQualifications
        qualifications(qualificationType: $qualType) {
          id
          name
          fileUrl
          startDate
          endDate
          grade
          qualificationType
        }
    }
  `
});
