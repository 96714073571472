import * as React from 'react';
import { QueryRenderer, graphql } from 'react-relay';
import environment from 'api/environment';
import styled from 'styled-components';
import { SignoffPageQueryResponse } from './__generated__/SignoffPageQuery.graphql';
import moment from 'moment';
import SignoffRequestCard from 'components/SignoffRequestCard';
import Paginator, { Page } from 'components/Pagination/Paginator';
import Spacer from 'components/core/Spacers/Spacer';

const SignoffPageRow = styled.div`
  display: grid;
  grid-gap: 20px;
  grid-template-columns: repeat(auto-fill, 268px);
  @media (max-width: 1300px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media (max-width: 550px) {
    grid-template-columns: repeat(1, 1fr);
  }
  padding-bottom: 20px;
`;

type Props = {
  placementID: string;
};

const OFFSET = 0;
const LIMIT = 10;

function SignoffPage({ placementID }: Props) {
  const [offset, setOffset] = React.useState(OFFSET);
  const [limit, setLimit] = React.useState(LIMIT);

  React.useEffect(() => {
    setOffset(OFFSET);
    setLimit(LIMIT);
  }, [placementID]);

  const onUpdatePage = ({ offset, limit }: Page) => {
    if (offset <= 0) {
      offset = 1;
    }

    setLimit(limit);
    setOffset((offset - 1) * limit);
  };

  return (
    <QueryRenderer
      environment={environment}
      query={graphql`
        query SignoffPageQuery($placementID: ID, $limit: Int!, $offset: Int!) {
          signoffRequests(
            filter: { placementID: $placementID }
            page: { limit: $limit, offset: $offset }
          ) {
            edges {
              id
              title
              createdAt
              approval
              PDFURL
              entries {
                edges {
                  id
                }
              }
            }
            pageInfo {
              total
              limit
              offset
            }
          }
        }
      `}
      variables={{
        placementID: placementID,
        limit: limit,
        offset: offset
      }}
      render={({ error, props }) => {
        if (!props) {
          return <div></div>;
        }
        const { signoffRequests } = props as SignoffPageQueryResponse;
        const page = {
          currentPage:
            signoffRequests?.pageInfo != null
              ? Math.ceil(offset / limit) + 1
              : 1,
          numPages:
            signoffRequests?.pageInfo != null
              ? Math.ceil(signoffRequests?.pageInfo?.total / limit)
              : 1
        };

        if (signoffRequests?.edges?.length != null) {
          if (signoffRequests?.edges?.length === 0) {
            return <div>No requests found for this placement.</div>;
          }
        }

        return (
          <div>
            <SignoffPageRow>
              {signoffRequests?.edges?.map((signoffRequest: any) => (
                <SignoffRequestCard
                  entriesLength={signoffRequest?.entries?.edges?.length || '0'}
                  createdAt={
                    moment(signoffRequest?.createdAt).format('Do MMM YY') || ''
                  }
                  approval={signoffRequest?.approval}
                  title={signoffRequest?.title}
                  PDFURL={signoffRequest?.PDFURL || ''}
                  signoffRequestID={signoffRequest?.id}
                />
              ))}
            </SignoffPageRow>
            <Paginator
              currentPage={page.currentPage ?? 1}
              updatePage={onUpdatePage}
              numPages={page.numPages ?? 1}
              itemsPerPage={limit}
              showRange={page.numPages} //{(page.numPages ?? 1) > 4 ? 4 : page.numPages ?? 1}
            />
            <Spacer vertical spacing={1} />
          </div>
        );
      }}
    />
  );
}

export default SignoffPage;
