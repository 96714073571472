import * as React from 'react';
import styled from 'styled-components';
import Spacer from 'components/core/Spacers/Spacer';
import OtherQWE from './OtherQWE';
import Portfolio from './Portfolio';
import theme from 'helpers/theme';

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const SubTitle = styled.div`
  font-weight: 300;
  font-size: 14px;
  color: ${theme.colors.textGrey};
  max-width: 600px;
`;

type Props = {
  candidateID: string;
};

function PortfolioPage({ candidateID }: Props) {
  React.useEffect(() => {}, [candidateID]);

  return (
    <Container>
      <SubTitle>
        Safely and securely record all your work experience in your very own
        portfolio. Remember not to add any confidential information at any
        point!
      </SubTitle>
      <Spacer vertical spacing={3} />
      <Portfolio candidateID={candidateID} />
      <Spacer vertical spacing={2} />
    </Container>
  );
}

export default PortfolioPage;
