/* tslint:disable */
/* eslint-disable */
/* @relayHash 1b3f8fe2313dd2f6b2ff96af559f42fe */

import { ConcreteRequest } from "relay-runtime";
export type SingleUser_categories_QueryVariables = {};
export type SingleUser_categories_QueryResponse = {
    readonly categories: {
        readonly edges: ReadonlyArray<{
            readonly name: string;
            readonly uuid: string | null;
            readonly classroomOnly: boolean;
            readonly secondaryCategories: ReadonlyArray<{
                readonly uuid: string | null;
                readonly name: string;
                readonly hasCourses: boolean;
            } | null> | null;
        } | null> | null;
    } | null;
};
export type SingleUser_categories_Query = {
    readonly response: SingleUser_categories_QueryResponse;
    readonly variables: SingleUser_categories_QueryVariables;
};



/*
query SingleUser_categories_Query {
  categories {
    edges {
      name
      uuid
      classroomOnly
      secondaryCategories {
        uuid
        name
        hasCourses
      }
    }
  }
}
*/

const node: ConcreteRequest = (function () {
    var v0 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "name",
        "args": null,
        "storageKey": null
    } as any), v1 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "uuid",
        "args": null,
        "storageKey": null
    } as any), v2 = [
        ({
            "kind": "LinkedField",
            "alias": null,
            "name": "categories",
            "storageKey": null,
            "args": null,
            "concreteType": "CategoryPage",
            "plural": false,
            "selections": [
                {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "edges",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "Category",
                    "plural": true,
                    "selections": [
                        (v0 /*: any*/),
                        (v1 /*: any*/),
                        {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "classroomOnly",
                            "args": null,
                            "storageKey": null
                        },
                        {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "secondaryCategories",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "SecondaryCategory",
                            "plural": true,
                            "selections": [
                                (v1 /*: any*/),
                                (v0 /*: any*/),
                                {
                                    "kind": "ScalarField",
                                    "alias": null,
                                    "name": "hasCourses",
                                    "args": null,
                                    "storageKey": null
                                }
                            ]
                        }
                    ]
                }
            ]
        } as any)
    ];
    return {
        "kind": "Request",
        "fragment": {
            "kind": "Fragment",
            "name": "SingleUser_categories_Query",
            "type": "Query",
            "metadata": null,
            "argumentDefinitions": [],
            "selections": (v2 /*: any*/)
        },
        "operation": {
            "kind": "Operation",
            "name": "SingleUser_categories_Query",
            "argumentDefinitions": [],
            "selections": (v2 /*: any*/)
        },
        "params": {
            "operationKind": "query",
            "name": "SingleUser_categories_Query",
            "id": null,
            "text": "query SingleUser_categories_Query {\n  categories {\n    edges {\n      name\n      uuid\n      classroomOnly\n      secondaryCategories {\n        uuid\n        name\n        hasCourses\n      }\n    }\n  }\n}\n",
            "metadata": {}
        }
    } as any;
})();
(node as any).hash = '5b4187ca8f0d088cbfe0a0387782160a';
export default node;
