import moment from "moment-timezone"
export const getTimeFromString = (timeString: String) => {
    let hour = parseInt(timeString.split(":")[0]),
      min = parseInt(timeString.split(":")[1]);
    return new Date().setHours(hour, min, 0, 0);
};

export const getDateFromDateTimeString = (dateString: Date | string, timeString: string) => {
  // Convert the date string to a moment object and set the time zone to "Europe/London"
  const londonMoment = moment.tz(dateString, 'Europe/London');
  const safariFixTimeString = moment(timeString, 'hh:mm A').format('HH:mm');
  const combinedString = `${londonMoment.format('YYYY')}-${londonMoment.format('MM')}-${londonMoment.format('DD')} ${safariFixTimeString}`;
  console.log('combinedString', combinedString);
  window.moment = moment;
  const isoString = moment.tz(combinedString, 'Europe/London').toISOString();
  return isoString;

}

export const getTimeStringDate = (dateString:Date | string) => {

  const date = new Date(dateString);

  // Check if the date is valid
  // if (date) {
  //   console.error("Invalid date string:", dateString);
  //   return null; // or handle the error in some way
  // }

  // Format hours and minutes
  const hours = date.getHours().toString().padStart(2, '0');
  const minutes = date.getMinutes().toString().padStart(2, '0');

  const timeString = `${hours}:${minutes}`;
  return timeString;
}

export const formatDateString = (inputDateString: string) => {
  console.log('formatDateString', inputDateString)
  return moment.tz(inputDateString, 'Europe/London').format('DD/MM/YYYY hh:mm A');
  // const date = new Date(inputDateString);
  
  // const options : Intl.DateTimeFormatOptions = {
  //   timeZone: 'Europe/London',
  //   day: '2-digit',
  //   month: '2-digit',
  //   year: 'numeric',
  //   hour: '2-digit',
  //   minute: '2-digit',
  //   hour12: true,
  // };

  // return date.toLocaleString('en-GB', options);
}


export const formatMonthYearString = (inputDateString: Date | string) => {
  const inputDate = new Date(inputDateString);

  const options: Intl.DateTimeFormatOptions = {
    // timeZone: 'Europe/London',
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
  };

  const formattedDate = inputDate.toLocaleString('en-GB', options);
  return formattedDate;
}
  