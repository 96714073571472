/* tslint:disable */
/* eslint-disable */
/* @relayHash b0db9af869916ae849ce6451acf584e8 */

import { ConcreteRequest } from "relay-runtime";
export type MeetingRequestType = "appraisal" | "monthly" | "tpm" | "%future added value";
export type mutationsSubmitMeetingRequestMutationVariables = {
    mentorID: string;
    availabilitySlotID?: string | null;
    availableTime?: string | null;
    type: MeetingRequestType;
};
export type mutationsSubmitMeetingRequestMutationResponse = {
    readonly submitMeetingRequest: boolean;
};
export type mutationsSubmitMeetingRequestMutation = {
    readonly response: mutationsSubmitMeetingRequestMutationResponse;
    readonly variables: mutationsSubmitMeetingRequestMutationVariables;
};



/*
mutation mutationsSubmitMeetingRequestMutation(
  $mentorID: ID!
  $availabilitySlotID: ID
  $availableTime: Time
  $type: MeetingRequestType!
) {
  submitMeetingRequest(input: {mentorID: $mentorID, availabilitySlotID: $availabilitySlotID, availableTime: $availableTime, type: $type})
}
*/

const node: ConcreteRequest = (function () {
    var v0 = [
        ({
            "kind": "LocalArgument",
            "name": "mentorID",
            "type": "ID!",
            "defaultValue": null
        } as any),
        ({
            "kind": "LocalArgument",
            "name": "availabilitySlotID",
            "type": "ID",
            "defaultValue": null
        } as any),
        ({
            "kind": "LocalArgument",
            "name": "availableTime",
            "type": "Time",
            "defaultValue": null
        } as any),
        ({
            "kind": "LocalArgument",
            "name": "type",
            "type": "MeetingRequestType!",
            "defaultValue": null
        } as any)
    ], v1 = [
        ({
            "kind": "ScalarField",
            "alias": null,
            "name": "submitMeetingRequest",
            "args": [
                {
                    "kind": "ObjectValue",
                    "name": "input",
                    "fields": [
                        {
                            "kind": "Variable",
                            "name": "availabilitySlotID",
                            "variableName": "availabilitySlotID"
                        },
                        {
                            "kind": "Variable",
                            "name": "availableTime",
                            "variableName": "availableTime"
                        },
                        {
                            "kind": "Variable",
                            "name": "mentorID",
                            "variableName": "mentorID"
                        },
                        {
                            "kind": "Variable",
                            "name": "type",
                            "variableName": "type"
                        }
                    ]
                }
            ],
            "storageKey": null
        } as any)
    ];
    return {
        "kind": "Request",
        "fragment": {
            "kind": "Fragment",
            "name": "mutationsSubmitMeetingRequestMutation",
            "type": "Mutation",
            "metadata": null,
            "argumentDefinitions": (v0 /*: any*/),
            "selections": (v1 /*: any*/)
        },
        "operation": {
            "kind": "Operation",
            "name": "mutationsSubmitMeetingRequestMutation",
            "argumentDefinitions": (v0 /*: any*/),
            "selections": (v1 /*: any*/)
        },
        "params": {
            "operationKind": "mutation",
            "name": "mutationsSubmitMeetingRequestMutation",
            "id": null,
            "text": "mutation mutationsSubmitMeetingRequestMutation(\n  $mentorID: ID!\n  $availabilitySlotID: ID\n  $availableTime: Time\n  $type: MeetingRequestType!\n) {\n  submitMeetingRequest(input: {mentorID: $mentorID, availabilitySlotID: $availabilitySlotID, availableTime: $availableTime, type: $type})\n}\n",
            "metadata": {}
        }
    } as any;
})();
(node as any).hash = 'e1491b9cadead9c78de35ba1a8680fdb';
export default node;
