/* tslint:disable */
/* eslint-disable */

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type CandidateType = "level1" | "level2" | "level3" | "%future added value";
export type Weekday = "friday" | "monday" | "saturday" | "sunday" | "thursday" | "tuesday" | "wednesday" | "%future added value";
export type QWE_candidate = {
    readonly id: string;
    readonly skillGroupType: string | null;
    readonly candidateType: CandidateType;
    readonly mentor: {
        readonly id: string;
        readonly firstName: string;
        readonly lastName: string;
        readonly email: string;
    } | null;
    readonly placements: {
        readonly edges: ReadonlyArray<{
            readonly id: string;
            readonly name: string;
            readonly startDate: string;
            readonly endDate: string | null;
            readonly qweIndividual: string | null;
            readonly orgPosition: string | null;
            readonly sraNumber: string | null;
            readonly approved: boolean;
            readonly orgSRANumber: string | null;
            readonly supervisorName: string;
            readonly supervisorEmail: string;
            readonly email: string | null;
            readonly fullTime: boolean;
            readonly partTimeDays: ReadonlyArray<Weekday> | null;
            readonly daysMissed: number;
            readonly qweDocURL: string | null;
            readonly qweDocFilename: string | null;
            readonly client: {
                readonly name: string;
                readonly managers: {
                    readonly edges: ReadonlyArray<{
                        readonly id: string;
                        readonly firstName: string;
                        readonly lastName: string;
                        readonly email: string;
                        readonly profileImageUrl: string | null;
                    } | null> | null;
                };
            } | null;
        } | null> | null;
    } | null;
    readonly " $fragmentRefs": FragmentRefs<"CompetencySkillsPage_candidate">;
    readonly " $refType": "QWE_candidate";
};
export type QWE_candidate$data = QWE_candidate;
export type QWE_candidate$key = {
    readonly " $data"?: QWE_candidate$data;
    readonly " $fragmentRefs": FragmentRefs<"QWE_candidate">;
};



const node: ReaderFragment = (function () {
    var v0 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "id",
        "args": null,
        "storageKey": null
    } as any), v1 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "firstName",
        "args": null,
        "storageKey": null
    } as any), v2 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "lastName",
        "args": null,
        "storageKey": null
    } as any), v3 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "email",
        "args": null,
        "storageKey": null
    } as any), v4 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "name",
        "args": null,
        "storageKey": null
    } as any);
    return {
        "kind": "Fragment",
        "name": "QWE_candidate",
        "type": "Candidate",
        "metadata": null,
        "argumentDefinitions": [],
        "selections": [
            (v0 /*: any*/),
            {
                "kind": "ScalarField",
                "alias": null,
                "name": "skillGroupType",
                "args": null,
                "storageKey": null
            },
            {
                "kind": "ScalarField",
                "alias": null,
                "name": "candidateType",
                "args": null,
                "storageKey": null
            },
            {
                "kind": "LinkedField",
                "alias": null,
                "name": "mentor",
                "storageKey": null,
                "args": null,
                "concreteType": "Mentor",
                "plural": false,
                "selections": [
                    (v0 /*: any*/),
                    (v1 /*: any*/),
                    (v2 /*: any*/),
                    (v3 /*: any*/)
                ]
            },
            {
                "kind": "LinkedField",
                "alias": null,
                "name": "placements",
                "storageKey": null,
                "args": null,
                "concreteType": "PlacementPage",
                "plural": false,
                "selections": [
                    {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "edges",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "Placement",
                        "plural": true,
                        "selections": [
                            (v0 /*: any*/),
                            (v4 /*: any*/),
                            {
                                "kind": "ScalarField",
                                "alias": null,
                                "name": "startDate",
                                "args": null,
                                "storageKey": null
                            },
                            {
                                "kind": "ScalarField",
                                "alias": null,
                                "name": "endDate",
                                "args": null,
                                "storageKey": null
                            },
                            {
                                "kind": "ScalarField",
                                "alias": null,
                                "name": "qweIndividual",
                                "args": null,
                                "storageKey": null
                            },
                            {
                                "kind": "ScalarField",
                                "alias": null,
                                "name": "orgPosition",
                                "args": null,
                                "storageKey": null
                            },
                            {
                                "kind": "ScalarField",
                                "alias": null,
                                "name": "sraNumber",
                                "args": null,
                                "storageKey": null
                            },
                            {
                                "kind": "ScalarField",
                                "alias": null,
                                "name": "approved",
                                "args": null,
                                "storageKey": null
                            },
                            {
                                "kind": "ScalarField",
                                "alias": null,
                                "name": "orgSRANumber",
                                "args": null,
                                "storageKey": null
                            },
                            {
                                "kind": "ScalarField",
                                "alias": null,
                                "name": "supervisorName",
                                "args": null,
                                "storageKey": null
                            },
                            {
                                "kind": "ScalarField",
                                "alias": null,
                                "name": "supervisorEmail",
                                "args": null,
                                "storageKey": null
                            },
                            (v3 /*: any*/),
                            {
                                "kind": "ScalarField",
                                "alias": null,
                                "name": "fullTime",
                                "args": null,
                                "storageKey": null
                            },
                            {
                                "kind": "ScalarField",
                                "alias": null,
                                "name": "partTimeDays",
                                "args": null,
                                "storageKey": null
                            },
                            {
                                "kind": "ScalarField",
                                "alias": null,
                                "name": "daysMissed",
                                "args": null,
                                "storageKey": null
                            },
                            {
                                "kind": "ScalarField",
                                "alias": null,
                                "name": "qweDocURL",
                                "args": null,
                                "storageKey": null
                            },
                            {
                                "kind": "ScalarField",
                                "alias": null,
                                "name": "qweDocFilename",
                                "args": null,
                                "storageKey": null
                            },
                            {
                                "kind": "LinkedField",
                                "alias": null,
                                "name": "client",
                                "storageKey": null,
                                "args": null,
                                "concreteType": "Client",
                                "plural": false,
                                "selections": [
                                    (v4 /*: any*/),
                                    {
                                        "kind": "LinkedField",
                                        "alias": null,
                                        "name": "managers",
                                        "storageKey": null,
                                        "args": null,
                                        "concreteType": "ManagerPage",
                                        "plural": false,
                                        "selections": [
                                            {
                                                "kind": "LinkedField",
                                                "alias": null,
                                                "name": "edges",
                                                "storageKey": null,
                                                "args": null,
                                                "concreteType": "Manager",
                                                "plural": true,
                                                "selections": [
                                                    (v0 /*: any*/),
                                                    (v1 /*: any*/),
                                                    (v2 /*: any*/),
                                                    (v3 /*: any*/),
                                                    {
                                                        "kind": "ScalarField",
                                                        "alias": null,
                                                        "name": "profileImageUrl",
                                                        "args": null,
                                                        "storageKey": null
                                                    }
                                                ]
                                            }
                                        ]
                                    }
                                ]
                            }
                        ]
                    }
                ]
            },
            {
                "kind": "FragmentSpread",
                "name": "CompetencySkillsPage_candidate",
                "args": null
            }
        ]
    } as any;
})();
(node as any).hash = '016b93108eaeec7222835473f5a352f6';
export default node;
