import * as React from 'react';
//@ts-ignore
import { BrowserProtocol, queryMiddleware } from 'farce';
import {
  createFarceRouter,
  createRender,
  makeRouteConfig,
  Route,
  RouteRenderArgs,
  RenderErrorArgs,
  RedirectException,
  Redirect,
  Match
} from 'found';
//@ts-ignore
import { Resolver } from 'found-relay';
import environment from './api/environment';
import { graphql, createFragmentContainer } from 'react-relay';
import LoginPage from 'views/Login';
import CheckTimestamp from 'views/CheckTimestamp';
import FinaliseLogin from 'views/FinaliseLogin';
import { ThemeProvider } from 'react-jss';
import theme from './helpers/theme';
import AppHolder from 'views/AppHolder';
import OnlineCoursePage from 'views/OnlineCourse';
import MyCourses from 'views/MyCourses';
import CertGenerator from 'views/CertGenerator';
import CertGeneratorTest from 'views/CertGenerator/CertGeneratorTest';
import ErrorBoundary from 'components/ErrorBoundarys/PageBoundary';
import Module from 'views/Module';
import Test from 'views/Test/Test';
import { SideModalProvider } from 'views/SideModalProvider';
import RecoverPassword from 'views/RecoverPassword/RecoverPassword';
import Lesson from 'views/Lesson';
import ResetPassword from 'views/ResetPassword';
import Qualifications from 'views/Qualifications/Qualifications';
import EntryCreate from 'views/Entry/EntryCreate';
import EntryUpdate from 'views/Entry/EntryUpdate';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import { ToastProvider } from 'components/Toast/ToastProvider';
import QWE from 'views/QWE/QWE';
import SignoffGenerator from 'views/SignoffGenerator';
import SocialEvents from 'views/SocialEvents/SocialEvents';
import Dashboard from 'views/Dashboard/Dashboard';
import SocialEvent from 'views/SocialEvent/SocialEvent';
import Mentoring from 'views/Mentoring';
import AllCourses, { PAGE_SIZE as ALL_COURSES_LIMIT } from 'views/AllCourses';
import Advices from 'views/AdviceList/Advices';
import Advice from 'views/Advice/Advice';
import CharacterSuitability from 'views/CharacterSuitability/CharacterSuitability';
import Appraisals from 'views/Appraisals';
import Tutorials from 'views/Tutorials';
import Tutorial from 'views/Tutorial';
import SignoffGeneratorTest from "./views/SignoffGenerator/SignoffGeneratorTest";

const protectedRenderer = (Comp: React.ReactNode) => (
  args: RouteRenderArgs
) => {
  // Sadly found-relay has no types...
  //@ts-ignore
  if (args?.error && args?.error.type == 'ErrUnauthorized') {
    args.match.router.push('/login');
    return;
  }
  //@ts-ignore
  return <Comp {...args.props} />;
};

const Router = createFarceRouter({
  historyProtocol: new BrowserProtocol(),
  historyMiddlewares: [queryMiddleware],
  routeConfig: makeRouteConfig(
    <Route>
      <Route
        path="/(login)?"
        Component={LoginPage}
        query={graphql`
          query App_Login_Query {
            user {
              ...LoginPage_user
            }
          }
        `}
        render={(args: any) => {
          if (!args.props) {
            return <LoginPage />;
          }
          return <LoginPage {...args.props} />;
        }}
      />
      <Route path="/check-timestamp/:timestamp" Component={CheckTimestamp} />
      <Route path="/password" Component={RecoverPassword} />
      <Route path="/reset/:token" Component={ResetPassword} />
      <Route path="/finalise/:token" Component={FinaliseLogin} />
      <Route
        path="/app"
        Component={AppHolder}
        query={graphql`
          query App_Holder_Query {
            user {
              ...AppHolder_user
            }
            candidate {
              ...AppHolder_candidate
            }
          }
        `}
        render={protectedRenderer(AppHolder)}
      >
        <Route
          path="/"
          Component={Dashboard}
          query={graphql`
            query App_Dashboard_Query(
              $page: Page
              $filter: SocialEventFilter
              $orderBy: OrderBy
            ) {
              socialEvents(page: $page, filter: $filter, orderBy: $orderBy) {
                ...Dashboard_socialEvents
              }
              candidate {
                ...Dashboard_candidate
              }
            }
          `}
          prepareVariables={(params: any, { location }: any) => {
            const { offset, limit } = location.query;

            const _params = {
              page: {
                offset: parseInt(offset) || 0,
                limit: parseInt(limit) || 10
              },
              filter: {
                afterDate: new Date()
              }
            };
            return _params;
          }}
        />
        <Route
          path="/entry/create"
          Component={EntryCreate}
          query={graphql`
            query App_EntryCreate_Query {
              candidate {
                ...EntryCreate_candidate
              }
            }
          `}
        />
        <Route
          path="/entry/update/:id"
          Component={EntryUpdate}
          query={graphql`
            query App_EntryUpdate_Query($id: ID!) {
              candidate {
                ...EntryUpdate_candidate
              }
              node(id: $id) {
                ...EntryUpdate_node
              }
            }
          `}
          prepareVariables={(params: any) => {
            const { id } = params;
            return {
              id: id
            };
          }}
        />
        <Route
          path="/qualifications/:qualType?"
          Component={Qualifications}
          query={graphql`
            query App_Qualifications_Query($qualType: QualificationType!) {
              candidate {
                ...Qualifications_candidate @arguments(qualType: $qualType)
              }
            }
          `}
          prepareVariables={(params: any, { location }: Match) => {
            return {
              ...params,
              qualType: params.qualType || 'a_level'
            };
          }}
        />
        <Route
          path="/qwe/:tab?"
          Component={QWE}
          query={graphql`
            query App_QWE_Query {
              candidate {
                ...QWE_candidate
              }
              clients {
                ...QWE_clients
              }
            }
          `}
        />
        <Route
          path="/mentoring"
          Component={Mentoring}
          query={graphql`
            query App_Mentoring_Query {
              candidate {
                ...Mentoring_candidate
              }
            }
          `}
        />
        <Route
          path="/appraisals"
          Component={Appraisals}
          query={graphql`
            query App_Appraisals_Query {
              mentors {
                ...Appraisals_mentors
              }
            }
          `}
        />
        <Route
          path="/character-and-suitability"
          Component={CharacterSuitability}
          query={graphql`
            query App_CharacterSuitability_Query {
              characterSuitability {
                ...CharacterSuitability_characterSuitability
              }
              candidate {
                ...CharacterSuitability_candidate
              }
            }
          `}
        />
        <Route
          path="/advices"
          Component={Advices}
          query={graphql`
            query App_Advices_Query($page: Page, $filter: AdviceFilter) {
              user {
                ...Advices_user
              }
              advices(page: $page, filter: $filter) {
                ...Advices_advices
              }
            }
          `}
          prepareVariables={(params: any, { location }: any) => {
            const { offset, limit } = location.query;

            const _params = {
              page: {
                offset: parseInt(offset) || 0,
                limit: parseInt(limit) || 10
              }
            };
            return _params;
          }}
        />
        <Route
          path="/advices/:id"
          Component={Advice}
          query={graphql`
            query App_Advice_Query($id: ID!) {
              advice(id: $id) {
                ...Advice_advice
              }
            }
          `}
          prepareVariables={(params: any, { location }: any) => {
            const { id } = params;
            return {
              id: id
            };
          }}
        />
        <Route
          path="/socialEvents"
          Component={SocialEvents}
          query={graphql`
            query App_SocialEvents_Query(
              $page: Page
              $filter: SocialEventFilter
              $orderBy: OrderBy
            ) {
              socialEvents(page: $page, filter: $filter, orderBy: $orderBy) {
                ...SocialEvents_socialEvents
              }
            }
          `}
          prepareVariables={(params: any, { location }: any) => {
            const { offset, limit } = location.query;

            const _params = {
              page: {
                offset: parseInt(offset) || 0,
                limit: parseInt(limit) || 10
              },
              filter: {
                afterDate: new Date()
              }
            };
            return _params;
          }}
        />
        <Route
          path="/socialEvents/:id"
          Component={SocialEvent}
          query={graphql`
            query App_SocialEvent_Query($id: ID!) {
              socialEvent(id: $id) {
                ...SocialEvent_socialEvent
              }
            }
          `}
          prepareVariables={(params: any, { location }: any) => {
            const { id } = params;

            return {
              id: id
            };
          }}
        />
        <Route
          path="/all-courses"
          Component={AllCourses}
          query={graphql`
            query App_AllCourses_Query($limit: Int) {
              courses(page: { limit: $limit }) {
                ...AllCourses_courses
              }
            }
          `}
          prepareVariables={(params: any, { location }: any) => {
            const { limit } = location.query;

            const _params = {
              limit: parseInt(limit) || ALL_COURSES_LIMIT
            };
            return _params;
          }}
        />
        <Route
          path="/my-courses"
          Component={MyCourses}
          query={graphql`
            query App_Courses_Query {
              user {
                ...MyCourses_user
              }
            }
          `}
          render={(args: any) => {
            return (
              <ErrorBoundary>
                <MyCourses {...args.props} />
              </ErrorBoundary>
            );
          }}
        />
        <Route
          path="/courses/:id"
          Component={OnlineCoursePage}
          query={graphql`
            query App_Course_Query($ident: Int!) {
              user {
                myActiveCourse(id: $ident) {
                  ...OnlineCourse_myActiveCourse
                }
              }
            }
          `}
          prepareVariables={(params: any, { location }: any) => {
            const { id } = params;
            return {
              ident: parseInt(id)
            };
          }}
          render={(args: any) => {
            return (
              <ErrorBoundary>
                <OnlineCoursePage
                  {...args.props}
                  myActiveCourse={args.props?.user?.myActiveCourse ?? null}
                />
              </ErrorBoundary>
            );
          }}
        />
        <Route
          path="/courses/:courseID/module/:moduleUUID"
          query={graphql`
            query App_Module_Query($id: Int!, $uuid: UUID!) {
              user {
                myActiveCourse(id: $id) {
                  ...Module_myActiveCourse
                }
                ...Module_user
              }
              module(uuid: $uuid) {
                ...Module_module
              }
            }
          `}
          prepareVariables={(params: any, { location }: any) => {
            const { courseID, moduleUUID } = params;
            return {
              id: parseInt(courseID),
              uuid: moduleUUID
            };
          }}
          render={(args: any) => {
            console.log('args', args);
            if (args.error) {
              args.match.router.push('/app');
            }
            if (!args.props) {
              return <div></div>;
            }
            return (
              <ErrorBoundary>
                <Module
                  {...args.props}
                  myActiveCourse={args.props?.user?.myActiveCourse}
                />
              </ErrorBoundary>
            );
          }}
        />
        <Route
          path="/courses/:courseID/test/:testUUID"
          Component={Test}
          query={graphql`
            query App_Test_Query($id: Int!, $uuid: UUID!) {
              user {
                myActiveCourse(id: $id) {
                  ...Test_myActiveCourse
                }
                ...Test_user
              }
              test(uuid: $uuid) {
                ...Test_test
              }
            }
          `}
          prepareVariables={(params: any, { location }: any) => {
            const { courseID, testUUID } = params;
            return {
              id: parseInt(courseID),
              uuid: testUUID
            };
          }}
          render={(args: any) => {
            console.log('args', args);
            if (args.error) {
              args.match.router.push('/app');
            }
            if (!args.props) {
              return <div></div>;
            }
            return (
              <ErrorBoundary>
                <Test
                  {...args.props}
                  myActiveCourse={args.props?.user?.myActiveCourse}
                />
              </ErrorBoundary>
            );
          }}
        />
        <Route
          path="/courses/:courseID/lesson/:lessonUUID"
          Component={Lesson}
          query={graphql`
            query App_Lesson_Query($id: Int!, $uuid: UUID!) {
              user {
                myActiveCourse(id: $id) {
                  ...Lesson_myActiveCourse
                }
                ...Lesson_user
              }
              lesson(uuid: $uuid) {
                ...Lesson_lesson
              }
            }
          `}
          prepareVariables={(params: any, { location }: any) => {
            const { courseID, lessonUUID } = params;
            return {
              id: parseInt(courseID),
              uuid: lessonUUID
            };
          }}
          render={(args: any) => {
            console.log('args', args);
            if (args.error) {
              args.match.router.push('/app');
            }
            if (!args.props) {
              return <div></div>;
            }
            return (
              <ErrorBoundary>
                <Lesson
                  {...args.props}
                  myActiveCourse={args.props?.user?.myActiveCourse}
                />
              </ErrorBoundary>
            );
          }}
        />
        <Route
          path="/tutorials"
          Component={Tutorials}
          query={graphql`
            query App_Tutorials_Query {
              user {
                ...Tutorials_user
              }
            }
          `}
          render={(args: any) => (
            <ErrorBoundary>
              <Tutorials {...args.props} />
            </ErrorBoundary>
          )}
        />
        <Route
          path="/tutorial/:id"
          Component={Tutorial}
          render={(args) => {
            if (!args.match){
              return <div></div>;
            }

            const { id } = args.match.params;
            return (
              <ErrorBoundary>
                <Tutorial id={id} />
              </ErrorBoundary>
            );
          }}
        />
      </Route>
      <Route
        path="/cert-generator"
        Component={CertGenerator}
        query={graphql`
          query App_Certificate_Query($token: String!) {
            certificateInfo(token: $token) {
              ...CertGenerator_certificateInfo
            }
          }
        `}
        render={(args: any) => {
          if (!args.props) {
            return <div></div>;
          }
          return (
            <ErrorBoundary>
              <CertGenerator {...args.props} />
            </ErrorBoundary>
          );
        }}
        prepareVariables={(params: any, { location }: any) => {
          const { token } = location.query;
          return {
            token
          };
        }}
      />
      <Route
        path="/cert-generator-test"
        Component={CertGeneratorTest}
        render={(args: any) => {
          if (!args.props) {
            return <div></div>;
          }
          return (
            <CertGeneratorTest
              {...args.props}
              certificateInfo={{
                trainingRecord: [
                  {
                    type: 'module',
                    name: 'RA - Module 9 Recurrent - Prohibited Articles'
                  },
                  {
                    type: 'module',
                    name: 'RA - Module 9 Recurrent - Prohibited Articles'
                  },
                  {
                    type: 'module',
                    name: 'RA - Module 9 Recurrent - Prohibited Articles'
                  },
                  {
                    type: 'module',
                    name: 'RA - Module 9 Recurrent - Prohibited Articles'
                  },
                  {
                    type: 'module',
                    name:
                      'RA - Module 11 Recurrent - Overview of Screening Methods and Selection of the Most Appropriate Method'
                  },
                  {
                    type: 'module',
                    name: 'RA - Module 9 Recurrent - Prohibited Articles'
                  },
                  {
                    type: 'module',
                    name: 'RA - Module 9 Recurrent - Prohibited Articles'
                  },
                  {
                    type: 'module',
                    name: 'RA - Module 9 Recurrent - Prohibited Articles'
                  },
                  {
                    type: 'module',
                    name:
                      'RA - Module 11 Recurrent - Overview of Screening Methods and Selection of the Most Appropriate Method'
                  },
                  {
                    type: 'module',
                    name: 'RA - Module 9 Recurrent - Prohibited Articles'
                  },
                  {
                    type: 'module',
                    name: 'RA - Module 9 Recurrent - Prohibited Articles'
                  },
                  {
                    type: 'module',
                    name:
                      'RA - Module 11 Recurrent - Overview of Screening Methods and Selection of the Most Appropriate Method'
                  },
                  {
                    type: 'module',
                    name:
                      'RA - Module 11 Recurrent - Overview of Screening Methods and Selection of the Most Appropriate Method'
                  },
                  {
                    type: 'module',
                    name: 'RA - Module 9 Recurrent - Prohibited Articles'
                  },
                  {
                    type: 'module',
                    name: 'RA - Module 9 Recurrent - Prohibited Articles'
                  },
                  {
                    type: 'module',
                    name: 'RA - Module 9 Recurrent - Prohibited Articles'
                  },
                  {
                    type: 'module',
                    name: 'RA - Module 9 Recurrent - Prohibited Articles'
                  },
                  {
                    type: 'module',
                    name: 'RA - Module 9 Recurrent - Prohibited Articles'
                  },
                  {
                    type: 'module',
                    name: 'RA - Module 9 Recurrent - Prohibited Articles'
                  },
                  {
                    type: 'module',
                    name: 'RA - Module 9 Recurrent - Prohibited Articles'
                  },
                  {
                    type: 'module',
                    name: 'RA - Module 9 Recurrent - Prohibited Articles'
                  },
                  {
                    type: 'module',
                    name: 'RA - Module 9 Recurrent - Prohibited Articles'
                  },
                  {
                    type: 'module',
                    name: 'RA - Module 9 Recurrent - Prohibited Articles'
                  },
                  {
                    type: 'module',
                    name: 'RA - Module 9 Recurrent - Prohibited Articles1'
                  }
                ]
              }}
            />
          );
        }}
        prepareVariables={(params: any, { location }: any) => {
          const { token } = location.query;
          return {
            token
          };
        }}
      />
      <Route
        path="/signoff-generator"
        Component={SignoffGenerator}
        query={graphql`
          query App_Signoff_Query($signoffID: ID!) {
            node(id: $signoffID) {
              ...SignoffGenerator_node
            }
          }
        `}
        prepareVariables={(params: any, { location }: any) => {
          const { signoffID } = location.query;
          return {
            signoffID
          };
        }}
      />
      <Route
        path="/signoff-generator-test"
        Component={SignoffGeneratorTest}
        prepareVariables={(params: any, { location }: any) => {
          const { signoffID } = location.query;
          return {
            signoffID
          };
        }}
        render={(args: any) => {
          if (!args.props){
            return <div></div>;
          }
          return (
            <ErrorBoundary>
              <SignoffGeneratorTest {...args.props}/>
            </ErrorBoundary>
          );
        }}
      />
    </Route>
  ),
  render: createRender({})
});

const App = () => (
  <ThemeProvider theme={theme}>
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <ToastProvider>
        <SideModalProvider>
          <Router resolver={new Resolver(environment)} />
        </SideModalProvider>
      </ToastProvider>
    </MuiPickersUtilsProvider>
  </ThemeProvider>
);

export default App;
