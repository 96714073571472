import { commitMutation, GraphQLTaggedNode } from 'react-relay';
import { SelectorStoreUpdater, Store } from 'relay-runtime';
import environment from './environment';

export async function makeMutation(
  mutation: GraphQLTaggedNode,
  variables: any,
  updater?: SelectorStoreUpdater<{}> | null | undefined
) {
  return new Promise((resolve, reject: (err: Error) => void) => {
    commitMutation(environment, {
      mutation: mutation,
      variables,
      onCompleted: (response, errors) => {
        console.log('Response received from server.', response, errors);
        resolve(response);
      },
      onError: (err) => {
        console.error('Mutation error - ', err);
        reject(err);
      },
      updater
    });
  });
}
