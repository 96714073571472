/* tslint:disable */
/* eslint-disable */
/* @relayHash 2c0c0286c557b4baa4a4d59b025c6562 */

import { ConcreteRequest } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type App_Appraisals_QueryVariables = {};
export type App_Appraisals_QueryResponse = {
    readonly mentors: {
        readonly " $fragmentRefs": FragmentRefs<"Appraisals_mentors">;
    } | null;
};
export type App_Appraisals_Query = {
    readonly response: App_Appraisals_QueryResponse;
    readonly variables: App_Appraisals_QueryVariables;
};



/*
query App_Appraisals_Query {
  mentors {
    ...Appraisals_mentors
  }
}

fragment Appraisals_mentors on MentorPage {
  edges {
    id
    email
    firstName
    lastName
    isTP
  }
  pageInfo {
    total
    offset
    limit
    given
  }
}
*/

const node: ConcreteRequest = ({
    "kind": "Request",
    "fragment": {
        "kind": "Fragment",
        "name": "App_Appraisals_Query",
        "type": "Query",
        "metadata": null,
        "argumentDefinitions": [],
        "selections": [
            {
                "kind": "LinkedField",
                "alias": null,
                "name": "mentors",
                "storageKey": null,
                "args": null,
                "concreteType": "MentorPage",
                "plural": false,
                "selections": [
                    {
                        "kind": "FragmentSpread",
                        "name": "Appraisals_mentors",
                        "args": null
                    }
                ]
            }
        ]
    },
    "operation": {
        "kind": "Operation",
        "name": "App_Appraisals_Query",
        "argumentDefinitions": [],
        "selections": [
            {
                "kind": "LinkedField",
                "alias": null,
                "name": "mentors",
                "storageKey": null,
                "args": null,
                "concreteType": "MentorPage",
                "plural": false,
                "selections": [
                    {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "edges",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "Mentor",
                        "plural": true,
                        "selections": [
                            {
                                "kind": "ScalarField",
                                "alias": null,
                                "name": "id",
                                "args": null,
                                "storageKey": null
                            },
                            {
                                "kind": "ScalarField",
                                "alias": null,
                                "name": "email",
                                "args": null,
                                "storageKey": null
                            },
                            {
                                "kind": "ScalarField",
                                "alias": null,
                                "name": "firstName",
                                "args": null,
                                "storageKey": null
                            },
                            {
                                "kind": "ScalarField",
                                "alias": null,
                                "name": "lastName",
                                "args": null,
                                "storageKey": null
                            },
                            {
                                "kind": "ScalarField",
                                "alias": null,
                                "name": "isTP",
                                "args": null,
                                "storageKey": null
                            }
                        ]
                    },
                    {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "pageInfo",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "PageInfo",
                        "plural": false,
                        "selections": [
                            {
                                "kind": "ScalarField",
                                "alias": null,
                                "name": "total",
                                "args": null,
                                "storageKey": null
                            },
                            {
                                "kind": "ScalarField",
                                "alias": null,
                                "name": "offset",
                                "args": null,
                                "storageKey": null
                            },
                            {
                                "kind": "ScalarField",
                                "alias": null,
                                "name": "limit",
                                "args": null,
                                "storageKey": null
                            },
                            {
                                "kind": "ScalarField",
                                "alias": null,
                                "name": "given",
                                "args": null,
                                "storageKey": null
                            }
                        ]
                    }
                ]
            }
        ]
    },
    "params": {
        "operationKind": "query",
        "name": "App_Appraisals_Query",
        "id": null,
        "text": "query App_Appraisals_Query {\n  mentors {\n    ...Appraisals_mentors\n  }\n}\n\nfragment Appraisals_mentors on MentorPage {\n  edges {\n    id\n    email\n    firstName\n    lastName\n    isTP\n  }\n  pageInfo {\n    total\n    offset\n    limit\n    given\n  }\n}\n",
        "metadata": {}
    }
} as any);
(node as any).hash = 'e3e11ad4f8bf124dbd70ae88fc6841cf';
export default node;
