import * as React from 'react';
import { createUseStyles, useTheme } from 'react-jss';
import classNames from 'classnames';
import { animated, useSpring, config } from 'react-spring';
import { useMediaQuery } from 'react-responsive';
import { Theme } from 'helpers/theme';
import Icon, { IconNames } from 'components/core/Icon/Icon';
import Spacer from 'components/core/Spacers/Spacer';
import Button from 'components/core/Input/ButtonBase';

const useStyles = createUseStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    zIndex: 11,
    top: 0,
    overflowY: 'scroll',
    width: 274,
    gridRow: '1 / span 2',
    position: 'relative',
    height: '100%',
    flexDirection: 'column',
    overflowX: 'hidden',
    alignItems: 'center',
    boxShadow: '1px 0px 0px rgba(0,0,0,0.03)',
    background: theme.colors.primaryWhite,
    '@media (max-width: 750px)': {
      position: 'absolute'
    },
    flex: 1
  },
  rootWrapper: {
      display: 'flex',
      zIndex: 11,
      top: 0, 
      width: 274,
      gridRow: '1 / span 2',
      position: 'relative',
      height: '100%',
      flexDirection: 'column',
      alignItems: 'center',
      boxShadow: '1px 0px 0px rgba(0,0,0,0.03)',
      background: theme.colors.primaryWhite,
      '@media (max-width: 750px)': {
        position: 'absolute'
      },
  },
  iconRow: {
    display: 'flex',
    width: '100%',
    padding: '0 25px',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  menu: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center'
  },
  tab: {
    width: '274px',
    minHeight: 50,
    cursor: 'pointer',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
    transition: 'background-color 0.3s linear, opacity 0.3s linear'
  },
  selected: {
    backgroundColor: theme.colors.hoverOrange,
    opacity: 1,
    transition: 'background-color 0.3s linear, opacity 0.3s linear'
  },
  fold: {
    width: 17,
    height: 40,
    position: 'absolute',
    left: '-13px',
    opacity: 1,
    transition: 'opacity 0.2s linear',
    borderRadius: 13,
    backgroundColor: theme.colors.secondaryOrange
  },
  noFold: {
    height: 40,
    width: 0,
    opacity: 0,
    transition: 'opacity 0.2s linear'
  },
  logo: {
    cursor: 'pointer',
    height: '50px',
    width: '190px',
    margin: '25px 0',
    '@media (max-width: 750px)': {
      height: '40px',
      width: '40px'
    }
  },
  title: {
    fontSize: theme.fontSizes.large,
    fontWeight: '300',
    color: theme.colors.primaryBlack,
    margin: '0 25px'
  },
  titleBold: {
    fontSize: theme.fontSizes.large,
    fontWeight: '500',
    color: theme.colors.primaryBlack,
    margin: '0 25px'
  },
  body: {
    padding: '30px 30px',
    backgroundColor: theme.colors.backgroundGrey,
    boxShadow: theme.shadows.body,
    flexGrow: 1
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    marginLeft: '30px'
  },
  dropdownTab: {
    flexDirection: 'column',
    alignItems: 'start',
    paddingTop: '10px'
  },
  childTabContainer: {
    padding: '20px 30px 5px 73px',
    width: '100%',
    display: 'flex',
    flexDirection: 'column'
  },
  childTab: {
    fontSize: 15,
    fontWeight: '300',
    marginBottom: 15,
    marginLeft: 20
  },
  childTabSelected: {
    fontWeight: 700
  },
  accessConnectContainer: {
    padding: 20
  }
}));

export type ChildTab = {
  id: number;
  title: string;
};

export interface Tab {
  id: number;
  icon: IconNames;
  title: string;
  size?: number;
  childTabs?: ChildTab[];
}

type Props = {
  tabs: Tab[];
  isOpen: boolean;
  toggleOpen: () => void;
  selected: Tab | ChildTab | undefined;
  onClick?: (tab: Tab | ChildTab) => void;
  onLogoClick?: () => void;
  className?: string;
  showAccessConnectButton: boolean;
  onOpenAccessConnect: () => void;
};

function SideMenu({
  tabs,
  isOpen,
  toggleOpen,
  selected,
  onClick,
  onLogoClick,
  className,
  showAccessConnectButton,
  onOpenAccessConnect
}: Props) {
  const theme = useTheme();
  const classes = useStyles({ theme });
  const isMobile = useMediaQuery({ maxWidth: 750 });
  const { left } = useSpring({
    left: !isMobile || isOpen ? 0 : -274,
    config: config.default
  });

 
  return (
    <animated.div
      className={classNames(classes.rootWrapper, className)}
      style={{ left }}
    >
      <div className={classNames(classes.root)}>
      <div className={classes.iconRow}>
        <img
          className={classNames(classes.logo)}
          onClick={() => onLogoClick && onLogoClick()}
          src={require(`../../../assets/logo/accutrainee-${isMobile ? 'icon.svg' : 'logo-pad.png'
            }`)}
          alt="Logo"
        />
        {isMobile && (
          <Icon
            name="CloseHamburger"
            size={15}
            style={{ cursor: 'pointer' }}
            onClick={() => toggleOpen()}
          />
        )}
      </div>
      <Spacer vertical spacing={2} />
      <Spacer vertical spacing={1} />
      <div className={classNames(classes.menu)}>
        {tabs &&
          tabs.map((tab) => {
            if (tab.childTabs) {
              return (
                <div
                  key={tab.id}
                  className={classNames(classes.tab, classes.dropdownTab)}
                >
                  <div className={classes.row}>
                    <Icon
                      name={tab.icon}
                      size={tab.size ?? 20}
                      style={{ cursor: 'pointer' }}
                    />
                    <div
                      className={
                        tab.title == 'Learning & Development' || 'Resources'
                          ? classes.titleBold
                          : classes.title
                      }
                    >
                      {tab.title}
                    </div>
                  </div>
                  <div className={classes.childTabContainer}>
                    {tab.childTabs.map((child, i) => (
                      <div
                        key={i}
                        className={classNames(
                          classes.childTab,
                          selected &&
                          selected.id === child.id &&
                          classes.childTabSelected
                        )}
                        onClick={() => {
                          if (onClick) onClick(child);
                        }}
                      >
                        {child.title}
                      </div>
                    ))}
                  </div>
                </div>
              );
            }

            return (
              <div
                key={tab.id}
                className={classNames(
                  classes.tab,
                  selected && selected.id === tab.id && classes.selected
                )}
                onClick={() => {
                  if (onClick) onClick(tab);
                }}
              >
                <div
                  className={classNames(
                    selected && selected.id === tab.id
                      ? classes.fold
                      : classes.noFold
                  )}
                />
                <div className={classes.row}>
                  <Icon
                    name={tab.icon}
                    size={tab.size ?? 20}
                    style={{ cursor: 'pointer' }}
                  />
                  <div className={classes.title}>{tab.title}</div>
                </div>
                <div />
              </div>
            );
          })}
      </div>
      </div>
     {
      showAccessConnectButton ?
       <div  className={classNames(classes.accessConnectContainer)}>
       <Button archetype = {"submit"} bold onClick={()=>onOpenAccessConnect()}>
        Access Connect
      </Button>
      </div> : null
      
    }
    </animated.div>
  );
}

export default SideMenu;
