import React from 'react';
import { AlertType, Toast as ToastComponent } from './Toast';
import styled from 'styled-components';
import { Transition, animated } from 'react-spring/renderprops';

type Toast = {
  id: string;
  text: string;
  alert: AlertType;
  timeout?: number;
};

type State = {
  shown: boolean;
  toasts: Toast[];
};

type Action = {
  input?: InputAction;
  id: string;
  type: 'add' | 'remove';
  timeout?: number;
};

type Dispatch = (action: Action) => void;

const ToastDispatchContext = React.createContext<Dispatch | undefined>(
  undefined
);

const initState: State = {
  shown: false,
  toasts: []
};

function ToastReducer(state: State, action: Action) {
  const toasts = state.toasts;

  if ((action.type === 'add' || !action.type) && action.input) {
    var newState: State = {
      ...state,
      toasts: [
        ...toasts,
        {
          text: action.input.text,
          alert: action.input.alert,
          id: action.id,
          timeout: action.timeout
        }
      ]
    };
    return newState;
  }

  if (action.type === 'remove') {
    var newState: State = {
      ...state,
      toasts: toasts.filter((toast) => toast.id !== action.id)
    };
    return newState;
  }

  throw new Error('Unable to toast, reducer error');
}

const ToastContainer = styled.div`
  position: fixed;
  width: 400px;
  right: 30px;
  top: 50px;
  z-index: 10000;
`;

function ToastProvider({ children }: { children: React.ReactNode }) {
  const [state, dispatch] = React.useReducer(ToastReducer, initState);

  return (
    <ToastDispatchContext.Provider value={dispatch}>
      <ToastContainer>
        <Transition
          items={state.toasts}
          keys={(item) => item.id}
          from={{ transform: 'translate3d(450px, 0, 0)' }}
          enter={{ transform: 'translate3d(0px, 0, 0px)' }}
          leave={{ transform: 'translate3d(450px, 0, 0)' }}
        >
          {(item) => (props) => (
            <ToastComponent
              style={props}
              alertType={item.alert}
              text={item.text}
              onClose={() => dispatch({ type: 'remove', id: item.id })}
            />
          )}
        </Transition>
      </ToastContainer>
      {children}
    </ToastDispatchContext.Provider>
  );
}

type InputAction = {
  text: string;
  alert: AlertType;
  stayFor?: number;
};

function useToastDispatch() {
  const context = React.useContext(ToastDispatchContext);
  if (context === undefined) {
    throw new Error('ToastDispatch must be used with ToastProvider');
  }
  return (action: InputAction) => {
    const actionID = `${action.text}-${action.alert}-${(
      Math.random().toString(36) + Date.now().toString(36)
    ).substr(2, 10)}`;

    const timeout = window.setTimeout(() => {
      context({
        id: actionID,
        type: 'remove'
      });
    }, action.stayFor ?? 2000);

    context({
      input: action,
      id: actionID,
      type: 'add',
      timeout
    });
  };
}

export { ToastProvider, useToastDispatch };
