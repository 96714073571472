import SideModal from 'components/SideModal/SideModal';
import Tabs from 'components/SideModal/Tabs';
import React from 'react';
import Content, { State } from './Content';

type Props = {
  isOpen: boolean;
  supervisorID?: string;
  mentorID?: string;
  placementID: string;
  onClose: () => void;
};

function SendEntriesModal({
  isOpen,
  supervisorID,
  mentorID,
  placementID,
  onClose
}: Props) {
  const initialState: State = {
    supervisorID,
    mentorID,
    placementID,
    saving: false,
    selected: [],
    startDate: undefined,
    endDate: undefined,
    entries: [],
    title: '',
  };

  return (
    <SideModal isOpen={isOpen} title="Send Entries" closeModal={onClose}>
      <Tabs
        content={[
          {
            key: 'Entries',
            component: Content
          }
        ]}
        closeModal={onClose}
        initialState={initialState}
      />
    </SideModal>
  );
}

export default SendEntriesModal;
