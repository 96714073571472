import React from 'react';
import styled, { keyframes } from 'styled-components';
import theme from 'helpers/theme';
import moment from 'moment';
import { Dashboard_socialEvents } from './__generated__/Dashboard_socialEvents.graphql';

const Container = styled.div`
  display: flex;
  flex-direction: row;
`;

const Title = styled.div`
  padding: 0px 20px 0px 0px;
  font-weight: 700;
  font-size: 15px;
`;

const RibbonContainer = styled.div`
  overflow: hidden;
  width: 100%;
  max-width: 700px;
`;

const animation = keyframes`
0% {
  -webkit-transform: translate3d(100%, 0, 0);
  transform: translate3d(100%, 0, 0);
  visibility: visible;
}

100% {
  -webkit-transform: translate3d(-100%, 0, 0);
  transform: translate3d(-100%, 0, 0);
}
`;

const Ribbon = styled.div`
  display: flex;
  flex-direction: row;
  margin-left: 20px;
  color: ${theme.colors.textGrey};
  white-space: nowrap;
  animation-name: ${animation};
  animation-duration: 15s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
`;

const RibbonContent = styled.div`
  display: flex;
  flex-direction: row;
`;

const Date = styled.div`
  padding-left: 10px;
  padding-right: 5px;
  font-weight: 700;
`;

const Name = styled.div`
  padding-right: 10px;
`;

type Props = {
  socialEvents: Dashboard_socialEvents;
};

function UpdatesRibbon({ socialEvents }: Props) {
  return (
    <Container>
      <Title>UPDATES</Title>
      <RibbonContainer>
        <Ribbon>
          {socialEvents?.edges &&
            socialEvents?.edges.map((socialEvent, id) => (
              <RibbonContent>
                <Date>
                  {moment(socialEvent?.startDate).format('DD MMMM YYYY')} -{' '}
                </Date>
                <Name>{socialEvent?.name}</Name> {' / '}
              </RibbonContent>
            ))}
        </Ribbon>
      </RibbonContainer>
    </Container>
  );
}

export default UpdatesRibbon;
