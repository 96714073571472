import Button from 'components/core/Input/ButtonBase';
import Spacer from 'components/core/Spacers/Spacer';
import theme from 'helpers/theme';
import React from 'react';
import styled from 'styled-components';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen, faTrash } from '@fortawesome/free-solid-svg-icons';

const Container = styled.div`
  background: white;
  border-radius: 5px;
  border: 1px solid ${theme.colors.borderGrey};
  padding: 20px;
`;

const Title = styled.div`
  font-weight: 800;
  font-size: 17px;
`;

const TopRow = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Grade = styled.div`
  font-weight: 700;
`;

const DateContainer = styled.div`
  display: flex;
  font-size: 13px;
  color: #6f6f6f;
`;

const Date = styled.div``;

export type Qualification = {
  name: string;
  id?: string;
  grade?: string;
  startDate?: string;
  endDate?: string;
  fileUrl: string;
};

type Props = {
  qualification: Qualification;
  onEdit: () => void;
  onDelete: () => void;
};

function QualificationCard({ qualification, onEdit, onDelete }: Props) {
  const { name, grade, startDate, endDate, fileUrl } = qualification;
  return (
    <Container>
      <TopRow>
        <Title>{name}</Title>
        <TopRow>
          <FontAwesomeIcon
            icon={faPen}
            color={'#a4acb5'}
            style={{cursor: 'pointer'}}
            onClick={onEdit}
          />
          <FontAwesomeIcon
            icon={faTrash}
            color={'#a4acb5'}
            style={{cursor: 'pointer', marginLeft: 20}}
            onClick={onDelete}
          />
          </TopRow>
      </TopRow>
      <Spacer vertical spacing={2} />
      <Grade>{grade || '-'}</Grade>
      <Spacer vertical spacing={2} />
      <DateContainer>
        <Date>{startDate ? moment(startDate).format('DD/MM/YYYY') : '-'}</Date>
        <Spacer horizontal spacing={1} />
        to
        <Spacer horizontal spacing={1} />
        <Date>{endDate ? moment(endDate).format('DD/MM/YYYY') : '-'}</Date>
      </DateContainer>
      <Spacer vertical spacing={2} />
      <Button
        archetype={'default'}
        onClick={() => window.open(fileUrl, '_blank')}
        fullWidth
      >
        Certificate
      </Button>
    </Container>
  );
}

export default QualificationCard;
