/* tslint:disable */
/* eslint-disable */
/* @relayHash 99c35af98859cdd084dcb8ffc2f1f533 */

import { ConcreteRequest } from "relay-runtime";
export type logout_MutationVariables = {};
export type logout_MutationResponse = {
    readonly logout: boolean;
};
export type logout_Mutation = {
    readonly response: logout_MutationResponse;
    readonly variables: logout_MutationVariables;
};



/*
mutation logout_Mutation {
  logout
}
*/

const node: ConcreteRequest = (function () {
    var v0 = [
        ({
            "kind": "ScalarField",
            "alias": null,
            "name": "logout",
            "args": null,
            "storageKey": null
        } as any)
    ];
    return {
        "kind": "Request",
        "fragment": {
            "kind": "Fragment",
            "name": "logout_Mutation",
            "type": "Mutation",
            "metadata": null,
            "argumentDefinitions": [],
            "selections": (v0 /*: any*/)
        },
        "operation": {
            "kind": "Operation",
            "name": "logout_Mutation",
            "argumentDefinitions": [],
            "selections": (v0 /*: any*/)
        },
        "params": {
            "operationKind": "mutation",
            "name": "logout_Mutation",
            "id": null,
            "text": "mutation logout_Mutation {\n  logout\n}\n",
            "metadata": {}
        }
    } as any;
})();
(node as any).hash = '9f06ab303cdd184cb4a5e442fa874864';
export default node;
