import * as React from 'react';
import styled from 'styled-components';
import Spacer from 'components/core/Spacers/Spacer';
import { useRouter } from 'found';
import SearchBar from 'components/SearchBar';
import PortfolioQueryRenderer from './PortfolioQueryRenderer';

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const SubHeading = styled.div`
  font-weight: 800;
  font-size: 18px;
`;

const SearchBarContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 10px;
`;

const SearchBarRow = styled.div`
  width: 30%;
`;

const Button = styled.div`
  background-color: #f37a21;
  color: white;
  text-decoration: none;
  border-radius: 6px;
  padding: 10px;
  font-size: 12px;
  font-weight: 500;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin-right: 15px;
  width: 150px;
`;

type Props = {
  candidateID: string;
};

function Portfolio({ candidateID }: Props) {
  const [searchText, setSearchText] = React.useState('');
  const { router } = useRouter();

  return (
    <Container>
      <SubHeading>Portfolio</SubHeading>
      <Spacer spacing={1} vertical />
      <SearchBarContainer>
        <SearchBarRow>
          <SearchBar
            onChange={(inp) => {
              setSearchText(inp);
            }}
            value={searchText || ''}
          />
        </SearchBarRow>
        <Button onClick={() => router.push(`/app/entry/create`)}>
          Add Entry
        </Button>
      </SearchBarContainer>
      <Spacer spacing={2} vertical />
      <PortfolioQueryRenderer
        candidateID={candidateID}
        searchText={searchText}
      />
    </Container>
  );
}

export default Portfolio;
