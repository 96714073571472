import React from 'react';
import classes from "./Modal.module.scss";

export interface ModalProps {
    children: React.ReactNode;
    isShow: boolean;
    isCloseOnClickBackground?: boolean;
    backgroundColor?: string;
    onClose: () => void;
}

export const Modal = ({
    children,
    isShow,
    onClose,
    backgroundColor,
    isCloseOnClickBackground = true,
}: ModalProps) => {
    if (!isShow) return null;

    return (
        <div
            className={classes.wrapper}
            style={{ backgroundColor }}
        >
            <div
                className={classes.content}
                onClick={(e) => {
                    e.stopPropagation();
                    if (isCloseOnClickBackground) onClose();
                }}
            >
                {children}
            </div>
        </div>
    );
};

