import SideModal from 'components/SideModal/SideModal';
import Tabs from 'components/SideModal/Tabs';
import React from 'react';
import Content, { State } from './Content';
import ContentTPMAppraisal from './ContentTPMAppraisal';

type Props = {
  isOpen: boolean;
  mentorID: string;
  meetingRequestType: string;
  onClose: () => void;
};

function SendMeetingRequestModal({
  isOpen,
  mentorID,
  meetingRequestType,
  onClose
}: Props) {
  const initialState: State =
    meetingRequestType === 'monthly'
      ? {
          mentorID,
          selected: '',
          saving: false,
          meetingRequestType
        }
      : {
          mentorID,
          availableTime: '',
          saving: false,
          meetingRequestType
        };

  var titleMap = {
    monthly: 'Book an Appointment',
    tpm: 'Book a TPM',
    appraisal: 'Book an Appraisal'
  };

  return (
    <SideModal
      isOpen={isOpen}
      title={titleMap[meetingRequestType]}
      closeModal={onClose}
    >
      <Tabs
        content={[
          {
            key: 'Booking',
            component:
              meetingRequestType === 'monthly' ? Content : ContentTPMAppraisal
          }
        ]}
        closeModal={onClose}
        initialState={initialState}
      />
    </SideModal>
  );
}

export default SendMeetingRequestModal;
