import * as React from 'react';
import { createUseStyles } from 'react-jss';
import { Theme } from 'helpers/theme';
import Spacer from 'components/core/Spacers/Spacer';

type Props = {
  title?: string;
  text: string;
};

const useStyles = createUseStyles((theme: Theme) => ({
  moduleRowRoot: {
    display: 'flex'
  },
  title: {
    fontWeight: 700,
    textTransform: 'capitalize'
  },
  text: {
    margin: '5px 0px'
  }
}));

function ModuleRow({ title, text }: Props) {
  const classes = useStyles();

  return (
    <div className={classes.moduleRowRoot}>
      {title && (
        <>
          <p className={classes.title}>{title} -</p>
          <Spacer horizontal spacing={1} />
        </>
      )}
      <p className={classes.text}>{text}</p>
    </div>
  );
}

export default ModuleRow;
