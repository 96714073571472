/* tslint:disable */
/* eslint-disable */

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type AttendanceStatus = "going" | "invited" | "notGoing" | "%future added value";
export type SocialEvent_socialEvent = {
    readonly id: string;
    readonly name: string;
    readonly title: string;
    readonly startDate: string;
    readonly endDate: string;
    readonly addressLine1: string | null;
    readonly addressLine2: string | null;
    readonly postCode: string | null;
    readonly bodyText: string;
    readonly imageURL: string | null;
    readonly userAttendanceStatus: AttendanceStatus | null;
    readonly " $refType": "SocialEvent_socialEvent";
};
export type SocialEvent_socialEvent$data = SocialEvent_socialEvent;
export type SocialEvent_socialEvent$key = {
    readonly " $data"?: SocialEvent_socialEvent$data;
    readonly " $fragmentRefs": FragmentRefs<"SocialEvent_socialEvent">;
};



const node: ReaderFragment = ({
    "kind": "Fragment",
    "name": "SocialEvent_socialEvent",
    "type": "SocialEvent",
    "metadata": null,
    "argumentDefinitions": [],
    "selections": [
        {
            "kind": "ScalarField",
            "alias": null,
            "name": "id",
            "args": null,
            "storageKey": null
        },
        {
            "kind": "ScalarField",
            "alias": null,
            "name": "name",
            "args": null,
            "storageKey": null
        },
        {
            "kind": "ScalarField",
            "alias": null,
            "name": "title",
            "args": null,
            "storageKey": null
        },
        {
            "kind": "ScalarField",
            "alias": null,
            "name": "startDate",
            "args": null,
            "storageKey": null
        },
        {
            "kind": "ScalarField",
            "alias": null,
            "name": "endDate",
            "args": null,
            "storageKey": null
        },
        {
            "kind": "ScalarField",
            "alias": null,
            "name": "addressLine1",
            "args": null,
            "storageKey": null
        },
        {
            "kind": "ScalarField",
            "alias": null,
            "name": "addressLine2",
            "args": null,
            "storageKey": null
        },
        {
            "kind": "ScalarField",
            "alias": null,
            "name": "postCode",
            "args": null,
            "storageKey": null
        },
        {
            "kind": "ScalarField",
            "alias": null,
            "name": "bodyText",
            "args": null,
            "storageKey": null
        },
        {
            "kind": "ScalarField",
            "alias": null,
            "name": "imageURL",
            "args": null,
            "storageKey": null
        },
        {
            "kind": "ScalarField",
            "alias": null,
            "name": "userAttendanceStatus",
            "args": null,
            "storageKey": null
        }
    ]
} as any);
(node as any).hash = '5f8f9222a534307e5a27d60565a504d6';
export default node;
