/* tslint:disable */
/* eslint-disable */

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type AdviceCard_advice = {
    readonly id: string;
    readonly name: string;
    readonly introduction: string;
    readonly description: string;
    readonly postedAt: string;
    readonly bannerImageURL: string | null;
    readonly links: ReadonlyArray<string> | null;
    readonly voiceoverURL: string | null;
    readonly videoURL: string | null;
    readonly " $refType": "AdviceCard_advice";
};
export type AdviceCard_advice$data = AdviceCard_advice;
export type AdviceCard_advice$key = {
    readonly " $data"?: AdviceCard_advice$data;
    readonly " $fragmentRefs": FragmentRefs<"AdviceCard_advice">;
};



const node: ReaderFragment = ({
    "kind": "Fragment",
    "name": "AdviceCard_advice",
    "type": "Advice",
    "metadata": null,
    "argumentDefinitions": [],
    "selections": [
        {
            "kind": "ScalarField",
            "alias": null,
            "name": "id",
            "args": null,
            "storageKey": null
        },
        {
            "kind": "ScalarField",
            "alias": null,
            "name": "name",
            "args": null,
            "storageKey": null
        },
        {
            "kind": "ScalarField",
            "alias": null,
            "name": "introduction",
            "args": null,
            "storageKey": null
        },
        {
            "kind": "ScalarField",
            "alias": null,
            "name": "description",
            "args": null,
            "storageKey": null
        },
        {
            "kind": "ScalarField",
            "alias": null,
            "name": "postedAt",
            "args": null,
            "storageKey": null
        },
        {
            "kind": "ScalarField",
            "alias": null,
            "name": "bannerImageURL",
            "args": null,
            "storageKey": null
        },
        {
            "kind": "ScalarField",
            "alias": null,
            "name": "links",
            "args": null,
            "storageKey": null
        },
        {
            "kind": "ScalarField",
            "alias": null,
            "name": "voiceoverURL",
            "args": null,
            "storageKey": null
        },
        {
            "kind": "ScalarField",
            "alias": null,
            "name": "videoURL",
            "args": null,
            "storageKey": null
        }
    ]
} as any);
(node as any).hash = '4b075e49760de1c6bfe5465b926b67f4';
export default node;
