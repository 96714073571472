import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CardMedia from '@material-ui/core/CardMedia';
import Spacer from 'components/core/Spacers/Spacer';
import moment from 'moment';
import theme from 'helpers/theme';
import { Grid } from '@material-ui/core';
import { Page as _Page } from 'components/Pagination/Paginator';
import { SocialEvent_socialEvent } from 'views/SocialEvent/__generated__/SocialEvent_socialEvent.graphql';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { useRouter } from 'found';
import { buildURL } from 'react-imgix';
import Button from 'components/core/Input/ButtonBase';
import { makeMutation } from 'api/helpers';
import { SetCandidateEventAttendance } from 'api/mutations';

const useStyles = makeStyles({
  media: {
    height: '280px',
    borderRadius: '5px'
  }
});

const Container = styled.div`
  @media (max-width: 1300px) {
    width: 700px;
  }
  @media (max-width: 1100px) {
    width: 600px;
  }
  @media (max-width: 1000px) {
    width: 400px;
  }
  @media (max-width: 750px) {
    width: 100vw;
  }

  width: 1000px;
  background: white;
  min-height: 100vh;
  border: 1px solid ${theme.colors.borderGrey};
`;

const Content = styled.div`
  padding: 42px 60px;
`;

const Heading = styled.div`
  font-weight: 700;
  font-size: 32px;
  color: ${theme.colors.primaryBlack};
  margin-bottom: 10px;
`;

const Title = styled.div`
  font-weight: 400;
  font-size: 16px;
  color: ${theme.colors.textGrey};
`;

const MediaContainer = styled.div`
  margin: 10px 10px 10px 0;
  border: 1px solid ${theme.colors.borderGrey};
  box-shadow: 0px 3px 18px #0000000d;
  border-radius: 5px;
`;

const Date = styled.div`
  font-weight: 400;
  font-size: 14px;
  color: ${theme.colors.textGrey};
`;

const Address = styled.div`
  font-weight: 400;
  font-size: 14px;
  color: ${theme.colors.textGrey};
`;

const BodyText = styled.div`
  font-weight: 300;
  font-size: 16px;
`;

const BackHeading = styled.div`
  border-bottom: 1px solid ${theme.colors.borderGrey};
  padding: 25px 60px;
  color: ${theme.colors.textGrey};
  cursor: pointer;
  font-size: 14px;
  font-weight: 600;
  display: flex;
  align-items: center;
  transition: all 0.2s;
  background: white;
  &:hover {
    color: #444750;
    background: #fbfbfb;
  }
`;

type Props = {
  event: SocialEvent_socialEvent;
};

function EventPage({ event }: Props) {
  const classes = useStyles();

  const {
    id,
    name,
    title,
    startDate,
    endDate,
    addressLine1,
    addressLine2,
    postCode,
    bodyText,
    imageURL,
    userAttendanceStatus
  } = event;
  
    let address = addressLine1
    
    if (postCode != undefined && postCode != null && postCode?.length > 2) {
      const addressLine = addressLine2 && addressLine2 != undefined && addressLine2 != null
    ? addressLine1?.concat(', ', addressLine2)
    : addressLine1;
      if (addressLine != undefined && addressLine != null) {
        address = addressLine?.concat(', ', postCode || "");
      }
    }

  const [attending, setAttending] = React.useState<boolean>(userAttendanceStatus === 'going');
  
  const handleSubmit = async () => {
    try {
      await makeMutation(SetCandidateEventAttendance, {
        eventID: id,
        status: !attending ? 'going' : 'notGoing'
      });
      setAttending(!attending);
    }catch(err){
      console.error(err);
    }
  };

  const { router } = useRouter();
  return (
    <Container>
      <BackHeading
        onClick={() => {
          router.push('/app/socialEvents');
        }}
      >
        <FontAwesomeIcon icon={faArrowLeft} />
        <Spacer horizontal spacing={2} />
        <span>Back to Events</span>
      </BackHeading>
      <Content>
        <Heading>{name}</Heading>
        <Title>{title}</Title>
        <Spacer vertical spacing={2} />
        <Grid>
          <MediaContainer>
            <CardMedia
              className={classes.media}
              image={
                imageURL
                  ? buildURL(imageURL, { bri: -5 })
                  : require(`../../assets/defaultImage.svg`)
              }
              title="Event image"
            />
          </MediaContainer>
          <Spacer vertical spacing={2} />
          <Date>{moment(startDate).format('hh:mmA - MMM DD, YYYY ')} - {moment(endDate).format('hh:mmA - MMM DD, YYYY ')} </Date>
          <Address> {address}</Address>
          <Spacer vertical spacing={2} />
          <BodyText style={{ whiteSpace: 'pre-line' }}> {bodyText}</BodyText>
          <Spacer vertical spacing={2} />
          <Button
            archetype="submit"
            style={{ background: attending ? 'red' : '#f37a21', float: 'right' }}
            onClick={handleSubmit}
          >
            {attending ? 'Decline' : 'Accept'} invite
          </Button>
          <Spacer vertical spacing={2} />
        </Grid>
      </Content>
    </Container>
  );
}

export default EventPage;
