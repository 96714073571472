/* tslint:disable */
/* eslint-disable */

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type CandidateType = "level1" | "level2" | "level3" | "%future added value";
export type PracticeSubSkillType = "both" | "sqe" | "tc" | "%future added value";
export type CompetencySkillsPage_candidate = {
    readonly id: string;
    readonly firstName: string;
    readonly lastName: string;
    readonly skillGroupType: string | null;
    readonly candidateType: CandidateType;
    readonly areaProgress: ReadonlyArray<{
        readonly practiceArea: {
            readonly id: string;
            readonly name: string;
            readonly practiceSkills: {
                readonly edges: ReadonlyArray<{
                    readonly id: string;
                    readonly name: string;
                    readonly practiceSkillGroups: {
                        readonly edges: ReadonlyArray<{
                            readonly id: string;
                            readonly name: string;
                            readonly practiceSubSkills: {
                                readonly edges: ReadonlyArray<{
                                    readonly id: string;
                                    readonly name: string;
                                    readonly practiceSubSkillType: PracticeSubSkillType;
                                    readonly minSuggestedEntryCount: number;
                                    readonly doneEntryCount: number;
                                    readonly order: number;
                                } | null> | null;
                            } | null;
                        } | null> | null;
                    } | null;
                } | null> | null;
            } | null;
        };
    }> | null;
    readonly " $refType": "CompetencySkillsPage_candidate";
};
export type CompetencySkillsPage_candidate$data = CompetencySkillsPage_candidate;
export type CompetencySkillsPage_candidate$key = {
    readonly " $data"?: CompetencySkillsPage_candidate$data;
    readonly " $fragmentRefs": FragmentRefs<"CompetencySkillsPage_candidate">;
};



const node: ReaderFragment = (function () {
    var v0 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "id",
        "args": null,
        "storageKey": null
    } as any), v1 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "name",
        "args": null,
        "storageKey": null
    } as any);
    return {
        "kind": "Fragment",
        "name": "CompetencySkillsPage_candidate",
        "type": "Candidate",
        "metadata": null,
        "argumentDefinitions": [],
        "selections": [
            (v0 /*: any*/),
            {
                "kind": "ScalarField",
                "alias": null,
                "name": "firstName",
                "args": null,
                "storageKey": null
            },
            {
                "kind": "ScalarField",
                "alias": null,
                "name": "lastName",
                "args": null,
                "storageKey": null
            },
            {
                "kind": "ScalarField",
                "alias": null,
                "name": "skillGroupType",
                "args": null,
                "storageKey": null
            },
            {
                "kind": "ScalarField",
                "alias": null,
                "name": "candidateType",
                "args": null,
                "storageKey": null
            },
            {
                "kind": "LinkedField",
                "alias": null,
                "name": "areaProgress",
                "storageKey": null,
                "args": null,
                "concreteType": "PracticeAreaProgress",
                "plural": true,
                "selections": [
                    {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "practiceArea",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "PracticeArea",
                        "plural": false,
                        "selections": [
                            (v0 /*: any*/),
                            (v1 /*: any*/),
                            {
                                "kind": "LinkedField",
                                "alias": null,
                                "name": "practiceSkills",
                                "storageKey": null,
                                "args": null,
                                "concreteType": "PracticeSkillPage",
                                "plural": false,
                                "selections": [
                                    {
                                        "kind": "LinkedField",
                                        "alias": null,
                                        "name": "edges",
                                        "storageKey": null,
                                        "args": null,
                                        "concreteType": "PracticeSkill",
                                        "plural": true,
                                        "selections": [
                                            (v0 /*: any*/),
                                            (v1 /*: any*/),
                                            {
                                                "kind": "LinkedField",
                                                "alias": null,
                                                "name": "practiceSkillGroups",
                                                "storageKey": null,
                                                "args": null,
                                                "concreteType": "PracticeSkillGroupPage",
                                                "plural": false,
                                                "selections": [
                                                    {
                                                        "kind": "LinkedField",
                                                        "alias": null,
                                                        "name": "edges",
                                                        "storageKey": null,
                                                        "args": null,
                                                        "concreteType": "PracticeSkillGroup",
                                                        "plural": true,
                                                        "selections": [
                                                            (v0 /*: any*/),
                                                            (v1 /*: any*/),
                                                            {
                                                                "kind": "LinkedField",
                                                                "alias": null,
                                                                "name": "practiceSubSkills",
                                                                "storageKey": null,
                                                                "args": null,
                                                                "concreteType": "PracticeSubSkillPage",
                                                                "plural": false,
                                                                "selections": [
                                                                    {
                                                                        "kind": "LinkedField",
                                                                        "alias": null,
                                                                        "name": "edges",
                                                                        "storageKey": null,
                                                                        "args": null,
                                                                        "concreteType": "PracticeSubSkill",
                                                                        "plural": true,
                                                                        "selections": [
                                                                            (v0 /*: any*/),
                                                                            (v1 /*: any*/),
                                                                            {
                                                                                "kind": "ScalarField",
                                                                                "alias": null,
                                                                                "name": "practiceSubSkillType",
                                                                                "args": null,
                                                                                "storageKey": null
                                                                            },
                                                                            {
                                                                                "kind": "ScalarField",
                                                                                "alias": null,
                                                                                "name": "minSuggestedEntryCount",
                                                                                "args": null,
                                                                                "storageKey": null
                                                                            },
                                                                            {
                                                                                "kind": "ScalarField",
                                                                                "alias": null,
                                                                                "name": "doneEntryCount",
                                                                                "args": null,
                                                                                "storageKey": null
                                                                            },
                                                                            {
                                                                                "kind": "ScalarField",
                                                                                "alias": null,
                                                                                "name": "order",
                                                                                "args": null,
                                                                                "storageKey": null
                                                                            }
                                                                        ]
                                                                    }
                                                                ]
                                                            }
                                                        ]
                                                    }
                                                ]
                                            }
                                        ]
                                    }
                                ]
                            }
                        ]
                    }
                ]
            }
        ]
    } as any;
})();
(node as any).hash = '03232f4e2ecf6452f5243053592f8022';
export default node;
