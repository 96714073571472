import * as React from 'react';
import SideModal from 'components/SideModal/SideModal';
import Tabs from 'components/SideModal/Tabs';
import { classroomCourseTab, tabList } from './Tabs';
import { Course } from 'components/core/Input/SearchableDropdown';
import { QueryRenderer, graphql } from 'react-relay';
import environment from 'api/environment';
import { SingleUser_courses_Query } from './__generated__/SingleUser_courses_Query.graphql';

const trainingInitialValue = [
  {
    label:
      'I Fred Eccs can confirm that the above delegates have undergone the necessary 5-year background checks for this Training Programme',
    checked: false
  }
];

const ToBInitialValue = [
  {
    label:
      'I Fred Eccs can confirm that I have read and understood the TTC Terms of Business',
    checked: false
  }
];

type Props = {
  isOpen: boolean;
  onClose: Function;
  initialCourses?: Course[];
  categories: SingleUser_courses_Query['response']['categories'];
  isClassroom: boolean;
};

const SingleUser = ({
  isOpen,
  onClose,
  initialCourses = [],
  categories,
  isClassroom
}: Props) => {
  console.log('initial', initialCourses);
  return (
    <SideModal
      isOpen={isOpen}
      title="Book a new course"
      closeModal={() => onClose()}
    >
      <Tabs
        content={isClassroom ? [classroomCourseTab, ...tabList] : tabList}
        closeModal={() => onClose()}
        initialState={{
          courses: initialCourses,
          training: trainingInitialValue,
          ToB: ToBInitialValue,
          categories: categories ? categories : { edges: [] }
        }}
        disableSwitching
      />
    </SideModal>
  );
};

type InputProps = {
  isOpen: boolean;
  onClose: Function;
  initialCourse?: number;
  userUUID?: string;
};

export default (singleUserProps: InputProps) => {
  const queryCourse = singleUserProps.initialCourse
    ? graphql`
        query SingleUser_courses_Query($courseID: Int!) {
          course(id: $courseID) {
            ident: id
            name
            price
            specificTerms
            type
          }
          categories {
            edges {
              name
              uuid
              classroomOnly
              secondaryCategories {
                uuid
                name
                hasCourses
              }
            }
          }
        }
      `
    : graphql`
        query SingleUser_categories_Query {
          categories {
            edges {
              name
              uuid
              classroomOnly
              secondaryCategories {
                uuid
                name
                hasCourses
              }
            }
          }
        }
      `;
  return (
    <QueryRenderer
      environment={environment}
      query={queryCourse}
      variables={{
        courseID: singleUserProps.initialCourse
      }}
      render={({ error, props }: any) => {
        if (props) {
          if (props.course) {
            return (
              <SingleUser
                {...singleUserProps}
                categories={props.categories}
                initialCourses={[{
                  id: props.course.ident,
                  name: props.course.name,
                  price: props.course.price,
                  specificTerms: props.course.specificTerms
                }]}
                isClassroom={props.course.type === 'classroom'}
              />
            );
          } else {
            return (
              <SingleUser {...singleUserProps} categories={props.categories} isClassroom={props.categories.edges[0] && props.categories.edges[0].classroomOnly} />
            );
          }
        }

        return <SingleUser {...singleUserProps} categories={{ edges: [] }} isClassroom={false} />;
      }}
    />
  );
};
