import * as React from 'react';
import { createUseStyles, useTheme } from 'react-jss';
import { Theme } from 'helpers/theme';
import GenericPopup from './GenericPopup';
import Button from 'components/core/Input/ButtonBase';
import Spacer from 'components/core/Spacers/Spacer';

const useStyles = createUseStyles((theme: Theme) => ({
  tryAgainRoot: {},
  bodyText: {
    fontSize: 22,
    fontWeight: 300
  },
  buttons: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: theme.spacing(4)
  },
  heading: {
    fontWeight: 900,
    fontSize: 38,
    color: theme.colors.primaryBlack
  }
}));

type Props = {
  open: boolean;
  firstName: string;
  onClose: () => void;
  onRetake: () => void;
};

function TryAgain({ open, firstName, onClose, onRetake }: Props) {
  const theme = useTheme();
  const classes = useStyles({ theme });

  if (!open) {
    return null;
  }
  return (
    <GenericPopup
      open={open}
      onClose={onClose}
      title={`Try Again, ${firstName}...`}
    >
      <h1 className={classes.heading}>Try again...</h1>
      <p className={classes.bodyText}>
        {firstName} - sorry but on this occasion you have failed to meet the
        pass criteria to complete this test
      </p>
      <div className={classes.buttons}>
        <Button archetype="submit" onClick={onRetake}>
          Retake Immediately
        </Button>
        <Spacer horizontal spacing={2} />
        <Button archetype="grey" onClick={onClose}>
          Return to Dashboard
        </Button>
      </div>
    </GenericPopup>
  );
}

export default TryAgain;
