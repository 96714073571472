/* tslint:disable */
/* eslint-disable */

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type ContentiousType = "both" | "contentious" | "non_contentious" | "%future added value";
export type SignoffGenerator_node = {
    readonly candidate: {
        readonly firstName: string;
        readonly lastName: string;
    } | null;
    readonly entries: {
        readonly edges: ReadonlyArray<{
            readonly id: string;
            readonly title: string;
            readonly startDate: string;
            readonly endDate: string;
            readonly experience: string;
            readonly evidence: string;
            readonly learnt: string;
            readonly moreExperience: string;
            readonly taskedBy: string | null;
            readonly contentious: ContentiousType;
            readonly needMoreExperience: string;
            readonly fileUrl: string | null;
            readonly practiceAreas: ReadonlyArray<number> | null;
            readonly practiceSubSkills: ReadonlyArray<{
                readonly practiceSkillGroup: {
                    readonly practiceSkill: {
                        readonly id: string;
                        readonly name: string;
                    } | null;
                    readonly name: string;
                } | null;
                readonly id: string;
                readonly name: string;
            }> | null;
        } | null> | null;
    } | null;
    readonly " $refType": "SignoffGenerator_node";
};
export type SignoffGenerator_node$data = SignoffGenerator_node;
export type SignoffGenerator_node$key = {
    readonly " $data"?: SignoffGenerator_node$data;
    readonly " $fragmentRefs": FragmentRefs<"SignoffGenerator_node">;
};



const node: ReaderFragment = (function () {
    var v0 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "id",
        "args": null,
        "storageKey": null
    } as any), v1 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "name",
        "args": null,
        "storageKey": null
    } as any);
    return {
        "kind": "Fragment",
        "name": "SignoffGenerator_node",
        "type": "SignoffRequest",
        "metadata": null,
        "argumentDefinitions": [],
        "selections": [
            {
                "kind": "LinkedField",
                "alias": null,
                "name": "candidate",
                "storageKey": null,
                "args": null,
                "concreteType": "Candidate",
                "plural": false,
                "selections": [
                    {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "firstName",
                        "args": null,
                        "storageKey": null
                    },
                    {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "lastName",
                        "args": null,
                        "storageKey": null
                    }
                ]
            },
            {
                "kind": "LinkedField",
                "alias": null,
                "name": "entries",
                "storageKey": null,
                "args": null,
                "concreteType": "EntryPage",
                "plural": false,
                "selections": [
                    {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "edges",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "QWEEntry",
                        "plural": true,
                        "selections": [
                            (v0 /*: any*/),
                            {
                                "kind": "ScalarField",
                                "alias": null,
                                "name": "title",
                                "args": null,
                                "storageKey": null
                            },
                            {
                                "kind": "ScalarField",
                                "alias": null,
                                "name": "startDate",
                                "args": null,
                                "storageKey": null
                            },
                            {
                                "kind": "ScalarField",
                                "alias": null,
                                "name": "endDate",
                                "args": null,
                                "storageKey": null
                            },
                            {
                                "kind": "ScalarField",
                                "alias": null,
                                "name": "experience",
                                "args": null,
                                "storageKey": null
                            },
                            {
                                "kind": "ScalarField",
                                "alias": null,
                                "name": "evidence",
                                "args": null,
                                "storageKey": null
                            },
                            {
                                "kind": "ScalarField",
                                "alias": null,
                                "name": "learnt",
                                "args": null,
                                "storageKey": null
                            },
                            {
                                "kind": "ScalarField",
                                "alias": null,
                                "name": "moreExperience",
                                "args": null,
                                "storageKey": null
                            },
                            {
                                "kind": "ScalarField",
                                "alias": null,
                                "name": "taskedBy",
                                "args": null,
                                "storageKey": null
                            },
                            {
                                "kind": "ScalarField",
                                "alias": null,
                                "name": "contentious",
                                "args": null,
                                "storageKey": null
                            },
                            {
                                "kind": "ScalarField",
                                "alias": null,
                                "name": "needMoreExperience",
                                "args": null,
                                "storageKey": null
                            },
                            {
                                "kind": "ScalarField",
                                "alias": null,
                                "name": "fileUrl",
                                "args": null,
                                "storageKey": null
                            },
                            {
                                "kind": "ScalarField",
                                "alias": null,
                                "name": "practiceAreas",
                                "args": null,
                                "storageKey": null
                            },
                            {
                                "kind": "LinkedField",
                                "alias": null,
                                "name": "practiceSubSkills",
                                "storageKey": null,
                                "args": null,
                                "concreteType": "PracticeSubSkill",
                                "plural": true,
                                "selections": [
                                    {
                                        "kind": "LinkedField",
                                        "alias": null,
                                        "name": "practiceSkillGroup",
                                        "storageKey": null,
                                        "args": null,
                                        "concreteType": "PracticeSkillGroup",
                                        "plural": false,
                                        "selections": [
                                            {
                                                "kind": "LinkedField",
                                                "alias": null,
                                                "name": "practiceSkill",
                                                "storageKey": null,
                                                "args": null,
                                                "concreteType": "PracticeSkill",
                                                "plural": false,
                                                "selections": [
                                                    (v0 /*: any*/),
                                                    (v1 /*: any*/)
                                                ]
                                            },
                                            (v1 /*: any*/)
                                        ]
                                    },
                                    (v0 /*: any*/),
                                    (v1 /*: any*/)
                                ]
                            }
                        ]
                    }
                ]
            }
        ]
    } as any;
})();
(node as any).hash = '3198ec3e35a974e41ed0f1bfab6a6eb0';
export default node;
