/* tslint:disable */
/* eslint-disable */

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type AllCourses_courses = {
    readonly edges: ReadonlyArray<{
        readonly " $fragmentRefs": FragmentRefs<"CourseCard_course">;
    } | null> | null;
    readonly pageInfo: {
        readonly given: number;
        readonly total: number;
    } | null;
    readonly " $refType": "AllCourses_courses";
};
export type AllCourses_courses$data = AllCourses_courses;
export type AllCourses_courses$key = {
    readonly " $data"?: AllCourses_courses$data;
    readonly " $fragmentRefs": FragmentRefs<"AllCourses_courses">;
};



const node: ReaderFragment = ({
    "kind": "Fragment",
    "name": "AllCourses_courses",
    "type": "CoursePage",
    "metadata": null,
    "argumentDefinitions": [],
    "selections": [
        {
            "kind": "LinkedField",
            "alias": null,
            "name": "edges",
            "storageKey": null,
            "args": null,
            "concreteType": null,
            "plural": true,
            "selections": [
                {
                    "kind": "FragmentSpread",
                    "name": "CourseCard_course",
                    "args": null
                }
            ]
        },
        {
            "kind": "LinkedField",
            "alias": null,
            "name": "pageInfo",
            "storageKey": null,
            "args": null,
            "concreteType": "PageInfo",
            "plural": false,
            "selections": [
                {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "given",
                    "args": null,
                    "storageKey": null
                },
                {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "total",
                    "args": null,
                    "storageKey": null
                }
            ]
        }
    ]
} as any);
(node as any).hash = 'e21b6b5d5baa35daa1fd8c7b74a0cc23';
export default node;
