/* tslint:disable */
/* eslint-disable */
/* @relayHash b4851ddd80454dceb0645bcc03267744 */

import { ConcreteRequest } from "relay-runtime";
export type QualificationType = "a_level" | "degree" | "gdl" | "lpc" | "other" | "practicing_cert" | "qualification_cert" | "sqe1" | "sqe2" | "%future added value";
export type FileUpload = {
    fileBase64: string;
    fileType: string;
    fileName?: string | null;
};
export type mutationsUpdateQualificationMutationVariables = {
    id: string;
    name?: string | null;
    qualificationType?: QualificationType | null;
    file?: FileUpload | null;
    startDate?: string | null;
    endDate?: string | null;
    grade?: string | null;
};
export type mutationsUpdateQualificationMutationResponse = {
    readonly updateQualification: {
        readonly id: string;
        readonly qualificationType: QualificationType;
        readonly name: string;
        readonly fileUrl: string;
        readonly startDate: string | null;
        readonly endDate: string | null;
        readonly grade: string | null;
    } | null;
};
export type mutationsUpdateQualificationMutation = {
    readonly response: mutationsUpdateQualificationMutationResponse;
    readonly variables: mutationsUpdateQualificationMutationVariables;
};



/*
mutation mutationsUpdateQualificationMutation(
  $id: ID!
  $name: String
  $qualificationType: QualificationType
  $file: FileUpload
  $startDate: Time
  $endDate: Time
  $grade: String
) {
  updateQualification(input: {id: $id, name: $name, qualificationType: $qualificationType, startDate: $startDate, endDate: $endDate, grade: $grade, file: $file}) {
    id
    qualificationType
    name
    fileUrl
    startDate
    endDate
    grade
  }
}
*/

const node: ConcreteRequest = (function () {
    var v0 = [
        ({
            "kind": "LocalArgument",
            "name": "id",
            "type": "ID!",
            "defaultValue": null
        } as any),
        ({
            "kind": "LocalArgument",
            "name": "name",
            "type": "String",
            "defaultValue": null
        } as any),
        ({
            "kind": "LocalArgument",
            "name": "qualificationType",
            "type": "QualificationType",
            "defaultValue": null
        } as any),
        ({
            "kind": "LocalArgument",
            "name": "file",
            "type": "FileUpload",
            "defaultValue": null
        } as any),
        ({
            "kind": "LocalArgument",
            "name": "startDate",
            "type": "Time",
            "defaultValue": null
        } as any),
        ({
            "kind": "LocalArgument",
            "name": "endDate",
            "type": "Time",
            "defaultValue": null
        } as any),
        ({
            "kind": "LocalArgument",
            "name": "grade",
            "type": "String",
            "defaultValue": null
        } as any)
    ], v1 = [
        ({
            "kind": "LinkedField",
            "alias": null,
            "name": "updateQualification",
            "storageKey": null,
            "args": [
                {
                    "kind": "ObjectValue",
                    "name": "input",
                    "fields": [
                        {
                            "kind": "Variable",
                            "name": "endDate",
                            "variableName": "endDate"
                        },
                        {
                            "kind": "Variable",
                            "name": "file",
                            "variableName": "file"
                        },
                        {
                            "kind": "Variable",
                            "name": "grade",
                            "variableName": "grade"
                        },
                        {
                            "kind": "Variable",
                            "name": "id",
                            "variableName": "id"
                        },
                        {
                            "kind": "Variable",
                            "name": "name",
                            "variableName": "name"
                        },
                        {
                            "kind": "Variable",
                            "name": "qualificationType",
                            "variableName": "qualificationType"
                        },
                        {
                            "kind": "Variable",
                            "name": "startDate",
                            "variableName": "startDate"
                        }
                    ]
                }
            ],
            "concreteType": "Qualification",
            "plural": false,
            "selections": [
                {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "id",
                    "args": null,
                    "storageKey": null
                },
                {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "qualificationType",
                    "args": null,
                    "storageKey": null
                },
                {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "name",
                    "args": null,
                    "storageKey": null
                },
                {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "fileUrl",
                    "args": null,
                    "storageKey": null
                },
                {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "startDate",
                    "args": null,
                    "storageKey": null
                },
                {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "endDate",
                    "args": null,
                    "storageKey": null
                },
                {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "grade",
                    "args": null,
                    "storageKey": null
                }
            ]
        } as any)
    ];
    return {
        "kind": "Request",
        "fragment": {
            "kind": "Fragment",
            "name": "mutationsUpdateQualificationMutation",
            "type": "Mutation",
            "metadata": null,
            "argumentDefinitions": (v0 /*: any*/),
            "selections": (v1 /*: any*/)
        },
        "operation": {
            "kind": "Operation",
            "name": "mutationsUpdateQualificationMutation",
            "argumentDefinitions": (v0 /*: any*/),
            "selections": (v1 /*: any*/)
        },
        "params": {
            "operationKind": "mutation",
            "name": "mutationsUpdateQualificationMutation",
            "id": null,
            "text": "mutation mutationsUpdateQualificationMutation(\n  $id: ID!\n  $name: String\n  $qualificationType: QualificationType\n  $file: FileUpload\n  $startDate: Time\n  $endDate: Time\n  $grade: String\n) {\n  updateQualification(input: {id: $id, name: $name, qualificationType: $qualificationType, startDate: $startDate, endDate: $endDate, grade: $grade, file: $file}) {\n    id\n    qualificationType\n    name\n    fileUrl\n    startDate\n    endDate\n    grade\n  }\n}\n",
            "metadata": {}
        }
    } as any;
})();
(node as any).hash = 'd2549d2b06c511a2ee5387902ebca15d';
export default node;
