import React from 'react';
import SingleUser from 'components/SingleUser';
import TryAgain from 'components/CoursePopups/TryAgain';
import Congratulations from 'components/CoursePopups/Congratulations';
import Failed from 'components/CoursePopups/Failed';
import QualificationModal from 'components/QualificationSlideIn/QualificationModal';
import PlacementModal from 'components/PlacementSlideIn/PlacementModal';
import SendEntriesModal from 'components/SendEntriesSlideIn/SendEntriesModal';
import SendMeetingRequestModal from 'components/SendMeetingRequestSlideIn/SendMeetingRequestModal';
import { Qualification } from 'components/QualificationCard';
import OtherQWEModal from 'components/OtherQWESlideIn/OtherQWEModal';
import { Placement } from 'components/PlacementCard';

type State = {
  shown: ModalType;
  courseId?: number;
  firstName: string;
  onClose: () => void;
  onRetake: () => void;
  courseName: string;
  qualificationType?: string;
  prefillQualification?: Qualification;
  candidateID?: string;
  clients?: { id: string; title: string }[];
  supervisorID?: string;
  mentorID?: string;
  page?: { offset: number; limit: number };
  placementID?: string;
  meetingRequestType?: string;
  prefillPlacement?: Placement;
  isCandidateTC: boolean;
};

type ModalType =
  | 'singleuser'
  | 'tryagain'
  | 'complete'
  | 'failed'
  | 'qualification'
  | 'placement'
  | 'otherQWE'
  | 'sendentries'
  | 'sendmeetingrequest'
  | false;
type SingleUserAction = {
  modal: 'singleuser';
  courseId?: number;
};
type TryAgainAction = {
  modal: 'tryagain';
  firstName: string;
  onClose: () => void;
  onRetake?: () => void;
};
type CompleteAction = {
  modal: 'complete';
  firstName: string;
  onClose: () => void;
  courseName: string;
};
type FailedAction = {
  modal: 'failed';
  firstName: string;
  onClose: () => void;
};
type QualificationAction = {
  modal: 'qualification';
  qualificationType?: string;
  prefillQualification?: Qualification;
};
type PlacementAction = {
  modal: 'placement';
  candidateID: string;
  isCandidateTC: boolean;
  clients?: { id: string; title: string }[];
  prefillPlacement?: Placement;
  onClose?: () => void;
};
type OtherQWEAction = {
  modal: 'otherQWE';
  candidateID: string;
  page?: { offset: number; limit: number };
};
type SendEntriesAction = {
  modal: 'sendentries';
  supervisorID?: string;
  mentorID?: string;
  placementID: string;
};
type SendMeetingRequestAction = {
  modal: 'sendmeetingrequest';
  mentorID: string;
  meetingRequestType: string;
};
type Close = {
  modal: false;
};

type Action =
  | SingleUserAction
  | TryAgainAction
  | CompleteAction
  | FailedAction
  | QualificationAction
  | PlacementAction
  | OtherQWEAction
  | SendEntriesAction
  | SendMeetingRequestAction
  | Close;
type Dispatch = (action: Action) => void;

const SideModalDispatchContext = React.createContext<Dispatch | undefined>(
  undefined
);

const initState: State = {
  shown: false,
  courseId: undefined,
  firstName: '',
  onClose: () => {},
  onRetake: () => {},
  courseName: '',
  isCandidateTC: false
};

function sideModalReducer(state: State, action: Action) {
  var stateUpdates = {};
  switch (action.modal) {
    case 'singleuser':
      stateUpdates = { shown: 'singleuser', courseId: action.courseId };
      break;
    case 'complete':
      stateUpdates = {
        shown: 'complete',
        firstName: action.firstName,
        onClose: action.onClose,
        courseName: action.courseName
      };
      break;
    case 'tryagain':
      stateUpdates = {
        shown: 'tryagain',
        firstName: action.firstName,
        onClose: action.onClose
      };
      break;
    case 'failed':
      stateUpdates = {
        shown: 'failed',
        firstName: action.firstName,
        onClose: action.onClose
      };
      break;
    case 'qualification':
      stateUpdates = {
        shown: 'qualification',
        qualificationType: action.qualificationType,
        prefillQualification: action.prefillQualification
      };
      break;
    case 'placement':
      stateUpdates = {
        shown: 'placement',
        candidateID: action.candidateID,
        clients: action.clients,
        prefillPlacement: action.prefillPlacement,
        onClose: action.onClose,
        isCandidateTC: action.isCandidateTC
      };
      break;
    case 'otherQWE':
      stateUpdates = {
        shown: 'otherQWE',
        candidateID: action.candidateID,
        page: action.page
      };
      break;
    case 'sendentries':
      stateUpdates = {
        shown: 'sendentries',
        supervisorID: action.supervisorID,
        mentorID: action.mentorID,
        placementID: action.placementID
      };
      break;
    case 'sendmeetingrequest':
      stateUpdates = {
        shown: 'sendmeetingrequest',
        mentorID: action.mentorID,
        meetingRequestType: action.meetingRequestType
      };
      break;
    case false:
      stateUpdates = {
        shown: false
      };
      break;
  }

  var newState: State = { ...initState, ...stateUpdates };
  return newState;
}

function SideModalProvider({ children }: { children: React.ReactNode }) {
  const [state, dispatch] = React.useReducer(sideModalReducer, initState);

  return (
    <SideModalDispatchContext.Provider value={dispatch}>
      <SingleUser
        isOpen={state.shown == 'singleuser'}
        onClose={() => {
          dispatch({ modal: false });
        }}
        initialCourse={state.courseId}
      />
      <TryAgain
        open={state.shown == 'tryagain'}
        firstName={state.firstName}
        onClose={() => {
          state.onClose();
          dispatch({ modal: false });
        }}
        onRetake={() => {
          state.onRetake();
          dispatch({ modal: false });
        }}
      />
      <Congratulations
        open={state.shown == 'complete'}
        firstName={state.firstName}
        onClose={() => {
          state.onClose();
          dispatch({ modal: false });
        }}
        courseName={state.courseName}
      />
      <Failed
        open={state.shown == 'failed'}
        firstName={state.firstName}
        onClose={() => {
          state.onClose();
          dispatch({ modal: false });
        }}
      />
      <QualificationModal
        isOpen={state.shown == 'qualification'}
        onClose={() => dispatch({ modal: false })}
        qualificationType={state.qualificationType}
        prefillState={state.prefillQualification}
      />
      <PlacementModal
        isOpen={state.shown == 'placement'}
        candidateID={state.candidateID ?? ''}
        clients={state.clients}
        onClose={() => {
          state.onClose();
          dispatch({ modal: false });
        }}
        prefillState={state.prefillPlacement}
        isCandidateTC={state.isCandidateTC}
      />
      <OtherQWEModal
        isOpen={state.shown == 'otherQWE'}
        candidateID={state.candidateID ?? ''}
        page={state.page ?? { offset: 0, limit: 5 }}
        onClose={() => dispatch({ modal: false })}
      />
      <SendEntriesModal
        isOpen={state.shown == 'sendentries'}
        supervisorID={state.supervisorID ?? ''}
        mentorID={state.mentorID ?? ''}
        placementID={state.placementID ?? ''}
        onClose={() => dispatch({ modal: false })}
      />
      <SendMeetingRequestModal
        isOpen={state.shown == 'sendmeetingrequest'}
        mentorID={state.mentorID ?? ''}
        meetingRequestType={state.meetingRequestType ?? ''}
        onClose={() => dispatch({ modal: false })}
      />
      {children}
    </SideModalDispatchContext.Provider>
  );
}

function useSideModalDispatch() {
  const context = React.useContext(SideModalDispatchContext);
  if (context === undefined) {
    throw new Error('SideModalDispatch must be used with SideModalProvider');
  }
  return context;
}

export { SideModalProvider, useSideModalDispatch };
