import React from 'react';
import styled from 'styled-components';
import MediaQuery from 'react-responsive';
import Dropdown from 'components/core/Input/Dropdown';
import { makeStyles, Tab, Tabs } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  tabIndictator: {
    background: 'black'
  }
}));

const Container = styled.div`
  position: relative;
`;

const Runner = styled.div`
  height: 2px;
  width: 100%;
  background: #cccdce;
  position: absolute;
  bottom: 0px;
  z-index: -1;
`;

const Items = styled.div`
  display: flex;
`;

const Item = styled.span`
  text-transform: none;
  font-size: 15px;
  color: black;
  letter-spacing: normal;
  font-weight: 400;
`;

export type SelectorItem = {
  text: string;
  value: string;
  component?: React.ReactElement;
};

type Props = {
  items: SelectorItem[];
  skillGroupType: string | null,
  hiddenQualifications: Number;
  selected: string;
  onChange: (item: SelectorItem) => void;
};

function ListSelector({ items, skillGroupType, hiddenQualifications, selected, onChange }: Props) {
  const selectedItem = items.find((item) => item.value == selected);
  const classes = useStyles();

  const isHidden = (index: number) => {
    if (skillGroupType == "SQE") {
      if (index == 4) {
        return true;
      }
    }

    let res = hiddenQualifications.toString(2);
    if (res == "-1") { res = "0"; }
    if (res.length > index && res.charAt(res.length - index - 1) == "1") {
      return true;
    }
    return false;
  }

  return (
    <Container>
      <MediaQuery minWidth={1200}>
        <Tabs
          onChange={(_, val) => onChange(items.find(item => item.value === val) || items[0])}
          variant="scrollable"
          scrollButtons="auto"
          value={selected}
          classes={{
            indicator: classes.tabIndictator
          }}
        >
          {items.filter((_, index) => !isHidden(index)).map((item) => (
            <Tab
              key={item.value}
              label={item.component ? 
                item.component : 
                <Item>{item.text}</Item>
              }
              value={item.value}
              disableRipple
            />
          ))}
        </Tabs>
        <Runner />
      </MediaQuery>
      <MediaQuery maxWidth={1200}>
        <Dropdown
          options={items.map((item) => ({ id: item.value, title: item.text }))}
          placeholder={'Select a section'}
          setSelected={(dropItem) => {
            if (typeof dropItem.id == 'number') {
              console.error('ListSelector cannot have number ids');
              return;
            }
            onChange({
              text: dropItem.title,
              value: dropItem.id
            });
          }}
          selected={
            selectedItem && {
              id: selectedItem?.value,
              title: selectedItem?.text
            }
          }
        />
      </MediaQuery>
    </Container>
  );
}

export default ListSelector;
