/* tslint:disable */
/* eslint-disable */
/* @relayHash 4a7e1f1fff6d93279da6a5ea2da96afb */

import { ConcreteRequest } from "relay-runtime";
export type Page = {
    offset?: number | null;
    limit?: number | null;
};
export type OrderBy = {
    ascending?: boolean | null;
    field: string;
};
export type AvailabilityDropdown_QueryVariables = {
    mentorID: string;
    booked: boolean;
    page: Page;
    orderBy?: OrderBy | null;
};
export type AvailabilityDropdown_QueryResponse = {
    readonly availability: {
        readonly edges: ReadonlyArray<{
            readonly id: string;
            readonly startTime: string;
            readonly endTime: string;
            readonly interviewType: string;
        } | null> | null;
        readonly pageInfo: {
            readonly total: number;
            readonly offset: number;
            readonly limit: number;
            readonly given: number;
        } | null;
    } | null;
};
export type AvailabilityDropdown_Query = {
    readonly response: AvailabilityDropdown_QueryResponse;
    readonly variables: AvailabilityDropdown_QueryVariables;
};



/*
query AvailabilityDropdown_Query(
  $mentorID: ID!
  $booked: Boolean!
  $page: Page!
  $orderBy: OrderBy
) {
  availability(filter: {mentorID: $mentorID, booked: $booked}, page: $page, orderBy: $orderBy) {
    edges {
      id
      startTime
      endTime
    }
    pageInfo {
      total
      offset
      limit
      given
    }
  }
}
*/

const node: ConcreteRequest = (function () {
    var v0 = [
        ({
            "kind": "LocalArgument",
            "name": "mentorID",
            "type": "ID!",
            "defaultValue": null
        } as any),
        ({
            "kind": "LocalArgument",
            "name": "booked",
            "type": "Boolean!",
            "defaultValue": null
        } as any),
        ({
            "kind": "LocalArgument",
            "name": "page",
            "type": "Page!",
            "defaultValue": null
        } as any),
        ({
            "kind": "LocalArgument",
            "name": "orderBy",
            "type": "OrderBy",
            "defaultValue": null
        } as any)
    ], v1 = [
        ({
            "kind": "LinkedField",
            "alias": null,
            "name": "availability",
            "storageKey": null,
            "args": [
                {
                    "kind": "ObjectValue",
                    "name": "filter",
                    "fields": [
                        {
                            "kind": "Variable",
                            "name": "booked",
                            "variableName": "booked"
                        },
                        {
                            "kind": "Variable",
                            "name": "mentorID",
                            "variableName": "mentorID"
                        }
                    ]
                },
                {
                    "kind": "Variable",
                    "name": "orderBy",
                    "variableName": "orderBy"
                },
                {
                    "kind": "Variable",
                    "name": "page",
                    "variableName": "page"
                }
            ],
            "concreteType": "AvailabilityPage",
            "plural": false,
            "selections": [
                {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "edges",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "AvailabilitySlot",
                    "plural": true,
                    "selections": [
                        {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "id",
                            "args": null,
                            "storageKey": null
                        },
                        {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "startTime",
                            "args": null,
                            "storageKey": null
                        },
                        {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "endTime",
                            "args": null,
                            "storageKey": null
                        },
                        {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "interviewType",
                            "args": null,
                            "storageKey": null
                        }
                    ]
                },
                {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "pageInfo",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "PageInfo",
                    "plural": false,
                    "selections": [
                        {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "total",
                            "args": null,
                            "storageKey": null
                        },
                        {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "offset",
                            "args": null,
                            "storageKey": null
                        },
                        {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "limit",
                            "args": null,
                            "storageKey": null
                        },
                        {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "given",
                            "args": null,
                            "storageKey": null
                        }
                    ]
                }
            ]
        } as any)
    ];
    return {
        "kind": "Request",
        "fragment": {
            "kind": "Fragment",
            "name": "AvailabilityDropdown_Query",
            "type": "Query",
            "metadata": null,
            "argumentDefinitions": (v0 /*: any*/),
            "selections": (v1 /*: any*/)
        },
        "operation": {
            "kind": "Operation",
            "name": "AvailabilityDropdown_Query",
            "argumentDefinitions": (v0 /*: any*/),
            "selections": (v1 /*: any*/)
        },
        "params": {
            "operationKind": "query",
            "name": "AvailabilityDropdown_Query",
            "id": null,
            "text": "query AvailabilityDropdown_Query(\n  $mentorID: ID!\n  $booked: Boolean!\n  $page: Page!\n  $orderBy: OrderBy\n) {\n  availability(filter: {mentorID: $mentorID, booked: $booked}, page: $page, orderBy: $orderBy) {\n    edges {\n      id\n      startTime\n      endTime\n      interviewType\n    }\n    pageInfo {\n      total\n      offset\n      limit\n      given\n    }\n  }\n}\n",
            "metadata": {}
        }
    } as any;
})();
(node as any).hash = 'f8ef3b639f3f280c32ade390e3e6be4f';
export default node;
