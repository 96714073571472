/* tslint:disable */
/* eslint-disable */
/* @relayHash 92d62ee9bf1fa8df30cd1f9d7e80e763 */

import { ConcreteRequest } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type Page = {
    offset?: number | null;
    limit?: number | null;
};
export type SocialEventFilter = {
    name?: string | null;
    title?: string | null;
    afterDate?: string | null;
    beforeDate?: string | null;
    createdAfter?: string | null;
};
export type OrderBy = {
    ascending?: boolean | null;
    field: string;
};
export type App_SocialEvents_QueryVariables = {
    page?: Page | null;
    filter?: SocialEventFilter | null;
    orderBy?: OrderBy | null;
};
export type App_SocialEvents_QueryResponse = {
    readonly socialEvents: {
        readonly " $fragmentRefs": FragmentRefs<"SocialEvents_socialEvents">;
    } | null;
};
export type App_SocialEvents_Query = {
    readonly response: App_SocialEvents_QueryResponse;
    readonly variables: App_SocialEvents_QueryVariables;
};



/*
query App_SocialEvents_Query(
  $page: Page
  $filter: SocialEventFilter
  $orderBy: OrderBy
) {
  socialEvents(page: $page, filter: $filter, orderBy: $orderBy) {
    ...SocialEvents_socialEvents
  }
}

fragment EventCard_event on SocialEvent {
  id
  name
  title
  startDate
  endDate
  addressLine1
  addressLine2
  postCode
  bodyText
  imageURL
  userAttendanceStatus
}

fragment SocialEvents_socialEvents on SocialEventPage {
  edges {
    id
    ...EventCard_event
  }
  pageInfo {
    total
    offset
    limit
    given
  }
}
*/

const node: ConcreteRequest = (function () {
    var v0 = [
        ({
            "kind": "LocalArgument",
            "name": "page",
            "type": "Page",
            "defaultValue": null
        } as any),
        ({
            "kind": "LocalArgument",
            "name": "filter",
            "type": "SocialEventFilter",
            "defaultValue": null
        } as any),
        ({
            "kind": "LocalArgument",
            "name": "orderBy",
            "type": "OrderBy",
            "defaultValue": null
        } as any)
    ], v1 = [
        ({
            "kind": "Variable",
            "name": "filter",
            "variableName": "filter"
        } as any),
        ({
            "kind": "Variable",
            "name": "orderBy",
            "variableName": "orderBy"
        } as any),
        ({
            "kind": "Variable",
            "name": "page",
            "variableName": "page"
        } as any)
    ];
    return {
        "kind": "Request",
        "fragment": {
            "kind": "Fragment",
            "name": "App_SocialEvents_Query",
            "type": "Query",
            "metadata": null,
            "argumentDefinitions": (v0 /*: any*/),
            "selections": [
                {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "socialEvents",
                    "storageKey": null,
                    "args": (v1 /*: any*/),
                    "concreteType": "SocialEventPage",
                    "plural": false,
                    "selections": [
                        {
                            "kind": "FragmentSpread",
                            "name": "SocialEvents_socialEvents",
                            "args": null
                        }
                    ]
                }
            ]
        },
        "operation": {
            "kind": "Operation",
            "name": "App_SocialEvents_Query",
            "argumentDefinitions": (v0 /*: any*/),
            "selections": [
                {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "socialEvents",
                    "storageKey": null,
                    "args": (v1 /*: any*/),
                    "concreteType": "SocialEventPage",
                    "plural": false,
                    "selections": [
                        {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "edges",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "SocialEvent",
                            "plural": true,
                            "selections": [
                                {
                                    "kind": "ScalarField",
                                    "alias": null,
                                    "name": "id",
                                    "args": null,
                                    "storageKey": null
                                },
                                {
                                    "kind": "ScalarField",
                                    "alias": null,
                                    "name": "name",
                                    "args": null,
                                    "storageKey": null
                                },
                                {
                                    "kind": "ScalarField",
                                    "alias": null,
                                    "name": "title",
                                    "args": null,
                                    "storageKey": null
                                },
                                {
                                    "kind": "ScalarField",
                                    "alias": null,
                                    "name": "startDate",
                                    "args": null,
                                    "storageKey": null
                                },
                                {
                                    "kind": "ScalarField",
                                    "alias": null,
                                    "name": "endDate",
                                    "args": null,
                                    "storageKey": null
                                },
                                {
                                    "kind": "ScalarField",
                                    "alias": null,
                                    "name": "addressLine1",
                                    "args": null,
                                    "storageKey": null
                                },
                                {
                                    "kind": "ScalarField",
                                    "alias": null,
                                    "name": "addressLine2",
                                    "args": null,
                                    "storageKey": null
                                },
                                {
                                    "kind": "ScalarField",
                                    "alias": null,
                                    "name": "postCode",
                                    "args": null,
                                    "storageKey": null
                                },
                                {
                                    "kind": "ScalarField",
                                    "alias": null,
                                    "name": "bodyText",
                                    "args": null,
                                    "storageKey": null
                                },
                                {
                                    "kind": "ScalarField",
                                    "alias": null,
                                    "name": "imageURL",
                                    "args": null,
                                    "storageKey": null
                                },
                                {
                                    "kind": "ScalarField",
                                    "alias": null,
                                    "name": "userAttendanceStatus",
                                    "args": null,
                                    "storageKey": null
                                }
                            ]
                        },
                        {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "pageInfo",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "PageInfo",
                            "plural": false,
                            "selections": [
                                {
                                    "kind": "ScalarField",
                                    "alias": null,
                                    "name": "total",
                                    "args": null,
                                    "storageKey": null
                                },
                                {
                                    "kind": "ScalarField",
                                    "alias": null,
                                    "name": "offset",
                                    "args": null,
                                    "storageKey": null
                                },
                                {
                                    "kind": "ScalarField",
                                    "alias": null,
                                    "name": "limit",
                                    "args": null,
                                    "storageKey": null
                                },
                                {
                                    "kind": "ScalarField",
                                    "alias": null,
                                    "name": "given",
                                    "args": null,
                                    "storageKey": null
                                }
                            ]
                        }
                    ]
                }
            ]
        },
        "params": {
            "operationKind": "query",
            "name": "App_SocialEvents_Query",
            "id": null,
            "text": "query App_SocialEvents_Query(\n  $page: Page\n  $filter: SocialEventFilter\n  $orderBy: OrderBy\n) {\n  socialEvents(page: $page, filter: $filter, orderBy: $orderBy) {\n    ...SocialEvents_socialEvents\n  }\n}\n\nfragment EventCard_event on SocialEvent {\n  id\n  name\n  title\n  startDate\n  endDate\n  addressLine1\n  addressLine2\n  postCode\n  bodyText\n  imageURL\n  userAttendanceStatus\n}\n\nfragment SocialEvents_socialEvents on SocialEventPage {\n  edges {\n    id\n    ...EventCard_event\n  }\n  pageInfo {\n    total\n    offset\n    limit\n    given\n  }\n}\n",
            "metadata": {}
        }
    } as any;
})();
(node as any).hash = '5ba9dd2a8d524c41c6d0cfb78061f412';
export default node;
