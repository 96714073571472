import * as React from 'react';
import { createUseStyles, useTheme } from 'react-jss';
import classNames from 'classnames';
import Button from 'components/core/Input/Button';
import { createFragmentContainer, graphql } from 'react-relay';
import { Theme } from 'helpers/theme';
import PageTitle from 'components/PageTitle';
import FlatCard from 'components/core/Cards/FlatCard';
import CourseSyllabusCard from 'components/Overview/CourseSyllabusCard';
import Icon from 'components/core/Icon';
import { useRouter } from 'found';
import { OnlineCourse_myActiveCourse } from './__generated__/OnlineCourse_myActiveCourse.graphql';
import moment from 'moment';
import Spacer from 'components/core/Spacers/Spacer';
import Page from 'components/Page';
import CourseSyllabusCardFrag from 'components/Overview/CourseSyllabusCard/CourseSyllabusCardFrag';
import { goToNextURL } from 'views/helpers';

const useStyles = createUseStyles((theme: Theme) => ({
  rootOnlineCourse: {
    gridTemplateColumns: '2fr 1fr',
    gridGap: theme.spacing(3),
    display: 'grid',
    maxWidth: 1040
  },
  courseHead: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: 26,
    marginTop: 32
  },
  courseHeadItem: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: 80,
    '&:last-child': {
      marginRight: 0
    }
  },
  labelBold: {
    textTransform: 'uppercase',
    color: theme.colors.primaryBlack,
    fontWeight: 600,
    fontSize: theme.fontSizes.xSmall,
    letterSpacing: -0.3,
    lineHeight: `18px`,
    marginRight: 5
  },
  labelValue: {
    textTransform: 'uppercase',
    color: theme.colors.secondaryBlack,
    fontWeight: 400,
    fontSize: theme.fontSizes.xSmall,
    letterSpacing: -0.3,
    lineHeight: `18px`
  },
  flatCard: {
    alignItems: 'center',
    marginBottom: 28
  },
  flatCardText: {
    color: theme.colors.primaryBlack,
    fontWeight: 'bold',
    fontSize: theme.fontSizes.large,
    letterSpacing: -0.25,
    lineHeight: `22px`,
    margin: [0, 35, 0, 26]
  },
  courseContent: {},
  courseContentTitle: {
    color: theme.colors.primaryBlack,
    fontWeight: 'bold',
    fontSize: theme.fontSizes.tinyHeading,
    letterSpacing: -0.5,
    lineHeight: `51px`,
    margin: 0
  },
  courseContentText: {
    color: theme.colors.primaryBlack,
    fontSize: theme.fontSizes.extraLarge,
    letterSpacing: -0.45,
    lineHeight: `30px`,
    margin: 0,
    fontWeight: 300
  },
  howToCompleteDescription: {
    '& p': {
      marginBottom: 20,
      '&:last-child': {
        marginBottom: 0
      }
    }
  },
  keyThings: {
    padding: 0,
    margin: [4, 0],
    listStyle: 'none'
  },
  keyThingDot: {
    position: 'relative',
    paddingLeft: 21,
    marginBottom: 8,
    '&:last-child': {
      marginBottom: 0
    },
    '&:before': {
      content: "''",
      width: 10,
      height: 10,
      backgroundColor: theme.colors.textOrange,
      marginRight: 10,
      display: 'block',
      position: 'absolute',
      borderRadius: 50,
      left: 0,
      top: `calc(50% - 5px)`
    }
  },
  courseSyllabus: {
    marginTop: 52
  },
  button: {
    color: theme.colors.primaryWhite
  }
}));

export type Course = {
  id: number;
  title: string;
  category: string;
  percentCompleted: number;
  enrolled: string | Date;
  aboutDescription?: string | React.ReactNode;
  howToCompleteDescription?: string[];
  keyThings?: string[];
  estimateTimeComplete?: number;
};

export type OnlineCourseProps = {
  course: Course;
  // onClick: Function;
  className?: string;
  myActiveCourse?: OnlineCourse_myActiveCourse;
};

function OnlineCourse({
  course,
  myActiveCourse,
  className
}: OnlineCourseProps) {
  const theme = useTheme();
  const classes = useStyles({ theme });
  const { router } = useRouter();

  if (!myActiveCourse?.course) return <div>Unable to get course</div>;

  return (
    <Page>
      <div className={classes.rootOnlineCourse}>
        <div>
          <PageTitle
            title={myActiveCourse?.course?.name ?? ''}
            backProps={{
              text: 'all Online Courses',
              onClick: () => {
                router.push('/app/my-courses');
              }
            }}
          />
          <div className={classes.courseHead}>
            <div className={classes.courseHeadItem}>
              <span className={classes.labelBold}>Category: </span>
              <span className={classes.labelValue}>
                {myActiveCourse?.course?.category?.name ?? ''}
              </span>
            </div>
            <div className={classes.courseHeadItem}>
              <span className={classes.labelBold}>
                {myActiveCourse?.progress?.percent}% Completed
              </span>
            </div>
            <div className={classes.courseHeadItem}>
              <span className={classes.labelBold}>Enrolled:</span>
              <span className={classes.labelValue}>
                {moment(myActiveCourse?.enrolledAt).format('DD/MM/YYYY')}
              </span>
            </div>
          </div>
          <FlatCard shadow padding="large" className={classes.flatCard}>
            <Icon name="Volume" size={51} />
            <p className={classes.flatCardText}>
              Make sure your speakers are turned on before you start this
              course.
            </p>
            <Button
              title={myActiveCourse?.upTo ? 'Continue Course' : 'Begin Course'}
              className={classes.button}
              onClick={() => {
                if (
                  !myActiveCourse?.course?.syllabus ||
                  myActiveCourse?.course.syllabus.length == 0
                ) {
                  return;
                }

                const url = goToNextURL(
                  myActiveCourse?.course.ident,
                  myActiveCourse?.course.syllabus,
                  myActiveCourse.upTo ?? undefined,
                  () => {}
                );

                if (url) {
                  router.push(url);
                }
              }}
              padding="massive"
              noWrap
            />
          </FlatCard>
          <div className={classes.courseContent}>
            <h6 className={classes.courseContentTitle}>
              About this Course
              {myActiveCourse?.course?.hoursToComplete
                ? `– Estimated time to complete ${myActiveCourse?.course.hoursToComplete} hours`
                : ''}
            </h6>
            <p className={classes.courseContentText}>
              {myActiveCourse?.course?.excerpt}
            </p>
            {myActiveCourse?.course?.name == 'Attention to Detail' && (
              <a href="https://pathways-dev-uploads.s3.eu-west-1.amazonaws.com/courseDocument/Paralegal+Guideline+Document.docx">
                Exercise Instructions
              </a>
            )}
          </div>
          <Spacer vertical spacing={3} />
          {myActiveCourse?.course?.howToComplete && (
            <div className={classes.courseContent}>
              <h6 className={classes.courseContentTitle}>
                How to complete this Course
              </h6>
              <div className={classes.courseContentText}>
                {myActiveCourse?.course.howToComplete}
              </div>
            </div>
          )}
          <Spacer vertical spacing={3} />
          {myActiveCourse?.course?.requirements &&
            myActiveCourse?.course.requirements.length > 0 && (
              <div className={classes.courseContent}>
                <h6 className={classes.courseContentTitle}>
                  Key things to consider
                </h6>
                <ul className={classes.keyThings}>
                  {myActiveCourse?.course.requirements.map((text, index) => (
                    <li
                      className={classNames(
                        classes.courseContentText,
                        classes.keyThingDot
                      )}
                      key={index}
                    >
                      {text}
                    </li>
                  ))}
                </ul>
              </div>
            )}
        </div>
        <div className={classes.courseSyllabus}>
          <CourseSyllabusCardFrag
            course={myActiveCourse?.course}
            upTo={myActiveCourse?.upTo ?? undefined}
            completePercent={myActiveCourse?.progress?.percent}
          />
        </div>
      </div>
    </Page>
  );
}

export default createFragmentContainer(OnlineCourse, {
  myActiveCourse: graphql`
    fragment OnlineCourse_myActiveCourse on MyCourse {
      status
      enrolledAt
      upTo
      progress {
        percent
      }
      course {
        ident: id
        name
        excerpt
        introduction
        howToComplete
        requirements
        hoursToComplete
        category {
          name
        }
        syllabus {
          name
          type
          uuid
          ... on Module {
            syllabus {
              name
              uuid
              type
            }
          }
        }
        ...CourseSyllabusCardFrag_course
      }
    }
  `
});
