import React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import { Page as _Page } from 'components/Pagination/Paginator';
import { Advice_advice } from './__generated__/Advice_advice.graphql';
import AdvicePage from 'components/AdvicePage';

type Props = {
  advice: Advice_advice;
};

function Advice({ advice }: Props) {
  return <div>{advice && <AdvicePage advice={{ ...advice }} />}</div>;
}

export default createFragmentContainer(Advice, {
  advice: graphql`
    fragment Advice_advice on Advice {
      id
      name
      introduction
      description
      postedAt
      bannerImageURL
      links
      voiceoverURL
      videoURL
    }
  `
});
