/* tslint:disable */
/* eslint-disable */
/* @relayHash 4a345b68f2d016c68a6c017438937a1c */

import { ConcreteRequest } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type App_CharacterSuitability_QueryVariables = {};
export type App_CharacterSuitability_QueryResponse = {
    readonly characterSuitability: {
        readonly " $fragmentRefs": FragmentRefs<"CharacterSuitability_characterSuitability">;
    } | null;
    readonly candidate: {
        readonly " $fragmentRefs": FragmentRefs<"CharacterSuitability_candidate">;
    } | null;
};
export type App_CharacterSuitability_Query = {
    readonly response: App_CharacterSuitability_QueryResponse;
    readonly variables: App_CharacterSuitability_QueryVariables;
};



/*
query App_CharacterSuitability_Query {
  characterSuitability {
    ...CharacterSuitability_characterSuitability
    id
  }
  candidate {
    ...CharacterSuitability_candidate
    id
  }
}

fragment CharacterSuitability_candidate on Candidate {
  id
  skillGroupType
  characterSuitabilityCompleted
}

fragment CharacterSuitability_characterSuitability on CharacterSuitability {
  id
  rules
  infoForQualifiedSolicitors
  links
}
*/

const node: ConcreteRequest = (function () {
    var v0 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "id",
        "args": null,
        "storageKey": null
    } as any);
    return {
        "kind": "Request",
        "fragment": {
            "kind": "Fragment",
            "name": "App_CharacterSuitability_Query",
            "type": "Query",
            "metadata": null,
            "argumentDefinitions": [],
            "selections": [
                {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "characterSuitability",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "CharacterSuitability",
                    "plural": false,
                    "selections": [
                        {
                            "kind": "FragmentSpread",
                            "name": "CharacterSuitability_characterSuitability",
                            "args": null
                        }
                    ]
                },
                {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "candidate",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "Candidate",
                    "plural": false,
                    "selections": [
                        {
                            "kind": "FragmentSpread",
                            "name": "CharacterSuitability_candidate",
                            "args": null
                        }
                    ]
                }
            ]
        },
        "operation": {
            "kind": "Operation",
            "name": "App_CharacterSuitability_Query",
            "argumentDefinitions": [],
            "selections": [
                {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "characterSuitability",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "CharacterSuitability",
                    "plural": false,
                    "selections": [
                        (v0 /*: any*/),
                        {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "rules",
                            "args": null,
                            "storageKey": null
                        },
                        {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "infoForQualifiedSolicitors",
                            "args": null,
                            "storageKey": null
                        },
                        {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "links",
                            "args": null,
                            "storageKey": null
                        }
                    ]
                },
                {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "candidate",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "Candidate",
                    "plural": false,
                    "selections": [
                        (v0 /*: any*/),
                        {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "skillGroupType",
                            "args": null,
                            "storageKey": null
                        },
                        {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "characterSuitabilityCompleted",
                            "args": null,
                            "storageKey": null
                        }
                    ]
                }
            ]
        },
        "params": {
            "operationKind": "query",
            "name": "App_CharacterSuitability_Query",
            "id": null,
            "text": "query App_CharacterSuitability_Query {\n  characterSuitability {\n    ...CharacterSuitability_characterSuitability\n    id\n  }\n  candidate {\n    ...CharacterSuitability_candidate\n    id\n  }\n}\n\nfragment CharacterSuitability_candidate on Candidate {\n  id\n  skillGroupType\n  characterSuitabilityCompleted\n}\n\nfragment CharacterSuitability_characterSuitability on CharacterSuitability {\n  id\n  rules\n  infoForQualifiedSolicitors\n  links\n}\n",
            "metadata": {}
        }
    } as any;
})();
(node as any).hash = 'd277fda6c563f251a639e6ba6149f299';
export default node;
