/* tslint:disable */
/* eslint-disable */

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type CandidateType = "level1" | "level2" | "level3" | "%future added value";
export type AppHolder_candidate = {
    readonly candidateType: CandidateType;
    readonly firstName: string;
    readonly lastName: string;
    readonly jobTitle: string;
    readonly email: string;
    readonly telephone: string | null;
    readonly profileImageUrl: string | null;
    readonly skillGroupType: string | null;
    readonly skillGroupTypeConfirmed: boolean;
    readonly hiddenQualifications: number;
    readonly chatHistory: string | null;
    readonly " $fragmentRefs": FragmentRefs<"CandidateModal_candidate">;
    readonly " $refType": "AppHolder_candidate";
};
export type AppHolder_candidate$data = AppHolder_candidate;
export type AppHolder_candidate$key = {
    readonly " $data"?: AppHolder_candidate$data;
    readonly " $fragmentRefs": FragmentRefs<"AppHolder_candidate">;
};



const node: ReaderFragment = ({
    "kind": "Fragment",
    "name": "AppHolder_candidate",
    "type": "Candidate",
    "metadata": null,
    "argumentDefinitions": [],
    "selections": [
        {
            "kind": "ScalarField",
            "alias": null,
            "name": "candidateType",
            "args": null,
            "storageKey": null
        },
        {
            "kind": "ScalarField",
            "alias": null,
            "name": "firstName",
            "args": null,
            "storageKey": null
        },
        {
            "kind": "ScalarField",
            "alias": null,
            "name": "lastName",
            "args": null,
            "storageKey": null
        },
        {
            "kind": "ScalarField",
            "alias": null,
            "name": "jobTitle",
            "args": null,
            "storageKey": null
        },
        {
            "kind": "ScalarField",
            "alias": null,
            "name": "email",
            "args": null,
            "storageKey": null
        },
        {
            "kind": "ScalarField",
            "alias": null,
            "name": "telephone",
            "args": null,
            "storageKey": null
        },
        {
            "kind": "ScalarField",
            "alias": null,
            "name": "profileImageUrl",
            "args": null,
            "storageKey": null
        },
        {
            "kind": "ScalarField",
            "alias": null,
            "name": "skillGroupType",
            "args": null,
            "storageKey": null
        },
        {
            "kind": "ScalarField",
            "alias": null,
            "name": "skillGroupTypeConfirmed",
            "args": null,
            "storageKey": null
        },
        {
            "kind": "ScalarField",
            "alias": null,
            "name": "hiddenQualifications",
            "args": null,
            "storageKey": null
        },
        {
            "kind": "ScalarField",
            "alias": null,
            "name": "chatHistory",
            "args": null,
            "storageKey": null
        },
        {
            "kind": "FragmentSpread",
            "name": "CandidateModal_candidate",
            "args": null
        }
    ]
} as any);
(node as any).hash = '0fc918da25333ed07e21e0d24ec0227e';
export default node;
