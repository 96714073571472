/* tslint:disable */
/* eslint-disable */

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type CharacterSuitability_characterSuitability = {
    readonly id: string;
    readonly rules: string;
    readonly infoForQualifiedSolicitors: string;
    readonly links: ReadonlyArray<string> | null;
    readonly " $refType": "CharacterSuitability_characterSuitability";
};
export type CharacterSuitability_characterSuitability$data = CharacterSuitability_characterSuitability;
export type CharacterSuitability_characterSuitability$key = {
    readonly " $data"?: CharacterSuitability_characterSuitability$data;
    readonly " $fragmentRefs": FragmentRefs<"CharacterSuitability_characterSuitability">;
};



const node: ReaderFragment = ({
    "kind": "Fragment",
    "name": "CharacterSuitability_characterSuitability",
    "type": "CharacterSuitability",
    "metadata": null,
    "argumentDefinitions": [],
    "selections": [
        {
            "kind": "ScalarField",
            "alias": null,
            "name": "id",
            "args": null,
            "storageKey": null
        },
        {
            "kind": "ScalarField",
            "alias": null,
            "name": "rules",
            "args": null,
            "storageKey": null
        },
        {
            "kind": "ScalarField",
            "alias": null,
            "name": "infoForQualifiedSolicitors",
            "args": null,
            "storageKey": null
        },
        {
            "kind": "ScalarField",
            "alias": null,
            "name": "links",
            "args": null,
            "storageKey": null
        }
    ]
} as any);
(node as any).hash = 'a9b6637c3d842ac64edae1f534f3deb8';
export default node;
