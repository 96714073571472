/* tslint:disable */
/* eslint-disable */

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type AttendanceStatus = "going" | "invited" | "notGoing" | "%future added value";
export type EventCard_event = {
    readonly id: string;
    readonly name: string;
    readonly title: string;
    readonly startDate: string;
    readonly endDate: string;
    readonly addressLine1: string | null;
    readonly addressLine2: string | null;
    readonly postCode: string | null;
    readonly bodyText: string;
    readonly imageURL: string | null;
    readonly userAttendanceStatus: AttendanceStatus | null;
    readonly " $refType": "EventCard_event";
};
export type EventCard_event$data = EventCard_event;
export type EventCard_event$key = {
    readonly " $data"?: EventCard_event$data;
    readonly " $fragmentRefs": FragmentRefs<"EventCard_event">;
};



const node: ReaderFragment = ({
    "kind": "Fragment",
    "name": "EventCard_event",
    "type": "SocialEvent",
    "metadata": null,
    "argumentDefinitions": [],
    "selections": [
        {
            "kind": "ScalarField",
            "alias": null,
            "name": "id",
            "args": null,
            "storageKey": null
        },
        {
            "kind": "ScalarField",
            "alias": null,
            "name": "name",
            "args": null,
            "storageKey": null
        },
        {
            "kind": "ScalarField",
            "alias": null,
            "name": "title",
            "args": null,
            "storageKey": null
        },
        {
            "kind": "ScalarField",
            "alias": null,
            "name": "startDate",
            "args": null,
            "storageKey": null
        },
        {
            "kind": "ScalarField",
            "alias": null,
            "name": "endDate",
            "args": null,
            "storageKey": null
        },
        {
            "kind": "ScalarField",
            "alias": null,
            "name": "addressLine1",
            "args": null,
            "storageKey": null
        },
        {
            "kind": "ScalarField",
            "alias": null,
            "name": "addressLine2",
            "args": null,
            "storageKey": null
        },
        {
            "kind": "ScalarField",
            "alias": null,
            "name": "postCode",
            "args": null,
            "storageKey": null
        },
        {
            "kind": "ScalarField",
            "alias": null,
            "name": "bodyText",
            "args": null,
            "storageKey": null
        },
        {
            "kind": "ScalarField",
            "alias": null,
            "name": "imageURL",
            "args": null,
            "storageKey": null
        },
        {
            "kind": "ScalarField",
            "alias": null,
            "name": "userAttendanceStatus",
            "args": null,
            "storageKey": null
        }
    ]
} as any);
(node as any).hash = '336f6a792d4a00309e77575ccc1383c9';
export default node;
