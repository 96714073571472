import { commitMutation, graphql } from 'react-relay';
import environment from 'api/environment';
import { GraphError } from 'types/general';
import { mutations_UpdateCandidateMutationResponse, FileUpload } from './__generated__/mutations_UpdateCandidateMutation.graphql';

const updateMutation = graphql`
  mutation mutations_UpdateCandidateMutation(
    $id: ID!
    $firstName: String
    $lastName: String
    $jobTitle: String
    $email: String
    $skillGroupType: String
    $telephone: String
    $profileImage: FileUpload
  ) {
    updateCandidate(
      input: {
        id: $id
        firstName: $firstName
        lastName: $lastName
        email: $email
        skillGroupType: $skillGroupType
        jobTitle: $jobTitle
        telephone: $telephone
        profileImage: $profileImage
      }
    ) {
      firstName
      lastName
      jobTitle
      email
      skillGroupType
      telephone
      profileImageUrl
    }
  }
`;

export const UpdateCandidate = (
  candidate: {
    id: string;
    firstName?: string;
    lastName?: string;
    jobTitle?: string;
    email?: string;
    skillGroupType?: string;
    telephone?: string;
    profilePicture?: FileUpload;
  },
  errorCallback: (err: string) => void,
  successCallback: (response: mutations_UpdateCandidateMutationResponse) => void
) => {
  const variables = {
    id: candidate.id,
    firstName: candidate.firstName,
    lastName: candidate.lastName,
    jobTitle: candidate.jobTitle,
    email: candidate.email,
    skillGroupType: candidate.skillGroupType,
    telephone: candidate.telephone,
    profileImage: candidate.profilePicture
  };

  commitMutation(environment, {
    mutation: updateMutation,
    variables,
    onCompleted: (
      response: mutations_UpdateCandidateMutationResponse,
      errors: GraphError[]
    ) => {
      if (errors) {
        errorCallback(`${errors[0]?.extensions?.message}`);
        return;
      }
      successCallback(response);
    }
  });
};