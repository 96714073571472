import React from 'react';
import { RecordSourceSelectorProxy } from 'relay-runtime';
import { Grid } from '@material-ui/core';
import Switch from '@material-ui/core/Switch';
import { createUseStyles, useTheme } from 'react-jss';
import styled from 'styled-components';
import { makeMutation } from 'api/helpers';
import { CreatePlacement, UpdatePlacement } from 'api/mutations';
import { Theme } from 'helpers/theme';
import Button from 'components/core/Input/ButtonBase';
import EasyInput from 'components/core/Input/EasyInput';
import Spacer from 'components/core/Spacers/Spacer';
import { Body, Footer } from 'components/SideModal/Tabs';
import { useToastDispatch } from 'components/Toast/ToastProvider';
import { ScaleLoader } from 'react-spinners';
import { ThemeProvider } from '@material-ui/core/styles';
import themeCustom from '../../helpers/themeCustom';
import ChipAutocomplete from 'components/core/Input/ChipAutocomplete';
import { Weekday } from 'views/Dashboard/__generated__/Roadmap_candidate.graphql';
import { FileUpload } from 'api/__generated__/mutationsCreatePlacementMutation.graphql';
import { toBase64 } from 'helpers/upload';
import OverrideModal from 'components/OverrideModal';
import { formatMonthYearString } from 'utils/getTimeFromString';

const useStyles = createUseStyles((theme: Theme) => ({
  root: {
    padding: '10px 10px !important',
    border: '1px solid #08080814 !important',
    borderRadius: 5,
    '& div': {
      '&:before': { borderBottom: 'none !important' },
      '&:after': { borderBottom: 'none !important' }
    },
  },
}));

const SectionTitle = styled.h1`
  font-size: 20px;
  font-weight: 700;
`;

const FileInput = styled.input`
  display: none;
`;

const UploadAndViewButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

const UploadContainer = styled.label`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
`;

const UploadWarning = styled.div`
  font-size: 14px;
  color: #757575;
`;

export type State = {
  id?: string;
  candidateID: string;
  isCandidateTC: boolean;
  name?: string;
  startDate?: string;
  endDate?: string;
  qweIndividual?: string;
  orgSRANumber?: string;
  supervisorName: string;
  supervisorEmail: string;
  email?: string;
  fullTime: boolean;
  sraNumber?: string;
  saving?: boolean;
  editing?: boolean;
  partTimeDays?: Weekday[];
  daysMissed: number;
  qweDocURL?: string;
  qweDocFile?: FileUpload;
  qweDocFileName?: string;
};

type Props = {
  state: State;
  setState: (newState: any) => void;
  closeModal: () => void;
};

function Content({ state, setState, closeModal }: Props) {
  const theme = useTheme();
  const classes = useStyles({ theme });
  const dispatch = useToastDispatch();
  const [isOpenOverrideModal, setIsOpenOverrideModal] = React.useState(false);
  const [dateIndex, setDateIndex] = React.useState(0)
  const inputRef = React.useRef<HTMLInputElement>(null);

  const onSave = async () => {
    if (!state.name || state.name.length < 1) {
      dispatch({
        text: 'Please enter a valid placement name',
        alert: 'warning',
        stayFor: 6000
      });
      return;
    }
    if (!state.startDate) {
      dispatch({
        text: 'Please enter a start date',
        alert: 'warning',
        stayFor: 6000
      });
      return;
    }
    if (state.supervisorEmail === '' || !state.supervisorEmail.includes('@')) {
      dispatch({
        text: 'Please enter a valid supervisor email',
        alert: 'warning',
        stayFor: 6000
      });
      return;
    }
    if (state.supervisorName.length < 2) {
      dispatch({
        text: 'Please enter a valid name for the supervisor',
        alert: 'warning',
        stayFor: 6000
      });
      return;
    }
    try {
      setState((s: State) => ({ ...s, saving: true }));
      await makeMutation(
        CreatePlacement,
        {
          candidateID: state.candidateID,
          name: state.name,
          startDate: state.startDate,
          endDate: state.endDate,
          qweIndividual: state.qweIndividual,
          email: state.email,
          orgSRANumber: state.orgSRANumber,
          supervisorName: state.supervisorName,
          supervisorEmail: state.supervisorEmail.toLowerCase(),
          fullTime: state.fullTime,
          sraNumber: state.sraNumber,
          partTimeDays: state.partTimeDays,
          daysMissed: state.daysMissed,
          qweDocFile: state.qweDocFile
        },
        (store: RecordSourceSelectorProxy<{}>, data: any) => {
          const root = store.get('client:root');
          if (!root) {
            console.error('could not get root');
            return;
          }
          const user = root.getLinkedRecord('candidate');
          if (!user) {
            console.log('Could not get user');
            return;
          }
          const newPlacement = store.get(data.createPlacement.id);
          if (!newPlacement) {
            console.error('could not find placement', data.createPlacement.id);
            return;
          }

          const placements = user.getLinkedRecord('placements');
          if (!placements) {
            console.error('could not get placements');
            return;
          }

          const edges = placements.getLinkedRecords('edges');
          if (!edges) {
            console.error('could not get placement edges');
            return;
          }

          const newRecords = [newPlacement, ...edges];
          placements.setLinkedRecords(newRecords, 'edges');
        }
      );
      dispatch({
        text: 'Placement created successfully',
        alert: 'success',
        stayFor: 4000
      });
      closeModal();
    } catch (err) {
      console.log('Unable to create placement', err);
      dispatch({
        text: 'Unable to create placement' + err.message,
        alert: 'error',
        stayFor: 6000
      });
    }
    setState((s: State) => ({ ...s, saving: false }));
  };

  const onEdit = async () => {
    if (!state.name || state.name.length < 1) {
      dispatch({
        text: 'Please enter a valid placement name',
        alert: 'warning',
        stayFor: 6000
      });
      return;
    }
    if (!state.startDate) {
      dispatch({
        text: 'Please enter a start date',
        alert: 'warning',
        stayFor: 6000
      });
      return;
    }
    if (state.supervisorEmail === '' || !state.supervisorEmail.includes('@')) {
      dispatch({
        text: 'Please enter a valid supervisor email',
        alert: 'warning',
        stayFor: 6000
      });
      return;
    }
    if (state.supervisorName.length < 2) {
      dispatch({
        text: 'Please enter a valid name for the supervisor',
        alert: 'warning',
        stayFor: 6000
      });
      return;
    }
    try {
      setState((s: State) => ({ ...s, saving: true }));
      await makeMutation(UpdatePlacement, {
        id: state.id,
        name: state.name,
        startDate: state.startDate,
        endDate: state.endDate,
        qweIndividual: state.qweIndividual,
        email: state.email,
        orgSRANumber: state.orgSRANumber,
        supervisorName: state.supervisorName,
        supervisorEmail: state.supervisorEmail.toLowerCase(),
        fullTime: state.fullTime,
        sraNumber: state.sraNumber,
        partTimeDays: state.partTimeDays,
        daysMissed: state.daysMissed,
        qweDocFile: state.qweDocFile
      });
      dispatch({
        text: 'Placement updated successfully',
        alert: 'success',
        stayFor: 4000
      });
      closeModal();
    } catch (err) {
      console.log('Unable to update placement', err);
      dispatch({
        text: err.message,
        alert: 'error',
        stayFor: 6000
      });
    }
    setState((s: State) => ({ ...s, saving: false }));
  };

  const onUpload = async (evt: React.ChangeEvent<HTMLInputElement>) => {
    const files = evt?.target?.files;
    if (files && files.length > 0) {
      const file = files[0];
      const split = file.name.split('.');
      const fType = split[split.length - 1].toLowerCase();
      if (
        fType !== 'jpg' &&
        fType !== 'jpeg' &&
        fType !== 'png' &&
        fType !== 'pdf' &&
        fType !== 'doc' &&
        fType !== 'docx'
      ) {
        dispatch({
          text: 'Pick a png, jpg, pdf, jpeg, doc or docs file',
          alert: 'error',
          stayFor: 6000
        });
        return;
      }

      try {
        const base64 = await toBase64(file);
        if (!base64) {
          dispatch({
            text: 'Unable to upload file, please try again',
            alert: 'error',
            stayFor: 6000
          });
          throw new Error('Base64 is undefined');
        }
        setState((s: State) => ({
          ...s,
          //fileURL: URL.createObjectURL(file),
          qweDocFile: {
            fileBase64: base64,
            fileType: fType,
            fileName: file.name
          },
          qweDocFileName: file.name
        }));

        // To fix issue where input doesn't work twice in a row
        // https://stackoverflow.com/questions/12030686/html-input-file-selection-event-not-firing-upon-selecting-the-same-file
        if (inputRef && inputRef.current) {
          inputRef.current.value = '';
        }
      } catch (err) {
        dispatch({
          text: 'Unable to upload file, please try again',
          alert: 'error',
          stayFor: 6000
        });
        console.error('Unable to base64 file', err);
      }
    }
  };

  const handleAttach = (event: any) => {
    event.preventDefault();

    if (inputRef && inputRef.current) {
      inputRef.current.click();
    }
  };

  const handleCloseOverrideModal = () => {
    setIsOpenOverrideModal(false);
  };

  const handleOpen = (index: number) => {
    setIsOpenOverrideModal(true);
    
    setDateIndex(index)
    
  }

  const CustomDatePicker = ({dateIndex}: {dateIndex:number}) => {
    return (
      <div className="react-google-flight-datepicker">
        <div className="date-picker">
          <div className="date-picker-input-wrap">
            <div className="date-picker-input" onClick={() => handleOpen(dateIndex)}>
              <div className="date-picker-date-group">
                <div className="date is-focus" role="button" id="start-date-input-button">
                  <div className="selected-date">{dateIndex === 0 ? 
                    (state?.startDate ? formatMonthYearString(state?.startDate) : new Date().toLocaleDateString()) : 
                    (state?.endDate ? formatMonthYearString(state?.endDate) : new Date().toLocaleDateString())}</div>
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" className="icon-calendar">
                    <path d="M9 11H7v2h2v-2zm4 0h-2v2h2v-2zm4 0h-2v2h2v-2zm2-7h-1V2h-2v2H8V2H6v2H5c-1.11 0-1.99.9-1.99 2L3 20a2 2 0 002 2h14c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm0 16H5V9h14v11z"></path>
                    </svg>
                    <label></label>
                </div>
              </div>
            </div>            
          </div>
        </div>
      </div>
    )
  }


  const weekdays: Weekday[] = ["monday", "tuesday", "wednesday", "thursday", "friday"];

  return (
    <>
      <Body>
        <SectionTitle>Organisation Name*</SectionTitle>
        <EasyInput
        // quang : 13-07-2023
          placeholder="Please use companies full legal name"
          type="text"
          value={state.name}
          onChange={(text) => {
            setState((s: State) => ({ ...s, name: text }));
          }}
        />
        <Spacer vertical spacing={1} />
        <SectionTitle>Organisation SRA Number (if applicable)</SectionTitle>
        <EasyInput
          placeholder="Enter number"
          type="text"
          value={state.orgSRANumber}
          onChange={(text) => {
            setState((s: State) => ({ ...s, orgSRANumber: text }));
          }}
        />
        <Spacer vertical spacing={1} />
        <SectionTitle>Start Date*</SectionTitle>
        <ThemeProvider theme={themeCustom}>
          <CustomDatePicker dateIndex={0}/>
          {/* <KeyboardDatePicker
            value={state.startDate ? state.startDate : null}
            placeholder="10/10/2018"
            onChange={(date) => {
              setState((s: State) => ({ ...s, startDate: date?.format() }));
            }}
            format="DD/MM/YYYY"
            className={classes.root}
            inputProps={{ style: { font: "12px 'Muli', sans-serif" } }}
            autoOk={true}
          /> */}
        </ThemeProvider>
        <Spacer vertical spacing={1} />
        <SectionTitle>End Date</SectionTitle>
        <ThemeProvider theme={themeCustom}>
          <CustomDatePicker dateIndex={1}/>
          {/* <KeyboardDatePicker
            value={state.endDate ? state.endDate : null}
            placeholder="10/10/2018"
            onChange={(date) => {
              setState((s: State) => ({ ...s, endDate: date?.format() }));
            }}
            format="DD/MM/YYYY"
            className={classes.root}
            inputProps={{ style: { font: "12px 'Muli', sans-serif" } }}
            autoOk={true}
          /> */}
        </ThemeProvider>
        <Spacer vertical spacing={1} />
        <SectionTitle>Is the placement*</SectionTitle>
        <Grid component="label" container alignItems="center" spacing={1}>
          <Grid item>Part Time</Grid>
          <Grid item>
            <Switch checked={state.fullTime} onChange={() => setState((s: State) => ({ ...s, fullTime: !state.fullTime }))} name="fullTime" />
          </Grid>
          <Grid item>Full Time</Grid>
        </Grid>
        <Spacer vertical spacing={1} />
        {!state.fullTime && (
          <>
            <ChipAutocomplete
              chips={weekdays}
              selectedChips={state.partTimeDays || []}
              onChange={(newChips) => setState((s: State) => ({ ...s, partTimeDays: newChips }))}
            />
            <Spacer vertical spacing={1} />
            <SectionTitle>Do you need to make any adjustments to the number of days you have worked in this placement?</SectionTitle>
            <EasyInput
              placeholder="Enter number"
              type="number"
              value={`${state.daysMissed}`}
              onChange={(val) => {
                setState((s: State) => ({ ...s, daysMissed: +val }))
              }}
            />
          </>
        )}
        <Spacer vertical spacing={1} />
        <SectionTitle>Name of Supervisor* (this can be different from the person confirming your QWE)</SectionTitle>
        <EasyInput
          placeholder="Enter name"
          type="text"
          value={state.supervisorName}
          onChange={(text) => {
            setState((s: State) => ({ ...s, supervisorName: text }));
          }}
        />
        <Spacer vertical spacing={1} />
        <SectionTitle>Supervisor's Email Address*</SectionTitle>
        <EasyInput
          placeholder="Enter email"
          type="text"
          value={state.supervisorEmail}
          onChange={(text) => {
            setState((s: State) => ({ ...s, supervisorEmail: text }));
          }}
        />
        <Spacer vertical spacing={1} />
        {state.isCandidateTC && (
          <SectionTitle style={{ color: '#f17f2c' }}>FOR SQE TRAINEES ONLY: THE REMAINDER OF THIS FORM IS ONLY APPLICABLE TO TRAINEES QUALIFYING VIA THE SQE ROUTE.</SectionTitle>
        )}
        <SectionTitle>Individual confirming your QWE? (They must be a qualified solicitor of England and Wales)</SectionTitle>
        <EasyInput
          placeholder="Enter name"
          type="text"
          value={state.qweIndividual}
          onChange={(text) => {
            setState((s: State) => ({ ...s, qweIndividual: text }));
          }}
        />
        <Spacer vertical spacing={1} />
        <SectionTitle>SRA Number</SectionTitle>
        <EasyInput
          placeholder="Enter SRA number of person confirming QWE"
          type="text"
          value={state.sraNumber}
          onChange={(text) => {
            setState((s: State) => ({ ...s, sraNumber: text }));
          }}
        />
        <Spacer vertical spacing={1} />
        <SectionTitle>Email Address</SectionTitle>
        <EasyInput
          placeholder="Enter Email address of person confirming QWE"
          type="text"
          value={state.email}
          onChange={(text) => {
            setState((s: State) => ({ ...s, email: text }));
          }}
        />
        <Spacer vertical spacing={1} />
        <SectionTitle>Confirmed Qualifying Working Experience</SectionTitle>
        <UploadAndViewButtonsContainer>
          <FileInput
            type="file"
            name="file"
            id={'upload-document'}
            onChange={onUpload}
            ref={inputRef}
          />
          {state.qweDocURL && (
            <>
              <Button
                archetype={'default'}
                onClick={() => window.open(state.qweDocURL, '_blank')}
              >
                View Document
              </Button>
              <Spacer horizontal spacing={1} />
            </>
          )}
          <UploadContainer htmlFor={'upload-document'}>
            <Button archetype={'submit'} onClick={handleAttach}>
              {state.qweDocFileName ? state.qweDocFileName : 'Upload Document'}
            </Button>
          </UploadContainer>
          <Spacer horizontal spacing={1}/>
          <Button archetype="submit" onClick={() => 
            window.open('https://pathways-dev-uploads.s3.eu-west-1.amazonaws.com/import/sqe_qwe_form.pdf', '_blank')}>
            Download Form
          </Button>
        </UploadAndViewButtonsContainer>
        <Spacer vertical spacing={0} />
        <UploadWarning>
          Supported files: PDF, JPG, PNG, DOC, DOCS (max 10MB)
        </UploadWarning>
        <Spacer vertical spacing={1} />
      </Body>
      <Footer>
        <div style={{ display: 'flex' }}>
          <Button archetype={'default'} onClick={closeModal}>
            Cancel
          </Button>
          <Spacer horizontal spacing={2} />
          <Button
            archetype="submit"
            onClick={() => {
              if (state.saving) {
                return;
              }

              state.editing ? onEdit() : onSave();
            }}
          >
            {state.saving ? (
              <ScaleLoader color="white" height={10} />
            ) : (
              `${state.editing ? 'Save' : 'Add Placement'}`
            )}
          </Button>
        </div>
      </Footer>
      <OverrideModal
        isEdit={state.id ? true : false}
        isOpen={isOpenOverrideModal}
        onClose={handleCloseOverrideModal}
        highlightToday={true}
        enablePast={true}      
        onChange={(data)=>{
          dateIndex === 0 ?
          setState((s: State) => ({ ...s, startDate: data.startTime })) :
          setState((s: State) => ({ ...s, endDate: data.startTime }));
        }} 
        data={ 
          { id: '',
            endTime: new Date(),
            selectedTimeRange: {
              id: '',
              start: '',
              end: ''
            },
            startTime : dateIndex === 0 ? 
              (state.startDate ? state.startDate : new Date()) :
              (state.endDate ? state.endDate : new Date()) }
        }
        isTime={false}
      />
    </>
  );
}

export default Content;
