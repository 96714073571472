/* tslint:disable */
/* eslint-disable */
/* @relayHash 04fdd08fb86003e94bad75ada191be16 */

import { ConcreteRequest } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type App_Holder_QueryVariables = {};
export type App_Holder_QueryResponse = {
    readonly user: {
        readonly " $fragmentRefs": FragmentRefs<"AppHolder_user">;
    } | null;
    readonly candidate: {
        readonly " $fragmentRefs": FragmentRefs<"AppHolder_candidate">;
    } | null;
};
export type App_Holder_Query = {
    readonly response: App_Holder_QueryResponse;
    readonly variables: App_Holder_QueryVariables;
};



/*
query App_Holder_Query {
  user {
    ...AppHolder_user
  }
  candidate {
    ...AppHolder_candidate
    id
  }
}

fragment AppHolder_candidate on Candidate {
  candidateType
  firstName
  lastName
  jobTitle
  email
  telephone
  profileImageUrl
  skillGroupType
  skillGroupTypeConfirmed
  hiddenQualifications
  chatHistory
  ...CandidateModal_candidate
}

fragment AppHolder_user on User {
  type
  firstName
  lastName
}

fragment CandidateModal_candidate on Candidate {
  id
  firstName
  lastName
  email
  jobTitle
  telephone
  profileImageUrl
  skillGroupType
}
*/

const node: ConcreteRequest = (function () {
    var v0 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "firstName",
        "args": null,
        "storageKey": null
    } as any), v1 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "lastName",
        "args": null,
        "storageKey": null
    } as any);
    return {
        "kind": "Request",
        "fragment": {
            "kind": "Fragment",
            "name": "App_Holder_Query",
            "type": "Query",
            "metadata": null,
            "argumentDefinitions": [],
            "selections": [
                {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "user",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "User",
                    "plural": false,
                    "selections": [
                        {
                            "kind": "FragmentSpread",
                            "name": "AppHolder_user",
                            "args": null
                        }
                    ]
                },
                {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "candidate",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "Candidate",
                    "plural": false,
                    "selections": [
                        {
                            "kind": "FragmentSpread",
                            "name": "AppHolder_candidate",
                            "args": null
                        }
                    ]
                }
            ]
        },
        "operation": {
            "kind": "Operation",
            "name": "App_Holder_Query",
            "argumentDefinitions": [],
            "selections": [
                {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "user",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "User",
                    "plural": false,
                    "selections": [
                        {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "type",
                            "args": null,
                            "storageKey": null
                        },
                        (v0 /*: any*/),
                        (v1 /*: any*/)
                    ]
                },
                {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "candidate",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "Candidate",
                    "plural": false,
                    "selections": [
                        {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "candidateType",
                            "args": null,
                            "storageKey": null
                        },
                        (v0 /*: any*/),
                        (v1 /*: any*/),
                        {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "jobTitle",
                            "args": null,
                            "storageKey": null
                        },
                        {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "email",
                            "args": null,
                            "storageKey": null
                        },
                        {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "telephone",
                            "args": null,
                            "storageKey": null
                        },
                        {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "profileImageUrl",
                            "args": null,
                            "storageKey": null
                        },
                        {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "skillGroupType",
                            "args": null,
                            "storageKey": null
                        },
                        {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "skillGroupTypeConfirmed",
                            "args": null,
                            "storageKey": null
                        },
                        {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "hiddenQualifications",
                            "args": null,
                            "storageKey": null
                        },
                        {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "chatHistory",
                            "args": null,
                            "storageKey": null
                        },
                        {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "id",
                            "args": null,
                            "storageKey": null
                        }
                    ]
                }
            ]
        },
        "params": {
            "operationKind": "query",
            "name": "App_Holder_Query",
            "id": null,
            "text": "query App_Holder_Query {\n  user {\n    ...AppHolder_user\n  }\n  candidate {\n    ...AppHolder_candidate\n    id\n  }\n}\n\nfragment AppHolder_candidate on Candidate {\n  candidateType\n  firstName\n  lastName\n  jobTitle\n  email\n  telephone\n  profileImageUrl\n  skillGroupType\n  skillGroupTypeConfirmed\n  hiddenQualifications\n  chatHistory\n  ...CandidateModal_candidate\n}\n\nfragment AppHolder_user on User {\n  type\n  firstName\n  lastName\n}\n\nfragment CandidateModal_candidate on Candidate {\n  id\n  firstName\n  lastName\n  email\n  jobTitle\n  telephone\n  profileImageUrl\n  skillGroupType\n}\n",
            "metadata": {}
        }
    } as any;
})();
(node as any).hash = '8b0a58baf179ccfd681ddba62ebfba02';
export default node;
