/* tslint:disable */
/* eslint-disable */

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type Mentoring_candidate = {
    readonly firstName: string;
    readonly mentor: {
        readonly id: string;
        readonly firstName: string;
        readonly lastName: string;
        readonly email: string;
    } | null;
    readonly " $refType": "Mentoring_candidate";
};
export type Mentoring_candidate$data = Mentoring_candidate;
export type Mentoring_candidate$key = {
    readonly " $data"?: Mentoring_candidate$data;
    readonly " $fragmentRefs": FragmentRefs<"Mentoring_candidate">;
};



const node: ReaderFragment = (function () {
    var v0 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "firstName",
        "args": null,
        "storageKey": null
    } as any);
    return {
        "kind": "Fragment",
        "name": "Mentoring_candidate",
        "type": "Candidate",
        "metadata": null,
        "argumentDefinitions": [],
        "selections": [
            (v0 /*: any*/),
            {
                "kind": "LinkedField",
                "alias": null,
                "name": "mentor",
                "storageKey": null,
                "args": null,
                "concreteType": "Mentor",
                "plural": false,
                "selections": [
                    {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "id",
                        "args": null,
                        "storageKey": null
                    },
                    (v0 /*: any*/),
                    {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "lastName",
                        "args": null,
                        "storageKey": null
                    },
                    {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "email",
                        "args": null,
                        "storageKey": null
                    }
                ]
            }
        ]
    } as any;
})();
(node as any).hash = '6c37aa31c66d5d4b72c72d45ca3fe708';
export default node;
