import React, { useRef, useEffect, useState } from 'react';
import { Player, BigPlayButton, ControlBar, PlayToggle, FullscreenToggle } from 'video-react';
import 'video-react/dist/video-react.css';

interface Props {
  src: string,
  onEnded?(): void,
  [additionProp: string]: any,
  enableControls?: boolean,
}

const Video = (props: Props) => {

  const videoRef: any = useRef(null);
  const [videoState, setVideoState]: any = useState({});
  const [hasEnded, setHasEnded] = useState(false);

  useEffect(() => {
    videoRef.current.subscribeToStateChange(setVideoState);
  }, [setVideoState]);

  useEffect(() => {
    if (videoState && videoState.ended && !hasEnded) {
      // Preventing onVideoEnd from being called multiple times
      setHasEnded(true);
      typeof props.onEnded === 'function' && props.onEnded();
    }
  }, [videoState, hasEnded]);

  useEffect(() => {
    // prevent users use key to seek
    window.addEventListener('keydown', function (event) {
      event.stopPropagation();
    }, true);
    window.addEventListener('keypress', function (event) {
      event.stopPropagation();
    }, true);
  }, []);

  return (
    <Player src={props.src} ref={videoRef}>
      <BigPlayButton position="center" />
      {props.enableControls ? (
        <ControlBar autoHide={false} />
      ): (
        <ControlBar autoHide={true} disableDefaultControls={true}>
          <PlayToggle />
          <div style={{ flex: 1 }} />
          <FullscreenToggle />
        </ControlBar>
      )}
    </Player>
  );
};

export default Video;