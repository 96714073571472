import { StructureElement } from './OnlineCourse/__generated__/OnlineCourse_myActiveCourse.graphql';
import { graphql, fetchQuery, commitMutation } from 'react-relay';
import environment from 'api/environment';
import { helpersCourseQueryResponse } from './__generated__/helpersCourseQuery.graphql';
import { GraphError } from 'types/general';
import { helpers_UpdateUpToMutationResponse } from './__generated__/helpers_UpdateUpToMutation.graphql';
import { captureMessage, captureException } from '@sentry/react';
import { helpersSignoffPDFQuery } from './__generated__/helpersSignoffPDFQuery.graphql';

type CourseSyllabus = ReadonlyArray<{
  readonly name: string;
  readonly type: StructureElement;
  readonly uuid: string;
  readonly syllabus?: ReadonlyArray<{
    readonly name: string;
    readonly uuid: string;
    readonly type: StructureElement;
  }> | null;
}> | null;

export function goToNextURL(
  courseID: number,
  courseSyllabus: CourseSyllabus,
  currentUUID: string | undefined,
  onCourseCompletion: () => void
) {
  if (courseSyllabus === null) {
    return '/app/courses';
  }

  if (currentUUID === undefined) {
    const type = courseSyllabus[0].type;
    const uuid = courseSyllabus[0].uuid;
    return `/app/courses/${courseID}/${type}/${uuid}`;
  }

  const flatSyllabus: {
    name: string;
    uuid: string;
    type: string;
  }[] = [];

  courseSyllabus.forEach((item, index) => {
    flatSyllabus.push(item);
    if (item.type === 'module') {
      item.syllabus?.forEach((modItem) => {
        flatSyllabus.push(modItem);
      });
    }
  });

  var found = false;
  for (const item of flatSyllabus) {
    console.log('item', item);
    if (found) {
      return `/app/courses/${courseID}/${item.type}/${item.uuid}`;
    }
    if (item.uuid === currentUUID) {
      UpdateUpTo(
        courseID,
        item.uuid,
        (err) => {
          console.log('Unable to save upto: ', err);
          captureMessage(
            `Unable to save upTo: ${item.uuid} - ${courseID} : ${err}`
          );
        },
        (resp) => {
          if (resp.updateUpTo?.courseCompleted) {
            onCourseCompletion();
          }
        }
      );
      found = true;
    }
  }

  // Assumed finished
  return undefined;
}

export async function getCourse(id: number) {
  const query = graphql`
    query helpersCourseQuery($id: Int!) {
      course(id: $id) {
        ident: id
        name
        bannerImageURL
        introduction
        price
        specificTerms
      }
    }
  `;

  const variables = {
    id: id
  };

  try {
    const data = (await fetchQuery(
      environment,
      query,
      variables
    )) as helpersCourseQueryResponse;

    if (data.course) {
      return data.course;
    }
  } catch (err) {
    console.warn(err);
    return undefined;
  }

  return undefined;
}

const upToMutation = graphql`
  mutation helpers_UpdateUpToMutation($courseID: Int!, $upTo: UUID) {
    updateUpTo(input: { courseID: $courseID, upTo: $upTo }) {
      updateSuccess
      courseCompleted
    }
  }
`;

export const UpdateUpTo = (
  courseID: number,
  upTo: string,
  errorCallback: (err: string) => void,
  successCallback: (response: helpers_UpdateUpToMutationResponse) => void
) => {
  const variables = {
    courseID,
    upTo
  };

  commitMutation(environment, {
    mutation: upToMutation,
    variables,
    onCompleted: (
      response: helpers_UpdateUpToMutationResponse,
      errors: GraphError[]
    ) => {
      if (errors) {
        // Display error
        errorCallback(`${errors[0]?.extensions?.message}`);
        return;
      }
      console.log('Response received from server.', response, errors);
      successCallback(response);
    },
    onError: (err: Error) => {
      errorCallback(err.message || err.name);
    }
  });
};
export async function getSignoffPDF(id: string) {
  const query = graphql`
    query helpersSignoffPDFQuery($id: ID!) {
      signoffPDF(id: $id)
    }
  `;
  const variables = { id };
  try {
    const response = await fetchQuery<helpersSignoffPDFQuery>(
      environment,
      query,
      variables,
    );
    return response.signoffPDF;
  } catch (error) {
    console.error(error);
    return null;
  }
}
