/* tslint:disable */
/* eslint-disable */
/* @relayHash fe0c3591bcc0131eb976017994c4b767 */

import { ConcreteRequest } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type App_AllCourses_QueryVariables = {
    limit?: number | null;
};
export type App_AllCourses_QueryResponse = {
    readonly courses: {
        readonly " $fragmentRefs": FragmentRefs<"AllCourses_courses">;
    } | null;
};
export type App_AllCourses_Query = {
    readonly response: App_AllCourses_QueryResponse;
    readonly variables: App_AllCourses_QueryVariables;
};



/*
query App_AllCourses_Query(
  $limit: Int
) {
  courses(page: {limit: $limit}) {
    ...AllCourses_courses
  }
}

fragment AllCourses_courses on CoursePage {
  edges {
    __typename
    ...CourseCard_course
  }
  pageInfo {
    given
    total
  }
}

fragment CourseCard_course on Course {
  ident: id
  name
  bannerImageURL
  externalLink
  excerpt
  category {
    color
    name
  }
}
*/

const node: ConcreteRequest = (function () {
    var v0 = [
        ({
            "kind": "LocalArgument",
            "name": "limit",
            "type": "Int",
            "defaultValue": null
        } as any)
    ], v1 = [
        ({
            "kind": "ObjectValue",
            "name": "page",
            "fields": [
                {
                    "kind": "Variable",
                    "name": "limit",
                    "variableName": "limit"
                }
            ]
        } as any)
    ], v2 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "name",
        "args": null,
        "storageKey": null
    } as any);
    return {
        "kind": "Request",
        "fragment": {
            "kind": "Fragment",
            "name": "App_AllCourses_Query",
            "type": "Query",
            "metadata": null,
            "argumentDefinitions": (v0 /*: any*/),
            "selections": [
                {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "courses",
                    "storageKey": null,
                    "args": (v1 /*: any*/),
                    "concreteType": "CoursePage",
                    "plural": false,
                    "selections": [
                        {
                            "kind": "FragmentSpread",
                            "name": "AllCourses_courses",
                            "args": null
                        }
                    ]
                }
            ]
        },
        "operation": {
            "kind": "Operation",
            "name": "App_AllCourses_Query",
            "argumentDefinitions": (v0 /*: any*/),
            "selections": [
                {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "courses",
                    "storageKey": null,
                    "args": (v1 /*: any*/),
                    "concreteType": "CoursePage",
                    "plural": false,
                    "selections": [
                        {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "edges",
                            "storageKey": null,
                            "args": null,
                            "concreteType": null,
                            "plural": true,
                            "selections": [
                                {
                                    "kind": "ScalarField",
                                    "alias": null,
                                    "name": "__typename",
                                    "args": null,
                                    "storageKey": null
                                },
                                {
                                    "kind": "ScalarField",
                                    "alias": "ident",
                                    "name": "id",
                                    "args": null,
                                    "storageKey": null
                                },
                                (v2 /*: any*/),
                                {
                                    "kind": "ScalarField",
                                    "alias": null,
                                    "name": "bannerImageURL",
                                    "args": null,
                                    "storageKey": null
                                },
                                {
                                    "kind": "ScalarField",
                                    "alias": null,
                                    "name": "externalLink",
                                    "args": null,
                                    "storageKey": null
                                },
                                {
                                    "kind": "ScalarField",
                                    "alias": null,
                                    "name": "excerpt",
                                    "args": null,
                                    "storageKey": null
                                },
                                {
                                    "kind": "LinkedField",
                                    "alias": null,
                                    "name": "category",
                                    "storageKey": null,
                                    "args": null,
                                    "concreteType": "Category",
                                    "plural": false,
                                    "selections": [
                                        {
                                            "kind": "ScalarField",
                                            "alias": null,
                                            "name": "color",
                                            "args": null,
                                            "storageKey": null
                                        },
                                        (v2 /*: any*/)
                                    ]
                                }
                            ]
                        },
                        {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "pageInfo",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "PageInfo",
                            "plural": false,
                            "selections": [
                                {
                                    "kind": "ScalarField",
                                    "alias": null,
                                    "name": "given",
                                    "args": null,
                                    "storageKey": null
                                },
                                {
                                    "kind": "ScalarField",
                                    "alias": null,
                                    "name": "total",
                                    "args": null,
                                    "storageKey": null
                                }
                            ]
                        }
                    ]
                }
            ]
        },
        "params": {
            "operationKind": "query",
            "name": "App_AllCourses_Query",
            "id": null,
            "text": "query App_AllCourses_Query(\n  $limit: Int\n) {\n  courses(page: {limit: $limit}) {\n    ...AllCourses_courses\n  }\n}\n\nfragment AllCourses_courses on CoursePage {\n  edges {\n    __typename\n    ...CourseCard_course\n  }\n  pageInfo {\n    given\n    total\n  }\n}\n\nfragment CourseCard_course on Course {\n  ident: id\n  name\n  bannerImageURL\n  externalLink\n  excerpt\n  category {\n    color\n    name\n  }\n}\n",
            "metadata": {}
        }
    } as any;
})();
(node as any).hash = 'c31eba0af12f1bb2b8c549b2ef47d3b7';
export default node;
