import * as React from 'react';
import { createUseStyles } from 'react-jss';
import classNames from 'classnames';

import { Theme } from 'helpers/theme';
import Tooltip from 'components/core/Tooltip';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';

const useStyles = createUseStyles((theme: Theme) => ({
  container: {
    height: 48,
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'strech',
    background: theme.colors.primaryWhite
  },
  clickableBox: {
    height: 48,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: [0, 15],
    border: `1px solid ${theme.colors.borderGrey}`,
    borderRadius: theme.buttonBorderRadius,
    cursor: 'pointer'
  },
  dropdown: {
    position: 'absolute',
    top: 50,
    width: '100%',
    maxHeight: 450, // don't know how this should be calculated
    border: `1px solid ${theme.colors.borderGrey}`,
    borderRadius: theme.primaryBorderRadius,
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: theme.colors.primaryWhite
  },
  searchResults: {
    position: 'absolute',
    minWidth: '100%',
    maxHeight: 450,
    overflowY: 'auto',
    zIndex: 10,
    boxShadow: theme.shadows.primary,
    borderBottomLeftRadius: '5px',
    borderBottomRightRadius: '5px'
  },
  title: {
    fontSize: theme.fontSizes.small,
    fontWeight: 300,
    marginRight: 19
  },
  option: {
    height: 40,
    cursor: 'pointer',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: [0, 15],
    fontSize: theme.fontSizes.default,
    backgroundColor: theme.colors.primaryWhite,
    borderBottom: `1px solid ${theme.colors.borderGrey}`,
    '&:last-child': {
      borderBottom: 'none'
    }
  },
  triangleDown: {
    width: 0,
    height: 0,
    borderLeft: '5px solid transparent',
    borderRight: '5px solid transparent',
    borderTop: ` ${5 * 2 * 0.866}px solid ${theme.colors.secondaryOrange}`
  },
  triangleUp: {
    width: 0,
    height: 0,
    borderLeft: '5px solid transparent',
    borderRight: '5px solid transparent',
    borderBottom: `${5 * 2 * 0.866}px solid ${theme.colors.secondaryOrange}`
  },
  backgroundHider: {
    position: 'fixed',
    width: '100%',
    height: '100%',
    top: 0,
    left: 0,
  }
}));

export type DropdownOption = {
  id: number | string;
  title: string;
  component?: React.ReactNode;
};

type Props = {
  placeholder: string;
  options: DropdownOption[];
  selected?: DropdownOption;
  setSelected: (
    selected: DropdownOption
  ) => void;
  boxStyle?: string;
  fontStyle?: string;
  fontStyleOption?: string;
  icon?: React.ReactElement;
  uncloseable?: boolean;
  className?: string;
  hint?: string;
};

function Dropdown({
  placeholder,
  options,
  selected,
  setSelected,
  boxStyle,
  fontStyle,
  fontStyleOption,
  icon,
  uncloseable,
  className,
  hint
}: Props) {
  const classes = useStyles();
  const [isOpen, setOpen] = React.useState<boolean>(false);

  return (
    <>
      {isOpen && <div className={classes.backgroundHider} onClick={() => setOpen(false)} />}
      <div className={classNames(classes.container, className)}>
        <div
          className={classNames(classes.clickableBox, boxStyle)}
          onClick={() => setOpen((o) => !o)}
        >
          <p className={classNames(classes.title, fontStyle)}>
            {selected ? selected.title : placeholder}
          </p>
          {hint && (
            <Tooltip text={hint} element={<FontAwesomeIcon icon={faInfoCircle}/>}/>
          )}
          {icon ? (
            icon
          ) : (
            <div className={isOpen ? classes.triangleUp : classes.triangleDown} />
          )}
        </div>
        {isOpen && (
          <div className={classes.dropdown}>
            <div className={classes.searchResults}>
              {options
                .filter(x => !(selected && x.id === selected.id))
                .map(({ id, title, component }: DropdownOption) => (
                  <div
                    key={id}
                    className={classes.option}
                    onClick={() => {
                      setSelected({ id, title, component });
                      !uncloseable && setOpen(false);
                    }}
                  >
                    <span className={classNames(classes.title, fontStyleOption)}>
                      {component ? component : title}
                    </span>
                  </div>
                ))}
            </div>
          </div>
        )}
      </div>
    </>
  );
}

export default Dropdown;
