/* tslint:disable */
/* eslint-disable */

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type Module_user = {
    readonly firstName: string;
    readonly lastName: string;
    readonly " $refType": "Module_user";
};
export type Module_user$data = Module_user;
export type Module_user$key = {
    readonly " $data"?: Module_user$data;
    readonly " $fragmentRefs": FragmentRefs<"Module_user">;
};



const node: ReaderFragment = ({
    "kind": "Fragment",
    "name": "Module_user",
    "type": "User",
    "metadata": null,
    "argumentDefinitions": [],
    "selections": [
        {
            "kind": "ScalarField",
            "alias": null,
            "name": "firstName",
            "args": null,
            "storageKey": null
        },
        {
            "kind": "ScalarField",
            "alias": null,
            "name": "lastName",
            "args": null,
            "storageKey": null
        }
    ]
} as any);
(node as any).hash = 'c153b4c5d400ce3c6913943c734b89d0';
export default node;
