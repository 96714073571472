import * as React from 'react';
import { createUseStyles, useTheme } from 'react-jss';
import { Theme } from 'helpers/theme';
import Heading from 'components/core/Heading';
import SelectButton from 'components/core/Input/SelectButton';
import { createFragmentContainer, graphql } from 'react-relay';
import { MyCourses_user } from './__generated__/MyCourses_user.graphql';
import Page from 'components/Page';
import CourseCard from 'components/CourseCard';
import theme from 'helpers/theme';
import styled from 'styled-components';
import PageTitle from 'components/PageTitle';

const useStyles = createUseStyles((theme: Theme) => ({
  myCoursesRoot: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1
  },
  mainHeading: {
    gridArea: 'headin'
  },
  subHeading: {
    gridArea: 'subhea',
    maxWidth: 466
  },
  selectButton: {
    gridArea: 'select'
  },
  filterRow: {
    marginTop: theme.spacing(2),
    display: 'flex',
    justifyContent: 'space-between'
  },
  courseHolder: {
    display: 'grid',
    gridGap: 26,
    margin: `${theme.spacing(3)}px 0`,
    gridTemplateColumns: 'repeat(auto-fit, 298px)'
  },
  page: {
    marginTop: theme.spacing(3)
  },
  subFilters: {
    display: 'flex'
  },
  filterButton: {
    marginRight: theme.spacing(1)
  }
}));

const SubTitle = styled.div`
  font-weight: 300;
  font-size: 14px;
  color: ${theme.colors.textGrey};
  max-width: 500px;
  margin-top: 15px;
`;

type Props = {
  user?: MyCourses_user;
};

function MyCourses({ user }: Props) {
  const theme = useTheme();
  const classes = useStyles({ theme });

  const selectOptions = ['Active Courses', 'Completed Courses'];
  const [selectedOption, setSelectedOption] = React.useState(selectOptions[0]);

  const onlineCourses = user?.myCourses ?? [];
  const classroomCourses = (user?.myClassroomCourses ?? []).filter(c => c.status === 'complete');

  return (
    <Page>
      <div className={classes.myCoursesRoot}>
        {/*<Heading
          text={'My Courses'}
          size={'large'}
          className={classes.mainHeading}
        />*/}
        <PageTitle title="My Courses" />
        <SubTitle>
          {user?.firstName}, here you can see your active courses for completion
          as well as all the courses you have already completed.
        </SubTitle>
        <div className={classes.filterRow}>
          <SelectButton
            options={selectOptions}
            selected={selectedOption}
            onClick={(option: string) => {
              setSelectedOption(option);
            }}
            className={classes.selectButton}
          />
        </div>
        <div className={classes.courseHolder}>
          {
          [
            ...onlineCourses,
            ...classroomCourses
          ].filter((course) =>
              selectedOption == selectOptions[0]
                ? course.status == 'incomplete'
                : course.status == 'complete'
            )
            .map(
              (course, index) =>
                course?.course && (
                  <CourseCard
                    key={index}
                    course={course.course}
                    certificateURL={course.certificateURL}
                    isTaking
                    completedAt={course.completedAt}
                  />
                )
            )}
        </div>
      </div>
    </Page>
  );
}

export default createFragmentContainer(MyCourses, {
  user: graphql`
    fragment MyCourses_user on User {
      firstName
      myCourses {
        status
        certificateURL
        completedAt
        course {
          ...CourseCard_course
        }
      }
      myClassroomCourses {
        status
        certificateURL
        completedAt
        course {
          ...CourseCard_course
        }
      }
    }
  `
});
