import * as React from 'react';
import styled from 'styled-components';
import Text from 'components/core/Table/Text';
import { makeMutation } from 'api/helpers';
import { graphql, QueryRenderer } from 'react-relay';
import { RecordSourceSelectorProxy } from 'relay-runtime';
import environment from 'api/environment';
import Spacer from 'components/core/Spacers/Spacer';
import moment from 'moment';
import Table from 'components/core/Table';
import theme, { Theme } from 'helpers/theme';
import Paginator, { Page } from 'components/Pagination/Paginator';
import { useRouter } from 'found';
import { PortfolioQueryRendererQueryResponse } from './__generated__/PortfolioQueryRendererQuery.graphql';
import { createUseStyles } from 'react-jss';
import IconButton from '@material-ui/core/IconButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faTrash } from '@fortawesome/free-solid-svg-icons';
import { DeleteEntry } from 'api/mutations';
import DeletePopup from 'components/DeletePopup';

const useStyles = createUseStyles((theme: Theme) => ({
  columnHeader: {
    cursor: 'pointer',
    zIndex: '2'
  }  
}));

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const NoneFoundMessage = styled.div`
  color: ${theme.colors.textGrey};
  text-align: center;
  margin-top: 20px;
  font-size: 16px;
`;

var nameMap = {
  draft: 'Draft',
  submitted: 'Submitted',
  signedoff: 'Approved'
};

type Props = {
  candidateID: string;
  searchText: string;
};

type OrderBy = {
  field: "title" | "start_date" | "end_date" | "status" | "created_at" | "placement";
  ascending: boolean;
}

const OFFSET = 0;
const LIMIT = 10;

// NOTE: Since one entry could contain more than one practice area and competency,
// it was decided not to show these two fields in the table. Instead of showing these fields,
// the QWE entry was displayed with the click operation.
function PortfolioQueryRenderer({ candidateID, searchText }: Props) {
  const [offset, setOffset] = React.useState(OFFSET);
  const [limit, setLimit] = React.useState(LIMIT);
  const { router } = useRouter();
  const [order, setOrder] = React.useState<OrderBy>();
  const [isOpen, setIsOpen] = React.useState(false);
  const [entryToDelete, setEntryToDelete] = React.useState({
    name: '',
    id: ''
  });

  const classes = useStyles();

  const deleteEntry = async (id: string) => {
    try {
      await makeMutation(
        DeleteEntry,
        {
          id: id
        },
        (store: RecordSourceSelectorProxy<{}>) => {
          const root = store.get('client:root');
          if (!root) {
            console.error('could not get root');
            return;
          }
          const user = root.getLinkedRecord('candidate');
          if (!user) {
            console.log('Could not get user');
            return;
          }
          const entries = user.getLinkedRecords('entries');
          if (!entries) {
            console.error('could not get entries');
            return;
          }
          const currentEntry = store.get(id);
          if (!currentEntry) {
            console.error('could not find currentEntry', id);
            return;
          }
        }
      );
    } catch (err) {
      console.log('Unable to delete qualification', err);
    }
  };

  React.useEffect(() => {
    setOffset(OFFSET);
    setLimit(LIMIT);
  }, [candidateID, searchText]);

  const onUpdatePage = ({ offset, limit }: Page) => {
    if (offset <= 0) {
      offset = 1;
    }

    setLimit(limit);
    setOffset((offset - 1) * limit);
  };

  const tableColumns = [
    {
      headerName: 'PLACEMENT',
      onHeaderClick: () => setOrder({
        field: 'placement',
        ascending: true
      }),
      className: classes.columnHeader, 
      component: (item: any) => <Text text={item.placement} />
    },
    {
      headerName: 'TITLE',
      onHeaderClick: () => {
        console.log('here');
        setOrder({
          field: 'title',
          ascending: true
        });
      },
      className: classes.columnHeader, 
      component: (item: any) => <Text text={item.title} />
    },
    /*{
      headerName: 'COMPETENCY',
      component: (item: any) => <Text text={item.competency} />
    },*/
    {
      headerName: 'START DATE',
      className: classes.columnHeader, 
      onHeaderClick: () => setOrder({
        field: 'start_date',
        ascending: false
      }),
      component: (item: any) => (
        <Text
          text={
            item.startDate ? moment(item.startDate).format('DD/MM/YYYY') : '-'
          }
        />
      )
    },
    {
      headerName: 'END DATE',
      onHeaderClick: () => setOrder({
        field: 'end_date',
        ascending: false
      }),
      className: classes.columnHeader, 
      component: (item: any) => (
        <Text
          text={item.endDate ? moment(item.endDate).format('DD/MM/YYYY') : '-'}
        />
      )
    },
    /*{
      headerName: 'PRACTICE AREA',
      component: (item: any) => <Text text={item.practiceArea} />
    },*/
    {
      headerName: 'STATUS',
      className: classes.columnHeader, 
      onHeaderClick: () => setOrder({
        field: 'status',
        ascending: true
      }),
      component: (item: any) => <Text text={item.status} />
    },
    {
      headerName: 'DOCUMENT',
      component: (item: any) => item.fileUrl ? (
        <IconButton
          color="default"
          onClick={(e) => {
            e.stopPropagation();
            window.open(item.fileUrl, '_blank');
          }}
        >
          <FontAwesomeIcon icon={faEye}/> 
        </IconButton>
      ): '-'
    },
    {
      headerName: 'DELETE',
      className: classes.columnHeader, 
      component: (item: any) => <IconButton
          color="secondary"
          onClick={() => {
            setEntryToDelete({
              id: item.id,
              name: item.title
            });
            setIsOpen((prev) => !prev);
          }}
        >
          <FontAwesomeIcon icon={faTrash}
            color={'#a4acb5'}
            style={{height: 15}}/> 
        </IconButton>
    }
  ];

  return (
    <QueryRenderer
      environment={environment}
      query={graphql`
        query PortfolioQueryRendererQuery($searchText: String, $order: OrderBy) {
          candidate {
            id
            firstName
            lastName
            placements {
              edges {
                id
                name
                entries(filter: { title: $searchText }, orderBy: $order) {
                  edges {
                    id
                    title
                    startDate
                    endDate
                    status
                    placement {
                      name
                    }
                    fileUrl
                    #practiceSubSkills {
                    #  practiceSkillGroup {
                    #    practiceSkill {
                    #      name
                    #      practiceArea {
                    #        name
                    #      }
                    #    }
                    #  }
                    #}
                  }
                }
              }
            }
          }
        }
      `}
      variables={{
        searchText: searchText,
        order: !order || (order && order.field == "placement") ? null : order
      }}
      render={({ error, props, retry }) => {
        if (!props) {
          return <div></div>;
        }
        const { candidate } = props as PortfolioQueryRendererQueryResponse;

        let entries: any = [];
        candidate?.placements?.edges?.map((placement) => {
          placement && placement.entries && placement.entries.edges && placement?.entries?.edges.map((entry) => {
            if (entry){
              const entryComponent = {
                id: entry.id,
                placement: entry.placement?.name || '',
                title: entry.title || '',
                startDate: entry.startDate,
                endDate: entry.endDate,
                status: entry.status ? nameMap[entry.status] : '-',
                fileUrl: entry.fileUrl
                /*
                competency:
                  entry.practiceSubSkills[0]?.practiceSkillGroup?.practiceSkill?.name ||
                  '',
                practiceArea:
                  entry.practiceSubSkills[0]?.practiceSkillGroup?.practiceSkill
                    ?.practiceArea?.name || '',
                */
              };
  
              entries.push(entryComponent);
            }
          });
        });

        if (order && order.field == "placement"){
          entries.sort((a: any, b: any) => a.placement.localeCompare(b.placement));
        }

        const page = {
          currentPage: Math.ceil(offset / limit) + 1,
          numPages: Math.ceil(entries.length / limit)
        };

        return (
          <Container>
            {entries.length === 0 ? (
              <NoneFoundMessage>No entries found.</NoneFoundMessage>
            ) : (
              <Table
                columns={tableColumns}
                rows={entries.slice(offset, offset + limit)}
                onRowClick={(row) => router.push(`/app/entry/update/${row.id}`)}
                entry
              />
            )}
            <Spacer spacing={2} vertical />
            {entries.length > 0 && (
              <Paginator
                currentPage={page.currentPage}
                updatePage={onUpdatePage}
                numPages={page.numPages ?? 1}
                itemsPerPage={limit}
                showRange={page.numPages} //{(page.numPages ?? 1) > 4 ? 4 : page.numPages ?? 1}
              />
            )}
            <DeletePopup
              open={isOpen}
              name={entryToDelete.name}
              onClose={() => setIsOpen((prev) => !prev)}
              onDelete={() => {deleteEntry(entryToDelete.id); retry && retry(); setIsOpen((prev) => !prev)}}
            />
          </Container>
        );
      }}
    />
  );
}

export default PortfolioQueryRenderer;
