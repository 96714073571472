/* tslint:disable */
/* eslint-disable */
/* @relayHash f7ca959aa31ac74d39f25387c4a24dbb */

import { ConcreteRequest } from "relay-runtime";
export type Weekday = "friday" | "monday" | "saturday" | "sunday" | "thursday" | "tuesday" | "wednesday" | "%future added value";
export type FileUpload = {
    fileBase64: string;
    fileType: string;
    fileName?: string | null;
};
export type mutationsCreatePlacementMutationVariables = {
    candidateID: string;
    name: string;
    startDate: string;
    endDate?: string | null;
    qweIndividual?: string | null;
    orgPosition?: string | null;
    sraNumber?: string | null;
    orgSRANumber?: string | null;
    supervisorName: string;
    supervisorEmail: string;
    email?: string | null;
    fullTime: boolean;
    partTimeDays?: Array<Weekday> | null;
    daysMissed: number;
    qweDocFile?: FileUpload | null;
};
export type mutationsCreatePlacementMutationResponse = {
    readonly createPlacement: {
        readonly id: string;
        readonly name: string;
        readonly startDate: string;
        readonly endDate: string | null;
        readonly qweIndividual: string | null;
        readonly orgPosition: string | null;
        readonly sraNumber: string | null;
        readonly orgSRANumber: string | null;
        readonly supervisorName: string;
        readonly supervisorEmail: string;
        readonly email: string | null;
        readonly fullTime: boolean;
        readonly partTimeDays: ReadonlyArray<Weekday> | null;
        readonly qweDocURL: string | null;
        readonly client: {
            readonly name: string;
            readonly managers: {
                readonly edges: ReadonlyArray<{
                    readonly id: string;
                    readonly firstName: string;
                    readonly lastName: string;
                    readonly email: string;
                    readonly profileImageUrl: string | null;
                } | null> | null;
            };
        } | null;
    } | null;
};
export type mutationsCreatePlacementMutation = {
    readonly response: mutationsCreatePlacementMutationResponse;
    readonly variables: mutationsCreatePlacementMutationVariables;
};



/*
mutation mutationsCreatePlacementMutation(
  $candidateID: ID!
  $name: String!
  $startDate: Time!
  $endDate: Time
  $qweIndividual: String
  $orgPosition: String
  $sraNumber: String
  $orgSRANumber: String
  $supervisorName: String!
  $supervisorEmail: String!
  $email: String
  $fullTime: Boolean!
  $partTimeDays: [Weekday!]
  $daysMissed: Int!
  $qweDocFile: FileUpload
) {
  createPlacement(input: {candidateID: $candidateID, name: $name, startDate: $startDate, endDate: $endDate, qweIndividual: $qweIndividual, orgPosition: $orgPosition, sraNumber: $sraNumber, orgSRANumber: $orgSRANumber, supervisorName: $supervisorName, supervisorEmail: $supervisorEmail, email: $email, fullTime: $fullTime, partTimeDays: $partTimeDays, daysMissed: $daysMissed, qweDocFile: $qweDocFile}) {
    id
    name
    startDate
    endDate
    qweIndividual
    orgPosition
    sraNumber
    orgSRANumber
    supervisorName
    supervisorEmail
    email
    fullTime
    partTimeDays
    qweDocURL
    client {
      name
      managers {
        edges {
          id
          firstName
          lastName
          email
          profileImageUrl
        }
      }
      id
    }
  }
}
*/

const node: ConcreteRequest = (function () {
    var v0 = [
        ({
            "kind": "LocalArgument",
            "name": "candidateID",
            "type": "ID!",
            "defaultValue": null
        } as any),
        ({
            "kind": "LocalArgument",
            "name": "name",
            "type": "String!",
            "defaultValue": null
        } as any),
        ({
            "kind": "LocalArgument",
            "name": "startDate",
            "type": "Time!",
            "defaultValue": null
        } as any),
        ({
            "kind": "LocalArgument",
            "name": "endDate",
            "type": "Time",
            "defaultValue": null
        } as any),
        ({
            "kind": "LocalArgument",
            "name": "qweIndividual",
            "type": "String",
            "defaultValue": null
        } as any),
        ({
            "kind": "LocalArgument",
            "name": "orgPosition",
            "type": "String",
            "defaultValue": null
        } as any),
        ({
            "kind": "LocalArgument",
            "name": "sraNumber",
            "type": "String",
            "defaultValue": null
        } as any),
        ({
            "kind": "LocalArgument",
            "name": "orgSRANumber",
            "type": "String",
            "defaultValue": null
        } as any),
        ({
            "kind": "LocalArgument",
            "name": "supervisorName",
            "type": "String!",
            "defaultValue": null
        } as any),
        ({
            "kind": "LocalArgument",
            "name": "supervisorEmail",
            "type": "String!",
            "defaultValue": null
        } as any),
        ({
            "kind": "LocalArgument",
            "name": "email",
            "type": "String",
            "defaultValue": null
        } as any),
        ({
            "kind": "LocalArgument",
            "name": "fullTime",
            "type": "Boolean!",
            "defaultValue": null
        } as any),
        ({
            "kind": "LocalArgument",
            "name": "partTimeDays",
            "type": "[Weekday!]",
            "defaultValue": null
        } as any),
        ({
            "kind": "LocalArgument",
            "name": "daysMissed",
            "type": "Int!",
            "defaultValue": null
        } as any),
        ({
            "kind": "LocalArgument",
            "name": "qweDocFile",
            "type": "FileUpload",
            "defaultValue": null
        } as any)
    ], v1 = [
        ({
            "kind": "ObjectValue",
            "name": "input",
            "fields": [
                {
                    "kind": "Variable",
                    "name": "candidateID",
                    "variableName": "candidateID"
                },
                {
                    "kind": "Variable",
                    "name": "daysMissed",
                    "variableName": "daysMissed"
                },
                {
                    "kind": "Variable",
                    "name": "email",
                    "variableName": "email"
                },
                {
                    "kind": "Variable",
                    "name": "endDate",
                    "variableName": "endDate"
                },
                {
                    "kind": "Variable",
                    "name": "fullTime",
                    "variableName": "fullTime"
                },
                {
                    "kind": "Variable",
                    "name": "name",
                    "variableName": "name"
                },
                {
                    "kind": "Variable",
                    "name": "orgPosition",
                    "variableName": "orgPosition"
                },
                {
                    "kind": "Variable",
                    "name": "orgSRANumber",
                    "variableName": "orgSRANumber"
                },
                {
                    "kind": "Variable",
                    "name": "partTimeDays",
                    "variableName": "partTimeDays"
                },
                {
                    "kind": "Variable",
                    "name": "qweDocFile",
                    "variableName": "qweDocFile"
                },
                {
                    "kind": "Variable",
                    "name": "qweIndividual",
                    "variableName": "qweIndividual"
                },
                {
                    "kind": "Variable",
                    "name": "sraNumber",
                    "variableName": "sraNumber"
                },
                {
                    "kind": "Variable",
                    "name": "startDate",
                    "variableName": "startDate"
                },
                {
                    "kind": "Variable",
                    "name": "supervisorEmail",
                    "variableName": "supervisorEmail"
                },
                {
                    "kind": "Variable",
                    "name": "supervisorName",
                    "variableName": "supervisorName"
                }
            ]
        } as any)
    ], v2 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "id",
        "args": null,
        "storageKey": null
    } as any), v3 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "name",
        "args": null,
        "storageKey": null
    } as any), v4 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "startDate",
        "args": null,
        "storageKey": null
    } as any), v5 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "endDate",
        "args": null,
        "storageKey": null
    } as any), v6 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "qweIndividual",
        "args": null,
        "storageKey": null
    } as any), v7 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "orgPosition",
        "args": null,
        "storageKey": null
    } as any), v8 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "sraNumber",
        "args": null,
        "storageKey": null
    } as any), v9 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "orgSRANumber",
        "args": null,
        "storageKey": null
    } as any), v10 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "supervisorName",
        "args": null,
        "storageKey": null
    } as any), v11 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "supervisorEmail",
        "args": null,
        "storageKey": null
    } as any), v12 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "email",
        "args": null,
        "storageKey": null
    } as any), v13 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "fullTime",
        "args": null,
        "storageKey": null
    } as any), v14 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "partTimeDays",
        "args": null,
        "storageKey": null
    } as any), v15 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "qweDocURL",
        "args": null,
        "storageKey": null
    } as any), v16 = ({
        "kind": "LinkedField",
        "alias": null,
        "name": "managers",
        "storageKey": null,
        "args": null,
        "concreteType": "ManagerPage",
        "plural": false,
        "selections": [
            {
                "kind": "LinkedField",
                "alias": null,
                "name": "edges",
                "storageKey": null,
                "args": null,
                "concreteType": "Manager",
                "plural": true,
                "selections": [
                    (v2 /*: any*/),
                    {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "firstName",
                        "args": null,
                        "storageKey": null
                    },
                    {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "lastName",
                        "args": null,
                        "storageKey": null
                    },
                    (v12 /*: any*/),
                    {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "profileImageUrl",
                        "args": null,
                        "storageKey": null
                    }
                ]
            }
        ]
    } as any);
    return {
        "kind": "Request",
        "fragment": {
            "kind": "Fragment",
            "name": "mutationsCreatePlacementMutation",
            "type": "Mutation",
            "metadata": null,
            "argumentDefinitions": (v0 /*: any*/),
            "selections": [
                {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "createPlacement",
                    "storageKey": null,
                    "args": (v1 /*: any*/),
                    "concreteType": "Placement",
                    "plural": false,
                    "selections": [
                        (v2 /*: any*/),
                        (v3 /*: any*/),
                        (v4 /*: any*/),
                        (v5 /*: any*/),
                        (v6 /*: any*/),
                        (v7 /*: any*/),
                        (v8 /*: any*/),
                        (v9 /*: any*/),
                        (v10 /*: any*/),
                        (v11 /*: any*/),
                        (v12 /*: any*/),
                        (v13 /*: any*/),
                        (v14 /*: any*/),
                        (v15 /*: any*/),
                        {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "client",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "Client",
                            "plural": false,
                            "selections": [
                                (v3 /*: any*/),
                                (v16 /*: any*/)
                            ]
                        }
                    ]
                }
            ]
        },
        "operation": {
            "kind": "Operation",
            "name": "mutationsCreatePlacementMutation",
            "argumentDefinitions": (v0 /*: any*/),
            "selections": [
                {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "createPlacement",
                    "storageKey": null,
                    "args": (v1 /*: any*/),
                    "concreteType": "Placement",
                    "plural": false,
                    "selections": [
                        (v2 /*: any*/),
                        (v3 /*: any*/),
                        (v4 /*: any*/),
                        (v5 /*: any*/),
                        (v6 /*: any*/),
                        (v7 /*: any*/),
                        (v8 /*: any*/),
                        (v9 /*: any*/),
                        (v10 /*: any*/),
                        (v11 /*: any*/),
                        (v12 /*: any*/),
                        (v13 /*: any*/),
                        (v14 /*: any*/),
                        (v15 /*: any*/),
                        {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "client",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "Client",
                            "plural": false,
                            "selections": [
                                (v3 /*: any*/),
                                (v16 /*: any*/),
                                (v2 /*: any*/)
                            ]
                        }
                    ]
                }
            ]
        },
        "params": {
            "operationKind": "mutation",
            "name": "mutationsCreatePlacementMutation",
            "id": null,
            "text": "mutation mutationsCreatePlacementMutation(\n  $candidateID: ID!\n  $name: String!\n  $startDate: Time!\n  $endDate: Time\n  $qweIndividual: String\n  $orgPosition: String\n  $sraNumber: String\n  $orgSRANumber: String\n  $supervisorName: String!\n  $supervisorEmail: String!\n  $email: String\n  $fullTime: Boolean!\n  $partTimeDays: [Weekday!]\n  $daysMissed: Int!\n  $qweDocFile: FileUpload\n) {\n  createPlacement(input: {candidateID: $candidateID, name: $name, startDate: $startDate, endDate: $endDate, qweIndividual: $qweIndividual, orgPosition: $orgPosition, sraNumber: $sraNumber, orgSRANumber: $orgSRANumber, supervisorName: $supervisorName, supervisorEmail: $supervisorEmail, email: $email, fullTime: $fullTime, partTimeDays: $partTimeDays, daysMissed: $daysMissed, qweDocFile: $qweDocFile}) {\n    id\n    name\n    startDate\n    endDate\n    qweIndividual\n    orgPosition\n    sraNumber\n    orgSRANumber\n    supervisorName\n    supervisorEmail\n    email\n    fullTime\n    partTimeDays\n    qweDocURL\n    client {\n      name\n      managers {\n        edges {\n          id\n          firstName\n          lastName\n          email\n          profileImageUrl\n        }\n      }\n      id\n    }\n  }\n}\n",
            "metadata": {}
        }
    } as any;
})();
(node as any).hash = '3e0d2ae741262a8f2ad46a20b851ec3f';
export default node;
