import React from 'react';
import { createFragmentContainer, createRefetchContainer, graphql, RefetchOptions } from 'react-relay';
import Spacer from 'components/core/Spacers/Spacer';
import ListSelector from 'components/ListSelector/ListSelector';
import Page from 'components/Page';
import { QWE_candidate } from './__generated__/QWE_candidate.graphql';
import { QWE_clients } from './__generated__/QWE_clients.graphql';
import PlacementsPage from './PlacementsPage';
import PageTitle from 'components/PageTitle';
import CompetencySkillsPage from './CompetencySkillsPage';
import { useRouter } from 'found';
import PortfolioPage from './Portfolio/PortfolioPage';
import theme from 'helpers/theme';
import styled from 'styled-components';
import Tooltip from 'components/core/Tooltip';

const SubTitle = styled.div`
  font-weight: 300;
  font-size: 14px;
  color: ${theme.colors.textGrey};
  max-width: 500px;
  margin-top: 15px;
`;

const LockContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

type Props = {
  candidate: QWE_candidate;
  clients: QWE_clients;
  relay: {
    refetch: (
      refetchVariables: Object | ((prevVars: Object) => void),
      renderVariables: Object,
      callback: (error?: Error) => void,
      options?: RefetchOptions
    ) => void;
  };
};


function QWE({ candidate, clients, relay }: Props) {
  const { match, router } = useRouter();

  const lockedSkills = (text: string) => (
    <Tooltip
      element={(
        <LockContainer>
          <span>
            {text}
          </span>
          <svg xmlns="http://www.w3.org/2000/svg"
            width="15" height="15"
            viewBox="0 0 24 24"
          >
            <path d="M 12 1 C 8.6761905 1 6 3.6761905 6 7 L 6 8 C 4.9069372 8 4 8.9069372 4 10 L 4 20 C 4 21.093063 4.9069372 22 6 22 L 18 22 C 19.093063 22 20 21.093063 20 20 L 20 10 C 20 8.9069372 19.093063 8 18 8 L 18 7 C 18 3.6761905 15.32381 1 12 1 z M 12 3 C 14.27619 3 16 4.7238095 16 7 L 16 8 L 8 8 L 8 7 C 8 4.7238095 9.7238095 3 12 3 z M 6 10 L 18 10 L 18 20 L 6 20 L 6 10 z M 12 13 C 10.9 13 10 13.9 10 15 C 10 16.1 10.9 17 12 17 C 13.1 17 14 16.1 14 15 C 14 13.9 13.1 13 12 13 z"></path>
          </svg>
        </LockContainer>
      )}
      text={`This fantastic feature is available for those employed by Accutrainee.
      To unlock this and other features, apply to Accutrainee today!`}
      position="right"
    />
  );

  const optionsSQE = [
    {
      text: 'Placements',
      value: 'placements'
    },
    {
      text: 'Competency/Skills',
      value: 'competencies',
      component: candidate.candidateType !== 'level3' ? lockedSkills('Competency/Skills') : undefined
    },
    {
      text: 'Portfolio',
      value: 'portfolio'
    }
  ];
  
  const options = [
    {
      text: 'Placements',
      value: 'placements'
    },
    {
      text: 'Practice Skills Standard',
      value: 'competencies',
      component: candidate.candidateType !== 'level3' ? lockedSkills('Practice Skills Standard') : undefined
    },
    {
      text: 'Portfolio',
      value: 'portfolio'
    }
  ];

  const { tab } = match.params;
  const currentTab = options.filter((option) => option.value === tab)[0];
  const [selected, setSelected] = React.useState(currentTab || options[0]);

  const onButtonClick = () => {
    setSelected(options[2]);
  };

  
  const title = () => {
    if (candidate.skillGroupType === "TC") {
      return "Training Record";
    }
    return "QWE Portfolio";
  }

  const subscription = () => {
    if (candidate.skillGroupType === "TC") {
      return "Use your portfolio to accurately record your training contract and track your experiences against the practice skills standards.";
    }
    return "Record, track and monitor all your QWE against the SRA competencies. Use your entries to support confirmation of your QWE by a solicitor.";
  }

  return (
    <Page>
      <PageTitle title={title()} />
      <SubTitle>
        {subscription()}
      </SubTitle>
      <Spacer vertical spacing={3} />
      <ListSelector
        items={candidate.skillGroupType === "TC" ? options : optionsSQE}
        skillGroupType={null}
        hiddenQualifications={0}
        selected={selected.value}
        onChange={(option) => {
          setSelected({...option, component: option.component});
        }}
      />
      <Spacer vertical spacing={2} />
      {selected.value === 'placements' && (
        <PlacementsPage candidate={candidate} clients={clients} refetch={relay.refetch} />
      )}
      {selected.value === 'competencies' && (
        <CompetencySkillsPage
          candidate={candidate}
          onButtonClick={onButtonClick}
        />
      )}
      {selected.value === 'portfolio' && (
        <PortfolioPage candidateID={candidate.id} />
      )}
    </Page>
  );
}

export default createRefetchContainer(QWE, {
  candidate: graphql`
    fragment QWE_candidate on Candidate {
      id
      skillGroupType
      candidateType
      mentor {
        id
        firstName
        lastName
        email
      }
      placements {
        edges {
          id
          name
          startDate
          endDate
          qweIndividual
          orgPosition
          sraNumber
          approved
          orgSRANumber
          supervisorName
          supervisorEmail
          email
          fullTime
          partTimeDays
          daysMissed
          qweDocURL
          qweDocFilename
          client {
            name
            managers {
              edges {
                id
                firstName
                lastName
                email
                profileImageUrl
              }
            }
          }
        }
      }
      ...CompetencySkillsPage_candidate
    }
  `,
  clients: graphql`
    fragment QWE_clients on ClientPage {
      edges {
        id
        name
      }
    }
  `,
},
  graphql`
    query QWERefetchQuery {
      candidate {
        ...QWE_candidate
      }
      clients {
        ...QWE_clients
      }
    }
  `
);
