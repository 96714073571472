import React, { useEffect, useState } from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import styled from 'styled-components';
import { Roadmap_candidate, Weekday } from './__generated__/Roadmap_candidate.graphql';
import theme from 'helpers/theme';
import ProgressBox from 'components/Roadmap/ProgressBox';

import runner from '../../assets/roadmap/runner.svg';
import arrowRight from '../../assets/roadmap/rightarrows.svg';
import arrowLeft from '../../assets/roadmap/leftarrows.svg';

import roadmapIcon1 from "../../assets/roadmap/Lightbulb.svg";
import roadmapIcon2 from "../../assets/roadmap/SQE.svg";
import roadmapIcon3 from "../../assets/roadmap/SQE.svg";
import roadmapIcon4 from "../../assets/roadmap/QWE.svg";
import roadmapIcon5 from "../../assets/roadmap/Character.svg";
import roadmapIcon6 from "../../assets/roadmap/qualified.svg";

import newRoadmapIcon1 from "../../assets/roadmap/newDegree.svg";
import newRoadmapIcon2 from "../../assets/roadmap/newSQE1.png";
import newRoadmapIcon3 from "../../assets/roadmap/newSQE2LPC.png";
import newRoadmapIcon4 from "../../assets/roadmap/QWE.svg";
import newRoadmapIcon5 from "../../assets/roadmap/newCS.png";
import newRoadmapIcon6 from "../../assets/roadmap/newQua.png";
import newRoadmapIcon7 from "../../assets/roadmap/newLearn.png";


import 'components/Roadmap/style.scss';
import moment from 'moment';
import QWE from 'views/QWE/QWE';

const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
`;

const CurveLine = styled.div<{ imageUrl: string }>`
  width: 620px;
  height: 320px;
  background-image: url(${(props) => props.imageUrl});
  margin: 64px;
  background-repeat: no-repeat;
`;

const ArrowRight = styled.div<{ imageUrl: string }>`
  width: 136px;
  height: 242px;
  top: 21px;
  left: 595px;
  background-image: url(${(props) => props.imageUrl});
  position: absolute;
  background-repeat: no-repeat;
`;

const ArrowLeft = styled.div<{ imageUrl: string }>`
  width: 136px;
  height: 242px;
  top: 165px;
  left: 62px;
  background-image: url(${(props) => props.imageUrl});
  position: absolute;
  background-repeat: no-repeat;
`;

const RoadmapIcons = [
  newRoadmapIcon1,
  newRoadmapIcon2,
  newRoadmapIcon3,
  newRoadmapIcon4,
  newRoadmapIcon5,
  newRoadmapIcon6,
  newRoadmapIcon7,
];

const SectionName = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: ${theme.colors.textGrey};
  font-size: 16px;
  width: 140px;
  top: 92px;
`;

const LandingMessage = styled.div`
  margin: 100px 0px;
  text-align: center;
`;

type Props = {
  candidate: Roadmap_candidate;
  chatConfirmed: boolean;
};

const isBetween = (startDate: any, endDate: any): boolean => {
  let now = new Date();
  let start = new Date(startDate);
  let end = new Date(endDate);

  return now.getTime() <= end.getTime() && now.getTime() >= start.getTime();
};

const daysElapsed = (startDate: any, endDate: any): number => {
  // let difference = new Date(endDate).getTime() - new Date(startDate).getTime();
  // var days = Math.ceil(difference / (1000 * 3600 * 24));
  // return days;

  let start = moment(startDate);
  let end = moment(endDate);
  let days = 0;

  for (var isoWeekday = 1; isoWeekday <= 5; isoWeekday++) {
    var daysToAdd = ((7 + isoWeekday) - start.isoWeekday()) % 7;
    var nextDay = start.clone().add(daysToAdd, 'days');
    if (nextDay.isAfter(end)) {
      break;
    }

    var weeksBetween = end.diff(nextDay, 'weeks');
    days += (weeksBetween + 1);
  }

  return days;
};

const partTimeDaysElapsed = (startDate: string | number, endDate: string | number, weekday: Weekday): number => {
  let start = moment(startDate);
  let end = moment(endDate);

  const isoWeekday = moment().isoWeekday(weekday).isoWeekday();

  var daysToAdd = ((7 + isoWeekday) - start.isoWeekday()) % 7;
  var nextDay = start.clone().add(daysToAdd, 'days');
  if (nextDay.isAfter(end)) {
    return 0;
  }

  var weeksBetween = end.diff(nextDay, 'weeks');
  return weeksBetween + 1;
};

function Roadmap({ candidate, chatConfirmed }: Props) {
  useEffect(() => {
    setSkillGroupTypeConfirmed(chatConfirmed);
  }, [chatConfirmed])

  const [skillGroupTypeConfirmed, setSkillGroupTypeConfirmed] = useState(chatConfirmed);

  let signedOff = 0;
  let running = 0;
  let pending = 0;
  let days = 0;
  let totalMinSuggestedEntryCount = 0;
  let totalDoneEntryCount = 0;

  candidate?.placements?.edges?.forEach((placement) => {
    if (placement && placement.approved) {
      signedOff += 1;
    } else if ((placement?.startDate && placement?.endDate) && isBetween(placement.startDate, placement.endDate)) {
      running += 1;

      localStorage.setItem('placement', placement.id);
      console.log("Set placement in storage: ", placement.id, days);
    } else {
      pending += 1;
    }

    const now = new Date();
    if (placement?.startDate && placement?.endDate) {
      if (new Date(placement.startDate) > now) return;
      const endDate = now < new Date(placement.endDate) ? now.getTime() : placement.endDate;

      if (!placement.fullTime && placement.partTimeDays && placement.partTimeDays.length > 0) {
        placement.partTimeDays.forEach(day => {
          days += partTimeDaysElapsed(placement.startDate, endDate, day);
        });
      } else {
        days += daysElapsed(placement.startDate, endDate);
      }
      days += placement.daysMissed;
    }
  });

  const binaryIndexValue = (value: number, index: number) => {
    let res = value.toString(2);
    if (res == "-1") { res = "0"; }
    if (res.length > index && res.charAt(res.length - index - 1) == "1") {
      return true;
    }
    return false;
  }

  const DegreeTab = 1
  const GDLTab = 2
  const LPCTab = 3
  const SQE1Tab = 5
  const SQE2Tab = 6
  const QWETab = 7
  const TCTab = 8
  const CSTab = 9
  const QualifiedTab = 10

  const degreeCompleted =
    (candidate?.qualificationsForDegree &&
      candidate?.qualificationsForDegree?.length > 0) || (binaryIndexValue(candidate.roadmapCompleted, DegreeTab));


  const gdlCompleted =
    (candidate?.qualificationsForGDL &&
      candidate?.qualificationsForGDL?.length > 0) || (binaryIndexValue(candidate.roadmapCompleted, GDLTab));

  const lpcCompleted =
    (candidate?.qualificationsForLPC &&
      candidate?.qualificationsForLPC?.length > 0) || (binaryIndexValue(candidate.roadmapCompleted, LPCTab));

  const sqe1Completed =
    (candidate?.qualificationsForSQE1 &&
      candidate?.qualificationsForSQE1?.length > 0) || (binaryIndexValue(candidate.roadmapCompleted, SQE1Tab));

  const sqe2Completed =
    (candidate?.qualificationsForSQE2 &&
      candidate?.qualificationsForSQE2?.length > 0) || (binaryIndexValue(candidate.roadmapCompleted, SQE2Tab));

  const tcCompleted = binaryIndexValue(candidate.roadmapCompleted, TCTab);


  let qweCompleted = candidate?.placements?.edges?.length ? true : false;
  candidate?.placements?.edges?.forEach((placement) => {
    if (placement && !placement.approved) {
      qweCompleted = false;
    }
  });
  qweCompleted = qweCompleted || binaryIndexValue(candidate.roadmapCompleted, QWETab);

  const characterSuitabilityCompleted = candidate?.characterSuitabilityCompleted || false;
  const qualified = degreeCompleted && sqe1Completed && sqe2Completed && qweCompleted && characterSuitabilityCompleted;

  var results = [];

  if (binaryIndexValue(candidate.roadmapItems, DegreeTab)) {
    results.push({
      sectionName: 'Degree',
      completed: degreeCompleted,
      icon: newRoadmapIcon1
    })
  }

  if (binaryIndexValue(candidate.roadmapItems, GDLTab)) {
    results.push({
      sectionName: 'GDL',
      completed: gdlCompleted,
      icon: newRoadmapIcon7
    })
  }

  if (binaryIndexValue(candidate.roadmapItems, LPCTab)) {
    results.push({
      sectionName: 'LPC',
      completed: lpcCompleted,
      icon: newRoadmapIcon3
    })
  }

  if (binaryIndexValue(candidate.roadmapItems, SQE1Tab)) {
    results.push({
      sectionName: 'SQE1',
      completed: sqe1Completed,
      icon: newRoadmapIcon2
    })
  }

  if (binaryIndexValue(candidate.roadmapItems, SQE2Tab)) {
    results.push({
      sectionName: 'SQE2',
      completed: sqe2Completed,
      icon: newRoadmapIcon3
    })
  }

  if (binaryIndexValue(candidate.roadmapItems, TCTab)) {
    results.push({
      sectionName: 'Training Contract',
      completed: tcCompleted,
      icon: newRoadmapIcon4
    })
  }

  if (binaryIndexValue(candidate.roadmapItems, QWETab)) {
    results.push({
      sectionName: 'Qualifying Work Experience',
      completed: qweCompleted,
      icon: newRoadmapIcon4
    })
  }

  if (binaryIndexValue(candidate.roadmapItems, CSTab)) {
    results.push({
      sectionName: 'Character & Suitability',
      completed: characterSuitabilityCompleted,
      icon: newRoadmapIcon5
    })
  }

  if (binaryIndexValue(candidate.roadmapItems, QualifiedTab)) {
    results.push({
      sectionName: 'Qualified',
      completed: qualified,
      icon: newRoadmapIcon6
    })
  }

  const areaProgress = candidate?.areaProgress ?? [];
  areaProgress.map((practiceArea: any) => {
    // prepare all skills (called 'competency' according to the new ui design) of the practice area
    if (practiceArea.practiceArea.name == candidate.skillGroupType) {
      practiceArea.practiceArea.practiceSkills.edges.map(
        (practiceSkill: any) => {

          // prepare all skill groups of the practice skill
          practiceSkill.practiceSkillGroups.edges.map(
            (practiceSkillGroup: any) => {
              // prepare all sub-skills (called 'skills' according to the new ui design) of the skill group
              practiceSkillGroup.practiceSubSkills.edges.map(
                (practiceSubSkill: any) => {

                  totalMinSuggestedEntryCount +=
                    practiceSubSkill.minSuggestedEntryCount || 0;
                  totalDoneEntryCount += practiceSubSkill.doneEntryCount || 0;
                }
              );
            }
          );
        }
      );
    }
  });

  return (
    <Container>
      {skillGroupTypeConfirmed ?
        <div className="roadmap-container">
          <div className="roadmap-end-icon start">Start</div>
          <div className="roadmap-end-icon finish">Finish</div>
          {results.map((result, index) => {
            return (
              <div className={"roadmap-icon pos-" + (index + 1)}>
                <img className="roadmap-img" src={result.icon} />
                <div className={"roadmap-icon " + (result.completed ? "completed" : "todo")} />
                <SectionName>{result.sectionName}</SectionName>
              </div>
            );
          })}
          <div className="roadmap-progressbox">
            <ProgressBox maxTime={522} maxSkills={totalMinSuggestedEntryCount} curTime={days} curSkills={totalDoneEntryCount} />
          </div>
          <ArrowRight imageUrl={arrowRight} />
          <ArrowLeft imageUrl={arrowLeft} />
          <CurveLine imageUrl={runner} />
        </div> : <LandingMessage>
          <strong>
            Please complete the questions Al asks you on the right. <br />
            Once complete, we will customise your tailored journey and your roadmap will appear.
          </strong>
        </LandingMessage>}
    </Container>
  );
}

export default createFragmentContainer(Roadmap, {
  candidate: graphql`
    fragment Roadmap_candidate on Candidate {
      qualificationsForDegree: qualifications(qualificationType: degree) {
        id
      }
      qualificationsForSQE1: qualifications(qualificationType: sqe1) {
        id
      }
      qualificationsForSQE2: qualifications(qualificationType: sqe2) {
        id
      }
      qualificationsForGDL: qualifications(qualificationType: gdl) {
        id
      }
      qualificationsForLPC: qualifications(qualificationType: lpc) {
        id
      }
      characterSuitabilityCompleted
      skillGroupType
      skillGroupTypeConfirmed
      hiddenQualifications
      roadmapItems
      roadmapCompleted
      placements {
        edges {
          id
          name
          startDate
          endDate
          approved
          partTimeDays
          fullTime
          daysMissed
        }
      }
      areaProgress {
        practiceArea {
          id
          name
          practiceSkills {
            edges {
              id
              name
              practiceSkillGroups {
                edges {
                  id
                  name
                  practiceSubSkills {
                    edges {
                      id
                      name
                      practiceSubSkillType
                      minSuggestedEntryCount
                      doneEntryCount
                      order
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `
});
