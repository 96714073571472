const spacing = {
  0: 5,
  1: 10,
  2: 20,
  3: 50,
  4: 70
};

export type Theme = {
  spacing: (amount: number) => number;
  primaryBorderRadius: number;
  secondaryBorderRadius: number;
  buttonBorderRadius: number;
  primaryGradient: string;
  verticalGradient: string;
  loginBackgroundGradient: string;
  searchBackground: string;
  colors: {
    primaryBlack: string;
    primaryBlue: string;
    primaryGreen: string;
    secondaryOrange: string;
    secondaryBlack: string;
    hoverOrange: string;
    primaryRed: string;
    secondaryGrey: string;
    primaryWhite: string;
    borderGrey: string;
    backgroundGrey: string;
    textOrange: string;
    textGrey: string;
    searchHoverGrey: string;
    progressGrey: string;
    navyBlue: string;
    blueRibbon: string;
    approxIron: string;
    approxZircon: string;
    secondaryDanger: string;
    certBackgroundGrey: string;
    textNavyBlue: string;
    textSolitude: string;
    textManatee: string;
  };
  shadows: {
    primary: string;
    body: string;
  };
  fontSizes: {
    heading: number;
    smallHeading: number;
    xSmallHeading: number;
    tinyHeading: number;
    extraLarge: number;
    large: number;
    xLarge: number;
    default: number;
    small: number;
    xSmall: number;
    tiny: number;
  };
  paperSizes: {
    A4: {
      width: number;
      height: number;
    };
  };
};

const theme: Theme = {
  spacing: (amount: 0 | 1 | 2 | 3) => spacing[amount],
  primaryBorderRadius: 5,
  secondaryBorderRadius: 10,
  buttonBorderRadius: 4,
  primaryGradient: 'linear-gradient(50deg, #0b57ff 0%,#16C225 100%)',
  verticalGradient: 'linear-gradient(to bottom left, #efc250 0%,#c35f2a 100%)',
  loginBackgroundGradient: 'linear-gradient(50deg, #0f6fcc 0%,#16a858 100%)',
  searchBackground: 'rgba(7,67,121,0.75)',
  colors: {
    primaryBlack: '#271507',
    primaryBlue: '#0b57ff',
    primaryGreen: '#10b73b',
    secondaryBlack: '#34373A',
    secondaryOrange: '#f37a21',
    hoverOrange: 'rgb(251 249 242)',
    primaryRed: '#CB463A',
    secondaryGrey: '#9ea2ad',
    primaryWhite: '#FFFFFF',
    borderGrey: '#E9EBEB',
    backgroundGrey: '#f7f9fb',
    searchHoverGrey: '#F5FAFC',
    textOrange: '#f17f2c',
    textGrey: '#737988',
    progressGrey: '#d2d6db',
    navyBlue: '#0E66E0',
    blueRibbon: '#0E5AF9',
    approxIron: '#CCCDCD',
    approxZircon: '#E9EBEB',
    secondaryDanger: '#DB5C5D',
    certBackgroundGrey: '#F5F5FE',
    textNavyBlue: '#6BAAE7',
    textSolitude: '#DFEEFD',
    textManatee: '#929CA6'
  },
  shadows: {
    primary: '2px 2px 10px rgba(0,0,0,0.07)',
    body: 'inset 0px -2px 10px 0px #0000001f'
  },
  fontSizes: {
    heading: 25,
    smallHeading: 24,
    xSmallHeading: 21,
    tinyHeading: 20,
    extraLarge: 18,
    large: 16,
    xLarge: 15,
    default: 14,
    small: 13,
    xSmall: 12,
    tiny: 11
  },
  paperSizes: {
    // Origin A4 Size: 96ppi(794x1123)
    A4: {
      width: 794,
      height: 1123
    }
  }
};

export default theme;
