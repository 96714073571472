import React from 'react';
import styled from 'styled-components';
import { makeMutation } from 'api/helpers';
import { SubmitEntries } from 'api/mutations';
import Button from 'components/core/Input/ButtonBase';
import Spacer from 'components/core/Spacers/Spacer';
import CoreInput from 'components/core/Input/CoreInput';
import { Body, Footer } from 'components/SideModal/Tabs';
import { useToastDispatch } from 'components/Toast/ToastProvider';
import { ScaleLoader } from 'react-spinners';
import EntriesDropdown from './EntriesDropdown';
import { KeyboardDatePicker } from '@material-ui/pickers';
import EntriesDateRange from './EntriesDateRange';
import { createUseStyles, useTheme } from 'react-jss';
import { Theme } from 'helpers/theme';
import { ThemeProvider } from '@material-ui/core/styles';
import themeCustom from '../../helpers/themeCustom';
import LoadMoreDropdown, { Option } from './LoadMoreDropdown';

const useStyles = createUseStyles((theme: Theme) => ({
  root: {
    padding: '10px 10px !important',
    border: '1px solid #08080814 !important',
    borderRadius: 5,
    '& div': {
      '&:before': { borderBottom: 'none !important' },
      '&:after': { borderBottom: 'none !important' }
    }
  },
  title: {
    padding: '15px 15px !important',
    border: '1px solid #08080814 !important',
    borderRadius: 5,
    '& div': {
      '&:before': { borderBottom: 'none !important' },
      '&:after': { borderBottom: 'none !important' }
    }
  },
}));

const SectionTitle = styled.h1`
  color: #271507;
  font-size: 20px;
  font-weight: 700;
  display: flex;
  align-items: center;
`;

const SectionSubTitle = styled.div`
  color: #271507;
  font-size: 14px;
  font-weight: 400;
  margin-left: 8px;
`;

export type State = {
  supervisorID?: string;
  mentorID?: string;
  placementID: string;
  selected: string[];
  saving?: boolean;
  startDate: string | undefined;
  endDate: string | undefined;
  entries: Option[];
  title: string;
};

type Props = {
  state: State;
  setState: (newState: any) => void;
  closeModal: () => void;
};

function arrayEquals(a: Option[], b: Option[]) {
  return (
    a.length === b.length &&
    !a.some(x => !b.find(y => x.value === y.value))
  );
}

function Content({ state, setState, closeModal }: Props) {
  const theme = useTheme();
  const classes = useStyles({ theme });
  const dispatch = useToastDispatch();

  const onSave = async () => {
    if (state.saving) return;

    if (!state.title) {
      dispatch({
        text: 'Please add a title',
        alert: 'warning'
      });
      return;
    }

    if (!state.startDate) {
      dispatch({
        text: 'Please select a start date',
        alert: 'warning'
      });
      return;
    }

    if (!state.endDate) {
      dispatch({
        text: 'Please select an end date',
        alert: 'warning'
      });
      return;
    }

    if (state.entries.length === 0) {
      dispatch({
        text: 'There must be at least one entry to send',
        alert: 'warning'
      });
      return;
    }

    if (state.selected.length === 0) {
      dispatch({
        text: 'Select some entries to send them',
        alert: 'warning'
      });
      return;
    }

    try {
      setState((s: State) => ({ ...s, saving: true }));
      await makeMutation(SubmitEntries, {
        supervisorID: state.supervisorID || undefined,
        mentorID: state.mentorID || undefined,
        entries: state.selected,
        title: state.title
      });
      dispatch({
        text: 'Entries sent successfully',
        alert: 'success',
        stayFor: 4000
      });
      closeModal();
    } catch (err) {
      console.log('Unable to send entries', err);
      dispatch({
        text: err.message,
        alert: 'error',
        stayFor: 6000
      });
    }
    setState((s: State) => ({ ...s, saving: false }));
  };

  return (
    <>
      <Body>
        <SectionTitle>Please select start and end date for getting entries</SectionTitle>
        <SectionTitle>Title* <SectionSubTitle>(Please state the month and year of the entries you are sending, e.g. July 2021)</SectionSubTitle></SectionTitle>
        <ThemeProvider theme={themeCustom}>
          <CoreInput
            placeholder="Please enter a title for this month's entries"
            type="text"
            value={state.title}
            className={classes.title}
            onChange={(event: string) => setState((s: State) => ({ ...s, title: event }))}
          />
        </ThemeProvider>
        <SectionTitle>Start Date*</SectionTitle>
        <ThemeProvider theme={themeCustom}>
          <KeyboardDatePicker
            value={state.startDate ? state.startDate : null}
            placeholder="FROM: 01/01/2021"
            onChange={(date) => {
              date?.set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
              setState((s: State) => ({ ...s, startDate: date?.format() }));
            }}
            format="DD/MM/YYYY"
            className={classes.root}
            inputProps={{ style: { font: "12px 'Muli', sans-serif" } }}
            autoOk={true}
          />
        </ThemeProvider>
        <Spacer vertical spacing={1} />
        <SectionTitle>End Date*</SectionTitle>
        <ThemeProvider theme={themeCustom}>
          <KeyboardDatePicker
            value={state.endDate ? state.endDate : null}
            placeholder="TO: 01/01/2021"
            onChange={(date) => {
              date?.set({ hour: 23, minute: 59, second: 59, millisecond: 0 });
              setState((s: State) => ({ ...s, endDate: date?.format() }));
            }}
            format="DD/MM/YYYY"
            className={classes.root}
            inputProps={{ style: { font: "12px 'Muli', sans-serif" } }}
            autoOk={true}
          />
        </ThemeProvider>
        <Spacer vertical spacing={1} />
        {state.startDate && state.endDate && (
          <EntriesDateRange
            placementID={state.placementID}
            startDate={state.startDate}
            endDate={state.endDate}
            setEntries={(sel: Option[]) => {
              !arrayEquals(sel, state.entries) &&
                setState((s: State) => ({ ...s, entries: sel }));
            }}
          />
        )}
        <Spacer vertical spacing={1} />
        <SectionTitle>
          Select entries
        </SectionTitle>

        <LoadMoreDropdown
          options={state.entries}
          onChange={(sel) => {
            setState((s: State) => ({ ...s, selected: sel }))
          }}
          placeholder={state.selected.length > 0 ?
            state.entries.find(e => e.value === state.selected[0])?.name ?? ''
            : "Please select one or more entries"
          }
          selected={state.selected}
        />
        <Spacer vertical spacing={1} />
      </Body>
      <Footer>
        <div style={{ display: 'flex' }}>
          <Button archetype={'default'} onClick={closeModal}>
            Cancel
          </Button>
          <Spacer horizontal spacing={2} />
          <Button archetype="submit" onClick={state.saving ? () => { } : onSave}>
            {state.saving ? (
              <ScaleLoader color="white" height={10} />
            ) : (
              `Send Entries`
            )}
          </Button>
        </div>
      </Footer>
    </>
  );
}

export default Content;
