import { commitMutation, graphql } from 'react-relay';
import environment from 'api/environment';
import { GraphError } from 'types/general';
import { logout_MutationResponse } from './__generated__/logout_Mutation.graphql';

const logoutMutation = graphql`
  mutation logout_Mutation {
    logout
  }
`;

export const Logout = (
  errorCallback: (err: string) => void,
  successCallback: (response: logout_MutationResponse) => void
) => {
  commitMutation(environment, {
    mutation: logoutMutation,
    variables: {},
    onCompleted: (response: logout_MutationResponse, errors: GraphError[]) => {
      if (errors) {
        errorCallback(`${errors[0]?.extensions?.message}`);
        return;
      }
      successCallback(response);
    }
  });
};
