export type Tutorial = {
  id: number | string;
  title: string;
  videoLink: string;
};

export const tutorials = [
  {
    id: 1,
    title: 'Pathway Tutorial 1 - Al',
    thumbnail: 'https://pathways-prod-uploads.s3.eu-west-1.amazonaws.com/videoTutorialThumbnails/tutorial_1.png',
    videoLink: 'https://pathways-prod-uploads.s3.eu-west-1.amazonaws.com/videoTutorials/tutorial_1.mp4'
  },
  {
    id: 2,
    title: 'Pathway Tutorial 2 - Qualifications',
    thumbnail: 'https://pathways-prod-uploads.s3.eu-west-1.amazonaws.com/videoTutorialThumbnails/tutorial_2.png',
    videoLink: 'https://pathways-prod-uploads.s3.eu-west-1.amazonaws.com/videoTutorials/tutorial_2.mp4'
  },
  {
    id: 3,
    title: 'Pathway Tutorial 3 - Welcome To Pathways',
    thumbnail: 'https://pathways-prod-uploads.s3.eu-west-1.amazonaws.com/videoTutorialThumbnails/tutorial_3.png',
    videoLink: 'https://pathways-prod-uploads.s3.eu-west-1.amazonaws.com/videoTutorials/tutorial_3.mp4'
  },
  {
    id: 4,
    title: 'Pathway Tutorial 4 - Adding a Placement',
    thumbnail: 'https://pathways-prod-uploads.s3.eu-west-1.amazonaws.com/videoTutorialThumbnails/tutorial_4.png',
    videoLink: 'https://pathways-prod-uploads.s3.eu-west-1.amazonaws.com/videoTutorials/tutorial_4.mp4'
  },
  {
    id: 5,
    title: 'Pathway Tutorial 5 - Adding a new entry',
    thumbnail: 'https://pathways-prod-uploads.s3.eu-west-1.amazonaws.com/videoTutorialThumbnails/tutorial_5.png',
    videoLink: 'https://pathways-prod-uploads.s3.eu-west-1.amazonaws.com/videoTutorials/tutorial_5.mp4'
  },
  {
    id: 6,
    title: 'Pathway Tutorial 6 - Resources',
    thumbnail: 'https://pathways-prod-uploads.s3.eu-west-1.amazonaws.com/videoTutorialThumbnails/tutorial_6.png',
    videoLink: 'https://pathways-prod-uploads.s3.eu-west-1.amazonaws.com/videoTutorials/tutorial_6.mp4'
  }
];