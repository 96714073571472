import * as React from 'react';
import HeaderMenu, { Option } from 'components/Menu/HeaderMenu';
import SideMenu from 'components/Menu/SideMenu';
import { Tab } from 'components/Menu/SideMenu/SideMenu';
import { createUseStyles, useTheme } from 'react-jss';
import { useRouter } from 'found';
import SearchResults from 'components/Search/SearchResults';
import { Theme } from 'helpers/theme';
import { createFragmentContainer, graphql, fetchQuery } from 'react-relay';
import { AppHolder_user } from './__generated__/AppHolder_user.graphql';
import { AppHolder_candidate } from './__generated__/AppHolder_candidate.graphql';
import environment from 'api/environment';
import { SideModalProvider, useSideModalDispatch } from './SideModalProvider';
import { getCourse } from './helpers';
import { Logout } from 'helpers/logout';
import { VariantContext } from '@material-ui/pickers/wrappers/Wrapper';
import CandidateModal from 'components/CandidateSlideIn/CandidateModal';
import FooterMenu from 'components/Menu/FooterMenu';
import AccessConnectPopup from '../components/AccessConnect/AccessConnectPopup';

const useStyles = createUseStyles((theme: Theme) => ({
  appHolder: {
    gridArea: 'appHolder',
    display: 'flex',
    justifyContent: 'center',
    position: 'relative',
    overflowY: 'scroll'
  },
  appHolderRoot: {
    display: 'grid',
    height: '100vh',
    gridTemplateColumns: 'auto 1fr',
    gridTemplateRows: '82px auto 60px',
    gridTemplateAreas: `
      "sideMenu header"
      "sideMenu appHolder"
      "sideMenu footer"
    `,
    '@media (max-width: 750px)': {
      gridTemplateColumns: '1fr',
      gridTemplateAreas: `
        "header"
        "appHolder"
        "footer"
      `
    }
  },
  appHolderSearch: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    top: 0,
    background: theme.searchBackground
  },
  header: {
    gridArea: 'header'
  },
  sideMenu: {
    gridArea: 'sideMenu'
  },
  footer: {
    gridArea: 'footer'
  }
}));

type Props = {
  children?: React.ReactChildren;
  user?: AppHolder_user;
  candidate: AppHolder_candidate;
  retry: () => void;
};

const AppHolder = ({ children, user, candidate, retry }: Props) => {
  const classes = useStyles();

  const { match, router } = useRouter();

  const holder = React.useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    if (holder.current) holder.current.scrollTo(0, 0);
  }, [children]);

  const tabs: Tab[] = [
    { id: 0, icon: 'DashboardIcon', title: 'Dashboard' },
    { id: 1, icon: 'QualificationsIcon', title: 'Qualifications' },
    { id: 2, icon: 'QWEIcon', title: candidate.skillGroupType === "TC" ? "Training Record" : "QWE Portfolio" },
    {
      id: 3,
      icon: 'CharacterSuitabilityIcon',
      title: 'Character & Suitability'
    },
    {
      id: 4,
      icon: 'LearningDevelopmentIcon',
      title: 'Learning & Development',
      childTabs: [
        {
          id: 5,
          title: 'Courses'
        },
        {
          id: 6,
          title: 'My Courses'
        },
        {
          id: 7,
          title: 'Competency Plus'
        },
        {
          id: 12,
          title: 'How To Tutorials'
        }
      ]
    }
  ];

  if (candidate?.candidateType == 'level3') {
    tabs.push({
      id: 8,
      icon: 'ResourcesIcon',
      title: 'Resources',
      childTabs: [
        {
          id: 9,
          title: 'Mentoring'
        },
        {
          id: 10,
          title: 'Appraisals'
        },
        {
          id: 11,
          title: 'Events'
        }
      ]
    });
  }

  if (user?.type && user?.type !== 'candidate') {
    router.push('/login');
    Logout(
      () => { },
      () => { }
    );
  }

  const selected = () => {
    const { routes } = match;
    const currentRouter = routes[routes.length - 1];

    switch (currentRouter.path) {
      case '/':
        return tabs[0];
      case '/qualifications/:qualType?':
        return tabs[1];
      case '/qwe/:tab?':
        return tabs[2];
      case '/entry/create':
        return tabs[2];
      case '/entry/update/:id':
        return tabs[2];
      case '/character-and-suitability':
        return tabs[3];
      case '/all-courses':
        return tabs[4].childTabs?.[0];
      case '/my-courses':
        return tabs[4].childTabs?.[1];
      case '/my-courses/:id':
        return tabs[4].childTabs?.[1];
      case '/tutorials':
        return tabs[4].childTabs?.[3];
      case '/tutorial/:id':
        return tabs[4].childTabs?.[3];
      case '/advices':
        return tabs[4].childTabs?.[2];
      case '/advices/:id':
        return tabs[4].childTabs?.[2];
      case '/mentoring':
        return tabs[5].childTabs?.[0];
      case '/appraisals':
        return tabs[5].childTabs?.[1];
      case '/socialEvents':
        return tabs[5].childTabs?.[2];
      case '/socialEvents/:id':
        return tabs[5].childTabs?.[2];
      default:
        return tabs[0];
    }
  };

  const [isShowSearchModal, setIsShowSearchModal] = React.useState<boolean>(
    false
  );
  const [isSideMenuOpen, setIsSideMenuOpen] = React.useState<boolean>(false);
  const [openCandidateModal, setOpenCandidateModal] = React.useState<boolean>(false);
  const onToggleSearchModal = () => setIsShowSearchModal(!isShowSearchModal);
  const dispatchModal = useSideModalDispatch();

  const options: Option[] = [
    {
      id: '0',
      title: 'Logout',
      onClick: () => {
        Logout(
          () => { },
          () => {
            router.push('/login');
          }
        );
      }
    },
    {
      id: '1',
      title: 'My Account',
      onClick: () => setOpenCandidateModal((prev) => !prev)
    }
  ];

  if (match.location.query.course) {
    try {
      var courseID = parseInt(match.location.query.course);
      // Get course info
      getCourse(courseID).then((course) => {
        if (!course) {
          console.log('Unable to fetch course');
          return;
        }

        // Dispatch the modal
        dispatchModal({
          modal: 'singleuser',
          courseId: course.ident
        });
      });
    } catch (err) {
      console.warn(err);
    }
  }

  const switchTab = (tab: Tab) => {
    switch (tab.id) {
      case 0:
        router.push('/app');
        break;
      case 1:
        router.push('/app/qualifications');
        break;
      case 2:
        router.push('/app/qwe');
        break;
      case 3:
        router.push('/app/character-and-suitability');
        break;
      case 5:
        router.push('/app/all-courses');
        break;
      case 6:
        router.push('/app/my-courses');
        break;
      case 7:
        router.push('/app/advices');
        break;
      case 9:
        router.push('/app/mentoring');
        break;
      case 10:
        router.push('/app/appraisals');
        break;
      case 11:
        router.push('/app/socialEvents');
        break;
      case 12:
        router.push('/app/tutorials');
        break;
      default:
        break;
    }
  };

  const [isOpenAccessPopup, setOpenAccessPopup] = React.useState(false)
  const openAccessConnect = React.useCallback(()=>{
    setOpenAccessPopup(true)
  },[])

  return (
    <div className={classes.appHolderRoot}>
      <SideMenu
        className={classes.sideMenu}
        isOpen={isSideMenuOpen}
        toggleOpen={() => setIsSideMenuOpen((prev) => !prev)}
        tabs={tabs}
        selected={selected()}
        onClick={switchTab}
        onOpenAccessConnect={openAccessConnect}
        showAccessConnectButton={candidate?.candidateType == "level3" || candidate?.candidateType === "level2"}
      />
      <HeaderMenu
        className={classes.header}
        onToggleSideMenu={() => setIsSideMenuOpen((prev) => !prev)}
        user={{
          name: `${candidate?.firstName} ${candidate?.lastName}`,
          color: candidate?.candidateType.replace(/[0-9]/g, ''),
          url: candidate?.profileImageUrl || undefined
        }}
        onToggleSearchModal={onToggleSearchModal}
        showSearch={user?.type === 'individual'}
        options={options}
      />
      <CandidateModal
        candidate={candidate}
        isOpen={openCandidateModal}
        onClose={() => setOpenCandidateModal(false)}
        refetch={retry}
      />
      <div className={classes.appHolder} ref={holder}>
        {children}
      </div>
      <FooterMenu className={classes.footer} />

      <AccessConnectPopup
        open={isOpenAccessPopup}
        name={"Access Connect"}
        onClose={() => setOpenAccessPopup((prev: boolean) => !prev)} 
        text={`Coming Soon`}
      />
    </div>
  );
};

export default createFragmentContainer(AppHolder, {
  user: graphql`
    fragment AppHolder_user on User {
      type
      firstName
      lastName
    }
  `,
  candidate: graphql`
    fragment AppHolder_candidate on Candidate {
      candidateType
      firstName
      lastName
      jobTitle
      email
      telephone
      profileImageUrl
      skillGroupType
      skillGroupTypeConfirmed
      hiddenQualifications
      chatHistory
      ...CandidateModal_candidate
    }
  `
});
