import React, { useState } from 'react';
import format from 'date-fns/format';
import markdownIt from 'markdown-it';
import markdownItSup from 'markdown-it-sup';
import markdownItSanitizer from 'markdown-it-sanitizer';
import markdownItClass from '@toycode/markdown-it-class';
import markdownItLinkAttributes from 'markdown-it-link-attributes';
import { AnyFunction } from 'components/RobertChat/Chat/utils/types';
import { MessageTypes } from 'components/RobertChat/Chat/store/types';

import {
  addUserMessage
} from 'components/RobertChat/';

import './styles.scss';

type Props = {
  message: MessageTypes;
  showTimeStamp: boolean;
  handleCandidateAnswer: AnyFunction;
}

function Message({ message, showTimeStamp, handleCandidateAnswer }: Props) {
  const [answered, setAnswered] = useState<boolean>(message.answered);

  const sanitizedHTML = markdownIt()
    .use(markdownItClass, {
      img: ['rcw-message-img']
    })
    .use(markdownItSup)
    .use(markdownItSanitizer)
    .use(markdownItLinkAttributes, { attrs: { target: '_blank', rel: 'noopener' } })
    .render(message.text);

  const handleAnswer = (answerId: number) => {
    if (handleCandidateAnswer) {
      handleCandidateAnswer(message.customId, answerId, "");
    }

    if (message.customId == "100") {
      return
    }

    message.answered = true;
    setAnswered(true);

    addUserMessage(message.answers[answerId]);
  }

  return (
    <div className={`rcw-${message.sender}`}>
      {showTimeStamp && <span className="rcw-timestamp">{format(message.timestamp, 'hh:mm a')}</span>}
      <div className="rcw-message-text" dangerouslySetInnerHTML={{ __html: message.text }} />
      {
        message.answers ? <div className="rcw-message-answer">
          {
            message.answers.map((answer, id) => (
              <div key={id} className="rcw-answer-option" onClick={() => { handleAnswer(id) }} hidden={answered}>{answer}</div>
            ))
          }
        </div> : <div />
      }
    </div>
  );
}

export default Message;
