import React, { useRef } from 'react';
import { ScaleLoader } from 'react-spinners';
import { RecordSourceSelectorProxy } from 'relay-runtime';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { createUseStyles, useTheme } from 'react-jss';
import { Theme } from 'helpers/theme';
import styled from 'styled-components';
import { toBase64 } from 'helpers/upload';
import { makeMutation } from 'api/helpers';
import { CreateOtherQWE } from 'api/mutations';
import { FileUpload } from 'api/__generated__/mutationsCreateQualificationMutation.graphql';
import { useToastDispatch } from 'components/Toast/ToastProvider';
import Button from 'components/core/Input/ButtonBase';
import EasyInput from 'components/core/Input/EasyInput';
import Spacer from 'components/core/Spacers/Spacer';
import { Body, Footer } from 'components/SideModal/Tabs';
import { ThemeProvider } from '@material-ui/core/styles';
import themeCustom from '../../helpers/themeCustom';

const useStyles = createUseStyles((theme: Theme) => ({
  root: {
    padding: '10px 10px !important',
    border: '1px solid #08080814 !important',
    borderRadius: 5,
    '& div': {
      '&:before': { borderBottom: 'none !important' },
      '&:after': { borderBottom: 'none !important' }
    }
  }
}));

const SectionTitle = styled.h1`
  color: #271507;
  font-size: 20px;
  font-weight: 700;
`;

const FileInput = styled.input`
  display: none;
`;

const UploadContainer = styled.label`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
`;

const UploadButton = styled.div`
  display: flex;
  padding: 10px 5px;
  justify-content: center;
  background: #e8e8e8;
  align-items: center;
  color: #585858;
  border-radius: 4px;
  font-size: 13px;
  flex: 1;
  cursor: pointer;
  min-width: 200px;
`;

const UploadWarning = styled.div`
  font-size: 14px;
  color: #757575;
`;

export type State = {
  id?: string;
  candidateID?: string;
  name: string;
  completionDate?: string;
  fileURL?: string;
  fileName?: string;
  file?: FileUpload;
  uploading?: boolean;
  page?: { offset: number; limit: number };
};

type Props = {
  state: State;
  setState: (newState: State) => void;
  closeModal: () => void;
};

function Content({ state, setState, closeModal }: Props) {
  const theme = useTheme();
  const classes = useStyles({ theme });
  const dispatch = useToastDispatch();

  const inputRef = useRef<HTMLInputElement>(null);

  const onUpload = async (evt: React.ChangeEvent<HTMLInputElement>) => {
    const files = evt?.target?.files;
    if (files && files.length > 0) {
      const file = files[0];
      const split = file.name.split('.');
      const fType = split[split.length - 1].toLowerCase();
      if (
        fType !== 'jpg' &&
        fType !== 'jpeg' &&
        fType !== 'png' &&
        fType !== 'pdf' &&
        fType !== 'doc' &&
        fType !== 'docx'
      ) {
        // TODO: alert users
        dispatch({
          text: 'Pick a png, jpg, pdf, jpeg, doc or docs file',
          alert: 'error',
          stayFor: 6000
        });
        return;
      }

      try {
        const base64 = await toBase64(file);
        if (!base64) {
          dispatch({
            text: 'Unable to upload file, please try again',
            alert: 'error',
            stayFor: 6000
          });
          throw new Error('Base64 is undefined');
        }
        setState((s: State) => ({
          ...s,
          fileURL: URL.createObjectURL(file),
          file: {
            fileBase64: base64,
            fileType: fType
          },
          fileName: file.name
        }));

        // To fix issue where input doesn't work twice in a row
        // https://stackoverflow.com/questions/12030686/html-input-file-selection-event-not-firing-upon-selecting-the-same-file
        if (inputRef && inputRef.current) {
          inputRef.current.value = '';
        }
      } catch (err) {
        dispatch({
          text: 'Unable to upload file, please try again',
          alert: 'error',
          stayFor: 6000
        });
        console.error('Unable to base64 file', err);
      }
    }
  };

  const onSave = async () => {
    if (!state.file) {
      dispatch({
        text: 'Please upload a document',
        alert: 'warning',
        stayFor: 6000
      });
      return;
    }
    if (!state.name) {
      dispatch({
        text: 'Please enter a name',
        alert: 'warning',
        stayFor: 6000
      });
      return;
    }
    if (!state.completionDate) {
      dispatch({
        text: 'Please enter a completion date',
        alert: 'warning',
        stayFor: 6000
      });
      return;
    }
    setState((s: State) => ({ ...s, uploading: true }));
    try {
      await makeMutation(
        CreateOtherQWE,
        {
          candidateID: state.candidateID,
          name: state.name,
          completionDate: state.completionDate,
          file: state.file
        },
        (store: RecordSourceSelectorProxy<{}>, data: any) => {
          const root = store.get('client:root');

          if (!root) {
            console.error('could not get root');
            return;
          }
          const user = root.getLinkedRecord('candidate');
          if (!user) {
            console.log('Could not get user');
            return;
          }
          const newQWE = store.get(data.createOtherQWE.id);
          if (!newQWE) {
            console.error('could not find qwes', data.createOtherQWE.id);
            return;
          }
          const otherQWEs = user.getLinkedRecord('otherQWEs');
          if (!otherQWEs) {
            console.error('could not get other QWEs');
            return;
          }
          const edges = otherQWEs.getLinkedRecords('edges');
          if (!edges) {
            console.error('could not get other qwe edges');
            return;
          }
          const newRecords = [newQWE, ...edges];
          otherQWEs.setLinkedRecords(newRecords, 'edges');
        }
      );
      setState((s: State) => ({ ...s, uploading: false }));
      dispatch({
        text: 'QWE uploaded successfully',
        alert: 'success',
        stayFor: 4000
      });
      closeModal();
    } catch (err) {
      console.log('Unable to upload file', err);
      dispatch({
        text: err.message,
        alert: 'error',
        stayFor: 6000
      });
      setState((s: State) => ({ ...s, uploading: false }));
    }
  };

  return (
    <>
      <Body>
        <SectionTitle>Name*</SectionTitle>
        <EasyInput
          placeholder="Name"
          type="text"
          value={state.name}
          onChange={(text) => {
            setState((s: State) => ({ ...s, name: text }));
          }}
        />
        <Spacer vertical spacing={1} />
        <SectionTitle>Completion Date*</SectionTitle>
        <ThemeProvider theme={themeCustom}>
          <KeyboardDatePicker
            value={state.completionDate ? state.completionDate : null}
            placeholder="10/10/2018"
            onChange={(date) => {
              setState((s: State) => ({
                ...s,
                completionDate: date?.format()
              }));
            }}
            format="DD/MM/YYYY"
            className={classes.root}
            inputProps={{ style: { font: "12px 'Muli', sans-serif" } }}
            autoOk={true}
          />
        </ThemeProvider>
        <Spacer vertical spacing={1} />
        <SectionTitle>Upload a Document*</SectionTitle>
        <FileInput
          type="file"
          name="file"
          id={'otherQWE-upload'}
          onChange={onUpload}
          ref={inputRef}
        />
        <UploadContainer htmlFor={'otherQWE-upload'}>
          <UploadButton onClick={(evt) => {}}>
            {state.file ? state.fileName : 'Upload Document'}
          </UploadButton>
          <Spacer horizontal spacing={2} />
          <UploadWarning>
            Supported files: PDF, JPG, PNG (max 20MB)
          </UploadWarning>
        </UploadContainer>
      </Body>
      <Footer>
        <div style={{ display: 'flex' }}>
          <Button archetype={'default'} onClick={closeModal}>
            Cancel
          </Button>
          <Spacer horizontal spacing={2} />
          <Button
            archetype="submit"
            onClick={() => {
              if (state.uploading) {
                return;
              }
              onSave();
            }}
          >
            {state.uploading ? (
              <ScaleLoader color="white" height={10} />
            ) : (
              `Add Document`
            )}
          </Button>
        </div>
      </Footer>
    </>
  );
}

export default Content;
