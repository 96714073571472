import React, { useRef } from 'react';
import classNames from 'classnames';
import { createUseStyles, useTheme } from 'react-jss';
import { useRouter } from 'found';
import { Theme } from 'helpers/theme';
import Dropdown, { DropdownOption } from 'components/core/Input/Dropdown';
import Button from 'components/core/Input/ButtonBase';
import CoreInput from 'components/core/Input/CoreInput';
import Heading from 'components/core/Heading';
import Spacer from 'components/core/Spacers/Spacer';
import Page from 'components/Page';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { EntryCreate_candidate } from './__generated__/EntryCreate_candidate.graphql';
import { EntryUpdate_candidate } from './__generated__/EntryUpdate_candidate.graphql';
import { KeyboardDatePicker } from '@material-ui/pickers';
import DropdownCompetencyQueryRenderer, { Competency } from './DropdownCompetencyQueryRenderer';
import CheckboxSingle from 'components/core/Input/CheckboxSingle';
import styled from 'styled-components';
import theme from 'helpers/theme';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { ThemeProvider } from '@material-ui/core/styles';
import themeCustom from '../../helpers/themeCustom';
import { toBase64 } from 'helpers/upload';
import { useToastDispatch } from 'components/Toast/ToastProvider';
import { FileUpload } from 'api/__generated__/mutationsCreateQWEEntryMutation.graphql';
import FancyDropdown from 'components/core/Input/FancyDropdown';
import { practiceAreaOptions } from 'helpers/practiceAreaOptions';

const useStyles = createUseStyles((theme: Theme) => ({
  placementRow: {
    display: 'grid',
    gridGap: '10px',
    gridTemplateColumns: '1fr 1fr 2fr',
    '@media (max-width: 1100px)': {
      gridTemplateColumns: '1fr'
    }
  },
  competencyRow: {
    display: 'grid',
    gridGap: '10px',
    maxWidth: '75%',
    gridTemplateColumns: '1fr',
    '@media (max-width: 1100px)': {
      maxWidth: '100%'
    }
  },
  dateRow: {
    display: 'grid',
    gridGap: '10px',
    gridTemplateColumns: '1fr 1fr 2fr',
    '@media (max-width: 1100px)': {
      gridTemplateColumns: '1fr'
    }
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    paddingBottom: '5%'
  },
  input: {
    fontSize: theme.fontSizes.default,
    padding: '11px',
    border: ['1px', 'solid', theme.colors.borderGrey],
    borderRadius: '6px',
    width: '100%',
    '&::placeholder': {
      opacity: 1,
      color: theme.colors.secondaryGrey,
      fontWeight: 300
    }
  },
  dateInput: {
    background: 'white',
    padding: '0px 10px !important',
    border: '1px solid #08080814 !important',
    borderRadius: 4,
    '& div': {
      '&:before': { borderBottom: 'none !important' },
      '&:after': { borderBottom: 'none !important' }
    },
    display: 'flex',
    justifyContent: 'center',
    height: '45px'
  },
  last: {
    resize: 'none',
    justifyContent: 'flex-start'
  },
  fancyInput: {
    fontSize: 14,
    border: '1px solid #08080814',
    borderRadius: 6,
    padding: '15px 10px',
    width: '100%',
    '&::placeholder': {
      opacity: 1,
      color: theme.colors.secondaryGrey,
      fontWeight: 300
    }
  },
  placement: {
    width: '100%',
    height: '45px'
  },
  practiceArea: {
    width: '100%',
    height: '45px'
  },
  practiceAreaTextField: {
    border: `1px solid ${theme.colors.borderGrey}`,
    borderRadius: theme.primaryBorderRadius
  },
  practiceAreaChip: {
    fontSize: theme.fontSizes.xSmall
  },
  contentious: {
    height: '45px'
  },
  boxStyle: {
    height: '45px'
  },
  fontStyle: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    width: '120px',
    '@media (max-width: 1100px)': {
      width: '400px'
    }
  },
  fontStyleOption: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap'
  },
  save: {
    alignSelf: 'flex-end',
    justifyContent: 'flex-end'
  },
  headingTitle: {
    textAlign: 'center',
    justifyContent: 'center',
    display: 'flex'
  },
  checkBox: {
    fontSize: 16,
    fontWeight: 800,
    maxWidth: '100%'
  }
}));

const BackHeading = styled.div`
  padding-bottom: 25px;
  color: ${theme.colors.textGrey};
  cursor: pointer;
  font-size: 14px;
  font-weight: 600;
  display: flex;
  align-items: center;
  transition: all 0.2s;
  &:hover {
    color: #444750;
  }
`;

const FileInput = styled.input`
  display: none;
`;

const UploadAndSaveButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

const UploadContainer = styled.label`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const UploadButton = styled.button`
  display: flex;
  padding: 10px 5px;
  justify-content: center;
  background: #e8e8e8;
  align-items: center;
  border-radius: 4px;
  border-style: none;
  font-size: 14px;
  font-weight: 400;
  cursor: pointer;
  min-width: 200px;
  height: 40px;
`;

const CompetencyContainer = styled.ul`
  list-style: none;
  list-style-position: inside;
  margin: ${theme.spacing(0)}px 0;
  padding: 0;
`;

const CompetencyItem = styled.li`
`;

const SkillContainer = styled.ul`
  list-style-position: inside;
  margin: ${theme.spacing(1)}px 0;
  padding-left: ${theme.spacing(2)}px;
`;

const SkillItem = styled.li`
  & > span {
    font-size: ${theme.fontSizes.default}px;
  }
`;

const SubskillContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const SubskillItem = styled.div`
  font-size: ${theme.fontSizes.tiny}px;
  width: 200px;
  background: white;
  color: #f37a21;
  padding: 5px 11px;
  border-radius: 6px;
  box-shadow: 0px 1px 3px #0000000f;
  border: 1px solid #ededed;
  margin: 6px;
`;

const TitlesRow = styled.div`
  display: grid;
  grid-gap: 10px;
  grid-template-columns: 3fr 2fr;
  @media (max-width: 1100px): {
    grid-template-columns: 1fr;
  }
`;

type Props = {
  candidate: EntryCreate_candidate | EntryUpdate_candidate;
  onSave: (state: EntryState) => void;
  initialState: EntryState;
};

export type EntryState = {
  new: boolean; // true: new entry, false: edit existing entry
  startDate: string;
  endDate: string;
  title: string;
  experience: string;
  evidence: string;
  whatWasLearned: string;
  moreExperience: string;
  needMoreExperience: string;
  selectedCompetencies: Competency[];
  selectedSkill?: DropdownOption; // called "competency" according to the new design
  selectedArea?: DropdownOption;
  selectedStaticAreas: DropdownOption[];
  selectedLatestArea?: DropdownOption;
  confirmed?: boolean;
  saving?: boolean;
  selectedContentiousOption?: DropdownOption;
  selectedPlacement?: DropdownOption;
  taskedBy?: string;
  file?: FileUpload;
  fileName?: string;
  fileURL?: string;
};

export var dropDownContentiousOptions = [
  { id: 'contentious', title: 'Contentious' },
  { id: 'non_contentious', title: 'Non-Contentious' },
  { id: 'both', title: 'Both' }
];

function checkWordLength(str: string, len: number) {
  return str.split(/\s/g).length - 1 <= len;
}

function Entry({ candidate, onSave, initialState }: Props) {
  const theme = useTheme();
  const classes = useStyles({ theme });
  const { match, router } = useRouter();
  const [state, setState] = React.useState<EntryState>(initialState);
  // const [subSkillTitles, setSubSkillTitles] = React.useState<string[]>([]);

  const dispatch = useToastDispatch();
  const inputRef = useRef<HTMLInputElement>(null);

  // practice area options
  let dropDownPracticeAreaOptions: DropdownOption[];
  if (
    candidate &&
    candidate.competency &&
    candidate.competency.practiceAreas &&
    candidate.competency.practiceAreas.edges &&
    candidate.competency.practiceAreas.edges.length > 0
  ) {
    dropDownPracticeAreaOptions = candidate.competency.practiceAreas.edges.map(
      (option) => ({
        id: option ? option.id : '',
        title: option ? option.name : ''
      })
    );
  } else {
    dropDownPracticeAreaOptions = [];
  }

  React.useEffect(() => {
    //this sets all product descriptions to a max length of 10 characters
    dropDownPracticeAreaOptions.forEach((element) => {
      if ((candidate.skillGroupType && element.title == candidate.skillGroupType)) {
        setState((s) => ({
          ...s,
          selectedArea: element,
          selectedSkill: initialState.selectedSkill,
          selectedCompetencies: initialState.selectedCompetencies,
        }));
      }
    });
  }, []);

  // placement options
  let dropDownPlacementOptions: DropdownOption[];
  if (
    candidate &&
    candidate.placements &&
    candidate.placements.edges &&
    candidate.placements.edges.length > 0
  ) {
    dropDownPlacementOptions = candidate.placements.edges.map(
      (option: any) => ({
        id: option ? option.id : '',
        title: option ? option.name : '',
      })
    );
  } else {
    dropDownPlacementOptions = [];
  }

  // const currentPlacement = localStorage.getItem('placement')

  // React.useEffect(() => {
  //   let option = dropDownPlacementOptions.find(i => i.id === currentPlacement);
  //   console.log("Current placement", currentPlacement, option)
  //   setState((s) => ({ ...s, selectedPlacement: option }))
  // }, [])

  const handleAttach = (event: any) => {
    event.preventDefault();

    if (inputRef && inputRef.current) {
      inputRef.current.click();
    }
  };

  const onUpload = async (evt: React.ChangeEvent<HTMLInputElement>) => {
    const files = evt?.target?.files;
    if (files && files.length > 0) {
      const file = files[0];
      const split = file.name.split('.');
      const fType = split[split.length - 1].toLowerCase();
      if (
        fType !== 'jpg' &&
        fType !== 'jpeg' &&
        fType !== 'png' &&
        fType !== 'pdf' &&
        fType !== 'doc' &&
        fType !== 'docx'
      ) {
        dispatch({
          text: 'Pick a png, jpg, pdf, jpeg, doc or docs file',
          alert: 'error',
          stayFor: 6000
        });
        return;
      }

      try {
        const base64 = await toBase64(file);
        if (!base64) {
          dispatch({
            text: 'Unable to upload file, please try again',
            alert: 'error',
            stayFor: 6000
          });
          throw new Error('Base64 is undefined');
        }
        setState((s) => ({
          ...s,
          //fileURL: URL.createObjectURL(file),
          file: {
            fileBase64: base64,
            fileType: fType
          },
          fileName: file.name
        }));

        // To fix issue where input doesn't work twice in a row
        // https://stackoverflow.com/questions/12030686/html-input-file-selection-event-not-firing-upon-selecting-the-same-file
        if (inputRef && inputRef.current) {
          inputRef.current.value = '';
        }
      } catch (err) {
        dispatch({
          text: 'Unable to upload file, please try again',
          alert: 'error',
          stayFor: 6000
        });
        console.error('Unable to base64 file', err);
      }
    }
  };

  return (
    <Page>
      <BackHeading
        onClick={() => {
          router.push('/app/qwe/portfolio');
        }}
      >
        <FontAwesomeIcon icon={faArrowLeft} />
        <Spacer horizontal spacing={2} />
        <span>Back to Portfolio</span>
      </BackHeading>
      <Heading className={classNames(classes.headingTitle)} text={'In the event that you are asked to edit an entry, please note you do NOT need to resubmit your edited entry to either your supervisor or mentor.'} size={"medium"} />
      <Heading text={state.new ? 'New Entry' : 'Edit Entry'} size="large" />
      <TitlesRow>
        <Heading text="Entry Title" size="medium" />
        <Heading text="Tasked By" size="medium" />
      </TitlesRow>
      <TitlesRow>
        <CoreInput
          placeholder="The title of the task. e.g. Managing conditions precedent list on Project Pathways"
          type="text"
          value={state.title}
          onChange={(event: string) => setState((s) => ({ ...s, title: event }))}
          className={classNames(classes.fancyInput)}
        />
        <CoreInput
          placeholder="The name of colleague who gave you the task. e.g. John Smith"
          type="text"
          value={state.taskedBy || ''}
          onChange={(event: string) => setState((s) => ({ ...s, taskedBy: event }))}
          className={classes.fancyInput}
        />
      </TitlesRow>
      <Spacer vertical spacing={2} />
      <Heading text="Placement Organisation" size="medium" />
      <div className={classNames(classes.placementRow)}>
        <Dropdown
          placeholder="Placement"
          options={dropDownPlacementOptions}
          setSelected={(placement) => {
            if (placement.id !== state.selectedPlacement?.id) {
              setState((s) => ({
                ...s,
                selectedPlacement: placement
              }));
              return;
            }
            setState((s) => ({ ...s, selectedPlacement: placement }));
          }}
          selected={state.selectedPlacement}
          icon={
            <FontAwesomeIcon
              icon={faChevronDown}
              size={'xs'}
              color={'#CFD1D1'}
            />
          }
          className={classes.placement}
          boxStyle={classes.boxStyle}
          fontStyle={classes.fontStyle}
          fontStyleOption={classes.fontStyleOption}
        />
        <Dropdown
          placeholder="Contentious"
          options={dropDownContentiousOptions}
          setSelected={(option) => {
            setState((s) => ({ ...s, selectedContentiousOption: option }));
          }}
          selected={
            state.selectedContentiousOption || dropDownContentiousOptions[1]
          }
          icon={
            <FontAwesomeIcon
              icon={faChevronDown}
              size={'xs'}
              color={'#CFD1D1'}
            />
          }
          className={classes.contentious}
          boxStyle={classes.boxStyle}
        />
        <FancyDropdown
          options={practiceAreaOptions}
          onChange={(areas) => {
            const news = areas.filter(a => !state.selectedStaticAreas.find(a2 => a2.id === a.id));
            setState((s) => ({
              ...s,
              selectedStaticAreas: areas.slice(0, 3),
              selectedLatestArea: news.length > 0 ? news[0] : areas[0]
            }));
          }}
          selected={state.selectedStaticAreas}
          placeholder={state.selectedStaticAreas.length > 0 ? state.selectedStaticAreas.map((item) => item.title).join(', ') : "Practice Area"}
          className={classes.practiceArea}
          hint={`Tick the main practice areas relevant to each entry. 
          Most entries will have one practice area but we recommend that you don't 
          tick more than three for any given entry.`}
        />
      </div>
      <Spacer vertical spacing={2} />
      <Heading text="Start Date/End Date" size="medium" />
      <div className={classNames(classes.dateRow)}>
        <ThemeProvider theme={themeCustom}>
          <KeyboardDatePicker
            value={state.startDate ? state.startDate : null}
            placeholder="FROM: 10/01/2021"
            onChange={(date) => {
              setState((s) => ({ ...s, startDate: date?.format() ?? '' }));
            }}
            format="DD/MM/YYYY"
            className={classes.dateInput}
            inputProps={{
              style: {
                font: "12px 'Muli', sans-serif",
                color: state.startDate === initialState.startDate ? '#bbb' : 'black'
              }
            }}
            autoOk={true}
          />
          <KeyboardDatePicker
            value={state.endDate ? state.endDate : null}
            placeholder="TO: 10/04/2021"
            onChange={(date) => {
              setState((s) => ({ ...s, endDate: date?.format() ?? '' }));
            }}
            format="DD/MM/YYYY"
            className={classes.dateInput}
            inputProps={{
              style: {
                font: "12px 'Muli', sans-serif",
                color: state.endDate === initialState.endDate ? '#bbb' : 'black'
              }
            }}
            autoOk={true}
          />
        </ThemeProvider>
      </div>
      <Spacer vertical spacing={2} />
      <div className={classNames(classes.container)}>
        <Heading text="Details of the task/work completed" size="medium" />
        <textarea
          placeholder="Use this box to describe the task you were asked to do and what you actually did."
          rows={6}
          onChange={(event) => {
            event.persist();
            const val = event.target.value;
            if (!checkWordLength(val, 1000)) return;

            setState((s) => ({ ...s, experience: val }));
          }}
          value={state.experience}
          className={classNames(classes.input, classes.last)}
        />
        <Spacer vertical spacing={2} />
        {/* Note: No longer used
        <Heading text="How can I evidence my experience?" size="medium" />
        <textarea
          rows={8}
          onChange={(event) => {
            event.persist();
            setState((s) => ({ ...s, evidence: event.target.value }));
          }}
          value={state.evidence}
          className={classNames(classes.input, classes.last)}
        />
        <Spacer vertical spacing={2} />
        */}
        <Heading text="Reflections/What did I learn?" size="medium" />
        <textarea
          placeholder="Reflections are extremely important. Stop and think about what you learnt. What did you gain from the experience that you would not have gained in a classroom."
          rows={6}
          onChange={(event) => {
            event.persist();
            const val = event.target.value;
            if (!checkWordLength(val, 1000)) return;

            setState((s) => ({ ...s, whatWasLearned: val }));
          }}
          value={state.whatWasLearned}
          className={classNames(classes.input, classes.last)}
        />
        <Spacer vertical spacing={2} />
        <Heading text="How can I improve?" size="medium" />
        <textarea
          placeholder="Some of the most valuable experiences are when we learn from our mistakes. Consider what you could have done differently/better and how you can aim to do that next time."
          rows={6}
          onChange={(event) => {
            event.persist();
            const val = event.target.value;
            if (!checkWordLength(val, 1000)) return;

            setState((s) => ({ ...s, moreExperience: val }));
          }}
          value={state.moreExperience}
          className={classNames(classes.input, classes.last)}
        />
        <Spacer vertical spacing={2} />
        <Heading text="Competency/Skills" size="medium" />
        <div className={classNames(classes.competencyRow)}>
          <DropdownCompetencyQueryRenderer
            practiceAreaID={state.selectedArea?.id || ''}
            selected={state.selectedSkill}
            setSelected={(skill) =>
              setState((s) => ({ ...s, selectedSkill: skill }))
            }
            selectedCompetencies={state.selectedCompetencies}
            setSelectedCompetencies={(selectedCompetencies) =>
              setState((s) => ({ ...s, selectedCompetencies: selectedCompetencies }))
            }
            hint={`Please consider ALL of the competencies and skills you feel you
          gained exposure to in completing this task. Skills and competencies can 
          be gained by doing work yourself first hand, helping team members or observing
          a third party. Remember to scroll down in the list below to ensure you don't miss any!`}
          />
          {/* <DropdownQueryRenderer
          practiceSkillID={state.selectedSkill?.id || ''}
          selected={state.selectedSubSkills}
          setSelected={(subSkills) =>
            setState((s) => ({ ...s, selectedSubSkills: subSkills }))
          }
        /> */}
        </div>
        <Spacer vertical spacing={1} />
        <CompetencyContainer>
          {state.selectedCompetencies.map(comp => (
            comp.skills.length > 0 && <CompetencyItem key={comp.id}>
              <Heading text={comp.title} size="medium" />
              <SkillContainer>
                {comp.skills.map((skill, index) => (
                  <SkillItem key={index}>
                    <span>{skill.name}</span>
                    <SubskillContainer>
                      {skill.options.map((option, index) => (
                        <SubskillItem key={index}>{option.name}</SubskillItem>
                      ))}
                    </SubskillContainer>
                  </SkillItem>
                ))}
              </SkillContainer>
            </CompetencyItem>
          ))}
        </CompetencyContainer>


        <p className={classes.checkBox}>
          You can add a document to this entry. However, you should only upload work
          that is predominantly your own, such as a letter of advice or a legal research
          note you have prepared. You should not add things like precedent documents
          where you have not been responsible for putting the document together but have
          been involved in amending it.
        </p>
        <CheckboxSingle
          label={`Tick this box to confirm that the document you wish to upload contains no confidential information or that any confidential information has been appropriately redacted.`}
          defaultChecked={state.confirmed}
          onChange={(checked) =>
            setState((s) => ({ ...s, confirmed: checked }))
          }
          fontStyle={classNames(classes.checkBox)}
          size={20}
        />
        <Spacer vertical spacing={1} />
        <UploadAndSaveButtonsContainer>
          <FileInput
            type="file"
            name="file"
            id={'upload-document'}
            onChange={onUpload}
            ref={inputRef}
          />
          {initialState.fileURL && (
            <>
              <UploadButton
                onClick={() => window.open(initialState.fileURL, '_blank')}
              >
                View Document
              </UploadButton>
              <Spacer horizontal spacing={1} />
            </>
          )}
          <UploadContainer htmlFor={'upload-document'}>
            <UploadButton onClick={handleAttach} disabled={!state.confirmed}>
              {state.file ? state.fileName : 'Upload Document'}
            </UploadButton>
          </UploadContainer>
          <Spacer horizontal spacing={1} />
          <Button
            onClick={() => {
              setState((s) => ({ ...s, saving: true }));
              onSave(state);
              if (state.selectedPlacement == null) {
                setState((s) => ({ ...s, saving: false }));
              }
            }}
            bold
            archetype="submit"
            disabled={state.saving}
          >
            Save Entry
          </Button>
        </UploadAndSaveButtonsContainer>
      </div>
    </Page>
  );
}

export default Entry;
