import * as React from 'react';
import { createUseStyles, useTheme } from 'react-jss';
import classNames from 'classnames';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Theme } from 'helpers/theme';
import { useMediaQuery } from 'react-responsive'
import Icon from '../../../components/core/Icon/Icon';
import CircleBorder, { User } from 'components/core/CircleBorder';
import Spacer from 'components/core/Spacers/Spacer';
import CoreInput from 'components/core/Input/CoreInput';

const useStyles = createUseStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    borderBottom: [1, 'solid', theme.colors.borderGrey],
    gridArea: '1 / 2',
    zIndex: 10
  },
  menu: {
    background: '#f37a21',
    padding: '17px 25px'
  },
  burgerButton: {
    cursor: 'pointer',
    color: theme.colors.primaryWhite
  },
  search: {
    display: 'flex',
    alignItems: 'center',
    flex: 1,
    padding: theme.spacing(1)
  },
  searchInput: {
    color: theme.colors.primaryWhite,
    fontSize: theme.fontSizes.large,
    fontWeight: 300,
    paddingLeft: theme.spacing(1),
    backgroundColor: 'transparent',
    '&::placeholder': {
      color: theme.colors.primaryWhite
    }
  },
  name: {
    fontSize: theme.fontSizes.tinyHeading,
    fontWeight: 800,
    color: theme.colors.primaryWhite,
    '@media (max-width: 750px)': {
      display: 'none'
    }
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    '@media (max-width: 750px)': {
    }
  },
  body: {
    backgroundColor: theme.colors.backgroundGrey,
    flexGrow: 1
  },
  hidden: {
    display: 'none'
  },
  dropdownWrapper: {
    position: 'absolute',
    top: '80px',
    right: '25px'
  },
  dropdown: {
    position: 'relative',
    border: ['0.5px', 'solid', theme.colors.borderGrey],
    borderRadius: '8px',
    backgroundColor: theme.colors.primaryWhite,
    boxShadow: '0px 3px 10px #0000001f'
  },
  option: {
    cursor: 'pointer',
    fontSize: theme.fontSizes.large,
    fontWeight: '400',
    margin: '15px'
  },
  topdesc:{
    fontSize: '15px',
    color: 'white',
    textAlign: 'center',
    width: 'calc(100% - 320px)'
  },
  topdesclink:{
    fontSize: '15px',
    color: '#271507',
    fontWeight:'bold',
  }
}));

export type Option = {
  id: string;
  title: string;
  onClick: () => void;
};

type Props = {
  user: User;
  options?: Option[];
  onProfileClick?: Function;
  className?: string;
  onToggleSideMenu: Function;
  onToggleSearchModal?: Function;
  showSearch?: boolean;
};

function HeaderMenu({
  user,
  options,
  onProfileClick,
  onToggleSideMenu,
  onToggleSearchModal,
  className,
  showSearch = false
}: Props) {
  const theme = useTheme();
  const classes = useStyles({ theme });
  const isMobile = useMediaQuery({ maxWidth: 750 });
  const [showOptions, setShowOptions] = React.useState(false);
  return (
    <div className={classNames(classes.root, className)}>
      <div className={classNames(classes.row, classes.menu)}>
        {isMobile && <FontAwesomeIcon
          icon={faBars}
          size="lg"
          className={classes.burgerButton}
          onClick={() => onToggleSideMenu()}
        />}
        {!isMobile && <div className={classes.topdesc}>Are you looking for great paralegal, training contract or QWE opportunities? <br></br>If so, <a className={classes.topdesclink} href="https://careers.accutrainee.com/" target='_blank'>apply now</a> for a variety of fantastic roles as well as the ability to unlock additional features on Pathways!</div>}
        <div
          className={classNames(
            classes.search,
            showSearch ? '' : classes.hidden
          )}
          onClick={() => onToggleSearchModal && onToggleSearchModal()}
        >
          <Icon name="SearchGlass" size={15} />
          <CoreInput
            type="search"
            placeholder="Search"
            disabled={true}
            className={classes.searchInput}
            onChange={(text: string) => {}}
            /*
            onFocus={onFocus}
            onBlur={onBlur}
            value={input}
            setValue={setInput}*/
          />
        </div>
        <div
          className={classes.row}
          onClick={() => onProfileClick && onProfileClick()}
        >
          <div className={classes.name}>{user.name}</div>
          <Spacer horizontal spacing={2} />
          <CircleBorder user={user} borderType="plain" />
          <Icon
            name="Card_SecondaryActon_Dots"
            size={20}
            style={{ cursor: 'pointer', marginLeft: '20px' }}
            onClick={() => options && setShowOptions((previous) => !previous)}
          />
          {showOptions && (
            <div className={classes.dropdownWrapper}>
              <div className={classes.dropdown}>
                {options &&
                  options.map((option: Option) => (
                    <div
                      key={option.id}
                      className={classes.option}
                      onClick={() => {
                        setShowOptions((previous) => !previous);
                        option.onClick();
                      }}
                    >
                      {option.title}
                    </div>
                  ))}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default HeaderMenu;
