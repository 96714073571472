import React, { useState } from 'react';
import classes from './CustomTimePicker.module.scss';

interface CustomTimePickerProps {
  data: string | null;
  onChange: (formattedTime: string) => void;
}

const minutesList = ['00', '15', '30', '45'];
const periods = ['AM', 'PM'];

interface Time {
  hour: string;
  minute: string;
  meridian: string;
}

const getTimes = (dataForm: string): Time => {
  
  // handle case dateForm -> 12:00 PM
  const regexp = /(\d{1,2}):(\d{2})\s([APap][Mm])/;
  if (regexp.test(dataForm)) {
    return {
      hour: dataForm.split(':')[0],
      minute: dataForm.split(':')[1].split(' ')[0],
      meridian: dataForm.split(' ')[1],
    }
  }
  const dateObj = new Date();

  const [hours, minutes, seconds] = dataForm.split(':');
  dateObj.setHours(parseInt(hours, 10));
  dateObj.setMinutes(parseInt(minutes, 10));
  dateObj.setSeconds(parseInt(seconds, 10));

  const formattedTime = dateObj.toLocaleString('en-US', {
    hour: '2-digit',
    minute: '2-digit',
    hour12: true,
  });

  const [timePart, meridian] = formattedTime.split(' ');
  const [hour, minute] = timePart.split(':');

  return {
    hour,
    minute,
    meridian,
  };
};

const convertTimeStyle = (timeForm: string) => {
  // Create a new Date object
  const dateObj = new Date();
      
  // Extract hours and minutes from the original time string
  const [hours, , minutes, , meridian] = timeForm.split(' ');

  // Convert hours to 24-hour format if PM
  dateObj.setHours(meridian === 'PM' ? parseInt(hours, 10) + 12 : parseInt(hours, 10));
  dateObj.setMinutes(parseInt(minutes, 10));

  // Format the result as HH:mm AM/PM
  const formattedTime = dateObj.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true });

  return formattedTime;
}

export const CustomTimePicker: React.FC<CustomTimePickerProps> = ({
  data,
  onChange
}) => {
  console.log('CustomTimePicker', data);
  const [showPicker, setShowPicker] = useState(false);
  const [selectedHour, setSelectedHour] = useState(
    data ? getTimes(data).hour : '12'
  );
  const [selectedMinute, setSelectedMinute] = useState(
    data ? getTimes(data).minute : '00'
  );
  const [selectedPeriod, setSelectedPeriod] = useState(
    data ? getTimes(data).meridian : 'AM'
  );

  const handleTimeChange = () => {
    onChange(`${selectedHour}:${selectedMinute} ${selectedPeriod}`);
    // const originalTime = `${selectedHour} : ${selectedMinute}  ${selectedPeriod}`;
    // console.log('originalTime', originalTime);
    // console.log('selectedHour', selectedHour);
    // console.log('selectedMinute', selectedMinute);
    // console.log('selectedPeriod', selectedPeriod);
    // const dateObj = new Date();

    // const [hours, , minutes, , meridian] = originalTime.split(' ');

    // const pmAddition = hours === '12' ? 0 : 12;
    // dateObj.setHours(meridian === 'PM' ? parseInt(hours, 10) + pmAddition : parseInt(hours, 10));
    // dateObj.setMinutes(parseInt(minutes, 10));

    
    // const formattedTime = dateObj.toLocaleString('en-US', {
    //   hour: 'numeric',
    //   minute: 'numeric',
    //   hour12: true,
    // });
    // console.log('formattedTime', formattedTime);
    // onChange(formattedTime);
  };

  const handleClockIconClick = () => {
    setShowPicker(!showPicker);
    if (!!showPicker) {
      handleTimeChange();
    }
  };

  const handleMinuteSelect = (minute: string) => {
    setSelectedMinute(minute);
  };

  const handlePeriodSelect = (period: string) => {
    setSelectedPeriod(period);
  };

  const dateInSelectedTimes = (value: string, selectedField: string) => {
    let isInSelectedDates = false;
    if (selectedField === 'hour') {
      if (datesAreOnSameTime(value, selectedHour)) {
        isInSelectedDates = true;
      }
    } else if (selectedField === 'min') {
      if (datesAreOnSameTime(value, selectedMinute)) {
        isInSelectedDates = true;
      }
    } else {
      if (datesAreOnSameTime(value, selectedPeriod)) {
        isInSelectedDates = true;
      }
    }
    return isInSelectedDates;
  };

  const datesAreOnSameTime = (first: string, second: string) => {
    if (!first || !second) return false;

    if (first === second) return true;

    return false;
  };

  const handleInputChange = (e: any, timeType: string) => {

    let inputValue = e.target.value;
    let originalTime = `${selectedHour} : ${selectedMinute}  ${selectedPeriod}`;

    // Remove non-numeric characters
    inputValue = inputValue.replace(/[^0-9]/g, '');

    if (timeType === 'hour') {
      // Ensure the value is within the range 00-59
      if (inputValue !== '' && (parseInt(inputValue) < 0 || parseInt(inputValue) > 12)) {
        // You can choose to handle out-of-range values here, e.g., show an error message
        console.log('Input out of range (00-12)');
        return;
      }
      setSelectedHour(inputValue);
      originalTime = `${inputValue} : ${selectedMinute}  ${selectedPeriod}`;
    } else {
      // Ensure the value is within the range 00-59
      if (inputValue !== '' && (parseInt(inputValue) < 0 || parseInt(inputValue) > 59)) {
        // You can choose to handle out-of-range values here, e.g., show an error message
        console.log('Input out of range (00-59)');
        return;
      }
      setSelectedMinute(inputValue);
      originalTime = `${selectedHour} : ${inputValue}  ${selectedPeriod}`;
    }

    // Format the result as HH:mm AM/PM
    const formattedTime = convertTimeStyle(originalTime);
    onChange(formattedTime)
  };

  return (
    <div className={classes.customTimePicker}>
      <div className={classes.customTimePickerContainer}>
        <div className={classes.customTimePickerContainer} onClick={handleClockIconClick}>
          <input
              type="text"
              id="hours"
              name="hours"
              value={`${selectedHour}`}
              onChange={(e) => {handleInputChange(e, 'hour')}}
              style={{width:'30px'}}
            />
            :
            <input 
              type="text"
              id="mins"
              name="mins"
              value={`${selectedMinute}`}
              onChange={(e) => handleInputChange(e, 'mins')}
              style={{width:'30px'}}
            />
            <input 
              type="text"
              id="period"
              name="period"
              value={`${selectedPeriod}`}
              style={{width:'40px'}}
            />
            <img src={require('../../assets/clock.png')} width="17px" height="17px" alt=''/>
        </div>

        {showPicker && (
          <div className={classes.customTimePickerPanel}>
            <div className={classes.timePickerSection}>
              <div className={classes.hourScrollContainer}>
                <div className={classes.hourList}>
                  {[...Array(12).keys()].map((hour) => (
                    <button
                      className={`${classes.normalBtn} ${dateInSelectedTimes(String(hour + 1).padStart(2, '0'), 'hour') ? classes.focusBtn : ''
                        } `}
                      key={hour + 1}
                      onClick={() => setSelectedHour(String(hour + 1).padStart(2, '0'))}
                    >
                      {String(hour + 1).padStart(2, '0')}
                    </button>
                  ))}
                </div>
              </div>
            </div>
            <div className={classes.timePickerSection}>
              {minutesList.map((minute) => (
                <button
                  className={`${classes.normalBtn} ${dateInSelectedTimes(minute, 'min') ? classes.focusBtn : ''} `}
                  key={minute}
                  onClick={() => handleMinuteSelect(minute)}
                >
                  {minute}
                </button>
              ))}
            </div>
            <div className={classes.timePickerSection}>
              <div className={classes.periodList}>
                {periods.map((period) => (
                  <button
                    className={`${classes.normalBtn} ${dateInSelectedTimes(period, 'period') ? classes.focusBtn : ''} `}
                    key={period}
                    onClick={() => handlePeriodSelect(period)}
                  >
                    {period}
                  </button>
                ))}
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

