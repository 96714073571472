/* tslint:disable */
/* eslint-disable */
/* @relayHash 12399e42164d9966961a43e524dac38c */

import { ConcreteRequest } from "relay-runtime";
export type EntriesDateRange_QueryVariables = {
    placementID: string;
    from?: string | null;
    to?: string | null;
};
export type EntriesDateRange_QueryResponse = {
    readonly node: {
        readonly entries?: {
            readonly edges: ReadonlyArray<{
                readonly id: string;
                readonly title: string;
                readonly startDate: Date;
            } | null> | null;
            readonly pageInfo: {
                readonly total: number;
                readonly given: number;
            } | null;
        } | null;
    } | null;
};
export type EntriesDateRange_Query = {
    readonly response: EntriesDateRange_QueryResponse;
    readonly variables: EntriesDateRange_QueryVariables;
};



/*
query EntriesDateRange_Query(
  $placementID: ID!
  $from: Time
  $to: Time
) {
  node(id: $placementID) {
    __typename
    ... on Placement {
      entries(filter: {from: $from, to: $to}) {
        edges {
          id
          title
        }
        pageInfo {
          total
          given
        }
      }
    }
    id
  }
}
*/

const node: ConcreteRequest = (function () {
    var v0 = [
        ({
            "kind": "LocalArgument",
            "name": "placementID",
            "type": "ID!",
            "defaultValue": null
        } as any),
        ({
            "kind": "LocalArgument",
            "name": "from",
            "type": "Time",
            "defaultValue": null
        } as any),
        ({
            "kind": "LocalArgument",
            "name": "to",
            "type": "Time",
            "defaultValue": null
        } as any)
    ], v1 = [
        ({
            "kind": "Variable",
            "name": "id",
            "variableName": "placementID"
        } as any)
    ], v2 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "id",
        "args": null,
        "storageKey": null
    } as any), v3 = ({
        "kind": "InlineFragment",
        "type": "Placement",
        "selections": [
            {
                "kind": "LinkedField",
                "alias": null,
                "name": "entries",
                "storageKey": null,
                "args": [
                    {
                        "kind": "ObjectValue",
                        "name": "filter",
                        "fields": [
                            {
                                "kind": "Variable",
                                "name": "from",
                                "variableName": "from"
                            },
                            {
                                "kind": "Variable",
                                "name": "to",
                                "variableName": "to"
                            }
                        ]
                    }
                ],
                "concreteType": "EntryPage",
                "plural": false,
                "selections": [
                    {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "edges",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "QWEEntry",
                        "plural": true,
                        "selections": [
                            (v2 /*: any*/),
                            {
                                "kind": "ScalarField",
                                "alias": null,
                                "name": "title",
                                "args": null,
                                "storageKey": null
                            },
                            {
                                "kind": "ScalarField",
                                "alias": null,
                                "name": "startDate",
                                "args": null,
                                "storageKey": null
                            }
                        ]
                    },
                    {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "pageInfo",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "PageInfo",
                        "plural": false,
                        "selections": [
                            {
                                "kind": "ScalarField",
                                "alias": null,
                                "name": "total",
                                "args": null,
                                "storageKey": null
                            },
                            {
                                "kind": "ScalarField",
                                "alias": null,
                                "name": "given",
                                "args": null,
                                "storageKey": null
                            }
                        ]
                    }
                ]
            }
        ]
    } as any);
    return {
        "kind": "Request",
        "fragment": {
            "kind": "Fragment",
            "name": "EntriesDateRange_Query",
            "type": "Query",
            "metadata": null,
            "argumentDefinitions": (v0 /*: any*/),
            "selections": [
                {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "node",
                    "storageKey": null,
                    "args": (v1 /*: any*/),
                    "concreteType": null,
                    "plural": false,
                    "selections": [
                        (v3 /*: any*/)
                    ]
                }
            ]
        },
        "operation": {
            "kind": "Operation",
            "name": "EntriesDateRange_Query",
            "argumentDefinitions": (v0 /*: any*/),
            "selections": [
                {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "node",
                    "storageKey": null,
                    "args": (v1 /*: any*/),
                    "concreteType": null,
                    "plural": false,
                    "selections": [
                        {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "__typename",
                            "args": null,
                            "storageKey": null
                        },
                        (v2 /*: any*/),
                        (v3 /*: any*/)
                    ]
                }
            ]
        },
        "params": {
            "operationKind": "query",
            "name": "EntriesDateRange_Query",
            "id": null,
            "text": "query EntriesDateRange_Query(\n  $placementID: ID!\n  $from: Time\n  $to: Time\n) {\n  node(id: $placementID) {\n    __typename\n    ... on Placement {\n      entries(filter: {from: $from, to: $to}) {\n        edges {\n          id\n          title\n        startDate\n        }\n        pageInfo {\n          total\n          given\n        }\n      }\n    }\n    id\n  }\n}\n",
            "metadata": {}
        }
    } as any;
})();
(node as any).hash = '6006c9aafd18ca381fbf04e40ecf0197';
export default node;
