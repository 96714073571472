import React from 'react';
import styled from 'styled-components';
import { makeMutation } from 'api/helpers';
import { SubmitMeetingRequest } from 'api/mutations';
import Button from 'components/core/Input/ButtonBase';
import Spacer from 'components/core/Spacers/Spacer';
import { Body, Footer } from 'components/SideModal/Tabs';
import { useToastDispatch } from 'components/Toast/ToastProvider';
import { ScaleLoader } from 'react-spinners';
import AvailabilityDropdown from './AvailabilityDropdown';

const SectionTitle = styled.h1`
  color: #271507;
  font-size: 20px;
  font-weight: 700;
`;

export type State = {
  mentorID: string;
  selected?: string; // selected slot of the mentor for montly review with mentor
  availableTime?: string; // available time of the candidate for TPM or appraisal with TP
  saving?: boolean;
  meetingRequestType: string;
};

type Props = {
  state: State;
  setState: (newState: any) => void;
  closeModal: () => void;
};

function Content({ state, setState, closeModal }: Props) {
  const dispatch = useToastDispatch();

  const onSave = async () => {
    if (state.saving) return;

    if (state.selected?.length === 0) {
      dispatch({
        text: 'Select a meeting slot',
        alert: 'warning'
      });
      return;
    }

    try {
      setState((s: State) => ({ ...s, saving: true }));
      await makeMutation(SubmitMeetingRequest, {
        mentorID: state.mentorID,
        availabilitySlotID: state.selected,
        type: state.meetingRequestType
      });
      dispatch({
        text: 'Meeting request sent successfully',
        alert: 'success',
        stayFor: 4000
      });
      closeModal();
    } catch (err) {
      console.log('Unable to send meeting request', err);
      dispatch({
        text: err.message,
        alert: 'error',
        stayFor: 6000
      });
    }
    setState((s: State) => ({ ...s, saving: false }));
  };

  return (
    <>
      <Body>
        <SectionTitle>Select Availability Slot</SectionTitle>
        <AvailabilityDropdown
          mentorID={state.mentorID}
          selected={state.selected || ''}
          setSelected={(sel) => {
            setState((s: State) => ({ ...s, selected: sel }));
          }}
        />
        <Spacer vertical spacing={1} />
      </Body>
      <Footer>
        <div style={{ display: 'flex' }}>
          <Button archetype={'default'} onClick={closeModal}>
            Cancel
          </Button>
          <Spacer horizontal spacing={2} />
          <Button archetype="submit" onClick={state.saving ? () => {} : onSave}>
            {state.saving ? (
              <ScaleLoader color="white" height={10} />
            ) : (
              'Send Meeting Request'
            )}
          </Button>
        </div>
      </Footer>
    </>
  );
}

export default Content;
