/* tslint:disable */
/* eslint-disable */
/* @relayHash 1453b7198b9dadff1d29b076640510f9 */

import { ConcreteRequest } from "relay-runtime";
export type mutationsDeleteQualificationMutationVariables = {
    id: string;
};
export type mutationsDeleteQualificationMutationResponse = {
    readonly deleteQualification: boolean;
};
export type mutationsDeleteQualificationMutation = {
    readonly response: mutationsDeleteQualificationMutationResponse;
    readonly variables: mutationsDeleteQualificationMutationVariables;
};



/*
mutation mutationsDeleteQualificationMutation(
  $id: ID!
) {
  deleteQualification(input: {id: $id})
}
*/

const node: ConcreteRequest = (function () {
    var v0 = [
        ({
            "kind": "LocalArgument",
            "name": "id",
            "type": "ID!",
            "defaultValue": null
        } as any)
    ], v1 = [
        ({
            "kind": "ScalarField",
            "alias": null,
            "name": "deleteQualification",
            "args": [
                {
                    "kind": "ObjectValue",
                    "name": "input",
                    "fields": [
                        {
                            "kind": "Variable",
                            "name": "id",
                            "variableName": "id"
                        }
                    ]
                }
            ],
            "storageKey": null
        } as any)
    ];
    return {
        "kind": "Request",
        "fragment": {
            "kind": "Fragment",
            "name": "mutationsDeleteQualificationMutation",
            "type": "Mutation",
            "metadata": null,
            "argumentDefinitions": (v0 /*: any*/),
            "selections": (v1 /*: any*/)
        },
        "operation": {
            "kind": "Operation",
            "name": "mutationsDeleteQualificationMutation",
            "argumentDefinitions": (v0 /*: any*/),
            "selections": (v1 /*: any*/)
        },
        "params": {
            "operationKind": "mutation",
            "name": "mutationsDeleteQualificationMutation",
            "id": null,
            "text": "mutation mutationsDeleteQualificationMutation(\n  $id: ID!\n) {\n  deleteQualification(input: {id: $id})\n}\n",
            "metadata": {}
        }
    } as any;
})();
(node as any).hash = 'fc0eec6048859e2f6679f67a054d3f0e';
export default node;
