import * as React from 'react';
import { createUseStyles } from 'react-jss';
import { Theme } from 'helpers/theme';
import Label from '../Label';

const useStyles = createUseStyles((theme: Theme) => ({
  container: {
    display: 'flex',
    boxSizing: 'border-box',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    background: theme.colors.certBackgroundGrey,
    paddingTop: '10px',
    flex: 1,
    marginTop: '10px'
  },
  certName: {
    fontSize: '2.5rem',
    marginTop: '2.5rem',
    marginBottom: '0rem',
    color: theme.colors.primaryBlack,
    textAlign: 'center'
  },
  certTitle: {
    fontSize: '1.3rem',
    margin: '1rem 0'
  },
  certInfoRow: {
    margin: 0,
    fontSize: '1.3rem',
    textAlign: 'center',

    '& span': {
      fontSize: '1.1rem',
      float: 'left'
    }
  },
  subtitle: {
    margin: 0,
    fontSize: '1.3rem',
    textAlign: 'center',
    '& span': {
      fontSize: '1.1rem',
      float: 'left'
    }
  },
  certInfoGroup: {
    marginBottom: '20px',
    display: 'flex',
    flexDirection: 'column'
  },
  certInfoLabel: {
    width: '150px',
    fontWeight: 900,
    textAlign: 'right',
    display: 'block',
    float: 'left',
    marginRight: '10px',
    fontSize: '1.7rem'
  },
  certLabel: {
    marginTop: '40px'
  }
}));

type Props = {
  certName: string;
  moduleDeliver: string;
  showModulesDelivered: boolean | null;
  forEu: string;
  certNo: string;
  trainingDate: string;
  expiryDate?: string;
  certSubTitle?: string;
};

function CertInfo({
  certName,
  moduleDeliver,
  showModulesDelivered,
  forEu,
  certNo,
  trainingDate,
  expiryDate,
  certSubTitle
}: Props) {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <p className={classes.certInfoRow}>Has successfully completed:</p>
      <h3 className={classes.certName}>{certName}</h3>
      <div className={classes.certInfoGroup}>
        {showModulesDelivered && (
          <p className={classes.certInfoRow}>{moduleDeliver}</p>
        )}
        <p className={classes.certInfoRow}>{forEu}</p>
      </div>
      <div className={classes.certInfoGroup}>
        <div className={classes.certLabel}>
          <Label label="Date:" text={trainingDate} />
        </div>
        <div className={classes.certLabel}>
          <Label label="Certificate No:" text={certNo} />
        </div>
      </div>
    </div>
  );
}

export default CertInfo;
