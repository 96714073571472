import * as React from 'react';
import { createUseStyles, useTheme } from 'react-jss';
import { Theme } from 'helpers/theme';
import FancyBorder from '../../components/Certificate/FancyBorder';
import CertInfo from '../../components/Certificate/CertInfo';
import Signature from '../../components/Certificate/Signature';
import { createFragmentContainer, graphql } from 'react-relay';
import { ReactComponent as Logo } from '../../assets/logo/accutrainee-logo.svg';
import { CertGenerator_certificateInfo } from './__generated__/CertGenerator_certificateInfo.graphql';
import moment from 'moment';
import ModuleRow from 'components/Certificate/ModuleRow';
import Label from 'components/Certificate/Label';

const useStyles = createUseStyles((theme: Theme) => ({
  certGeneratorRoot: {
    margin: 0
  },
  logoArea: {
    height: 100,
    marginBottom: 18,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  certLogo: {
    height: 100
  },
  logo: {
    height: 70
  },
  certHeader: {
    // marginTop: 20,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',

    '& h1': {
      fontSize: '2rem',
      fontWeight: 900,
      margin: 0,
      marginBottom: 10,
      textAlign: 'center',
      width: 600
    },

    '& p': {
      fontSize: '1.5rem',
      margin: 0
    },

    '& h3': {
      fontSize: '2rem',
      marginTop: '2rem',
      marginBottom: '0'
    },

    '& h4': {
      fontSize: '1.8rem',
      marginTop: 0,
      marginBottom: 0
    }
  },
  certInfoFirst: {
    marginTop: 0,
    display: 'flex'
  },
  certRow: {
    display: 'flex',
    alignItems: 'center',
    marginTop: 15
  },
  certLabel: {
    fontWeight: 900,
    marginRight: 15,
    fontSize: '1.3rem'
  },
  certRowLeft: {
    width: 240,
    textAlign: 'right',
    marginRight: 15
  },
  certRowRight: {
    display: 'flex',
    justifyContent: 'space-between',
    width: 400,

    '& span': {
      fontSize: '1.3rem'
    }
  },
  trainingClient: {
    display: 'flex',
    marginTop: 10,
    marginBottom: 9
  },
  trainingInfo: {
    width: 400,
    height: 130,
    padding: 10,
    display: 'flex',
    flexDirection: 'column',

    '& h4': {
      fontSize: '1rem',
      marginTop: 0,
      marginBottom: '0.5rem'
    },

    '& span': {
      fontSize: '1.2rem'
    }
  },
  bottomRow: {
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'center',
    margin: '10px 0',

    '& p': {
      margin: 0,
      fontSize: '0.7rem'
    }
  },
  certContent: {
    display: 'flex',
    marginTop: 30,
    marginBottom: 150,
    fontWeight: 'bold'
  },
  leftContent: {
    flex: 1,
    padding: '0 50px 0 20px',
    fontSize: '0.8rem'
  },
  rightContent: {
    flex: 1,
    padding: '0 50px 0 20px',
    fontSize: '0.8rem',

    '& span': {
      fontWeight: 100
    }
  },
  ttcInfo: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center'
  }
}));

type Props = {
  certificateInfo: CertGenerator_certificateInfo;
};

function CertGenerator({ certificateInfo }: Props) {
  const theme = useTheme();
  const classes = useStyles({ theme });

  const certData = {
    moduleDeliver: certificateInfo.modulesDelivered
      ? `Modules Delivered: ${certificateInfo.modulesDelivered}`
      : ''
  };

  // list all the delivered module in the text, only if all the module have numbers in name
  // will match
  // {Prefix} Module 1 {Suffix}
  // {Prefix} Module 2.1 {Suffix}
  // {Prefix} Module 2.2a {Suffix}
  // {Prefix} Module 3a {Suffix}
  // {Prefix} Module 3b {Suffix}
  // {Prefix} Module 3b.1 {Suffix}
  // {Prefix} Module 3b.2b {Suffix}
  // {Prefix} Module 4.1 {Suffix}
  certData.moduleDeliver = (() => {
    let allHaveNumber = true;
    let regs = [/Module \d+[a-z]?.\d+[a-z]?/g, /Module \d+[a-z]?/g];
    let moduleNumberArray = [];
    // @ts-ignore
    for (let i = 0; i < certificateInfo.trainingRecord?.length; i++) {
      // @ts-ignore
      const module = certificateInfo.trainingRecord[i];
      if (!module) {
        allHaveNumber = false;
        break;
      }
      let haveNumber = false;
      for (let j = 0; j < regs.length; j++) {
        if (!allHaveNumber) {
          break;
        }
        const reg = regs[j];
        if (reg.test(module.name)) {
          const matchReg = module.name.match(reg);
          !!matchReg &&
            moduleNumberArray.push(matchReg[0].replace('Module ', ''));
          haveNumber = true;
          break;
        }
      }
      if (!haveNumber) {
        allHaveNumber = false;
      }
    }
    return allHaveNumber
      ? `Modules Delivered: ${moduleNumberArray.join(', ')}`
      : `${certificateInfo.modulesDelivered} Modules Delivered`;
  })();
  console.log(certificateInfo);

  var trainingDate = moment(certificateInfo.completionEndDate).format(
    'DD MMMM YYYY'
  );

  if (certificateInfo.completionStartDate) {
    // If dates are the same just show it normally
    if (
      moment(certificateInfo.completionEndDate).format('DD MMMM YYYY') !==
      moment(certificateInfo.completionStartDate).format('DD MMMM YYYY')
    ) {
      trainingDate = `${moment(certificateInfo.completionStartDate).format(
        'DD MMMM YYYY'
      )} to  ${moment(certificateInfo.completionEndDate).format(
        'DD MMMM YYYY'
      )}`;
    }
  }

  // If more than 20 modules create another page and show them on there
  let modulesPerPage = [];
  if (certificateInfo?.trainingRecord) {
    const itemsPerPage = 20;
    var i = 0;
    while (i < certificateInfo?.trainingRecord.length) {
      modulesPerPage.push(
        certificateInfo?.trainingRecord.slice(i, (i += itemsPerPage))
      );
    }
  }

  return (
    <div className={classes.certGeneratorRoot}>
      <FancyBorder paperSize="A4">
        <div className={classes.logoArea}>
          <Logo className={classes.logo} />
        </div>
        <div className={classes.certHeader}>
          <p>This is to certify that</p>
          <h3>{`${certificateInfo.takerFirstName} ${certificateInfo.takerLastName}`}</h3>
        </div>
        <CertInfo
          certName={certificateInfo.courseTitle}
          moduleDeliver={certData.moduleDeliver}
          showModulesDelivered={certificateInfo.showModulesDelivered}
          forEu={certificateInfo.regulationText}
          certNo={String(parseInt(certificateInfo.certificateNumber) + 1585)}
          certSubTitle={certificateInfo.certificateSubTitle ?? undefined}
          trainingDate={trainingDate}
          expiryDate={
            certificateInfo.expiryDate
              ? moment(certificateInfo.expiryDate).format('DD MMMM YYYY')
              : undefined
          }
        />
        <div className={classes.ttcInfo}>
          <div className={classes.trainingClient}>
            <div className={classes.certRowLeft}>
              <span className={classes.certLabel}>Training Company:</span>
            </div>
            <div className={classes.trainingInfo}>
              <h4>
                {certificateInfo.trainingCompanyName} {certificateInfo.trainingCompanyPostcode} -  
                {certificateInfo.trainingCompanyTelephone}
              </h4>
            </div>
          </div>
          <div className={classes.bottomRow}>
            <p>
              For confirmation of the content of this document please contact
              the training provider.
            </p>
          </div>
        </div>
      </FancyBorder>

      {certificateInfo.trainingRecord &&
        modulesPerPage.map((modules) => (
          <FancyBorder paperSize="A4">
            <h1>Training Record</h1>
            <Label
              label="Name:"
              setWidth={false}
              text={`${certificateInfo.takerFirstName} ${certificateInfo.takerLastName}`}
            />
            {certificateInfo.clientName && (
              <Label
                label="Client Name:"
                setWidth={false}
                text={certificateInfo.clientName}
              />
            )}
            <Label
              label="Date of Training:"
              setWidth={false}
              text={trainingDate}
            />
            <h2>Modules Delivered</h2>
            {modules.map((module, index) => (
              <ModuleRow text={module.name} />
            ))}
          </FancyBorder>
        ))}
    </div>
  );
}

export default createFragmentContainer(CertGenerator, {
  certificateInfo: graphql`
    fragment CertGenerator_certificateInfo on CertificateInfo {
      courseTitle
      courseName
      expiryDate
      completionStartDate
      completionEndDate
      clientName
      takerFirstName
      takerLastName
      certificateBodyURL
      footerText
      regulationText
      title
      instructorName
      instructorSignatureURL
      certificateNumber
      trainingRecord {
        type
        name
      }
      modulesDelivered
      certificateSubTitle
      showSignature
      showModulesDelivered
      trainingCompanyName
      trainingCompanyWebsite
      trainingCompanyEmail
      trainingCompanyTelephone
      trainingCompanyPostcode
    }
  `
});
