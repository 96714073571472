import React from 'react';

import LoginDialogue from 'components/LoginDialogue';
import { Theme } from 'helpers/theme';
import environment from 'api/environment';
import { createUseStyles, useTheme } from 'react-jss';
import { commitMutation, graphql } from 'react-relay';
import { GraphError } from 'types/general';
import { Router, useRouter } from 'found';
import PasswordDialogue from 'components/LoginDialogue/PasswordDialogue';

type Props = {
  data: any;
};

const useStyles = createUseStyles((theme: Theme) => ({
  root: {
    background: theme.colors.backgroundGrey,
    width: '100%',
    height: '100%',
    position: 'absolute',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  }
}));

const mutation = graphql`
  mutation RecoverPassword_ResetRequestMutation(
    $email: String!
    $role: UserType!
  ) {
    resetPasswordRequest(input: { email: $email, role: $role })
  }
`;

const AttemptRecovery = (router: Router, setSent: (sent: boolean) => void) => (
  email: string,
  errorCallback: (err: string) => void
) => {
  // delegates cannot recover their password
  if (!email.includes('@')) {
    errorCallback('Contact your manager to reset password');
    return;
  }

  const variables = {
    email,
    role: 'candidate'
  };
  console.log(email);

  commitMutation(environment, {
    mutation,
    variables,
    onCompleted: (
      response: { delegateLogin: { token: string } },
      errors: GraphError[]
    ) => {
      if (errors) {
        // Display error
        errorCallback(`${errors[0]?.extensions?.message}`);
        return;
      }
      console.log('Response received from server.', response, errors);
      setSent(true);
    },
    onError: (err) => console.error(err)
  });
};

const RecoverPassword = ({ data }: Props) => {
  const theme = useTheme();
  const classes = useStyles({ theme });
  const { router } = useRouter();
  const onBack = () => router.push('/');
  const [sent, setSent] = React.useState(false);
  return (
    <>
      {/* <RedirectRequest/> */}
      <div className={classes.root}>
        <PasswordDialogue
          sent={sent}
          onBack={() => onBack()}
          onSubmit={AttemptRecovery(router, setSent)}
        />
      </div>
    </>
  );
};

export default RecoverPassword;
