import React from 'react';
import styled from 'styled-components';
import Spacer from 'components/core/Spacers/Spacer';
import ProgressBar from 'components/core/ProgressBar';

const ProgressBarCont = styled.div`
  display: flex;
  align-items: center;
`;

const ProgressText = styled.div`
  font-size: 12px;
`;

type Props = {
  doneEntryCount: number;
  minSuggestedEntryCount: number;
};

function CompetencySkillsProgress({
  doneEntryCount,
  minSuggestedEntryCount
}: Props) {
  let percent = 0;
  if(doneEntryCount == null || minSuggestedEntryCount == null) {
    percent = 0;
  }
  else if (doneEntryCount === 0 && minSuggestedEntryCount === 0) {
    percent = 0;
  } else if (doneEntryCount >= minSuggestedEntryCount) {
    percent = 100;
  } else {
    percent = (doneEntryCount / minSuggestedEntryCount) * 100;
    percent = Math.round(percent);
  }

  return (
    <div>
      <Spacer vertical spacing={1} />
      <ProgressBarCont>
        <ProgressText>{percent}%</ProgressText>
        <Spacer horizontal spacing={1} />
        <ProgressBar
          percent={percent}
          height={10}
        />
      </ProgressBarCont>
    </div>
  );
}

export default CompetencySkillsProgress;
