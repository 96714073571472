import { FileUpload } from './__generated__/mutations_UpdateCandidateMutation.graphql';
import Icon from 'components/core/Icon';
import Button from 'components/core/Input/ButtonBase';
import { empty } from 'components/SideModal/SideModal.stories';
import { useToastDispatch } from 'components/Toast/ToastProvider';
import { Theme } from 'helpers/theme';
import { toBase64 } from 'helpers/upload';
import * as React from 'react';
import { createUseStyles, useTheme } from 'react-jss';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 30%;
  align-items: center;
  justify-content: center;
  margin-right: 50px;
`;

const ProfilePicture = styled.img`
  height: 100px;
  width: 100px;
  border-radius: 100px;
  object-fit: cover;
`;

const UploadInput = styled.input`
  display: none;
`;

const useStyles = createUseStyles((theme: Theme) => ({
  editPicPencilIcon: {
    marginBottom: -30,
    marginRight: -65,
    zIndex: 15
  },
  editPhotoBtn: {
    marginTop: theme.spacing(1)
  }
}));

export type File = {
  file: FileUpload;
  url: string;
  name: string;
}

type Props = {
  title?: string;
  url?: string;
  acceptType?: string;
  onUploaded: (file: File) => void;
  onRemovedAvatar: ()=> void;
};

function UploadProfilePicture({
  title,
  url,
  acceptType,
  onUploaded,
  onRemovedAvatar
}: Props){

  const theme = useTheme();
  const classes = useStyles({ theme });
  const dispatch = useToastDispatch();

  const inputRef = React.createRef<HTMLInputElement>();
  const [uploadText, setUploadText] = React.useState(title);
  React.useEffect(() => {
    setUploadText(title);
  }, [title]);

  const uploadChange = async (evt: React.ChangeEvent<HTMLInputElement>) => {
    const files = evt?.target?.files;
    if (files && files.length > 0) {
      const file = files[0];
      const split = file.name.split('.');
      const fType = split[split.length - 1].toLowerCase();
      try{
        const base64 = await toBase64(file);
        if (!base64){
          dispatch({
            text: 'Unable to upload file, please try again',
            alert: 'error',
            stayFor: 6000
          });
          throw new Error('Base64 is undefined');
        }

        onUploaded({
          url: URL.createObjectURL(file),
          file: {
            fileBase64: base64,
            fileType: fType
          },
          name: file.name
        });

        if (inputRef && inputRef.current){
          inputRef.current.value = '';
        }
      }catch(err){
        dispatch({
          text: 'Unable to upload file, please try again',
          alert: 'error',
          stayFor: 6000
        });
        console.error('Unable to base64 file', err);
      }
    }
  };


  const randomId = `random-${Math.random()}`;
  return (
    <Container>
      <Icon
        name="EditPicPencil"
        size={30}
        className={classes.editPicPencilIcon}    
        onClick={() => inputRef.current?.click()}
      />
      {url ? (
        <ProfilePicture
          src={url}
        />
      ) : (
        <Icon
          name="EditDelegateProfilePic_Default"
          pointer
          size={100}
          onClick={() => inputRef.current?.click()}
        />
      )}
      <UploadInput
        accept={acceptType}
        onChange={uploadChange}
        id={'contained-button-file-' + randomId}
        ref={inputRef}
        type="file"
      />
      <label htmlFor={'contained-button-file-' + randomId}>
        <Button
          archetype="grey"
          className={classes.editPhotoBtn}
          onClick={() => url ? onRemovedAvatar() : inputRef.current?.click()}
        >
          {url? "Remove photo" : "Upload photo"}
        </Button>
      </label>
    </Container>
  );
}

export default UploadProfilePicture;