import React, { useEffect, useState } from "react";
import classes from "./Calendar.module.scss";
import moment from "moment-timezone";

const DAY = 1000 * 60 * 60 * 24;

const getStartDayInWeek = (
    year: number,
    month: number,
    weekStartIndex: number
) => {
    const _day = new Date(`${month}/1/${year}`).getDay();
    let startDay;

    if (_day === weekStartIndex) {
        startDay = new Date(`${month}/1/${year}`);
    } else if (_day < weekStartIndex) {
        startDay = moment(new Date(`${month}/1/${year}`))
            .subtract(1, "week")
            .add(weekStartIndex, "d")
            .toDate();
    } else {
        startDay = new Date(
            new Date(
                moment()
                    .set({
                        M: month - 1,
                        year: year,
                        date: 1,
                    })
                    .toDate()
            ).getTime() - (_day - weekStartIndex) * DAY
        );
    }

    return startDay;
};

const datesAreOnSameDay = ( 
    first: Date | string,
    second: Date | string | undefined
) => {
    if (!first || !second) return false;

    let _first = new Date(first);
    let _second = new Date(second);

    return (
        _first.getFullYear() === _second.getFullYear() &&
        _first.getMonth() === _second.getMonth() &&
        _first.getDate() === _second.getDate()
    );
};

const monthMap = [
    "Error",
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

const TOTEL_CELL = 7 * 6;

interface Props {
    onChangeSelectedDate: (date: Date | string) => void;
    selectedDate?: Date | string;
    isViewOnly?: boolean;
    multipleSelection?: boolean;
    disablePast?: boolean;
    highlightToday?: boolean;
    startWeekday: "Su" | "Mo" | "Tu" | "We" | "Th" | "Fr" | "Sa";
    isHighlightSlotDate?: boolean;
}

type DaysType = {
    day: Date;
    isCurrentMonth: boolean;
    isPast: boolean;
};

export const Calendar = (props: Props) => {
    
    const today = new Date();

    const weekStartIndex = ["Su", "Mo", "Tu", "We", "Th", "Fr", "Sa"].indexOf(
        props.startWeekday
    );

    const [currentYear, setCurrentYear] = useState(
        props.selectedDate
            ? new Date(props.selectedDate).getFullYear()
            : today.getFullYear()
    );
    const [currentMonth, setCurrentMonth] = useState(
        props.selectedDate
            ? new Date(props.selectedDate).getMonth() + 1
            : today.getMonth() + 1
    );

    const [days, setDays] = useState<DaysType[]>([]);
    const [selectedDate, setSelectedDate] = useState<Date | string>();

    useEffect(() => {
        if (props.selectedDate) {
            setSelectedDate(props.selectedDate);
        }
    }, [props.selectedDate]);

    useEffect(() => {
        if (selectedDate)
            props.onChangeSelectedDate(selectedDate);
    }, [selectedDate, props]);

    useEffect(() => {
        let startDate = getStartDayInWeek(
            currentYear,
            currentMonth,
            weekStartIndex
        );
        
        let _days = [];
        for (let i = 0; i < TOTEL_CELL; i++) {
            _days.push({
                day: new Date(startDate.getTime() + i * DAY),
                isCurrentMonth:
                    new Date(startDate.getTime() + i * DAY).getMonth() + 1 ===
                    currentMonth,
                isPast: 
                    props.selectedDate && new Date(props.selectedDate).setHours(0, 0, 0, 0) < new Date().setHours(0, 0, 0, 0) ? 
                        new Date(startDate.getTime() + i * DAY).setHours(0, 0, 0, 0) < new Date(props.selectedDate).setHours(0, 0, 0, 0) : 
                      new Date(startDate.getTime() + i * DAY).setHours(0, 0, 0, 0) < new Date().setHours(0, 0, 0, 0),
            });
        }
        setDays(_days);
    }, [currentMonth, props.selectedDate, weekStartIndex, currentYear]);

    const dateInSelectedDates = (date: Date) => {
        let isInSelectedDates = false;
        
        if (datesAreOnSameDay(date, selectedDate)) {
            isInSelectedDates = true;
        }

        return isInSelectedDates;
    };

    const handleClickLastMonth = () => {
        if (currentMonth <= 1) {
            setCurrentMonth(12);
            setCurrentYear((year) => year - 1);
            return;
        }

        setCurrentMonth((month) => month - 1);
    };

    const handleClickNextMonth = () => {
        if (currentMonth >= 12) {
            setCurrentMonth(1);
            setCurrentYear((year) => year + 1);
            return;
        }

        setCurrentMonth((month) => month + 1);
    };

    const handleClickDayCell = (date: Date) => {
      const currentDateStartOfDay = new Date().setHours(0, 0, 0, 0);
      const selectedDateStartOfDay = props.selectedDate ? new Date(props.selectedDate).setHours(0, 0, 0, 0) : null;
      if(selectedDateStartOfDay)
        if (
            props.disablePast && date.getTime() <  selectedDateStartOfDay && date.getTime() < currentDateStartOfDay
        ) {
            return false;
        }

      if (date.getMonth() + 1 !== currentMonth) {
        setCurrentMonth(date.getMonth() + 1);
      }
      setSelectedDate(date);  
      return true;
    };

    if (days.length !== TOTEL_CELL) return <div>Loading</div>;

    return (
        <div className={classes.calendarWrapper}>
            <div className={classes.calendarTop}>
                <div onClick={handleClickLastMonth}>{"<"}</div>
                <h1>{`${monthMap[currentMonth]} ${currentYear}`}</h1>
                <div onClick={handleClickNextMonth}>{">"}</div>
            </div>

            <div className={classes.weekdayWrapper}>
                {["Su", "Mo", "Tu", "We", "Th", "Fr", "Sa"].map(
                    (weekday, index) => (
                        <div key={`weekday${index}`}>
                            {
                                ["Su", "Mo", "Tu", "We", "Th", "Fr", "Sa"][
                                    index + weekStartIndex > 6
                                        ? index + weekStartIndex - 7
                                        : index + weekStartIndex
                                ]
                            }
                        </div>
                    )
                )}
            </div>

            <div className={classes.datesWrapper}>
                {days.map((_dayData) => {
                    
                    return (
                        <div
                            key={`${currentMonth}/${_dayData.day}/${currentYear}`}
                            className={`${
                                _dayData.isCurrentMonth && !_dayData.isPast
                                    ? classes.currentMonth
                                    : classes.nonCurrentMonth
                            } ${
                                datesAreOnSameDay(_dayData.day, today) &&
                                props.highlightToday === true
                                    ? classes.today
                                    : undefined
                            } ${
                                dateInSelectedDates(_dayData.day)
                                    ? classes.selectedDay
                                    : undefined
                            }`}
                            onClick={() => handleClickDayCell(_dayData.day)}
                        >
                            <div>{_dayData.day.getDate()}</div>
                        </div>
                    );
                })}
            </div>
        </div>
    );
};
