/* tslint:disable */
/* eslint-disable */
/* @relayHash 6b0205fb23b828f789269af541debd3e */

import { ConcreteRequest } from "relay-runtime";
export type SignoffPageQueryVariables = {
    placementID?: string | null;
    limit: number;
    offset: number;
};
export type SignoffPageQueryResponse = {
    readonly signoffRequests: {
        readonly edges: ReadonlyArray<{
            readonly id: string;
            readonly title: string | null;
            readonly createdAt: string | null;
            readonly approval: boolean | null;
            readonly PDFURL: string | null;
            readonly entries: {
                readonly edges: ReadonlyArray<{
                    readonly id: string;
                } | null> | null;
            } | null;
        } | null> | null;
        readonly pageInfo: {
            readonly total: number;
            readonly limit: number;
            readonly offset: number;
        } | null;
    } | null;
};
export type SignoffPageQuery = {
    readonly response: SignoffPageQueryResponse;
    readonly variables: SignoffPageQueryVariables;
};



/*
query SignoffPageQuery(
  $placementID: ID
  $limit: Int!
  $offset: Int!
) {
  signoffRequests(filter: {placementID: $placementID}, page: {limit: $limit, offset: $offset}) {
    edges {
      id
      title
      createdAt
      approval
      PDFURL
      entries {
        edges {
          id
        }
      }
    }
    pageInfo {
      total
      limit
      offset
    }
  }
}
*/

const node: ConcreteRequest = (function () {
    var v0 = [
        ({
            "kind": "LocalArgument",
            "name": "placementID",
            "type": "ID",
            "defaultValue": null
        } as any),
        ({
            "kind": "LocalArgument",
            "name": "limit",
            "type": "Int!",
            "defaultValue": null
        } as any),
        ({
            "kind": "LocalArgument",
            "name": "offset",
            "type": "Int!",
            "defaultValue": null
        } as any)
    ], v1 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "id",
        "args": null,
        "storageKey": null
    } as any), v2 = [
        ({
            "kind": "LinkedField",
            "alias": null,
            "name": "signoffRequests",
            "storageKey": null,
            "args": [
                {
                    "kind": "ObjectValue",
                    "name": "filter",
                    "fields": [
                        {
                            "kind": "Variable",
                            "name": "placementID",
                            "variableName": "placementID"
                        }
                    ]
                },
                {
                    "kind": "ObjectValue",
                    "name": "page",
                    "fields": [
                        {
                            "kind": "Variable",
                            "name": "limit",
                            "variableName": "limit"
                        },
                        {
                            "kind": "Variable",
                            "name": "offset",
                            "variableName": "offset"
                        }
                    ]
                }
            ],
            "concreteType": "SignoffRequestPage",
            "plural": false,
            "selections": [
                {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "edges",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "SignoffRequest",
                    "plural": true,
                    "selections": [
                        (v1 /*: any*/),
                        {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "title",
                            "args": null,
                            "storageKey": null
                        },
                        {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "createdAt",
                            "args": null,
                            "storageKey": null
                        },
                        {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "approval",
                            "args": null,
                            "storageKey": null
                        },
                        {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "PDFURL",
                            "args": null,
                            "storageKey": null
                        },
                        {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "entries",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "EntryPage",
                            "plural": false,
                            "selections": [
                                {
                                    "kind": "LinkedField",
                                    "alias": null,
                                    "name": "edges",
                                    "storageKey": null,
                                    "args": null,
                                    "concreteType": "QWEEntry",
                                    "plural": true,
                                    "selections": [
                                        (v1 /*: any*/)
                                    ]
                                }
                            ]
                        }
                    ]
                },
                {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "pageInfo",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "PageInfo",
                    "plural": false,
                    "selections": [
                        {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "total",
                            "args": null,
                            "storageKey": null
                        },
                        {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "limit",
                            "args": null,
                            "storageKey": null
                        },
                        {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "offset",
                            "args": null,
                            "storageKey": null
                        }
                    ]
                }
            ]
        } as any)
    ];
    return {
        "kind": "Request",
        "fragment": {
            "kind": "Fragment",
            "name": "SignoffPageQuery",
            "type": "Query",
            "metadata": null,
            "argumentDefinitions": (v0 /*: any*/),
            "selections": (v2 /*: any*/)
        },
        "operation": {
            "kind": "Operation",
            "name": "SignoffPageQuery",
            "argumentDefinitions": (v0 /*: any*/),
            "selections": (v2 /*: any*/)
        },
        "params": {
            "operationKind": "query",
            "name": "SignoffPageQuery",
            "id": null,
            "text": "query SignoffPageQuery(\n  $placementID: ID\n  $limit: Int!\n  $offset: Int!\n) {\n  signoffRequests(filter: {placementID: $placementID}, page: {limit: $limit, offset: $offset}) {\n    edges {\n      id\n      title\n      createdAt\n      approval\n      PDFURL\n      entries {\n        edges {\n          id\n        }\n      }\n    }\n    pageInfo {\n      total\n      limit\n      offset\n    }\n  }\n}\n",
            "metadata": {}
        }
    } as any;
})();
(node as any).hash = 'b5fd7eb9160c7d980bcfd4325ecfe2b0';
export default node;
