import React from 'react';
import styled from 'styled-components';

const ListItem = styled.li`
  position: relative;
  font-size: 14px;
  padding: 5px 10px;
  padding-right: 15px;
  cursor: pointer;
  user-select: none;
  outline: none;
  transition: all 160ms ease-out;
  white-space: wrap;
  display: flex;
  justify-content: space-between;
  font-weight: 300;
  align-items: center;
`;

type Props = {
  children: React.ReactNode;
  onClick?: () => void;
  className?: string
}

const Item = ({
  children,
  className,
  onClick = () => {},
  ...props
}: Props) => (
  <ListItem
    onClick={onClick}
    tabIndex={0}
    className={className}
    {...props}
  >
    {children}
  </ListItem>
);

export default Item;
