import * as React from 'react';
import { createUseStyles } from 'react-jss';
import moment from 'moment';
import classnames from 'classnames';
import { Theme } from 'helpers/theme';
import { format } from 'path';

const useStyles = createUseStyles((theme: Theme) => ({
  textRoot: {
    display: 'flex',
    alignItems: 'center',
    fontSize: theme.fontSizes.small,
    fontWeight: 400
  }
}));

type Props = {
  text: string | Date;
  color?: string;
  formatDate?: boolean; // If given formats the text as a date to "DD-MM-YY" format using moment
  invalidDateText?: string; // If given date is invalid or empty show this text instead
  className?: string;
};

function Text({ text, color, formatDate, invalidDateText, className }: Props) {
  const classes = useStyles();

  if (formatDate) {
    if (!moment(text).isValid() || text == '0001-01-01T00:00:00Z') {
      text = invalidDateText ?? '-';
    } else {
      text = moment(text).format('DD MMMM YYYY');
    }
  }

  const extraStyles: any = {};
  if (color) {
    extraStyles.color = color;
  }

  return (
    <div
      className={classnames(classes.textRoot, className)}
      style={extraStyles}
    >
      {text}
    </div>
  );
}

export default Text;
