import * as React from 'react';
import { createUseStyles, useTheme } from 'react-jss';
import { Theme } from 'helpers/theme';

const useStyles = createUseStyles((theme: Theme) => ({
  text: {
    color: theme.colors.primaryBlack,
    fontSize: theme.fontSizes.extraLarge,
    margin: '30px 0'
  },
  time: {
    color: theme.colors.textOrange,
    fontSize: theme.fontSizes.extraLarge
  }
}));

type Props = {
  transcript?: string;
};

function Transcript({ transcript }: Props) {
  const theme = useTheme();
  const classes = useStyles({ theme });

  // Look behind to split times from text
  const regx = new RegExp(
    '([\\s,.:;"\']|^)([0-9]+:[0-9][0-9]-[0-9]+:[0-9][0-9])([\\s,.:;"\']|$)',
    'g'
  );
  const timestamps = transcript?.split(regx) ?? [''];
  return (
    <>
      {timestamps.map((section: string) => {
        // Remove new line characters
        const formatted = section.replace(/\n|\r/g, '');
        // Change text format depending if it's a time or just text
        const time = formatted.match(
          new RegExp('([0-9]+:[0-9][0-9]-[0-9]+:[0-9][0-9])')
        );
        return time ? (
          <span className={classes.time}>{time.input} </span>
        ) : (
          <div className={classes.text}>{formatted}</div>
        );
      })}
    </>
  );
}

export default Transcript;
