/* tslint:disable */
/* eslint-disable */
/* @relayHash 385fd850aa5252804c004662797ba4b9 */

import { ConcreteRequest } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type QWERefetchQueryVariables = {};
export type QWERefetchQueryResponse = {
    readonly candidate: {
        readonly " $fragmentRefs": FragmentRefs<"QWE_candidate">;
    } | null;
    readonly clients: {
        readonly " $fragmentRefs": FragmentRefs<"QWE_clients">;
    } | null;
};
export type QWERefetchQuery = {
    readonly response: QWERefetchQueryResponse;
    readonly variables: QWERefetchQueryVariables;
};



/*
query QWERefetchQuery {
  candidate {
    ...QWE_candidate
    id
  }
  clients {
    ...QWE_clients
  }
}

fragment CompetencySkillsPage_candidate on Candidate {
  id
  firstName
  lastName
  skillGroupType
  candidateType
  areaProgress {
    practiceArea {
      id
      name
      practiceSkills {
        edges {
          id
          name
          practiceSkillGroups {
            edges {
              id
              name
              practiceSubSkills {
                edges {
                  id
                  name
                  practiceSubSkillType
                  minSuggestedEntryCount
                  doneEntryCount
                  order
                }
              }
            }
          }
        }
      }
    }
  }
}

fragment QWE_candidate on Candidate {
  id
  skillGroupType
  candidateType
  mentor {
    id
    firstName
    lastName
    email
  }
  placements {
    edges {
      id
      name
      startDate
      endDate
      qweIndividual
      orgPosition
      sraNumber
      approved
      orgSRANumber
      supervisorName
      supervisorEmail
      email
      fullTime
      partTimeDays
      daysMissed
      qweDocURL
      qweDocFilename
      client {
        name
        managers {
          edges {
            id
            firstName
            lastName
            email
            profileImageUrl
          }
        }
        id
      }
    }
  }
  ...CompetencySkillsPage_candidate
}

fragment QWE_clients on ClientPage {
  edges {
    id
    name
  }
}
*/

const node: ConcreteRequest = (function () {
    var v0 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "id",
        "args": null,
        "storageKey": null
    } as any), v1 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "firstName",
        "args": null,
        "storageKey": null
    } as any), v2 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "lastName",
        "args": null,
        "storageKey": null
    } as any), v3 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "email",
        "args": null,
        "storageKey": null
    } as any), v4 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "name",
        "args": null,
        "storageKey": null
    } as any);
    return {
        "kind": "Request",
        "fragment": {
            "kind": "Fragment",
            "name": "QWERefetchQuery",
            "type": "Query",
            "metadata": null,
            "argumentDefinitions": [],
            "selections": [
                {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "candidate",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "Candidate",
                    "plural": false,
                    "selections": [
                        {
                            "kind": "FragmentSpread",
                            "name": "QWE_candidate",
                            "args": null
                        }
                    ]
                },
                {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "clients",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "ClientPage",
                    "plural": false,
                    "selections": [
                        {
                            "kind": "FragmentSpread",
                            "name": "QWE_clients",
                            "args": null
                        }
                    ]
                }
            ]
        },
        "operation": {
            "kind": "Operation",
            "name": "QWERefetchQuery",
            "argumentDefinitions": [],
            "selections": [
                {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "candidate",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "Candidate",
                    "plural": false,
                    "selections": [
                        (v0 /*: any*/),
                        {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "skillGroupType",
                            "args": null,
                            "storageKey": null
                        },
                        {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "candidateType",
                            "args": null,
                            "storageKey": null
                        },
                        {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "mentor",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "Mentor",
                            "plural": false,
                            "selections": [
                                (v0 /*: any*/),
                                (v1 /*: any*/),
                                (v2 /*: any*/),
                                (v3 /*: any*/)
                            ]
                        },
                        {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "placements",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "PlacementPage",
                            "plural": false,
                            "selections": [
                                {
                                    "kind": "LinkedField",
                                    "alias": null,
                                    "name": "edges",
                                    "storageKey": null,
                                    "args": null,
                                    "concreteType": "Placement",
                                    "plural": true,
                                    "selections": [
                                        (v0 /*: any*/),
                                        (v4 /*: any*/),
                                        {
                                            "kind": "ScalarField",
                                            "alias": null,
                                            "name": "startDate",
                                            "args": null,
                                            "storageKey": null
                                        },
                                        {
                                            "kind": "ScalarField",
                                            "alias": null,
                                            "name": "endDate",
                                            "args": null,
                                            "storageKey": null
                                        },
                                        {
                                            "kind": "ScalarField",
                                            "alias": null,
                                            "name": "qweIndividual",
                                            "args": null,
                                            "storageKey": null
                                        },
                                        {
                                            "kind": "ScalarField",
                                            "alias": null,
                                            "name": "orgPosition",
                                            "args": null,
                                            "storageKey": null
                                        },
                                        {
                                            "kind": "ScalarField",
                                            "alias": null,
                                            "name": "sraNumber",
                                            "args": null,
                                            "storageKey": null
                                        },
                                        {
                                            "kind": "ScalarField",
                                            "alias": null,
                                            "name": "approved",
                                            "args": null,
                                            "storageKey": null
                                        },
                                        {
                                            "kind": "ScalarField",
                                            "alias": null,
                                            "name": "orgSRANumber",
                                            "args": null,
                                            "storageKey": null
                                        },
                                        {
                                            "kind": "ScalarField",
                                            "alias": null,
                                            "name": "supervisorName",
                                            "args": null,
                                            "storageKey": null
                                        },
                                        {
                                            "kind": "ScalarField",
                                            "alias": null,
                                            "name": "supervisorEmail",
                                            "args": null,
                                            "storageKey": null
                                        },
                                        (v3 /*: any*/),
                                        {
                                            "kind": "ScalarField",
                                            "alias": null,
                                            "name": "fullTime",
                                            "args": null,
                                            "storageKey": null
                                        },
                                        {
                                            "kind": "ScalarField",
                                            "alias": null,
                                            "name": "partTimeDays",
                                            "args": null,
                                            "storageKey": null
                                        },
                                        {
                                            "kind": "ScalarField",
                                            "alias": null,
                                            "name": "daysMissed",
                                            "args": null,
                                            "storageKey": null
                                        },
                                        {
                                            "kind": "ScalarField",
                                            "alias": null,
                                            "name": "qweDocURL",
                                            "args": null,
                                            "storageKey": null
                                        },
                                        {
                                            "kind": "ScalarField",
                                            "alias": null,
                                            "name": "qweDocFilename",
                                            "args": null,
                                            "storageKey": null
                                        },
                                        {
                                            "kind": "LinkedField",
                                            "alias": null,
                                            "name": "client",
                                            "storageKey": null,
                                            "args": null,
                                            "concreteType": "Client",
                                            "plural": false,
                                            "selections": [
                                                (v4 /*: any*/),
                                                {
                                                    "kind": "LinkedField",
                                                    "alias": null,
                                                    "name": "managers",
                                                    "storageKey": null,
                                                    "args": null,
                                                    "concreteType": "ManagerPage",
                                                    "plural": false,
                                                    "selections": [
                                                        {
                                                            "kind": "LinkedField",
                                                            "alias": null,
                                                            "name": "edges",
                                                            "storageKey": null,
                                                            "args": null,
                                                            "concreteType": "Manager",
                                                            "plural": true,
                                                            "selections": [
                                                                (v0 /*: any*/),
                                                                (v1 /*: any*/),
                                                                (v2 /*: any*/),
                                                                (v3 /*: any*/),
                                                                {
                                                                    "kind": "ScalarField",
                                                                    "alias": null,
                                                                    "name": "profileImageUrl",
                                                                    "args": null,
                                                                    "storageKey": null
                                                                }
                                                            ]
                                                        }
                                                    ]
                                                },
                                                (v0 /*: any*/)
                                            ]
                                        }
                                    ]
                                }
                            ]
                        },
                        (v1 /*: any*/),
                        (v2 /*: any*/),
                        {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "areaProgress",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "PracticeAreaProgress",
                            "plural": true,
                            "selections": [
                                {
                                    "kind": "LinkedField",
                                    "alias": null,
                                    "name": "practiceArea",
                                    "storageKey": null,
                                    "args": null,
                                    "concreteType": "PracticeArea",
                                    "plural": false,
                                    "selections": [
                                        (v0 /*: any*/),
                                        (v4 /*: any*/),
                                        {
                                            "kind": "LinkedField",
                                            "alias": null,
                                            "name": "practiceSkills",
                                            "storageKey": null,
                                            "args": null,
                                            "concreteType": "PracticeSkillPage",
                                            "plural": false,
                                            "selections": [
                                                {
                                                    "kind": "LinkedField",
                                                    "alias": null,
                                                    "name": "edges",
                                                    "storageKey": null,
                                                    "args": null,
                                                    "concreteType": "PracticeSkill",
                                                    "plural": true,
                                                    "selections": [
                                                        (v0 /*: any*/),
                                                        (v4 /*: any*/),
                                                        {
                                                            "kind": "LinkedField",
                                                            "alias": null,
                                                            "name": "practiceSkillGroups",
                                                            "storageKey": null,
                                                            "args": null,
                                                            "concreteType": "PracticeSkillGroupPage",
                                                            "plural": false,
                                                            "selections": [
                                                                {
                                                                    "kind": "LinkedField",
                                                                    "alias": null,
                                                                    "name": "edges",
                                                                    "storageKey": null,
                                                                    "args": null,
                                                                    "concreteType": "PracticeSkillGroup",
                                                                    "plural": true,
                                                                    "selections": [
                                                                        (v0 /*: any*/),
                                                                        (v4 /*: any*/),
                                                                        {
                                                                            "kind": "LinkedField",
                                                                            "alias": null,
                                                                            "name": "practiceSubSkills",
                                                                            "storageKey": null,
                                                                            "args": null,
                                                                            "concreteType": "PracticeSubSkillPage",
                                                                            "plural": false,
                                                                            "selections": [
                                                                                {
                                                                                    "kind": "LinkedField",
                                                                                    "alias": null,
                                                                                    "name": "edges",
                                                                                    "storageKey": null,
                                                                                    "args": null,
                                                                                    "concreteType": "PracticeSubSkill",
                                                                                    "plural": true,
                                                                                    "selections": [
                                                                                        (v0 /*: any*/),
                                                                                        (v4 /*: any*/),
                                                                                        {
                                                                                            "kind": "ScalarField",
                                                                                            "alias": null,
                                                                                            "name": "practiceSubSkillType",
                                                                                            "args": null,
                                                                                            "storageKey": null
                                                                                        },
                                                                                        {
                                                                                            "kind": "ScalarField",
                                                                                            "alias": null,
                                                                                            "name": "minSuggestedEntryCount",
                                                                                            "args": null,
                                                                                            "storageKey": null
                                                                                        },
                                                                                        {
                                                                                            "kind": "ScalarField",
                                                                                            "alias": null,
                                                                                            "name": "doneEntryCount",
                                                                                            "args": null,
                                                                                            "storageKey": null
                                                                                        },
                                                                                        {
                                                                                            "kind": "ScalarField",
                                                                                            "alias": null,
                                                                                            "name": "order",
                                                                                            "args": null,
                                                                                            "storageKey": null
                                                                                        }
                                                                                    ]
                                                                                }
                                                                            ]
                                                                        }
                                                                    ]
                                                                }
                                                            ]
                                                        }
                                                    ]
                                                }
                                            ]
                                        }
                                    ]
                                }
                            ]
                        }
                    ]
                },
                {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "clients",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "ClientPage",
                    "plural": false,
                    "selections": [
                        {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "edges",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "Client",
                            "plural": true,
                            "selections": [
                                (v0 /*: any*/),
                                (v4 /*: any*/)
                            ]
                        }
                    ]
                }
            ]
        },
        "params": {
            "operationKind": "query",
            "name": "QWERefetchQuery",
            "id": null,
            "text": "query QWERefetchQuery {\n  candidate {\n    ...QWE_candidate\n    id\n  }\n  clients {\n    ...QWE_clients\n  }\n}\n\nfragment CompetencySkillsPage_candidate on Candidate {\n  id\n  firstName\n  lastName\n  skillGroupType\n  candidateType\n  areaProgress {\n    practiceArea {\n      id\n      name\n      practiceSkills {\n        edges {\n          id\n          name\n          practiceSkillGroups {\n            edges {\n              id\n              name\n              practiceSubSkills {\n                edges {\n                  id\n                  name\n                  practiceSubSkillType\n                  minSuggestedEntryCount\n                  doneEntryCount\n                  order\n                }\n              }\n            }\n          }\n        }\n      }\n    }\n  }\n}\n\nfragment QWE_candidate on Candidate {\n  id\n  skillGroupType\n  candidateType\n  mentor {\n    id\n    firstName\n    lastName\n    email\n  }\n  placements {\n    edges {\n      id\n      name\n      startDate\n      endDate\n      qweIndividual\n      orgPosition\n      sraNumber\n      approved\n      orgSRANumber\n      supervisorName\n      supervisorEmail\n      email\n      fullTime\n      partTimeDays\n      daysMissed\n      qweDocURL\n      qweDocFilename\n      client {\n        name\n        managers {\n          edges {\n            id\n            firstName\n            lastName\n            email\n            profileImageUrl\n          }\n        }\n        id\n      }\n    }\n  }\n  ...CompetencySkillsPage_candidate\n}\n\nfragment QWE_clients on ClientPage {\n  edges {\n    id\n    name\n  }\n}\n",
            "metadata": {}
        }
    } as any;
})();
(node as any).hash = '344cf58592d9eddc0447cc42d6b4bb60';
export default node;
