import React, { useCallback, useEffect, useState } from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import Spacer from 'components/core/Spacers/Spacer';
import { Page as _Page } from 'components/Pagination/Paginator';
import { Dashboard_socialEvents } from './__generated__/Dashboard_socialEvents.graphql';
import theme from 'helpers/theme';
import Page from 'components/Page';
import UpdatesRibbon from './UpdatesRibbon';
import { Dashboard_candidate } from './__generated__/Dashboard_candidate.graphql';
import styled from 'styled-components';
import PracticeSkillsCard from 'components/PracticeSkillsCard';
import PlacementGraph from './PlacementGraph';
import CourseCard from 'components/CourseCard';
import LinkCard from 'components/LinkCard';
import Pathway from './Pathway';
import Roadmap from './Roadmap';
import PageTitle from 'components/PageTitle';
import RobertChat from './RobertChat';

const Container = styled.div`
  display: flex;
  flex-direction: row;
`;

const Heading = styled.div`
  display: flex;
  flex-direction: column;
  width: 65%;
`;

const Title = styled.div`
  font-weight: 700;
  font-size: 41px;
  color: ${theme.colors.primaryBlack};
  margin-bottom: 10px;
`;

const SmallTitle = styled(Title)`
  font-size: ${theme.fontSizes.heading}px;
`;

const SubTitle = styled.div<{ maxWidth: boolean }>`
  font-weight: 300;
  font-size: 14px;
  color: ${theme.colors.textGrey};
  max-width: ${(props) => (props.maxWidth ? '500px' : '100%')};
  margin-top: 15px;
`;

const InfoBar = styled.div`
  display: inline-grid;
  grid-gap: 10px;
  grid-template-columns: repeat(2, 1fr) 1.6fr;
  margin-left: 130px;
`;

const CourseHolder = styled.div`
  display: grid;
  grid-gap: 26px;
  padding: ${theme.spacing(2)}px 0;
  grid-template-columns: repeat(auto-fit, 298px);
`;

const LinkHolder = styled.div`
  display: grid;
  grid-gap: 20px;
  margin: ${theme.spacing(2)}px 0;
  grid-template-columns: repeat(auto-fit, 330px);
  @media (max-width: 1300px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media (max-width: 550px) {
    grid-template-columns: repeat(1, 1fr);
  }
`;

type Props = {
  socialEvents: Dashboard_socialEvents;
  candidate: Dashboard_candidate;
};


function Dashboard({ socialEvents, candidate }: Props) {

  const [chatConfirmed, setChatConfirmed] = useState(candidate.skillGroupTypeConfirmed);

  let totalPracticeSkillsDone = 0;
  candidate.areaProgress?.map((practiceArea: any) => {
    if (
      practiceArea.practiceSkillsDone &&
      practiceArea.practiceSkillsDone.length > 0
    ) {
      totalPracticeSkillsDone += practiceArea.practiceSkillsDone.length;
    }
  });

  const links = []
  if (candidate.skillGroupType === "TC") {
    links.push({
      title: 'Training Record',
      subTitle:
        'The SRA require you to keep an up-to-date training record throughout your training contract demonstrating the experiences and skills gained. You can access your unique training portfolio here to record all your experiences and monitor which skills might need further attention.',
      linkUrl: 'app/qwe'
    });
  } else {
    links.push({
      title: 'Qualifying Work Experience',
      subTitle:
        'Keep up to date with where you’re at with qualifying work experience. Access your dedicated training portfolio to capture all your work experience.',
      linkUrl: 'app/qwe'
    });
  }
  links.push({
    title: 'Learning & Development',
    subTitle:
      'Here you can book and complete courses on a variety of useful topics and access our Competency Plus library to further your skills.',
    linkUrl: 'app/all-courses'
  });

  if (candidate.candidateType === 'level3') {
    links.push(
      {
        title: 'Resources',
        subTitle:
          'Book and manage your mentor meetings and appraisals with your mentor and principal. You can also view our social and training events.',
        linkUrl: 'app/mentoring'
      }
    );
  }

  const coursesInProgress =
    candidate?.myCourses?.filter(
      (course) =>
        course &&
        course.progress &&
        course.progress.completed > 0 &&
        course.progress.completed < 100
    ) ?? [];


  const chatConfirmedHandler = (value: any) => {
    console.log(value)
    setChatConfirmed(value)
  }
  const showRobertChat = () => {
    return <RobertChat candidate={candidate} onChatConfirmed={chatConfirmedHandler} />
  }

  const completedCourses = candidate.myCourses?.filter(c => c && c.status === "complete").length ?? 0;

  return (
    <Page>
      {showRobertChat()}
      <Container>
        {chatConfirmed ? <Heading>
          <PageTitle title="Dashboard" />
          <SubTitle maxWidth={false}>
            Hi {candidate.firstName}, here is your unique pathway to qualification. As you upload documents in the Qualifications area, your pathway will change from blue to green so you can see where you're at in your qualification. We hope you have a great journey!
          </SubTitle>
        </Heading> : <></>
        }
        {/* <InfoBar>
          <PracticeSkillsCard
            name="Practical Skills"
            count={totalPracticeSkillsDone}
            imageUrl={require(`../../assets/Skill.svg`)}
          />
          <PracticeSkillsCard
            name="Course Certificates"
            count={completedCourses}
            imageUrl={require(`../../assets/Badge.svg`)}
          />
          <PlacementGraph candidate={candidate} />
        </InfoBar> */}
      </Container>
      <Roadmap chatConfirmed={chatConfirmed} candidate={candidate} />
      {candidate.candidateType === 'level3' && (
        <>
          <Spacer vertical spacing={3} />
          <UpdatesRibbon socialEvents={socialEvents} />
        </>
      )}
      <Spacer vertical spacing={3} />
      <LinkHolder>
        {links.map((link, index) => (
          <LinkCard
            key={index}
            title={link.title}
            subtitle={link.subTitle}
            linkUrl={link.linkUrl}
          />
        ))}
      </LinkHolder>
      <Spacer vertical spacing={3} />
      {coursesInProgress.length > 0 && (
        <>
          <SmallTitle>Jump back in</SmallTitle>
          <SubTitle maxWidth>
            If you're halfway through a course, you can jump right back in from
            here!
          </SubTitle>
          <CourseHolder>
            {coursesInProgress.map(
              (course, index) =>
                course?.course && (
                  <CourseCard
                    key={index}
                    course={course.course}
                    progress={course?.progress?.completed}
                    isTaking
                  />
                )
            )}
          </CourseHolder>
        </>
      )}
    </Page>
  );
}

export default createFragmentContainer(Dashboard, {
  socialEvents: graphql`
    fragment Dashboard_socialEvents on SocialEventPage {
      edges {
        id
        name
        startDate
      }
      pageInfo {
        total
        offset
        limit
        given
      }
    }
  `,
  candidate: graphql`
    fragment Dashboard_candidate on Candidate {
      firstName
      skillGroupType
      skillGroupTypeConfirmed
      chatHistory
      candidateType
      areaProgress {
        practiceArea {
          id
        }
        practiceSkillsDone {
          id
        }
        practiceSkillsRemaining {
          id
        }
      }
      ...PlacementGraph_candidate
      myCourses {
        progress {
          completed
        }
        status
        course {
          ...CourseCard_course
        }
      }
      ...Pathway_candidate
      ...Roadmap_candidate
      ...RobertChat_candidate
    }
  `
});
