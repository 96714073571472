import * as React from 'react';
import classNames from 'classnames';
import { createUseStyles, useTheme } from 'react-jss';
import { Theme } from 'helpers/theme';
import Icon from 'components/core/Icon';

const useStyles = createUseStyles((theme: Theme) => ({
  genericPopupRoot: {
    width: '50vw',
    maxWidth: 500,
    boxShadow: '2px 2px 10px 6px rgba(0,0,0,0.07)',
    borderRadius: 9
  },
  '@media (max-width: 500px)': {
    genericPopupRoot: {
      width: 261
    }
  },
  popupHolder: {
    display: 'flex',
    width: '100%',
    height: '100%',
    position: 'fixed',
    top: 0,
    left: 0,
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 10000,
    background: '#0000004d'
  },
  header: {
    background: theme.colors.secondaryOrange,
    display: 'flex',
    justifyContent: 'space-between',
    padding: '3px 35px',
    alignItems: 'center',
    borderTopRightRadius: 9,
    borderTopLeftRadius: 9
  },
  title: {
    color: 'white'
  },
  content: {
    borderBottomRightRadius: 9,
    borderBottomLeftRadius: 9,
    background: 'white',
    padding: '60px 35px',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column'
  }
}));

type Props = {
  children?: React.ReactNode;
  title?: string;
  className?: string;
  open: boolean;
  onClose?: () => void;
};

function GenericPopup({ children, title, className, open, onClose }: Props) {
  const theme = useTheme();
  const classes = useStyles({ theme });

  if (!open) {
    return null;
  }
  return (
    <div className={classes.popupHolder}>
      <div className={classes.genericPopupRoot}>
        <div className={classes.header}>
          <h2 className={classes.title}>{title}</h2>
          <Icon name={'CloseCourseManagementTray_X'} onClick={onClose} />
        </div>
        <div className={classNames(classes.content, className)}>{children}</div>
      </div>
    </div>
  );
}

export default GenericPopup;
