/* tslint:disable */
/* eslint-disable */

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type Advice_advice = {
    readonly id: string;
    readonly name: string;
    readonly introduction: string;
    readonly description: string;
    readonly postedAt: string;
    readonly bannerImageURL: string | null;
    readonly links: ReadonlyArray<string> | null;
    readonly voiceoverURL: string | null;
    readonly videoURL: string | null;
    readonly " $refType": "AdviceCard_advice";
};
export type Advice_advice$data = Advice_advice;
export type Advice_advice$key = {
    readonly " $data"?: Advice_advice$data;
    readonly " $fragmentRefs": FragmentRefs<"Advice_advice">;
};



const node: ReaderFragment = ({
    "kind": "Fragment",
    "name": "Advice_advice",
    "type": "Advice",
    "metadata": null,
    "argumentDefinitions": [],
    "selections": [
        {
            "kind": "ScalarField",
            "alias": null,
            "name": "id",
            "args": null,
            "storageKey": null
        },
        {
            "kind": "ScalarField",
            "alias": null,
            "name": "name",
            "args": null,
            "storageKey": null
        },
        {
            "kind": "ScalarField",
            "alias": null,
            "name": "introduction",
            "args": null,
            "storageKey": null
        },
        {
            "kind": "ScalarField",
            "alias": null,
            "name": "description",
            "args": null,
            "storageKey": null
        },
        {
            "kind": "ScalarField",
            "alias": null,
            "name": "postedAt",
            "args": null,
            "storageKey": null
        },
        {
            "kind": "ScalarField",
            "alias": null,
            "name": "bannerImageURL",
            "args": null,
            "storageKey": null
        },
        {
            "kind": "ScalarField",
            "alias": null,
            "name": "links",
            "args": null,
            "storageKey": null
        },
        {
            "kind": "ScalarField",
            "alias": null,
            "name": "voiceoverURL",
            "args": null,
            "storageKey": null
        },
        {
            "kind": "ScalarField",
            "alias": null,
            "name": "videoURL",
            "args": null,
            "storageKey": null
        }
    ]
} as any);
(node as any).hash = '46cb28d321b061d9b5701e6b791164db';
export default node;
