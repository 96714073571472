/* tslint:disable */
/* eslint-disable */
/* @relayHash d4545a5a3cd1d2d61838f13c3f44e7cb */

import { ConcreteRequest } from "relay-runtime";
export type mutationsSubmitCharacterSuitabilityCompletionMutationVariables = {};
export type mutationsSubmitCharacterSuitabilityCompletionMutationResponse = {
    readonly submitCharacterSuitabilityCompletion: boolean;
};
export type mutationsSubmitCharacterSuitabilityCompletionMutation = {
    readonly response: mutationsSubmitCharacterSuitabilityCompletionMutationResponse;
    readonly variables: mutationsSubmitCharacterSuitabilityCompletionMutationVariables;
};



/*
mutation mutationsSubmitCharacterSuitabilityCompletionMutation {
  submitCharacterSuitabilityCompletion
}
*/

const node: ConcreteRequest = (function () {
    var v0 = [
        ({
            "kind": "ScalarField",
            "alias": null,
            "name": "submitCharacterSuitabilityCompletion",
            "args": null,
            "storageKey": null
        } as any)
    ];
    return {
        "kind": "Request",
        "fragment": {
            "kind": "Fragment",
            "name": "mutationsSubmitCharacterSuitabilityCompletionMutation",
            "type": "Mutation",
            "metadata": null,
            "argumentDefinitions": [],
            "selections": (v0 /*: any*/)
        },
        "operation": {
            "kind": "Operation",
            "name": "mutationsSubmitCharacterSuitabilityCompletionMutation",
            "argumentDefinitions": [],
            "selections": (v0 /*: any*/)
        },
        "params": {
            "operationKind": "mutation",
            "name": "mutationsSubmitCharacterSuitabilityCompletionMutation",
            "id": null,
            "text": "mutation mutationsSubmitCharacterSuitabilityCompletionMutation {\n  submitCharacterSuitabilityCompletion\n}\n",
            "metadata": {}
        }
    } as any;
})();
(node as any).hash = '209cd3bc49f7c6e410faeea30c5470bb';
export default node;
