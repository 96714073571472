/* tslint:disable */
/* eslint-disable */
/* @relayHash eab6b0dbb917a01f8aa959f5d76487c6 */

import { ConcreteRequest } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type QualificationType = "a_level" | "degree" | "gdl" | "lpc" | "other" | "practicing_cert" | "qualification_cert" | "sqe1" | "sqe2" | "%future added value";
export type App_Qualifications_QueryVariables = {
    qualType: QualificationType;
};
export type App_Qualifications_QueryResponse = {
    readonly candidate: {
        readonly " $fragmentRefs": FragmentRefs<"Qualifications_candidate">;
    } | null;
};
export type App_Qualifications_Query = {
    readonly response: App_Qualifications_QueryResponse;
    readonly variables: App_Qualifications_QueryVariables;
};



/*
query App_Qualifications_Query(
  $qualType: QualificationType!
) {
  candidate {
    ...Qualifications_candidate_1eiC1o
    id
  }
}

fragment Qualifications_candidate_1eiC1o on Candidate {
  skillGroupType
  hiddenQualifications
  qualifications(qualificationType: $qualType) {
    id
    name
    fileUrl
    startDate
    endDate
    grade
    qualificationType
  }
}
*/

const node: ConcreteRequest = (function () {
    var v0 = [
        ({
            "kind": "LocalArgument",
            "name": "qualType",
            "type": "QualificationType!",
            "defaultValue": null
        } as any)
    ], v1 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "id",
        "args": null,
        "storageKey": null
    } as any);
    return {
        "kind": "Request",
        "fragment": {
            "kind": "Fragment",
            "name": "App_Qualifications_Query",
            "type": "Query",
            "metadata": null,
            "argumentDefinitions": (v0 /*: any*/),
            "selections": [
                {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "candidate",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "Candidate",
                    "plural": false,
                    "selections": [
                        {
                            "kind": "FragmentSpread",
                            "name": "Qualifications_candidate",
                            "args": [
                                {
                                    "kind": "Variable",
                                    "name": "qualType",
                                    "variableName": "qualType"
                                }
                            ]
                        }
                    ]
                }
            ]
        },
        "operation": {
            "kind": "Operation",
            "name": "App_Qualifications_Query",
            "argumentDefinitions": (v0 /*: any*/),
            "selections": [
                {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "candidate",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "Candidate",
                    "plural": false,
                    "selections": [
                        {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "skillGroupType",
                            "args": null,
                            "storageKey": null
                        },
                        {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "hiddenQualifications",
                            "args": null,
                            "storageKey": null
                        },
                        {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "qualifications",
                            "storageKey": null,
                            "args": [
                                {
                                    "kind": "Variable",
                                    "name": "qualificationType",
                                    "variableName": "qualType"
                                }
                            ],
                            "concreteType": "Qualification",
                            "plural": true,
                            "selections": [
                                (v1 /*: any*/),
                                {
                                    "kind": "ScalarField",
                                    "alias": null,
                                    "name": "name",
                                    "args": null,
                                    "storageKey": null
                                },
                                {
                                    "kind": "ScalarField",
                                    "alias": null,
                                    "name": "fileUrl",
                                    "args": null,
                                    "storageKey": null
                                },
                                {
                                    "kind": "ScalarField",
                                    "alias": null,
                                    "name": "startDate",
                                    "args": null,
                                    "storageKey": null
                                },
                                {
                                    "kind": "ScalarField",
                                    "alias": null,
                                    "name": "endDate",
                                    "args": null,
                                    "storageKey": null
                                },
                                {
                                    "kind": "ScalarField",
                                    "alias": null,
                                    "name": "grade",
                                    "args": null,
                                    "storageKey": null
                                },
                                {
                                    "kind": "ScalarField",
                                    "alias": null,
                                    "name": "qualificationType",
                                    "args": null,
                                    "storageKey": null
                                }
                            ]
                        },
                        (v1 /*: any*/)
                    ]
                }
            ]
        },
        "params": {
            "operationKind": "query",
            "name": "App_Qualifications_Query",
            "id": null,
            "text": "query App_Qualifications_Query(\n  $qualType: QualificationType!\n) {\n  candidate {\n    ...Qualifications_candidate_1eiC1o\n    id\n  }\n}\n\nfragment Qualifications_candidate_1eiC1o on Candidate {\n  skillGroupType\n  hiddenQualifications\n  qualifications(qualificationType: $qualType) {\n    id\n    name\n    fileUrl\n    startDate\n    endDate\n    grade\n    qualificationType\n  }\n}\n",
            "metadata": {}
        }
    } as any;
})();
(node as any).hash = 'a050a7f9c07b8afc45d505621d7510ed';
export default node;
