import * as React from 'react';
import { createUseStyles } from 'react-jss';
import { Theme } from 'helpers/theme';
import Text from './Text';
import classNames from 'classnames';

const useStyles = createUseStyles((theme: Theme) => ({
  table: {
    width: '100%',
    borderCollapse: 'separate',
    borderSpacing: '0 10px'
  },
  row: {
    background: 'white',
    height: 70,
    borderRadius: theme.primaryBorderRadius,
    transition: 'box-shadow 0.1s ease',
    '&:hover': {
      boxShadow: theme.shadows.primary
      // "& $cell": {
      //   "&:first-child": {
      //     borderRadius: 0,
      //   },
      //   "&:last-child": {
      //     borderRadius: 0,
      //   },
      // },
    }
    
  },
  rowIndex: {
    background: 'white',
    height: 70,
    borderRadius: theme.primaryBorderRadius,
    zIndex: '-1',
    transition: 'box-shadow 0.1s ease',
    '&:hover': {
      boxShadow: theme.shadows.primary
      // "& $cell": {
      //   "&:first-child": {
      //     borderRadius: 0,
      //   },
      //   "&:last-child": {
      //     borderRadius: 0,
      //   },
      // },
    }
  },
  headerCell: {
    color: '#929CA6',
    textTransform: 'uppercase',
    fontSize: theme.fontSizes.tiny,
    fontWeight: 300,
    padding: [0, 25],
    textAlign: 'left'
  },
  cell: {
    padding: [0, 25],
    borderTop: `1px solid ${theme.colors.borderGrey}`,
    borderRight: '0',
    borderBottom: `1px solid ${theme.colors.borderGrey}`,
    borderLeft: '0',
    transition: 'border-radius 0.1s ease',
    '&:first-child': {
      borderRadius: [
        theme.primaryBorderRadius,
        0,
        0,
        theme.primaryBorderRadius
      ],
      borderLeft: `1px solid ${theme.colors.borderGrey}`
    },
    '&:last-child': {
      borderRadius: [
        0,
        theme.primaryBorderRadius,
        theme.primaryBorderRadius,
        0
      ],
      borderRight: `1px solid ${theme.colors.borderGrey}`
    }
  },
  defaultCell: {
    fontSize: theme.fontSizes.default
  }
}));

type ColumnDef = {
  field?: string;
  component?: (row: any) => React.ReactNode;
  onClick?: (row: any) => void;
  colspan?: number;
  headerName: string;
  onHeaderClick?: () => void;
  className?: string;
};

type Props = {
  columns: ColumnDef[];
  rows: any[];
  onRowClick?: (row: any) => void;
  entry?: boolean;
};

function Table({ columns, rows, onRowClick, entry }: Props) {
  const classes = useStyles();

  return (
    <table className={classes.table}>
      <thead>
        <tr>
          {columns.map((column) => (
            <th key={column.headerName} className={classNames(classes.headerCell, column.className)} onClick={column.onHeaderClick}>
              {column.headerName}
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {rows.map((row, index) => {
          return (
            <tr
              key={Math.random()}
              className={classes.row}
            >
              {columns.map(({ field, component, colspan }, index) => (
                <td key={index} className={classes.cell} colSpan={colspan || 1} onClick={() => (entry && index < 6) ? (onRowClick && onRowClick(row)) : {}}>
                  {field && <Text text={row[field]} />}
                  {component && component(row)}
                </td>
              ))}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
}

export default Table;
