/* tslint:disable */
/* eslint-disable */
/* @relayHash 729faf213f7241eac67ef7290b090da7 */

import { ConcreteRequest } from "relay-runtime";
export type DropdownCompetencyQueryRendererQueryVariables = {
    practiceAreaID: string;
};
export type DropdownCompetencyQueryRendererQueryResponse = {
    readonly node: {
        readonly practiceSkills?: {
            readonly edges: ReadonlyArray<{
                readonly id: string;
                readonly name: string;
                readonly practiceSkillGroups: {
                    readonly edges: ReadonlyArray<{
                        readonly id: string;
                        readonly name: string;
                        readonly practiceSubSkills: {
                            readonly edges: ReadonlyArray<{
                                readonly id: string;
                                readonly name: string;
                            } | null> | null;
                        } | null;
                    } | null> | null;
                } | null;
            } | null> | null;
        } | null;
    } | null;
};
export type DropdownCompetencyQueryRendererQuery = {
    readonly response: DropdownCompetencyQueryRendererQueryResponse;
    readonly variables: DropdownCompetencyQueryRendererQueryVariables;
};



/*
query DropdownCompetencyQueryRendererQuery(
  $practiceAreaID: ID!
) {
  node(id: $practiceAreaID) {
    __typename
    ... on PracticeArea {
      practiceSkills {
        edges {
          id
          name
          practiceSkillGroups {
            edges {
              id
              name
              practiceSubSkills {
                edges {
                  id
                  name
                }
              }
            }
          }
        }
      }
    }
    id
  }
}
*/

const node: ConcreteRequest = (function () {
    var v0 = [
        ({
            "kind": "LocalArgument",
            "name": "practiceAreaID",
            "type": "ID!",
            "defaultValue": null
        } as any)
    ], v1 = [
        ({
            "kind": "Variable",
            "name": "id",
            "variableName": "practiceAreaID"
        } as any)
    ], v2 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "id",
        "args": null,
        "storageKey": null
    } as any), v3 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "name",
        "args": null,
        "storageKey": null
    } as any), v4 = ({
        "kind": "InlineFragment",
        "type": "PracticeArea",
        "selections": [
            {
                "kind": "LinkedField",
                "alias": null,
                "name": "practiceSkills",
                "storageKey": null,
                "args": null,
                "concreteType": "PracticeSkillPage",
                "plural": false,
                "selections": [
                    {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "edges",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "PracticeSkill",
                        "plural": true,
                        "selections": [
                            (v2 /*: any*/),
                            (v3 /*: any*/),
                            {
                                "kind": "LinkedField",
                                "alias": null,
                                "name": "practiceSkillGroups",
                                "storageKey": null,
                                "args": null,
                                "concreteType": "PracticeSkillGroupPage",
                                "plural": false,
                                "selections": [
                                    {
                                        "kind": "LinkedField",
                                        "alias": null,
                                        "name": "edges",
                                        "storageKey": null,
                                        "args": null,
                                        "concreteType": "PracticeSkillGroup",
                                        "plural": true,
                                        "selections": [
                                            (v2 /*: any*/),
                                            (v3 /*: any*/),
                                            {
                                                "kind": "LinkedField",
                                                "alias": null,
                                                "name": "practiceSubSkills",
                                                "storageKey": null,
                                                "args": null,
                                                "concreteType": "PracticeSubSkillPage",
                                                "plural": false,
                                                "selections": [
                                                    {
                                                        "kind": "LinkedField",
                                                        "alias": null,
                                                        "name": "edges",
                                                        "storageKey": null,
                                                        "args": null,
                                                        "concreteType": "PracticeSubSkill",
                                                        "plural": true,
                                                        "selections": [
                                                            (v2 /*: any*/),
                                                            (v3 /*: any*/)
                                                        ]
                                                    }
                                                ]
                                            }
                                        ]
                                    }
                                ]
                            }
                        ]
                    }
                ]
            }
        ]
    } as any);
    return {
        "kind": "Request",
        "fragment": {
            "kind": "Fragment",
            "name": "DropdownCompetencyQueryRendererQuery",
            "type": "Query",
            "metadata": null,
            "argumentDefinitions": (v0 /*: any*/),
            "selections": [
                {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "node",
                    "storageKey": null,
                    "args": (v1 /*: any*/),
                    "concreteType": null,
                    "plural": false,
                    "selections": [
                        (v4 /*: any*/)
                    ]
                }
            ]
        },
        "operation": {
            "kind": "Operation",
            "name": "DropdownCompetencyQueryRendererQuery",
            "argumentDefinitions": (v0 /*: any*/),
            "selections": [
                {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "node",
                    "storageKey": null,
                    "args": (v1 /*: any*/),
                    "concreteType": null,
                    "plural": false,
                    "selections": [
                        {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "__typename",
                            "args": null,
                            "storageKey": null
                        },
                        (v2 /*: any*/),
                        (v4 /*: any*/)
                    ]
                }
            ]
        },
        "params": {
            "operationKind": "query",
            "name": "DropdownCompetencyQueryRendererQuery",
            "id": null,
            "text": "query DropdownCompetencyQueryRendererQuery(\n  $practiceAreaID: ID!\n) {\n  node(id: $practiceAreaID) {\n    __typename\n    ... on PracticeArea {\n      practiceSkills {\n        edges {\n          id\n          name\n          practiceSkillGroups {\n            edges {\n              id\n              name\n              practiceSubSkills {\n                edges {\n                  id\n                  name\n                }\n              }\n            }\n          }\n        }\n      }\n    }\n    id\n  }\n}\n",
            "metadata": {}
        }
    } as any;
})();
(node as any).hash = 'c51d88c2099c4e65d7868187146215da';
export default node;
