/* tslint:disable */
/* eslint-disable */

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type PracticeSubSkillType = "both" | "sqe" | "tc" | "%future added value";
export type Weekday = "friday" | "monday" | "saturday" | "sunday" | "thursday" | "tuesday" | "wednesday" | "%future added value";
export type Roadmap_candidate = {
    readonly qualificationsForDegree: ReadonlyArray<{
        readonly id: string;
    }> | null;
    readonly qualificationsForSQE1: ReadonlyArray<{
        readonly id: string;
    }> | null;
    readonly qualificationsForSQE2: ReadonlyArray<{
        readonly id: string;
    }> | null;
    readonly qualificationsForGDL: ReadonlyArray<{
        readonly id: string;
    }> | null;
    readonly qualificationsForLPC: ReadonlyArray<{
        readonly id: string;
    }> | null;
    readonly characterSuitabilityCompleted: boolean;
    readonly skillGroupType: string | null;
    readonly skillGroupTypeConfirmed: boolean;
    readonly hiddenQualifications: number;
    readonly roadmapItems: number;
    readonly roadmapCompleted: number;
    readonly placements: {
        readonly edges: ReadonlyArray<{
            readonly id: string;
            readonly name: string;
            readonly startDate: string;
            readonly endDate: string | null;
            readonly approved: boolean;
            readonly partTimeDays: ReadonlyArray<Weekday> | null;
            readonly fullTime: boolean;
            readonly daysMissed: number;
        } | null> | null;
    } | null;
    readonly areaProgress: ReadonlyArray<{
        readonly practiceArea: {
            readonly id: string;
            readonly name: string;
            readonly practiceSkills: {
                readonly edges: ReadonlyArray<{
                    readonly id: string;
                    readonly name: string;
                    readonly practiceSkillGroups: {
                        readonly edges: ReadonlyArray<{
                            readonly id: string;
                            readonly name: string;
                            readonly practiceSubSkills: {
                                readonly edges: ReadonlyArray<{
                                    readonly id: string;
                                    readonly name: string;
                                    readonly practiceSubSkillType: PracticeSubSkillType;
                                    readonly minSuggestedEntryCount: number;
                                    readonly doneEntryCount: number;
                                    readonly order: number;
                                } | null> | null;
                            } | null;
                        } | null> | null;
                    } | null;
                } | null> | null;
            } | null;
        };
    }> | null;
    readonly " $refType": "Roadmap_candidate";
};
export type Roadmap_candidate$data = Roadmap_candidate;
export type Roadmap_candidate$key = {
    readonly " $data"?: Roadmap_candidate$data;
    readonly " $fragmentRefs": FragmentRefs<"Roadmap_candidate">;
};



const node: ReaderFragment = (function () {
    var v0 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "id",
        "args": null,
        "storageKey": null
    } as any), v1 = [
        (v0 /*: any*/)
    ], v2 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "name",
        "args": null,
        "storageKey": null
    } as any);
    return {
        "kind": "Fragment",
        "name": "Roadmap_candidate",
        "type": "Candidate",
        "metadata": null,
        "argumentDefinitions": [],
        "selections": [
            {
                "kind": "LinkedField",
                "alias": "qualificationsForDegree",
                "name": "qualifications",
                "storageKey": "qualifications(qualificationType:\"degree\")",
                "args": [
                    {
                        "kind": "Literal",
                        "name": "qualificationType",
                        "value": "degree"
                    }
                ],
                "concreteType": "Qualification",
                "plural": true,
                "selections": (v1 /*: any*/)
            },
            {
                "kind": "LinkedField",
                "alias": "qualificationsForSQE1",
                "name": "qualifications",
                "storageKey": "qualifications(qualificationType:\"sqe1\")",
                "args": [
                    {
                        "kind": "Literal",
                        "name": "qualificationType",
                        "value": "sqe1"
                    }
                ],
                "concreteType": "Qualification",
                "plural": true,
                "selections": (v1 /*: any*/)
            },
            {
                "kind": "LinkedField",
                "alias": "qualificationsForSQE2",
                "name": "qualifications",
                "storageKey": "qualifications(qualificationType:\"sqe2\")",
                "args": [
                    {
                        "kind": "Literal",
                        "name": "qualificationType",
                        "value": "sqe2"
                    }
                ],
                "concreteType": "Qualification",
                "plural": true,
                "selections": (v1 /*: any*/)
            },
            {
                "kind": "LinkedField",
                "alias": "qualificationsForGDL",
                "name": "qualifications",
                "storageKey": "qualifications(qualificationType:\"gdl\")",
                "args": [
                    {
                        "kind": "Literal",
                        "name": "qualificationType",
                        "value": "gdl"
                    }
                ],
                "concreteType": "Qualification",
                "plural": true,
                "selections": (v1 /*: any*/)
            },
            {
                "kind": "LinkedField",
                "alias": "qualificationsForLPC",
                "name": "qualifications",
                "storageKey": "qualifications(qualificationType:\"lpc\")",
                "args": [
                    {
                        "kind": "Literal",
                        "name": "qualificationType",
                        "value": "lpc"
                    }
                ],
                "concreteType": "Qualification",
                "plural": true,
                "selections": (v1 /*: any*/)
            },
            {
                "kind": "ScalarField",
                "alias": null,
                "name": "characterSuitabilityCompleted",
                "args": null,
                "storageKey": null
            },
            {
                "kind": "ScalarField",
                "alias": null,
                "name": "skillGroupType",
                "args": null,
                "storageKey": null
            },
            {
                "kind": "ScalarField",
                "alias": null,
                "name": "skillGroupTypeConfirmed",
                "args": null,
                "storageKey": null
            },
            {
                "kind": "ScalarField",
                "alias": null,
                "name": "hiddenQualifications",
                "args": null,
                "storageKey": null
            },
            {
                "kind": "ScalarField",
                "alias": null,
                "name": "roadmapItems",
                "args": null,
                "storageKey": null
            },
            {
                "kind": "ScalarField",
                "alias": null,
                "name": "roadmapCompleted",
                "args": null,
                "storageKey": null
            },
            {
                "kind": "LinkedField",
                "alias": null,
                "name": "placements",
                "storageKey": null,
                "args": null,
                "concreteType": "PlacementPage",
                "plural": false,
                "selections": [
                    {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "edges",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "Placement",
                        "plural": true,
                        "selections": [
                            (v0 /*: any*/),
                            (v2 /*: any*/),
                            {
                                "kind": "ScalarField",
                                "alias": null,
                                "name": "startDate",
                                "args": null,
                                "storageKey": null
                            },
                            {
                                "kind": "ScalarField",
                                "alias": null,
                                "name": "endDate",
                                "args": null,
                                "storageKey": null
                            },
                            {
                                "kind": "ScalarField",
                                "alias": null,
                                "name": "approved",
                                "args": null,
                                "storageKey": null
                            },
                            {
                                "kind": "ScalarField",
                                "alias": null,
                                "name": "partTimeDays",
                                "args": null,
                                "storageKey": null
                            },
                            {
                                "kind": "ScalarField",
                                "alias": null,
                                "name": "fullTime",
                                "args": null,
                                "storageKey": null
                            },
                            {
                                "kind": "ScalarField",
                                "alias": null,
                                "name": "daysMissed",
                                "args": null,
                                "storageKey": null
                            }
                        ]
                    }
                ]
            },
            {
                "kind": "LinkedField",
                "alias": null,
                "name": "areaProgress",
                "storageKey": null,
                "args": null,
                "concreteType": "PracticeAreaProgress",
                "plural": true,
                "selections": [
                    {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "practiceArea",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "PracticeArea",
                        "plural": false,
                        "selections": [
                            (v0 /*: any*/),
                            (v2 /*: any*/),
                            {
                                "kind": "LinkedField",
                                "alias": null,
                                "name": "practiceSkills",
                                "storageKey": null,
                                "args": null,
                                "concreteType": "PracticeSkillPage",
                                "plural": false,
                                "selections": [
                                    {
                                        "kind": "LinkedField",
                                        "alias": null,
                                        "name": "edges",
                                        "storageKey": null,
                                        "args": null,
                                        "concreteType": "PracticeSkill",
                                        "plural": true,
                                        "selections": [
                                            (v0 /*: any*/),
                                            (v2 /*: any*/),
                                            {
                                                "kind": "LinkedField",
                                                "alias": null,
                                                "name": "practiceSkillGroups",
                                                "storageKey": null,
                                                "args": null,
                                                "concreteType": "PracticeSkillGroupPage",
                                                "plural": false,
                                                "selections": [
                                                    {
                                                        "kind": "LinkedField",
                                                        "alias": null,
                                                        "name": "edges",
                                                        "storageKey": null,
                                                        "args": null,
                                                        "concreteType": "PracticeSkillGroup",
                                                        "plural": true,
                                                        "selections": [
                                                            (v0 /*: any*/),
                                                            (v2 /*: any*/),
                                                            {
                                                                "kind": "LinkedField",
                                                                "alias": null,
                                                                "name": "practiceSubSkills",
                                                                "storageKey": null,
                                                                "args": null,
                                                                "concreteType": "PracticeSubSkillPage",
                                                                "plural": false,
                                                                "selections": [
                                                                    {
                                                                        "kind": "LinkedField",
                                                                        "alias": null,
                                                                        "name": "edges",
                                                                        "storageKey": null,
                                                                        "args": null,
                                                                        "concreteType": "PracticeSubSkill",
                                                                        "plural": true,
                                                                        "selections": [
                                                                            (v0 /*: any*/),
                                                                            (v2 /*: any*/),
                                                                            {
                                                                                "kind": "ScalarField",
                                                                                "alias": null,
                                                                                "name": "practiceSubSkillType",
                                                                                "args": null,
                                                                                "storageKey": null
                                                                            },
                                                                            {
                                                                                "kind": "ScalarField",
                                                                                "alias": null,
                                                                                "name": "minSuggestedEntryCount",
                                                                                "args": null,
                                                                                "storageKey": null
                                                                            },
                                                                            {
                                                                                "kind": "ScalarField",
                                                                                "alias": null,
                                                                                "name": "doneEntryCount",
                                                                                "args": null,
                                                                                "storageKey": null
                                                                            },
                                                                            {
                                                                                "kind": "ScalarField",
                                                                                "alias": null,
                                                                                "name": "order",
                                                                                "args": null,
                                                                                "storageKey": null
                                                                            }
                                                                        ]
                                                                    }
                                                                ]
                                                            }
                                                        ]
                                                    }
                                                ]
                                            }
                                        ]
                                    }
                                ]
                            }
                        ]
                    }
                ]
            }
        ]
    } as any;
})();
(node as any).hash = 'ba8bf09c53106184262865c69b5f76ba';
export default node;
