import Spacer from 'components/core/Spacers/Spacer';
import Table from 'components/core/Table';
import Text from 'components/core/Table/Text';
import Page from 'components/Page';
import PageTitle from 'components/PageTitle';
import Button from 'components/core/Input/ButtonBase';
import React, { useRef } from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import { Mentoring_candidate } from './__generated__/Mentoring_candidate.graphql';
import { useSideModalDispatch } from '../../views/SideModalProvider';
import styled from 'styled-components';
import theme from 'helpers/theme';

const SubTitle = styled.div`
  font-weight: 300;
  font-size: 14px;
  color: ${theme.colors.textGrey};
  max-width: 500px;
  margin-top: 15px;
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
`;

type Props = {
  candidate: Mentoring_candidate;
};

function Mentoring({ candidate }: Props) {
  const dispatch = useSideModalDispatch();

  const inputRef = useRef<any>();
  const handleAttach = () => {
    if (inputRef.current) {
      inputRef.current.click();
    }
  };

  const columns = [
    {
      headerName: 'MENTOR',
      component: (item: Mentoring_candidate['mentor']) => (
        <Text text={`${item?.firstName} ${item?.lastName}`} />
      )
    },
    {
      headerName: 'EMAIL',
      field: 'email'
    },
    {
      headerName: '',
      component: (item: Mentoring_candidate['mentor']) => (
        <ButtonContainer>
          <Button
            onClick={() => {
              dispatch({
                modal: 'sendmeetingrequest',
                mentorID: item?.id || '',
                meetingRequestType: 'monthly'
              });
            }}
            style={{ /*height: 30, */ minWidth: '190px', marginRight: '15px' }}
          >
            Book an Appointment
          </Button>
          <Button
            onClick={() => {
              handleAttach();
            }}
            style={{ /*height: 30, */ minWidth: '190px' }}
          >
            Contact
            <a
              ref={inputRef}
              className={`display: none`}
              href={'mailto:' + item?.email}
            />
          </Button>
        </ButtonContainer>
      )
    }
  ];

  return (
    <Page>
      <PageTitle title="Mentoring" />
      <SubTitle>
        {candidate.firstName}, here you can book your monthly meeting with your
        mentor.
      </SubTitle>
      <Spacer vertical spacing={3} />
      <Table
        columns={columns}
        rows={candidate.mentor ? [candidate.mentor] : []}
      />
    </Page>
  );
}

export default createFragmentContainer(Mentoring, {
  candidate: graphql`
    fragment Mentoring_candidate on Candidate {
      firstName
      mentor {
        id
        firstName
        lastName
        email
      }
    }
  `
});
