import React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import Spacer from 'components/core/Spacers/Spacer';
import { SocialEvents_socialEvents } from './__generated__/SocialEvents_socialEvents.graphql';
import EventCard from 'components/EventCard';
import { Grid } from '@material-ui/core';
import Paginator, { Page as _Page } from 'components/Pagination/Paginator';
import { createUseStyles, useTheme } from 'react-jss';
import { Theme } from 'helpers/theme';
import Page from 'components/Page';
import { useRouter } from 'found';
import styled from 'styled-components';
import theme from 'helpers/theme';
import PageTitle from 'components/PageTitle';

const useStyles = createUseStyles((theme: Theme) => ({
  pagination: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  }
}));

const NoneFoundMessage = styled.div`
  color: ${theme.colors.textGrey};
  text-align: center;
  font-size: 20px;
`;

type Props = {
  socialEvents: SocialEvents_socialEvents;
};

function SocialEvents({ socialEvents }: Props) {
  const theme = useTheme();
  const classes = useStyles({ theme });

  const { router, match } = useRouter();

  const pageInfo = {
    total: socialEvents.pageInfo?.total ?? 0,
    limit: socialEvents.pageInfo?.limit ?? 10,
    offset: socialEvents.pageInfo?.offset ?? 0
  };
  const page = {
    currentPage: Math.ceil(pageInfo.offset / pageInfo.limit) + 1,
    totalPages: Math.ceil(pageInfo.total / pageInfo.limit)
  };

  const onUpdatePage = ({ offset, limit }: _Page) => {
    if (offset <= 0) {
      offset = 1;
    }

    router.push(
      `/app/socialEvents?offset=${(offset - 1) * limit}&limit=${limit}`
    );
  };

  return (
    <Page>
      <PageTitle title="Events" />
      <Spacer vertical spacing={3} />
      {socialEvents &&
        socialEvents.edges &&
        socialEvents.edges.length === 0 && (
          <NoneFoundMessage>No social events found.</NoneFoundMessage>
        )}
      <Grid container>
        {socialEvents.edges?.map(
          (socialEvent) =>
            socialEvent && (
              <EventCard
                event={{
                  ...socialEvent
                }}
                onClick={() => {
                  router.push(`/app/socialEvents/${socialEvent.id}`);
                }}
              />
            )
        )}
      </Grid>
      {socialEvents && socialEvents.edges && socialEvents.edges.length > 0 && (
        <>
          <div className={classes.pagination}>
            <Paginator
              currentPage={page.currentPage}
              updatePage={onUpdatePage}
              numPages={page.totalPages}
              itemsPerPage={pageInfo.limit}
              showRange={page.totalPages} //{page.totalPages > 4 ? 4 : page.totalPages}
            />
          </div>
          <Spacer vertical spacing={3} />
        </>
      )}
    </Page>
  );
}

export default createFragmentContainer(SocialEvents, {
  socialEvents: graphql`
    fragment SocialEvents_socialEvents on SocialEventPage {
      edges {
        id
        ...EventCard_event
      }
      pageInfo {
        total
        offset
        limit
        given
      }
    }
  `
});
