/* tslint:disable */
/* eslint-disable */

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type CourseStatus = "complete" | "failed" | "incomplete" | "%future added value";
export type MyCourses_user = {
    readonly firstName: string;
    readonly myCourses: ReadonlyArray<{
        readonly status: CourseStatus;
        readonly certificateURL: string | null;
        readonly completedAt: string | null;
        readonly course: {
            readonly " $fragmentRefs": FragmentRefs<"CourseCard_course">;
        } | null;
    }> | null;
    readonly myClassroomCourses: ReadonlyArray<{
        readonly status: CourseStatus;
        readonly certificateURL: string | null;
        readonly completedAt: string | null;
        readonly course: {
            readonly " $fragmentRefs": FragmentRefs<"CourseCard_course">;
        } | null;
    }> | null;
    readonly " $refType": "MyCourses_user";
};
export type MyCourses_user$data = MyCourses_user;
export type MyCourses_user$key = {
    readonly " $data"?: MyCourses_user$data;
    readonly " $fragmentRefs": FragmentRefs<"MyCourses_user">;
};



const node: ReaderFragment = (function () {
    var v0 = [
        ({
            "kind": "ScalarField",
            "alias": null,
            "name": "status",
            "args": null,
            "storageKey": null
        } as any),
        ({
            "kind": "ScalarField",
            "alias": null,
            "name": "certificateURL",
            "args": null,
            "storageKey": null
        } as any),
        ({
            "kind": "ScalarField",
            "alias": null,
            "name": "completedAt",
            "args": null,
            "storageKey": null
        } as any),
        ({
            "kind": "LinkedField",
            "alias": null,
            "name": "course",
            "storageKey": null,
            "args": null,
            "concreteType": null,
            "plural": false,
            "selections": [
                {
                    "kind": "FragmentSpread",
                    "name": "CourseCard_course",
                    "args": null
                }
            ]
        } as any)
    ];
    return {
        "kind": "Fragment",
        "name": "MyCourses_user",
        "type": "User",
        "metadata": null,
        "argumentDefinitions": [],
        "selections": [
            {
                "kind": "ScalarField",
                "alias": null,
                "name": "firstName",
                "args": null,
                "storageKey": null
            },
            {
                "kind": "LinkedField",
                "alias": null,
                "name": "myCourses",
                "storageKey": null,
                "args": null,
                "concreteType": "MyCourse",
                "plural": true,
                "selections": (v0 /*: any*/)
            },
            {
                "kind": "LinkedField",
                "alias": null,
                "name": "myClassroomCourses",
                "storageKey": null,
                "args": null,
                "concreteType": "MyCourse",
                "plural": true,
                "selections": (v0 /*: any*/)
            }
        ]
    } as any;
})();
(node as any).hash = '0122592c7e36c8dd8e77b6e228c466e5';
export default node;
