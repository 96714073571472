import * as React from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import Spacer from '../../Spacers/Spacer';
import { DropdownOption } from '../Dropdown';
import Tooltip from 'components/core/Tooltip';

const Container = styled.div`
  position: relative;
  outline: none;
  width: 100%;
`;
const CurrentOption = styled.div`
  background: white;
  padding: 13px 15px;
  display: flex;
  flex-direction: row;
  font-weight: 300;
  border-radius: 4px;
  font-size: 13px;
  border: 1px solid #e9ebeb;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
`;

const Placeholder = styled.div``;

const Dropdown = styled.div`
  padding: 15px;
  background: white;
  position: absolute;
  width: 100%;
  max-height: 500px;
  overflow-y: scroll;
  top: 50px;
  border-radius: 6px;
  border: 1px solid #e9ebeb;
  box-shadow: 0px 4px 9px #0000000f;
  z-index: 10;
`;

const CategoryItem = styled.div``;

const Header = styled.div`
  font-weight: 700;
  font-size: 14px;
`;

const DropdownItem = styled.div<{ selected?: boolean; }>`
  display: flex;
  padding: 7px 1px;
  align-items: center;
  border-radius: 6px;
  cursor: pointer;
  &:hover {
    background: #f8f9fb;
  }
  background: ${(props) => props.selected ? '#EEF3FF': 'white' };
`;

const IconArea = styled.div`
`

const SelectorContainer = styled.div`
  display: flex;
  width: 20px;
`;

const Selector = styled.div`
  border-radius: 100%;
  border: 1px solid gainsboro;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 20px;
  width: 20px;
`;

const OptionTextContainer = styled.div`
  display: flex;
  margin-left: 10px;
`;

const OptionText = styled.div<{ color?: string }>`
  font-size: 13px;
  font-weight: 700;
  color: ${(props) => props.color};
`;

const DropdownNothingToShow = styled.div`
  padding: 15px;
  background: white;
  position: absolute;
  width: 100%;
  top: 50px;
  border-radius: 6px;
  border: 1px solid #e9ebeb;
  box-shadow: 0px 4px 9px #0000000f;
  z-index: 10;
`;

const NothingToShow = styled.div`
  display: flex;
  align-items: center;
  font-size: 13px;
`;

const Tick = styled.img`
  width: 10px;
`;

export type Option = {
  name: string;
  value: string;
  color?: string;
};

export type Category = {
  name: string;
  options: Option[];
};

type Props = {
  options: DropdownOption[];
  placeholder: string;
  onChange: (selected: DropdownOption[]) => void;
  selected: DropdownOption[]; // list of values
  ticks?: boolean;
  className?: string;
  hint?: string;
  boxStyle?: string;
};

function FancyDropdown({
  options,
  onChange, 
  selected, 
  placeholder,
  ticks, 
  className,
  hint,
  boxStyle
 }: Props) {
  const [isOpen, setOpen] = React.useState<boolean>(false);

  return (
    <Container
      tabIndex={-1}
      className={className}
    >
      <CurrentOption onClick={() => isOpen ? setOpen(false) : setOpen(true)} className={boxStyle}>
        <Placeholder>{placeholder}</Placeholder>
        <IconArea>
          {hint && (
            <Tooltip text={hint} element={<FontAwesomeIcon icon={faInfoCircle} />} />
          )}
          <FontAwesomeIcon icon={faChevronDown} color={'#CFD1D1'} />
        </IconArea>
      </CurrentOption>
      {isOpen && (
        <>
          {options.length == 0 ? (
            <DropdownNothingToShow>
              <NothingToShow>Nothing to show</NothingToShow>{' '}
            </DropdownNothingToShow>
          ) : (
            <Dropdown>
              {options.map((option) => (
                <DropdownItem
                  onClick={() => {
                    if (selected.find(op => op.id === option.id)) {
                      onChange([
                        ...selected.filter((item) => item.id !== option.id)
                      ]);
                      return;
                    }

                    onChange([...selected, option]);
                  }}
                  selected={selected.find(op => op.id === option.id) !== undefined}
                >
                  {ticks && (
                    <SelectorContainer>
                      <Selector>
                        {selected.find((item) => item.id === option.id) && (
                          <Tick
                            src={require('../../../../assets/orangeTick.svg')}
                          />
                        )}
                      </Selector>
                    </SelectorContainer>
                  )}
                  <OptionTextContainer>
                    <OptionText>
                      {option.title}
                    </OptionText>
                  </OptionTextContainer>
                </DropdownItem>
              ))}
            </Dropdown>
          )}
        </>
      )}
    </Container>
  );
}

export default FancyDropdown;
