import React from 'react';
import { makeStyles, Tooltip as MuiTooltip } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  tooltip: {
    background: 'white',
    color: 'black',
    letterSpacing: 'normal',
    fontSize: 13,
    padding: '5px 10px'
  },
  contentContainer: {
    padding: '0 15px'
  }
}));


type TooltipPlacement = "top" | "bottom-end" | "bottom-start" | "bottom" | "left-end" | "left-start" | "left" | "right-end" | "right-start" | "right" | "top-end" | "top-start";

type Props = {
  text: string;
  element: React.ReactElement;
  position?: TooltipPlacement;
};

function Tooltip({
  text,
  element,
  position="left"
}: Props){
  const classes = useStyles();

  return (
    <MuiTooltip 
      title={text}
      placement={position}
      classes={{
        tooltip: classes.tooltip
      }}
    >
      <span className={classes.contentContainer}>
        {element}
      </span>
    </MuiTooltip>
  );
}

export default Tooltip;