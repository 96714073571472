import CourseCard from 'components/CourseCard';
import Button from 'components/core/Input/ButtonBase';
import Spacer from 'components/core/Spacers/Spacer';
import Page from 'components/Page';
import PageTitle from 'components/PageTitle';
import { useRouter } from 'found';
import React from 'react';
import { createFragmentContainer } from 'react-relay';
import { graphql } from 'relay-runtime';
import styled from 'styled-components';
import { AllCourses_courses } from './__generated__/AllCourses_courses.graphql';
import theme from 'helpers/theme';

const Container = styled.div``;

const SubTitle = styled.div`
  font-weight: 300;
  font-size: 14px;
  color: ${theme.colors.textGrey};
  max-width: 500px;
  margin-top: 15px;
`;

const CourseCards = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, 300px);
  grid-gap: 23px;
  justify-content: center;
`;

const ShowMoreSection = styled.div`
  display: flex;
  justify-content: center;
`;

type Props = {
  courses: AllCourses_courses;
};

export const PAGE_SIZE = 8;

function AllCourses({ courses }: Props) {
  const { router, match } = useRouter();
  console.log('match', match);

  const hasMore =
    (courses?.pageInfo?.given ?? false) != (courses?.pageInfo?.total ?? false);
  return (
    <Page>
      <Container>
        <PageTitle title="Courses" />
        <SubTitle>
          Choose from a variety of courses and exercises to further your
          technical knowledge and practice skills.
        </SubTitle>
        <Spacer vertical spacing={3} />
        <CourseCards>
          {courses?.edges?.map(
            (course) => course && <CourseCard course={course} />
          )}
        </CourseCards>
        <Spacer vertical spacing={3} />
        {hasMore && (
          <ShowMoreSection>
            <Button
              archetype="default"
              onClick={() => {
                console.log('IN', match.location.query['limit']);
                if (match.location.query['limit']) {
                  const newSize =
                    parseInt(match.location.query['limit'], 10) + PAGE_SIZE;
                  console.log('NEW', newSize);
                  router.push(`/app/all-courses?limit=${newSize}`);
                  return;
                }
                router.push('/app/all-courses?limit=' + PAGE_SIZE * 2);
              }}
            >
              Show more
            </Button>
          </ShowMoreSection>
        )}

        <Spacer vertical spacing={3} />
      </Container>
    </Page>
  );
}

export default createFragmentContainer(AllCourses, {
  courses: graphql`
    fragment AllCourses_courses on CoursePage {
      edges {
        ...CourseCard_course
      }
      pageInfo {
        given
        total
      }
    }
  `
});
