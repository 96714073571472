/* tslint:disable */
/* eslint-disable */

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type ContentiousType = "both" | "contentious" | "non_contentious" | "%future added value";
export type EntryUpdate_node = {
    readonly id: string;
    readonly title: string;
    readonly startDate: string;
    readonly endDate: string;
    readonly placement: {
        readonly id: string;
        readonly name: string;
    } | null;
    readonly practiceSubSkills: ReadonlyArray<{
        readonly practiceSkillGroup: {
            readonly practiceSkill: {
                readonly practiceArea: {
                    readonly id: string;
                    readonly name: string;
                } | null;
                readonly id: string;
                readonly name: string;
            } | null;
            readonly name: string;
        } | null;
        readonly id: string;
        readonly name: string;
    }> | null;
    readonly experience: string;
    readonly evidence: string;
    readonly learnt: string;
    readonly moreExperience: string;
    readonly needMoreExperience: string;
    readonly contentious: ContentiousType;
    readonly fileUrl: string | null;
    readonly practiceAreas: ReadonlyArray<number> | null;
    readonly taskedBy: string | null;
    readonly " $refType": "EntryUpdate_node";
};
export type EntryUpdate_node$data = EntryUpdate_node;
export type EntryUpdate_node$key = {
    readonly " $data"?: EntryUpdate_node$data;
    readonly " $fragmentRefs": FragmentRefs<"EntryUpdate_node">;
};



const node: ReaderFragment = (function () {
    var v0 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "id",
        "args": null,
        "storageKey": null
    } as any), v1 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "name",
        "args": null,
        "storageKey": null
    } as any), v2 = [
        (v0 /*: any*/),
        (v1 /*: any*/)
    ];
    return {
        "kind": "Fragment",
        "name": "EntryUpdate_node",
        "type": "QWEEntry",
        "metadata": null,
        "argumentDefinitions": [],
        "selections": [
            (v0 /*: any*/),
            {
                "kind": "ScalarField",
                "alias": null,
                "name": "title",
                "args": null,
                "storageKey": null
            },
            {
                "kind": "ScalarField",
                "alias": null,
                "name": "startDate",
                "args": null,
                "storageKey": null
            },
            {
                "kind": "ScalarField",
                "alias": null,
                "name": "endDate",
                "args": null,
                "storageKey": null
            },
            {
                "kind": "LinkedField",
                "alias": null,
                "name": "placement",
                "storageKey": null,
                "args": null,
                "concreteType": "Placement",
                "plural": false,
                "selections": (v2 /*: any*/)
            },
            {
                "kind": "LinkedField",
                "alias": null,
                "name": "practiceSubSkills",
                "storageKey": null,
                "args": null,
                "concreteType": "PracticeSubSkill",
                "plural": true,
                "selections": [
                    {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "practiceSkillGroup",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "PracticeSkillGroup",
                        "plural": false,
                        "selections": [
                            {
                                "kind": "LinkedField",
                                "alias": null,
                                "name": "practiceSkill",
                                "storageKey": null,
                                "args": null,
                                "concreteType": "PracticeSkill",
                                "plural": false,
                                "selections": [
                                    {
                                        "kind": "LinkedField",
                                        "alias": null,
                                        "name": "practiceArea",
                                        "storageKey": null,
                                        "args": null,
                                        "concreteType": "PracticeArea",
                                        "plural": false,
                                        "selections": (v2 /*: any*/)
                                    },
                                    (v0 /*: any*/),
                                    (v1 /*: any*/)
                                ]
                            },
                            (v1 /*: any*/)
                        ]
                    },
                    (v0 /*: any*/),
                    (v1 /*: any*/)
                ]
            },
            {
                "kind": "ScalarField",
                "alias": null,
                "name": "experience",
                "args": null,
                "storageKey": null
            },
            {
                "kind": "ScalarField",
                "alias": null,
                "name": "evidence",
                "args": null,
                "storageKey": null
            },
            {
                "kind": "ScalarField",
                "alias": null,
                "name": "learnt",
                "args": null,
                "storageKey": null
            },
            {
                "kind": "ScalarField",
                "alias": null,
                "name": "moreExperience",
                "args": null,
                "storageKey": null
            },
            {
                "kind": "ScalarField",
                "alias": null,
                "name": "needMoreExperience",
                "args": null,
                "storageKey": null
            },
            {
                "kind": "ScalarField",
                "alias": null,
                "name": "contentious",
                "args": null,
                "storageKey": null
            },
            {
                "kind": "ScalarField",
                "alias": null,
                "name": "fileUrl",
                "args": null,
                "storageKey": null
            },
            {
                "kind": "ScalarField",
                "alias": null,
                "name": "practiceAreas",
                "args": null,
                "storageKey": null
            },
            {
                "kind": "ScalarField",
                "alias": null,
                "name": "taskedBy",
                "args": null,
                "storageKey": null
            }
        ]
    } as any;
})();
(node as any).hash = 'b5b31bb3d8c319cf3dd2cdfcd0167fad';
export default node;
