import environment from 'api/environment';
import React from 'react';
import { graphql, QueryRenderer } from 'react-relay';
import { EntriesDateRange_QueryResponse } from './__generated__/EntriesDateRange_Query.graphql';
import styled from 'styled-components';

const Title = styled.h1`
  color: #f17f2c;
  font-size: 16px;
  font-weight: 700;
`;

const Names = styled.div`
  display: -webkit-box;
  -webkit-line-clamp: 15;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: 400;
  font-size: 13px;
`;

type Props = {
  placementID: string;
  startDate: string;
  endDate: string;
  setEntries: (sel: any[]) => void;
};

function EntriesDateRange({
  placementID,
  startDate,
  endDate,
  setEntries
}: Props) {
  return (
    <QueryRenderer
      environment={environment}
      query={graphql`
        query EntriesDateRange_Query(
          $placementID: ID!
          $from: Time
          $to: Time
        ) {
          node(id: $placementID) {
            ... on Placement {
              entries(filter: { from: $from, to: $to }) {
                edges {
                  id
                  title
                  startDate
                }
                pageInfo {
                  total
                  given
                }
              }
            }
          }
        }
      `}
      variables={{
        placementID,
        from: startDate,
        to: endDate
      }}
      render={({ error, props }) => {
        if (!props) {
          return <div></div>;
        }

        const { node } = props as EntriesDateRange_QueryResponse;
        const entries = node?.entries?.edges?.map((item) => ({
          name: item?.title ?? '',
          value: item?.id ?? '',
          startDate: item?.startDate?? new Date()
        })).sort((a, b) => (a?.startDate && b?.startDate) ? (new Date(a.startDate).getTime() - new Date(b.startDate).getTime()) : 0) || [];
        const length = entries.length;
        setEntries(entries);

        return length > 0 ? (
          <>
            <Title>
              {length} {length === 1 ? 'entry' : 'entries'} found between these
              dates
            </Title>
            <Names>
              <ul>
                {node?.entries?.edges?.slice() // Make a shallow copy of the array to avoid modifying the original one
                  .sort((a, b) => (a?.startDate && b?.startDate) ? (new Date(a.startDate).getTime() - new Date(b.startDate).getTime()) : 0)
                  .map((item) => (
                  <li key={item?.id}>
                    <div>
                      <span>{item?.title}</span>
                    </div>
                  </li>
                ))}
              </ul>
            </Names>
          </>
        ) : (
          <Title>No entry found between these dates</Title>
        );
      }}
    />
  );
}

export default EntriesDateRange;
