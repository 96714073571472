import React from 'react';

import LoginDialogue from 'components/LoginDialogue';
import { Theme } from 'helpers/theme';
import environment from 'api/environment';
import { createUseStyles, useTheme } from 'react-jss';
import { commitMutation, graphql, createFragmentContainer } from 'react-relay';
import { GraphError } from 'types/general';
import { Router, useRouter } from 'found';
import { LoginPage_user } from './__generated__/LoginPage_user.graphql';

type Props = {
  user?: LoginPage_user;
};

const useStyles = createUseStyles((theme: Theme) => ({
  root: {
    background: theme.colors.backgroundGrey,
    width: '100%',
    height: '100%',
    position: 'absolute',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  }
}));

const mutation = graphql`
  mutation LoginPage_LoginMutation($email: String!, $password: String!) {
    candidateLogin(input: { email: $email, password: $password }) {
      token
    }
  }
`;
var loading = false
const AttemptLogin = (router: Router) => (
  email: string,
  password: string,
  errorCallback: (err: string) => void
) => {
  if (loading) {
    return
  }
  loading = true
  const variables = {
    email,
    password
  };

  commitMutation(environment, {
    mutation,
    variables,
    onCompleted: (
      response: { candidateLogin: { token: string } },
      errors: GraphError[]
    ) => {
      loading = false
      if (errors) {
        // Display error
        errorCallback(`${errors[0]?.extensions?.message}`);
        return;
      }
     // console.log(response)
      window.location.href = '/app';
    },
    onError: (err) => {
      loading = false
      errorCallback(`${err}`)
    }
  });
};

const LoginPage = ({ user }: Props) => {
  const theme = useTheme();
  const classes = useStyles({ theme });
  const { router, match } = useRouter();
  const { pathname } = match.location;

  if (
    (pathname === '/' || pathname === '/login') &&
    user &&
    user?.type === 'candidate'
  ) {
    match.router.push('/app');
  }
  return (
    <>
      {/* <RedirectRequest/> */}
      <div className={classes.root}>
        <LoginDialogue onSubmit={AttemptLogin(router)} />
      </div>
    </>
  );
};

export default createFragmentContainer(LoginPage, {
  user: graphql`
    fragment LoginPage_user on User {
      type
      firstName
      lastName
    }
  `
});
