import * as React from 'react';
import Card from '../../components/core/Cards/Card';
import FancyInput from './FancyInput';
import FancyButton from './FancyButton';
import { createUseStyles, useTheme } from 'react-jss';
import { Theme } from 'helpers/theme';
import { ReactComponent as Logo } from '../../assets/logo/accutrainee-logo.svg';

const useStyles = createUseStyles((theme: Theme) => ({
  loginDialogueRoot: {},
  root: {
    display: 'flex',
    width: 378,
    flexDirection: 'column',
    background: 'white'
  },
  logoContainer: {
    padding: [30, 0, 20]
  },
  logo: {
    height: 70
  },
  heading: {
    fontSize: 25,
    fontWeight: 800,
    color: theme.colors.primaryBlack
  },
  subheading: {
    color: theme.colors.textGrey,
    fontWeight: 300,
    fontSize: 15,
    marginTop: 0,
    marginBottom: theme.spacing(2)
  },
  link: {
    margin: '22px 0 19px 0',
    textAlign: 'center',
    color: theme.colors.textOrange,
    fontSize: theme.fontSizes.small
  },
  errMessage: {
    color: theme.colors.secondaryDanger,
    fontWeight: 200,
    fontSize: 15,
    textAlign: 'center',
    margin: 3
  },
  border: {
    borderRadius: '5px',
    border: '1px solid rgba(8, 8, 8, 0.08)'
  }
}));

type Props = {
  email?: string;
  title: string;
  onSubmit: (
    password: string,
    passwordRepeat: string,
    errorCallback: (err: string) => void,
    firstName?: string,
    lastName?: string,
  ) => void;
};

function FinaliseDialogue({ email, title, onSubmit }: Props) {
  const theme = useTheme();
  const classes = useStyles({ theme });

  const [password, setPassword] = React.useState('');
  const [passwordRepeat, setPasswordRepeat] = React.useState('');
  const [firstName, setFirstName] = React.useState('');
  const [lastName, setLastName] = React.useState('');
  const [error, setError] = React.useState('');
  const onLogin = () => {
    onSubmit(password, passwordRepeat, (err) => {
      setError(err);
    }, firstName, lastName);
  };
  return (
    <div className={classes.loginDialogueRoot}>
      <Card padding="medium" className={classes.root}>
        <div className={classes.logoContainer}>
          <Logo className={classes.logo} />
        </div>
        <h1 className={classes.heading}>{title}</h1>
        <p className={classes.subheading}>Please enter your new password</p>
        <form
          onSubmit={(evt) => {
            evt.preventDefault();
            onLogin();
          }}
        >
          <FancyInput
            label={'Email'}
            labelColor={'#f37a21'}
            type={'text'}
            placeholder={email}
            labelClassName={classes.border}
            disabled
          />
          <FancyInput
            label="First Name"
            labelColor={'#f37a21'}
            type={'text'}
            onChange={setFirstName}
          />
          <FancyInput
            label="Last Name"
            labelColor={'#f37a21'}
            type={'text'}
            onChange={setLastName}
            autocomplete={"off"}
          />
          <FancyInput
            label="New Password"
            labelColor={'#f37a21'}
            type={'text'}
            onChange={setPassword}
            autocomplete={"off"}
          />
          <FancyInput
            label="New Password Repeated"
            labelColor={'#f37a21'}
            type={'text'}
            onChange={setPasswordRepeat}
            autocomplete={"off"}
          />
          <p className={classes.errMessage}>{error}</p>
          <FancyButton text="Save and Login" onClick={onLogin} />
        </form>
      </Card>
    </div>
  );
}

export default FinaliseDialogue;
