import * as React from 'react';
import { createUseStyles, useTheme } from 'react-jss';
import { Theme } from 'helpers/theme';
import GenericPopup from 'components/CoursePopups/GenericPopup';
import Button from 'components/core/Input/ButtonBase';
import Spacer from 'components/core/Spacers/Spacer';
import { useMediaQuery } from 'react-responsive';


const useStyles = createUseStyles((theme: Theme) => ({
  deleteRoot: {
    padding: '20px 30px'
  },
  body: {
    marginTop: theme.spacing(2),
    fontWeight: 600,
    fontSize: 30,
    padding: "30px 0px",
    color: theme.colors.secondaryBlack,
    paddingBottom: "50px"
  },
  buttons: {
    display: 'flex',
    width: '100%',
    justifyContent: 'center',
    alignSelf: 'flex-end',
    marginTop: theme.spacing(3),
    alignItems: 'center'
  },
  button: {
    with: 200
  }
}));

type Props = {
  open: boolean;
  name: string;
  onClose: () => void; 
  text?: string;
};

function AccessConnectPopup({ open, name, onClose, text }: Props) {
  const theme = useTheme();
  const classes = useStyles({ theme });

  if (!open) {
    return null;
  }
  return (
    <GenericPopup
      open={open}
      onClose={onClose}
      title={`${name}`}
      className={classes.deleteRoot}
    >
      <div className={classes.body}>
        {text }
      </div>
      
    </GenericPopup>
  );
}

export default AccessConnectPopup;
