/* tslint:disable */
/* eslint-disable */
/* @relayHash 078e576ca81fae9b761c06d6a3c67cd0 */

import { ConcreteRequest } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type App_Login_QueryVariables = {};
export type App_Login_QueryResponse = {
    readonly user: {
        readonly " $fragmentRefs": FragmentRefs<"LoginPage_user">;
    } | null;
};
export type App_Login_Query = {
    readonly response: App_Login_QueryResponse;
    readonly variables: App_Login_QueryVariables;
};



/*
query App_Login_Query {
  user {
    ...LoginPage_user
  }
}

fragment LoginPage_user on User {
  type
  firstName
  lastName
}
*/

const node: ConcreteRequest = ({
    "kind": "Request",
    "fragment": {
        "kind": "Fragment",
        "name": "App_Login_Query",
        "type": "Query",
        "metadata": null,
        "argumentDefinitions": [],
        "selections": [
            {
                "kind": "LinkedField",
                "alias": null,
                "name": "user",
                "storageKey": null,
                "args": null,
                "concreteType": "User",
                "plural": false,
                "selections": [
                    {
                        "kind": "FragmentSpread",
                        "name": "LoginPage_user",
                        "args": null
                    }
                ]
            }
        ]
    },
    "operation": {
        "kind": "Operation",
        "name": "App_Login_Query",
        "argumentDefinitions": [],
        "selections": [
            {
                "kind": "LinkedField",
                "alias": null,
                "name": "user",
                "storageKey": null,
                "args": null,
                "concreteType": "User",
                "plural": false,
                "selections": [
                    {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "type",
                        "args": null,
                        "storageKey": null
                    },
                    {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "firstName",
                        "args": null,
                        "storageKey": null
                    },
                    {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "lastName",
                        "args": null,
                        "storageKey": null
                    }
                ]
            }
        ]
    },
    "params": {
        "operationKind": "query",
        "name": "App_Login_Query",
        "id": null,
        "text": "query App_Login_Query {\n  user {\n    ...LoginPage_user\n  }\n}\n\nfragment LoginPage_user on User {\n  type\n  firstName\n  lastName\n}\n",
        "metadata": {}
    }
} as any);
(node as any).hash = '584cbd84ceeb5917996c06a23ee6b39b';
export default node;
