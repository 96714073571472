/* tslint:disable */
/* eslint-disable */

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type QualificationType = "a_level" | "degree" | "gdl" | "lpc" | "other" | "practicing_cert" | "qualification_cert" | "sqe1" | "sqe2" | "%future added value";
export type Qualifications_candidate = {
    readonly skillGroupType: string | null;
    readonly hiddenQualifications: number;
    readonly qualifications: ReadonlyArray<{
        readonly id: string;
        readonly name: string;
        readonly fileUrl: string;
        readonly startDate: string | null;
        readonly endDate: string | null;
        readonly grade: string | null;
        readonly qualificationType: QualificationType;
    }> | null;
    readonly " $refType": "Qualifications_candidate";
};
export type Qualifications_candidate$data = Qualifications_candidate;
export type Qualifications_candidate$key = {
    readonly " $data"?: Qualifications_candidate$data;
    readonly " $fragmentRefs": FragmentRefs<"Qualifications_candidate">;
};



const node: ReaderFragment = ({
    "kind": "Fragment",
    "name": "Qualifications_candidate",
    "type": "Candidate",
    "metadata": null,
    "argumentDefinitions": [
        {
            "kind": "LocalArgument",
            "name": "qualType",
            "type": "QualificationType!",
            "defaultValue": null
        }
    ],
    "selections": [
        {
            "kind": "ScalarField",
            "alias": null,
            "name": "skillGroupType",
            "args": null,
            "storageKey": null
        },
        {
            "kind": "ScalarField",
            "alias": null,
            "name": "hiddenQualifications",
            "args": null,
            "storageKey": null
        },
        {
            "kind": "LinkedField",
            "alias": null,
            "name": "qualifications",
            "storageKey": null,
            "args": [
                {
                    "kind": "Variable",
                    "name": "qualificationType",
                    "variableName": "qualType"
                }
            ],
            "concreteType": "Qualification",
            "plural": true,
            "selections": [
                {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "id",
                    "args": null,
                    "storageKey": null
                },
                {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "name",
                    "args": null,
                    "storageKey": null
                },
                {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "fileUrl",
                    "args": null,
                    "storageKey": null
                },
                {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "startDate",
                    "args": null,
                    "storageKey": null
                },
                {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "endDate",
                    "args": null,
                    "storageKey": null
                },
                {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "grade",
                    "args": null,
                    "storageKey": null
                },
                {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "qualificationType",
                    "args": null,
                    "storageKey": null
                }
            ]
        }
    ]
} as any);
(node as any).hash = 'a9311062bb82316dfeb3207efe2a3a49';
export default node;
