/* tslint:disable */
/* eslint-disable */
/* @relayHash d0ee3eea23b9ac4c8c0e8d7a9298d138 */

import { ConcreteRequest } from "relay-runtime";
export type FileUpload = {
    fileBase64: string;
    fileType: string;
    fileName?: string | null;
};
export type mutations_UpdateCandidateMutationVariables = {
    id: string;
    firstName?: string | null;
    lastName?: string | null;
    jobTitle?: string | null;
    email?: string | null;
    skillGroupType?: string | null;
    telephone?: string | null;
    profileImage?: FileUpload | null;
};
export type mutations_UpdateCandidateMutationResponse = {
    readonly updateCandidate: {
        readonly firstName: string;
        readonly lastName: string;
        readonly jobTitle: string;
        readonly email: string;
        readonly skillGroupType: string | null;
        readonly telephone: string | null;
        readonly profileImageUrl: string | null;
    } | null;
};
export type mutations_UpdateCandidateMutation = {
    readonly response: mutations_UpdateCandidateMutationResponse;
    readonly variables: mutations_UpdateCandidateMutationVariables;
};



/*
mutation mutations_UpdateCandidateMutation(
  $id: ID!
  $firstName: String
  $lastName: String
  $jobTitle: String
  $email: String
  $skillGroupType: String
  $telephone: String
  $profileImage: FileUpload
) {
  updateCandidate(input: {id: $id, firstName: $firstName, lastName: $lastName, email: $email, skillGroupType: $skillGroupType, jobTitle: $jobTitle, telephone: $telephone, profileImage: $profileImage}) {
    firstName
    lastName
    jobTitle
    email
    skillGroupType
    telephone
    profileImageUrl
    id
  }
}
*/

const node: ConcreteRequest = (function () {
    var v0 = [
        ({
            "kind": "LocalArgument",
            "name": "id",
            "type": "ID!",
            "defaultValue": null
        } as any),
        ({
            "kind": "LocalArgument",
            "name": "firstName",
            "type": "String",
            "defaultValue": null
        } as any),
        ({
            "kind": "LocalArgument",
            "name": "lastName",
            "type": "String",
            "defaultValue": null
        } as any),
        ({
            "kind": "LocalArgument",
            "name": "jobTitle",
            "type": "String",
            "defaultValue": null
        } as any),
        ({
            "kind": "LocalArgument",
            "name": "email",
            "type": "String",
            "defaultValue": null
        } as any),
        ({
            "kind": "LocalArgument",
            "name": "skillGroupType",
            "type": "String",
            "defaultValue": null
        } as any),
        ({
            "kind": "LocalArgument",
            "name": "telephone",
            "type": "String",
            "defaultValue": null
        } as any),
        ({
            "kind": "LocalArgument",
            "name": "profileImage",
            "type": "FileUpload",
            "defaultValue": null
        } as any)
    ], v1 = [
        ({
            "kind": "ObjectValue",
            "name": "input",
            "fields": [
                {
                    "kind": "Variable",
                    "name": "email",
                    "variableName": "email"
                },
                {
                    "kind": "Variable",
                    "name": "firstName",
                    "variableName": "firstName"
                },
                {
                    "kind": "Variable",
                    "name": "id",
                    "variableName": "id"
                },
                {
                    "kind": "Variable",
                    "name": "jobTitle",
                    "variableName": "jobTitle"
                },
                {
                    "kind": "Variable",
                    "name": "lastName",
                    "variableName": "lastName"
                },
                {
                    "kind": "Variable",
                    "name": "profileImage",
                    "variableName": "profileImage"
                },
                {
                    "kind": "Variable",
                    "name": "skillGroupType",
                    "variableName": "skillGroupType"
                },
                {
                    "kind": "Variable",
                    "name": "telephone",
                    "variableName": "telephone"
                }
            ]
        } as any)
    ], v2 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "firstName",
        "args": null,
        "storageKey": null
    } as any), v3 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "lastName",
        "args": null,
        "storageKey": null
    } as any), v4 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "jobTitle",
        "args": null,
        "storageKey": null
    } as any), v5 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "email",
        "args": null,
        "storageKey": null
    } as any), v6 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "skillGroupType",
        "args": null,
        "storageKey": null
    } as any), v7 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "telephone",
        "args": null,
        "storageKey": null
    } as any), v8 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "profileImageUrl",
        "args": null,
        "storageKey": null
    } as any);
    return {
        "kind": "Request",
        "fragment": {
            "kind": "Fragment",
            "name": "mutations_UpdateCandidateMutation",
            "type": "Mutation",
            "metadata": null,
            "argumentDefinitions": (v0 /*: any*/),
            "selections": [
                {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "updateCandidate",
                    "storageKey": null,
                    "args": (v1 /*: any*/),
                    "concreteType": "Candidate",
                    "plural": false,
                    "selections": [
                        (v2 /*: any*/),
                        (v3 /*: any*/),
                        (v4 /*: any*/),
                        (v5 /*: any*/),
                        (v6 /*: any*/),
                        (v7 /*: any*/),
                        (v8 /*: any*/)
                    ]
                }
            ]
        },
        "operation": {
            "kind": "Operation",
            "name": "mutations_UpdateCandidateMutation",
            "argumentDefinitions": (v0 /*: any*/),
            "selections": [
                {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "updateCandidate",
                    "storageKey": null,
                    "args": (v1 /*: any*/),
                    "concreteType": "Candidate",
                    "plural": false,
                    "selections": [
                        (v2 /*: any*/),
                        (v3 /*: any*/),
                        (v4 /*: any*/),
                        (v5 /*: any*/),
                        (v6 /*: any*/),
                        (v7 /*: any*/),
                        (v8 /*: any*/),
                        {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "id",
                            "args": null,
                            "storageKey": null
                        }
                    ]
                }
            ]
        },
        "params": {
            "operationKind": "mutation",
            "name": "mutations_UpdateCandidateMutation",
            "id": null,
            "text": "mutation mutations_UpdateCandidateMutation(\n  $id: ID!\n  $firstName: String\n  $lastName: String\n  $jobTitle: String\n  $email: String\n  $skillGroupType: String\n  $telephone: String\n  $profileImage: FileUpload\n) {\n  updateCandidate(input: {id: $id, firstName: $firstName, lastName: $lastName, email: $email, skillGroupType: $skillGroupType, jobTitle: $jobTitle, telephone: $telephone, profileImage: $profileImage}) {\n    firstName\n    lastName\n    jobTitle\n    email\n    skillGroupType\n    telephone\n    profileImageUrl\n    id\n  }\n}\n",
            "metadata": {}
        }
    } as any;
})();
(node as any).hash = '2dc530954b47c1bc2434a8cfcf97718d';
export default node;
