/* tslint:disable */
/* eslint-disable */

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type EntryUpdate_candidate = {
    readonly skillGroupType: string | null;
    readonly competency: {
        readonly practiceAreas: {
            readonly edges: ReadonlyArray<{
                readonly id: string;
                readonly name: string;
                readonly createdAt: string | null;
            } | null> | null;
        } | null;
    } | null;
    readonly placements: {
        readonly edges: ReadonlyArray<{
            readonly id: string;
            readonly name: string;
        } | null> | null;
    } | null;
    readonly " $refType": "EntryUpdate_candidate";
};
export type EntryUpdate_candidate$data = EntryUpdate_candidate;
export type EntryUpdate_candidate$key = {
    readonly " $data"?: EntryUpdate_candidate$data;
    readonly " $fragmentRefs": FragmentRefs<"EntryUpdate_candidate">;
};



const node: ReaderFragment = (function () {
    var v0 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "id",
        "args": null,
        "storageKey": null
    } as any), v1 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "name",
        "args": null,
        "storageKey": null
    } as any);
    return {
        "kind": "Fragment",
        "name": "EntryUpdate_candidate",
        "type": "Candidate",
        "metadata": null,
        "argumentDefinitions": [],
        "selections": [
            {
                "kind": "ScalarField",
                "alias": null,
                "name": "skillGroupType",
                "args": null,
                "storageKey": null
            },
            {
                "kind": "LinkedField",
                "alias": null,
                "name": "competency",
                "storageKey": null,
                "args": null,
                "concreteType": "Competency",
                "plural": false,
                "selections": [
                    {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "practiceAreas",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "PracticeAreaPage",
                        "plural": false,
                        "selections": [
                            {
                                "kind": "LinkedField",
                                "alias": null,
                                "name": "edges",
                                "storageKey": null,
                                "args": null,
                                "concreteType": "PracticeArea",
                                "plural": true,
                                "selections": [
                                    (v0 /*: any*/),
                                    (v1 /*: any*/),
                                    {
                                        "kind": "ScalarField",
                                        "alias": null,
                                        "name": "createdAt",
                                        "args": null,
                                        "storageKey": null
                                    }
                                ]
                            }
                        ]
                    }
                ]
            },
            {
                "kind": "LinkedField",
                "alias": null,
                "name": "placements",
                "storageKey": null,
                "args": null,
                "concreteType": "PlacementPage",
                "plural": false,
                "selections": [
                    {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "edges",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "Placement",
                        "plural": true,
                        "selections": [
                            (v0 /*: any*/),
                            (v1 /*: any*/)
                        ]
                    }
                ]
            }
        ]
    } as any;
})();
(node as any).hash = '208bac7797f0cdf78ff10424ee568dc6';
export default node;
