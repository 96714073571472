import React, { useEffect, useState } from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import Spacer from 'components/core/Spacers/Spacer';
import { Link } from '@material-ui/core';
import { Page as _Page } from 'components/Pagination/Paginator';
import theme from 'helpers/theme';
import Page from 'components/Page';
import PageTitle from 'components/PageTitle';
import { CharacterSuitability_characterSuitability } from './__generated__/CharacterSuitability_characterSuitability.graphql';
import styled from 'styled-components';
import CheckboxSingle from 'components/core/Input/CheckboxSingle';
import { makeMutation } from 'api/helpers';
import { SubmitCharacterSuitabilityCompletion } from 'api/mutations';
import { CharacterSuitability_candidate } from './__generated__/CharacterSuitability_candidate.graphql';
import Button from 'components/core/Input/ButtonBase';

const SubTitle = styled.div`
  font-weight: 300;
  font-size: 14px;
  color: ${theme.colors.textGrey};
`;

const Heading = styled.div`
  font-weight: 800;
  font-size: 18px;
  color: ${theme.colors.primaryBlack};
  margin-bottom: 15px;
`;

const BodyText = styled.div`
  font-weight: 400;
  font-size: 15px;
  margin-bottom: 15px;
`;

const ExternalLinkContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  width: 100%;
  overflow: hidden;
  padding: 0px 10px;
`;

const ListItem = styled.li`
  margin: 10px;
  margin-right: 20px;
  span {
    color: ${theme.colors.textOrange};
  }
`;

type Props = {
  characterSuitability: CharacterSuitability_characterSuitability;
  candidate: CharacterSuitability_candidate;
};

function CharacterSuitability({ characterSuitability, candidate }: Props) {
  const { characterSuitabilityCompleted, skillGroupType } = candidate;
  const [complete, setComplete] = useState(characterSuitabilityCompleted);
  const [rulesPart1, setRulesPart1] = useState('');
  const [rulesPart2, setRulesPart2] = useState('');

  const submitCharacterSuitabilityCompletion = async () => {
    try {
      await makeMutation(SubmitCharacterSuitabilityCompletion, {});
    } catch (err) {
      console.log('Unable to submit character and suitability completion', err);
    }
  };

  const rules = `The Solicitors Regulation Authority (SRA) is responsible for making sure that anyone admitted to the roll of solicitors has met the eligibility requirements, which includes being of satisfactory character and suitability. More details on exactly what that means can be found at www.sra.org.uk/solicitors/standards-regulations/assessment-character-suitability-rules

The SRA has an online platform called mySRA which allows trainees to manage their personal details through their account and to make applications and payment online.
  
What you must do
 
As you approach the end of your training contract (certainly no later than 6-8 weeks before your end date) you should activate your mySRA account if you haven’t already. 
 
Make sure all your personal details on record are correct. 
 
The first step of the application process will involve you completing the pre-admission screening (DBS check) through a company called Atlantic Data. 

The SRA should contact you 4 weeks before your training contract end date to start the DBS check. Please note, the link to start the DBS check may appear in your mySRA account earlier than 4 weeks, so keep an eye on your mySRA account and start the process as soon as possible. If you do not receive a link at this time, then you should contact the SRA directly. There is a fee of £39 to complete the screening.

You will be notified once the DBS check is complete. Your training principal will then be required to sign off your period of recognised training which includes confirmation that you are, in their opinion, of proper character and suitability to be admitted as a solicitor. Once your training principal has signed off your training, you will then be able to start your online application to be admitted as a solicitor via mySRA. In this application, you will be asked various character and suitability questions. The application fee for this is currently £100. 

Your application will then be processed and you will be asked on what date you would like to qualify (this is up to you, normally trainees choose the first available day after the end of their training contract).
You will have the choice to apply for a practising certificate once your admission has been confirmed. The payment for the practising certificate will be taken only once your admission has been granted. 
`;
  useEffect(() => {
    const fullRules = characterSuitability.rules;
    const r = fullRules.split('|^|');

    if (skillGroupType === "SQE") {
      if (r.length > 0) {
        const index = r[0].indexOf('What you must do');
        setRulesPart1(r[0].substring(0, index));
        setRulesPart2(r[0].substring(index + 17));
      }
    } else {
      if (r.length > 1) {
        const index = r[1].indexOf('What you must do');
        setRulesPart1(r[1].substring(0, index));
        setRulesPart2(r[1].substring(index + 17));
      }
    }
  }, []);

  // useEffect(() => {
  //   // only submit if not submitted previously
  //   if (complete && !characterSuitabilityCompleted) {
  //     submitCharacterSuitabilityCompletion();
  //   }
  // }, [complete]);

  return (
    <Page>
      <PageTitle title="Character and Suitability" />
      <Spacer vertical spacing={1} />
      <SubTitle>
        The final step to qualification is completing a character and
        suitability test.
      </SubTitle>
      <Spacer vertical spacing={3} />
      <Heading>Character and Suitability</Heading>
      <BodyText style={{ whiteSpace: 'pre-line' }}>
        <div dangerouslySetInnerHTML={{ __html: rulesPart1 }}></div>
      </BodyText>
      <BodyText style={{ whiteSpace: 'pre-line', fontWeight: 'bold' }}>
        {'What you must do'}
      </BodyText>
      <BodyText style={{ whiteSpace: 'pre-line' }}>
        <div dangerouslySetInnerHTML={{ __html: rulesPart2 }}></div>
      </BodyText>
      <Spacer vertical spacing={2} />
      <Heading>Information for Qualified Solicitors</Heading>
      <BodyText style={{ whiteSpace: 'pre-line' }}>
        {characterSuitability.infoForQualifiedSolicitors}
      </BodyText>
      <Spacer vertical spacing={2} />
      <Heading>External Links and Information</Heading>
      <ExternalLinkContainer>
        {characterSuitability.links?.map((link, index) => {
          return (
            <ListItem key={index}>
              <span>
                <Link
                  component="a"
                  href={link}
                  target={'_blank'}
                  rel="noreferrer"
                  color="inherit"
                >
                  {link}
                </Link>
              </span>
            </ListItem>
          );
        })}
      </ExternalLinkContainer>
      <Spacer vertical spacing={1} />
      <CheckboxSingle
        label={'Tick when Character and Suitability Test successfully completed'}
        defaultChecked={complete}
        onChange={(checked) => setComplete(checked)}
      />
      <Spacer vertical spacing={2} />
      <Button
        onClick={() => {
          submitCharacterSuitabilityCompletion();
          window.location.reload();
        }}
        archetype="submit"
        disabled={complete === characterSuitabilityCompleted}
      >
        Save
      </Button>
      <Spacer vertical spacing={3} />
    </Page>
  );
}

export default createFragmentContainer(CharacterSuitability, {
  characterSuitability: graphql`
    fragment CharacterSuitability_characterSuitability on CharacterSuitability {
      id
      rules
      infoForQualifiedSolicitors
      links
    }
  `,
  candidate: graphql`
    fragment CharacterSuitability_candidate on Candidate {
      id
      skillGroupType
      characterSuitabilityCompleted
    }
  `
});
