import clsx from "clsx";
import React, { ButtonHTMLAttributes } from "react";
import classes from "./AppButton.module.scss";

interface Props extends ButtonHTMLAttributes<HTMLButtonElement> {
	text?: string;
	textWeight?: 200 | 300 | 400 | 500 | 600 | 700 | 800 | 900;
	textSize?: number | string;
	isLoading?: boolean;
}

export const AppButton = ({ className, text, textWeight, textSize, isLoading, ...props }: Props) => {
	return (
		<button
			className={clsx(classes.button, className)}
			style={{
				fontWeight: textWeight,
				fontSize: textSize,
				display: "flex",
			}}
			disabled={isLoading}
			{...props}
		>
			{isLoading ? <div className={classes["lds-dual-ring"]}></div> : text}
		</button>
	);
};
