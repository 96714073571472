/* tslint:disable */
/* eslint-disable */
/* @relayHash a65819058ff061a8e627ad5e89b01331 */

import { ConcreteRequest } from "relay-runtime";
export type FileUpload = {
    fileBase64: string;
    fileType: string;
    fileName?: string | null;
};
export type mutationsCreateOtherQWEMutationVariables = {
    candidateID: string;
    name: string;
    completionDate: string;
    file: FileUpload;
};
export type mutationsCreateOtherQWEMutationResponse = {
    readonly createOtherQWE: {
        readonly id: string;
        readonly name: string;
        readonly completionDate: string;
        readonly fileUrl: string;
    } | null;
};
export type mutationsCreateOtherQWEMutation = {
    readonly response: mutationsCreateOtherQWEMutationResponse;
    readonly variables: mutationsCreateOtherQWEMutationVariables;
};



/*
mutation mutationsCreateOtherQWEMutation(
  $candidateID: ID!
  $name: String!
  $completionDate: Time!
  $file: FileUpload!
) {
  createOtherQWE(input: {candidateID: $candidateID, name: $name, completionDate: $completionDate, file: $file}) {
    id
    name
    completionDate
    fileUrl
  }
}
*/

const node: ConcreteRequest = (function () {
    var v0 = [
        ({
            "kind": "LocalArgument",
            "name": "candidateID",
            "type": "ID!",
            "defaultValue": null
        } as any),
        ({
            "kind": "LocalArgument",
            "name": "name",
            "type": "String!",
            "defaultValue": null
        } as any),
        ({
            "kind": "LocalArgument",
            "name": "completionDate",
            "type": "Time!",
            "defaultValue": null
        } as any),
        ({
            "kind": "LocalArgument",
            "name": "file",
            "type": "FileUpload!",
            "defaultValue": null
        } as any)
    ], v1 = [
        ({
            "kind": "LinkedField",
            "alias": null,
            "name": "createOtherQWE",
            "storageKey": null,
            "args": [
                {
                    "kind": "ObjectValue",
                    "name": "input",
                    "fields": [
                        {
                            "kind": "Variable",
                            "name": "candidateID",
                            "variableName": "candidateID"
                        },
                        {
                            "kind": "Variable",
                            "name": "completionDate",
                            "variableName": "completionDate"
                        },
                        {
                            "kind": "Variable",
                            "name": "file",
                            "variableName": "file"
                        },
                        {
                            "kind": "Variable",
                            "name": "name",
                            "variableName": "name"
                        }
                    ]
                }
            ],
            "concreteType": "OtherQWE",
            "plural": false,
            "selections": [
                {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "id",
                    "args": null,
                    "storageKey": null
                },
                {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "name",
                    "args": null,
                    "storageKey": null
                },
                {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "completionDate",
                    "args": null,
                    "storageKey": null
                },
                {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "fileUrl",
                    "args": null,
                    "storageKey": null
                }
            ]
        } as any)
    ];
    return {
        "kind": "Request",
        "fragment": {
            "kind": "Fragment",
            "name": "mutationsCreateOtherQWEMutation",
            "type": "Mutation",
            "metadata": null,
            "argumentDefinitions": (v0 /*: any*/),
            "selections": (v1 /*: any*/)
        },
        "operation": {
            "kind": "Operation",
            "name": "mutationsCreateOtherQWEMutation",
            "argumentDefinitions": (v0 /*: any*/),
            "selections": (v1 /*: any*/)
        },
        "params": {
            "operationKind": "mutation",
            "name": "mutationsCreateOtherQWEMutation",
            "id": null,
            "text": "mutation mutationsCreateOtherQWEMutation(\n  $candidateID: ID!\n  $name: String!\n  $completionDate: Time!\n  $file: FileUpload!\n) {\n  createOtherQWE(input: {candidateID: $candidateID, name: $name, completionDate: $completionDate, file: $file}) {\n    id\n    name\n    completionDate\n    fileUrl\n  }\n}\n",
            "metadata": {}
        }
    } as any;
})();
(node as any).hash = '0f4d857251e7c33c6264636bf5b496bd';
export default node;
