import { DropdownOption } from 'components/core/Input/Dropdown';
import SideModal from 'components/SideModal/SideModal';
import Tabs from 'components/SideModal/Tabs';
import { qualificationOptions } from 'helpers/qualificationOptions';
import React from 'react';
import UploadContent, { State } from './Content';
import { Qualification } from '../QualificationCard';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  qualificationType?: string;
  prefillState?: Qualification;
};

function QualificationModal({ isOpen, onClose, qualificationType, prefillState }: Props) {
  var qualType: DropdownOption | undefined = undefined;
  if (qualificationType) {
    qualificationOptions.forEach((qual) => {
      if (qual.value == qualificationType) {
        qualType = { id: qual.value, title: qual.text };
      }
    });
  }

  const initialState: State = {
    name: '',
    editing: prefillState !== undefined,
    ...prefillState,
    qualificationType: qualType
  };

  return (
    <SideModal
      isOpen={isOpen}
      title={`${prefillState ? 'Edit' : 'Upload'} Qualification`}
      closeModal={onClose}
    >
      <Tabs
        content={[
          {
            key: `${prefillState ? 'Edit' : 'Upload'} Qualification`,
            component: UploadContent
          }
        ]}
        closeModal={onClose}
        initialState={initialState}
      />
    </SideModal>
  );
}

export default QualificationModal;
