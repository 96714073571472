import * as React from 'react';
import Tabs, {
  TabContent,
  Body,
  Heading,
  LargeText,
  Footer,
  CurrentTotal,
  TermsBox,
  Text,
  Payment
} from 'components/SideModal/Tabs';
import SearchableDropdown, {
  CourseCategory,
  Course
} from 'components/core/Input/SearchableDropdown';
import Button from 'components/core/Input/ButtonBase';
import PaymentSuccess from 'components/SideModal/PaymentSuccess';
import LabelledCard from 'components/core/Cards/LabelledCard';
import CheckboxSingle from 'components/core/Input/CheckboxSingle';
import Spacer from 'components/core/Spacers/Spacer';
import { GraphError } from 'types/general';
import ClassroomInstanceSelector from 'components/SideModal/ClassroomInstanceSelector';

export const classroomCourseTab: TabContent = {
  key: 'Courses',
  component: ({ state, setState, closeModal, setTab }) => {
    const onSelected = React.useCallback((instance) => {
      setState((s: any) => ({
        ...s,
        courses: [{ ...s.courses[0], instanceUUID: instance.uuid }]
      }));
    }, []);  
    return (
      <>
        <Body>
          <Heading>Course Booking</Heading>
          <LargeText>Select an instance</LargeText>
          <Spacer vertical spacing={1} />
          {state.courses.length > 0 && (
            <ClassroomInstanceSelector
              courseID={state.courses[0].id || state.courses[0].ident}
              onSelected={onSelected}
            />
          )}
        </Body>
        <Footer>
          <CurrentTotal
            total={
              state.courses && state.courses?.length > 0
                ? state.courses[0].price
                : 0
            }
          />
          <div style={{ display: 'flex' }}>
            <Button archetype="default" onClick={() => closeModal()}>
              Cancel
            </Button>
            <Button
                  archetype="submit"
                  onClick={() => setTab('Terms of Business')}
                  style={{ marginLeft: 20 }}
                  disabled={
                    !(
                      state.courses?.length > 0
                    )
                  }
                >
                  Next
                </Button>
          </div>
        </Footer>
      </>
    );
  }
}

export const tabList: TabContent[] = [
  {
    key: 'Terms of Business',
    component: ({ state, setState, closeModal, setTab }) => {
      // console.log(state.termsAccepted);
      return (
        <>
          <Body>
            <Heading>Terms of Business</Heading>
            <LargeText>
              To book this course you will need to agree to our terms of
              business below.
            </LargeText>
            <TermsBox title="Accutrainee Pathways - Terms of Business">
              <Text>
                Course Booking Terms & Conditions <br />
                <br />
                Unless you are booking a free of charge course, on receipt of booking, we will send joining instructions and a VAT invoice. 
                We accept most Debit and Credit Cards. Online (on demand) courses are non-refundable
                once they have been purchased however, we reserve the right to offer a credit 
                or voucher in special circumstances.
                <br />
                <br />
                We reserve the right to cancel, postpone or reschedule
                any Classroom (in person) or Virtual (live online) course. In the event of cancellation, a full 
                refund will be available. In the event a course needs to be rescheduled, a refund may be available.
                <br />
                <br />
                If you cancel or reschedule a Classroom or Virtual course within 14 days of the course start date, 
                a £35 + VAT fee will apply. If you fail to turn up on the day of course, the 
                full course fee shall be payable.
                <br />
                <br />
                INTELLECTUAL PROPERTY RIGHTS
                <br />
                <br />
                Unless otherwise explicitly stated, we are the owner of all intellectual
                property rights in our site and in the material published, including
                but not limited to any concepts, ideas, methods, procedures, processes, 
                know-how, techniques, programs, publications, models, products, templates, 
                technologies, software designs, logos, artwork, graphics and information
                on or described in our site. All such rights are reserved.
                You must not modify, reproduce, publicly display, distribute or use without
                explicit written permission from the appropriate content or material provider (including third-party links)
                <br />
                <br />
                NO RELIANCE ON INFORMATION <br />
                <br />
                The content on our site, including but not limited to any guidance or advice offered on Pathways is provided 
                for general information purposes and guidance only. It is not intended to 
                amount to legal or any other professional advice, consultation or service.
              </Text>
              {state.courses.map((course: Course) => (
                <>
                  <Text>{course.specificTerms}</Text>
                </>
              ))}
            </TermsBox>
            <CheckboxSingle
              label={'I agree to the Accutrainee Terms of Business'}
              onChange={(checked) =>
                setState((state: object) => ({
                  ...state,
                  termsAccepted: checked
                }))
              }
              currentChecked={state.termsAccepted}
            />
          </Body>
          <Footer>
            <CurrentTotal
              total={state.courses
                .map(({ price }: { price: number }) => price)
                .reduce((a: number, b: number) => a + b, 0)}
            />
            <div style={{ display: 'flex' }}>
              <Button archetype="default" onClick={() => closeModal()}>
                Cancel
              </Button>
              <Button
                archetype="submit"
                onClick={() => setTab('Payment')}
                style={{ marginLeft: 20 }}
                disabled={!state.termsAccepted}
              >
                Continue to Payment
              </Button>
            </div>
          </Footer>
        </>
      );
    }
  },
  {
    key: 'Payment',
    component: ({ state, setState, setTab }) => {
      return (
        <Body>
          <Payment
            courses={state.courses.map((course: any) => ({
              ...course,
              id: course.ident || course.id
            }))}
            userUUIDs={[]}
            isContract={false}
            onSuccess={() => {
              setState((s: object) => ({ ...s, success: true, termsAccepted: false }));
            }}
            onError={(error: GraphError) => {
              setState((s: object) => ({ ...s, error }));
            }}
          />
          {state.success && <PaymentSuccess total={0} transactionId={``} />}
          {state.error !== undefined && (
            <LabelledCard
              label={'Transaction failed'}
              button={
                state.error?.extensions?.type == 'ErrCourseFull' ||
                state.error?.extensions?.type == 'ErrTooManyParticipants'
              } //if error is about slots left show button
              onClick={() => {
                setTab('Courses');
                setState((s: object) => ({ ...s, error: undefined }));
              }}
              labelBackground={'#ff7474'}
            >
              {state.error?.message}
            </LabelledCard>
          )}
        </Body>
      );
    }
  }
];
