const close = require('assets/clear-button.svg') as string;
const avatar = require('assets/ai_avatar.png') as string;

import React from 'react';
import './style.scss';

type Props = {
  title: string;
  subtitle: string;
  toggleChat: () => void;
  showCloseButton: boolean;
  titleAvatar?: string;
}

function Header({ title, subtitle, toggleChat, showCloseButton, titleAvatar }: Props) {
  return (
    <div className="rcw-header">
      <span dangerouslySetInnerHTML={{ __html: subtitle }} />
      <img src={avatar} className="avatar" alt="profile" />
    </div>
  );
}

export default Header;
