import * as React from 'react';
import { createUseStyles } from 'react-jss';
import { Theme } from 'helpers/theme';
import Text from '../core/Table/Text';
import classNames from 'classnames';
import styled from 'styled-components';

const useStyles = createUseStyles((theme: Theme) => ({
  table: {
    width: '100%',
    borderCollapse: 'separate',
    borderSpacing: '0 10px',
    verticalAlign: 'center'
  },
  tableInner: {
    paddingLeft: '30px'
  },
  row: {
    background: 'white',
    height: 60,
    borderRadius: theme.primaryBorderRadius,
    border: `1px solid ${theme.colors.borderGrey}`,
    transition: 'box-shadow 0.1s ease',
    '&:hover': {
      boxShadow: theme.shadows.primary
    },
    alignItems: 'center',
    display: 'flex',
    width: '100%',
    marginRight: '10px'
  },
  headerCell: {
    color: '#929CA6',
    textTransform: 'uppercase',
    fontSize: theme.fontSizes.tiny,
    fontWeight: 300,
    padding: [0, 48],
    textAlign: 'left',
    width: '100%',
    '&:first-child': {
      width: '64%'
    }
  },
  cell: {
    padding: [20, 25],
    transition: 'border-radius 0.1s ease',
    '&:nth-child(1)': {
      //width: '60%',
    },
    '&:nth-child(2)': {
      width: '45%'
    },
    '&:nth-child(3)': {
      width: '15%',
      marginRight: '10px'
    },
    alignItems: 'center',
    width: '100%',
    verticalAlign: 'middle',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },
  iconAndTableRow: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: '10px'
  },
  icon: {
    width: '30px',
    marginRight: '10px'
  }
}));

const Image = styled.div<{ imageUrl: string }>`
  width: 24px;
  height: 24px;
  background-image: url(${(props) => props.imageUrl});
  background-size: cover;
  cursor: pointer;
`;

type ColumnDef = {
  field?: string;
  component?: (row: any) => React.ReactNode;
  onClick?: (row: any) => void;
  colspan?: number;
  headerName: string;
};

type Props = {
  columns: ColumnDef[];
  rows: any[];
  onRowClick?: (row: any) => void;
  innerTable?: boolean;
  showTableHeaders?: boolean;
};

function CompetencySkillsTable({
  columns,
  rows,
  onRowClick,
  innerTable,
  showTableHeaders = true
}: Props) {
  const classes = useStyles();
  const [refresh, setRefresh] = React.useState(false);

  return (
    <table
      className={classNames(classes.table, innerTable && classes.tableInner)}
    >
      {showTableHeaders && (
        <thead>
          <div>
            <tr>
              {columns.map((column) => (
                <th key={Math.random()} className={classes.headerCell}>
                  {column.headerName}
                </th>
              ))}
            </tr>
          </div>
        </thead>
      )}
      <tbody>
        {rows.map((row, index) => {
          return (
            <>
              <div className={classes.iconAndTableRow}>
                <div className={classes.icon}>
                  {row.children && row.children.length > 0 && (
                    <Image
                      imageUrl={
                        row.open
                          ? require(`../../assets/Minus.png`)
                          : require(`../../assets/Plus.png`)
                      }
                      aria-label="expand row"
                      onClick={() => {
                        row.open = !row.open;
                        setRefresh(!refresh);
                      }}
                    />
                  )}
                </div>
                <tr
                  key={Math.random()}
                  className={classes.row}
                  onClick={() => {
                    onRowClick && onRowClick(row);
                  }}
                >
                  {columns.map(({ field, component, colspan }, index) => (
                    <td
                      key={index}
                      className={classes.cell}
                      colSpan={colspan || 1}
                    >
                      {field && <Text text={row[field]} />}
                      {component && component(row)}
                    </td>
                  ))}
                </tr>
              </div>
              {row.open &&
                row.children &&
                row.children.length > 0 &&
                row.childrenColumns && (
                  <CompetencySkillsTable
                    columns={row.childrenColumns}
                    rows={row.children}
                    innerTable={true}
                    showTableHeaders={row.showChildrenTableHeaders}
                  />
                )}
            </>
          );
        })}
      </tbody>
    </table>
  );
}

export default CompetencySkillsTable;
