import moment from 'moment';
/**
 * Computes the remaining months between now and the end date.
 * Returns 'Complete' if current date is greater than end date.
 * @param startDate The start date
 * @param endDate   The end date
 * @return          A string of 'X months left' or 'Complete' or 'Begins in X months'
 */
export const monthsLeft = (startDate: string, endDate: string) => {
  const nowToStart = moment(startDate).diff(moment(), 'months', true);
  const nowToEnd = moment(endDate).diff(moment(), 'months', true);
  if (nowToStart > 0) {
    return nowToStart < 1 ? `Begins in less than a month` : `Begins in ${Math.floor(nowToStart)} months`;
  } else if (nowToEnd > 0) {
    return nowToEnd < 1 ? `Less than 1 month left` : `${Math.floor(nowToEnd)} months left`
  }
  return 'Complete';
};
