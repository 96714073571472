/* tslint:disable */
/* eslint-disable */
/* @relayHash 12c3010af4d8a7a43cbc0bec985fd257 */

import { ConcreteRequest } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type App_EntryCreate_QueryVariables = {};
export type App_EntryCreate_QueryResponse = {
    readonly candidate: {
        readonly " $fragmentRefs": FragmentRefs<"EntryCreate_candidate">;
    } | null;
};
export type App_EntryCreate_Query = {
    readonly response: App_EntryCreate_QueryResponse;
    readonly variables: App_EntryCreate_QueryVariables;
};



/*
query App_EntryCreate_Query {
  candidate {
    ...EntryCreate_candidate
    id
  }
}

fragment EntryCreate_candidate on Candidate {
  skillGroupType
  competency {
    practiceAreas {
      edges {
        id
        name
        createdAt
      }
    }
    id
  }
  placements {
    edges {
      id
      name
    }
  }
}
*/

const node: ConcreteRequest = (function () {
    var v0 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "id",
        "args": null,
        "storageKey": null
    } as any), v1 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "name",
        "args": null,
        "storageKey": null
    } as any);
    return {
        "kind": "Request",
        "fragment": {
            "kind": "Fragment",
            "name": "App_EntryCreate_Query",
            "type": "Query",
            "metadata": null,
            "argumentDefinitions": [],
            "selections": [
                {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "candidate",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "Candidate",
                    "plural": false,
                    "selections": [
                        {
                            "kind": "FragmentSpread",
                            "name": "EntryCreate_candidate",
                            "args": null
                        }
                    ]
                }
            ]
        },
        "operation": {
            "kind": "Operation",
            "name": "App_EntryCreate_Query",
            "argumentDefinitions": [],
            "selections": [
                {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "candidate",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "Candidate",
                    "plural": false,
                    "selections": [
                        {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "skillGroupType",
                            "args": null,
                            "storageKey": null
                        },
                        {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "competency",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "Competency",
                            "plural": false,
                            "selections": [
                                {
                                    "kind": "LinkedField",
                                    "alias": null,
                                    "name": "practiceAreas",
                                    "storageKey": null,
                                    "args": null,
                                    "concreteType": "PracticeAreaPage",
                                    "plural": false,
                                    "selections": [
                                        {
                                            "kind": "LinkedField",
                                            "alias": null,
                                            "name": "edges",
                                            "storageKey": null,
                                            "args": null,
                                            "concreteType": "PracticeArea",
                                            "plural": true,
                                            "selections": [
                                                (v0 /*: any*/),
                                                (v1 /*: any*/),
                                                {
                                                    "kind": "ScalarField",
                                                    "alias": null,
                                                    "name": "createdAt",
                                                    "args": null,
                                                    "storageKey": null
                                                }
                                            ]
                                        }
                                    ]
                                },
                                (v0 /*: any*/)
                            ]
                        },
                        {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "placements",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "PlacementPage",
                            "plural": false,
                            "selections": [
                                {
                                    "kind": "LinkedField",
                                    "alias": null,
                                    "name": "edges",
                                    "storageKey": null,
                                    "args": null,
                                    "concreteType": "Placement",
                                    "plural": true,
                                    "selections": [
                                        (v0 /*: any*/),
                                        (v1 /*: any*/)
                                    ]
                                }
                            ]
                        },
                        (v0 /*: any*/)
                    ]
                }
            ]
        },
        "params": {
            "operationKind": "query",
            "name": "App_EntryCreate_Query",
            "id": null,
            "text": "query App_EntryCreate_Query {\n  candidate {\n    ...EntryCreate_candidate\n    id\n  }\n}\n\nfragment EntryCreate_candidate on Candidate {\n  skillGroupType\n  competency {\n    practiceAreas {\n      edges {\n        id\n        name\n        createdAt\n      }\n    }\n    id\n  }\n  placements {\n    edges {\n      id\n      name\n    }\n  }\n}\n",
            "metadata": {}
        }
    } as any;
})();
(node as any).hash = '581733112a052804ac0cab91b673a0a0';
export default node;
