import AppButton from '../../components/AppButton';
import Calendar from '../../components/Calendar';
import Modal from '../../components/Modal';
import ModalDialog from '../../components/ModalDialog';
import React, {ChangeEvent, useEffect, useState } from 'react';
import classes from './OverrideModal.module.scss';
import moment from 'moment';
import { getDateFromDateTimeString, getTimeStringDate } from '../../utils/getTimeFromString';
import CustomTimePicker from 'components/CustomTimePicker';

interface OverrideModalProps {
	data?: DataSlot;
	duration?: number;
	afterBufferTime?: number;
	beforeBufferTime?: number;
	isOpen: boolean;
	onClose: () => void;
	onChange: (data: DataSlot) => void;
	isEdit?: boolean;
	highlightToday?: boolean;
	hadSuggestHeader?: boolean;
  enablePast?: boolean;
  isTime?: boolean
}

type DataSlot = {
    id: string;
    startTime: Date | string;
    endTime: Date | string;
    selectedTimeRange: TimeRange;
}

type TimeRange = {
    id: string;
    start: string;
    end: string;
}

export const OverrideModal = (props: OverrideModalProps) => {
    const [selectedDate, setSelectedDate] = useState<Date | string>();
    const [selectedTimeRange, setSelectedTimeRange] = useState<TimeRange>(
            {id: '',
            start: '08:00:00',
            end:moment()
                    .set({ hour: 8, m: 0, s: 0 })
                    .add(60, 'm')
                    .format('HH:mm')}
        );

    // const [isLoadingTimeRange, setIsLoadingTimeRange] = useState(false);

    const handleChangeSelectedDate = (date: Date | string) => {
        setSelectedDate(date);
    };

    

    useEffect(() => {
        if (props.isEdit) {
          if (props.data && props.data.startTime && props.data.endTime) {
            setSelectedDate(props.data.startTime);
            setSelectedTimeRange({ 
                id: '',
                start: getTimeStringDate(props.data.startTime) || '00:00:00',
                end: getTimeStringDate(props.data.endTime) || '00:00:00'});
          }
        }

        // if (selectedTimeRange.end.split(':')[0] === '23') {
        //     return;
        // }
    }, [props.isEdit, props.data]);

    const handleChangeStartTime = (data: string) => {
        console.log('handleChangeStartTime', data);
        setSelectedTimeRange({
            ...selectedTimeRange,
            start: data,
        });
    };

    const handleConfirm = () => {   
      if(selectedDate)
        props.onChange(
            {
                id: '',
                startTime: props.isTime ? getDateFromDateTimeString(selectedDate, selectedTimeRange.start) : selectedDate,
                endTime: props.isTime ? getDateFromDateTimeString(selectedDate, selectedTimeRange.end) : selectedDate,
                selectedTimeRange: selectedTimeRange || null,
            }
        );
      props.onClose();
    };

    return (
        <Modal isShow={props.isOpen} onClose={props.onClose}>
          <ModalDialog
            style={{
                maxHeight: '90vh',
                overflowY: 'auto',
                overflowX: 'hidden',
            }}
          >
            <Calendar
                onChangeSelectedDate={handleChangeSelectedDate}
                selectedDate={props.data?.startTime && props.data.startTime}
                isViewOnly={props.isEdit}
                startWeekday={'Mo'}
                multipleSelection={false}
                disablePast={!props.enablePast}
                highlightToday={false}
                isHighlightSlotDate={true}
            />
            {props.isTime ? 
            <div className={classes.timeRangeWrapper}>
              <div className={classes.timeRange}>                                           
                <div key={`selectedTimeRange Time slots`}>
                  <div className={classes.timeWrapper}>
                    <div>
                      <CustomTimePicker 
                        data={selectedTimeRange.start}
                        onChange={(data) => handleChangeStartTime(data)}                    />
                      {/* <input
                          type={'time'}
                          step={8000}
                          id={`selectedTimeRange - start`}
                          name={`selectedTimeRange - start`}
                          value={selectedTimeRange.start}
                          onChange={(e) => handleChangeStartTime(e)}
                      /> */}
                    </div>                                                     
                  </div>
                </div>
              </div>
            </div> : null }

            <div className={classes.overrideModalButtons}>
                <button onClick={props.onClose}>Cancel</button>
                <AppButton
                    onClick={handleConfirm}
                    text='Apply'
                />
            </div>
          </ModalDialog>
        </Modal>
    );
};
