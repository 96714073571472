/* tslint:disable */
/* eslint-disable */

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type CharacterSuitability_candidate = {
    readonly id: string;
    readonly skillGroupType: string | null;
    readonly characterSuitabilityCompleted: boolean;
    readonly " $refType": "CharacterSuitability_candidate";
};
export type CharacterSuitability_candidate$data = CharacterSuitability_candidate;
export type CharacterSuitability_candidate$key = {
    readonly " $data"?: CharacterSuitability_candidate$data;
    readonly " $fragmentRefs": FragmentRefs<"CharacterSuitability_candidate">;
};



const node: ReaderFragment = ({
    "kind": "Fragment",
    "name": "CharacterSuitability_candidate",
    "type": "Candidate",
    "metadata": null,
    "argumentDefinitions": [],
    "selections": [
        {
            "kind": "ScalarField",
            "alias": null,
            "name": "id",
            "args": null,
            "storageKey": null
        },
        {
            "kind": "ScalarField",
            "alias": null,
            "name": "skillGroupType",
            "args": null,
            "storageKey": null
        },
        {
            "kind": "ScalarField",
            "alias": null,
            "name": "characterSuitabilityCompleted",
            "args": null,
            "storageKey": null
        }
    ]
} as any);
(node as any).hash = 'f6ce149a1965336cb766d1b232371c0d';
export default node;
