/* tslint:disable */
/* eslint-disable */
/* @relayHash dae7b91e92a11580206cb791eae1af6f */

import { ConcreteRequest } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type App_Courses_QueryVariables = {};
export type App_Courses_QueryResponse = {
    readonly user: {
        readonly " $fragmentRefs": FragmentRefs<"MyCourses_user">;
    } | null;
};
export type App_Courses_Query = {
    readonly response: App_Courses_QueryResponse;
    readonly variables: App_Courses_QueryVariables;
};



/*
query App_Courses_Query {
  user {
    ...MyCourses_user
  }
}

fragment CourseCard_course on Course {
  ident: id
  name
  bannerImageURL
  externalLink
  excerpt
  category {
    color
    name
  }
}

fragment MyCourses_user on User {
  firstName
  myCourses {
    status
    certificateURL
    completedAt
    course {
      __typename
      ...CourseCard_course
    }
  }
  myClassroomCourses {
    status
    certificateURL
    completedAt
    course {
      __typename
      ...CourseCard_course
    }
  }
}
*/

const node: ConcreteRequest = (function () {
    var v0 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "name",
        "args": null,
        "storageKey": null
    } as any), v1 = [
        ({
            "kind": "ScalarField",
            "alias": null,
            "name": "status",
            "args": null,
            "storageKey": null
        } as any),
        ({
            "kind": "ScalarField",
            "alias": null,
            "name": "certificateURL",
            "args": null,
            "storageKey": null
        } as any),
        ({
            "kind": "ScalarField",
            "alias": null,
            "name": "completedAt",
            "args": null,
            "storageKey": null
        } as any),
        ({
            "kind": "LinkedField",
            "alias": null,
            "name": "course",
            "storageKey": null,
            "args": null,
            "concreteType": null,
            "plural": false,
            "selections": [
                {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "__typename",
                    "args": null,
                    "storageKey": null
                },
                {
                    "kind": "ScalarField",
                    "alias": "ident",
                    "name": "id",
                    "args": null,
                    "storageKey": null
                },
                (v0 /*: any*/),
                {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "bannerImageURL",
                    "args": null,
                    "storageKey": null
                },
                {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "externalLink",
                    "args": null,
                    "storageKey": null
                },
                {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "excerpt",
                    "args": null,
                    "storageKey": null
                },
                {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "category",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "Category",
                    "plural": false,
                    "selections": [
                        {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "color",
                            "args": null,
                            "storageKey": null
                        },
                        (v0 /*: any*/)
                    ]
                }
            ]
        } as any)
    ];
    return {
        "kind": "Request",
        "fragment": {
            "kind": "Fragment",
            "name": "App_Courses_Query",
            "type": "Query",
            "metadata": null,
            "argumentDefinitions": [],
            "selections": [
                {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "user",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "User",
                    "plural": false,
                    "selections": [
                        {
                            "kind": "FragmentSpread",
                            "name": "MyCourses_user",
                            "args": null
                        }
                    ]
                }
            ]
        },
        "operation": {
            "kind": "Operation",
            "name": "App_Courses_Query",
            "argumentDefinitions": [],
            "selections": [
                {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "user",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "User",
                    "plural": false,
                    "selections": [
                        {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "firstName",
                            "args": null,
                            "storageKey": null
                        },
                        {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "myCourses",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "MyCourse",
                            "plural": true,
                            "selections": (v1 /*: any*/)
                        },
                        {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "myClassroomCourses",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "MyCourse",
                            "plural": true,
                            "selections": (v1 /*: any*/)
                        }
                    ]
                }
            ]
        },
        "params": {
            "operationKind": "query",
            "name": "App_Courses_Query",
            "id": null,
            "text": "query App_Courses_Query {\n  user {\n    ...MyCourses_user\n  }\n}\n\nfragment CourseCard_course on Course {\n  ident: id\n  name\n  bannerImageURL\n  externalLink\n  excerpt\n  category {\n    color\n    name\n  }\n}\n\nfragment MyCourses_user on User {\n  firstName\n  myCourses {\n    status\n    certificateURL\n    completedAt\n    course {\n      __typename\n      ...CourseCard_course\n    }\n  }\n  myClassroomCourses {\n    status\n    certificateURL\n    completedAt\n    course {\n      __typename\n      ...CourseCard_course\n    }\n  }\n}\n",
            "metadata": {}
        }
    } as any;
})();
(node as any).hash = '7a6c93135c3899d3dbf61428d1f45a8d';
export default node;
