import React, { HTMLAttributes } from "react";
import classes from "./ModalDialog.module.scss";

interface Props extends HTMLAttributes<HTMLDivElement> {
  className?: string;
}

export const ModalDialog = ({ className, ...props }: Props) => {
  return (
    <div
      className={`${classes.wrapper} ${className}`}
      onClick={(e) => {
        e.stopPropagation();
        props.onClick;
      }}
      {...props}
    >
      {props.children}
    </div>
  );
};
