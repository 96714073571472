import theme from 'helpers/theme';
import React from 'react';
import styled from 'styled-components';
import IconButton from '@material-ui/core/IconButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faPlus, faTrash } from '@fortawesome/free-solid-svg-icons';
import { monthsLeft } from 'helpers/monthsLeft';
import { Weekday } from 'views/Dashboard/__generated__/Roadmap_candidate.graphql';
import DeletePopup from 'components/DeletePopup';

const Container = styled.div`
  display: table;
  height: max-content;
  width: 100%;
  background: white;
  border-radius: 12px;
  border: 1px solid ${theme.colors.borderGrey};
  padding: 23px 10px 10px 10px;
  @media (max-width: 1100px) {
    display: block;
    overflow: hidden;
  }
`;

const Title = styled.div`
  font-weight: 700;
  font-size: 15px;
  color: #6f6f6f;
`;

const TopRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 10px;
  margin-bottom: 30px;
`;

const Placements = styled.div`
  @media (max-width: 1100px) {
    max-height: 225px;
    overflow: auto;
  }
`;

const Placement = styled.div<{ selected?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  padding: 15px 0;
  padding-right: 14px;
  background-color: ${(props) => props.selected && theme.colors.backgroundGrey};
  border-radius: 12px;
  margin-top: 5px;
  transition: all 0.2s;
  &:hover {
    background: ${theme.colors.backgroundGrey};
  }
`;

const Tab = styled.div<{ selected?: boolean }>`
  width: 5px;
  height: 30px;
  border-radius: 0 13px 13px 0;
  background-color: ${(props) => props.selected && '#6f6f6f'};
  margin-right: 10px;
`;

const PlacementRow = styled.div`
  display: flex;
`;

const PlacementDetails = styled.div`
  display: flex;
  flex-direction: column;
`;

const PlacementDate = styled.div`
  display: flex;
  align-items: center;
  font-size: 11px;
  font-weight: 600;
  color: ${theme.colors.textGrey};
`;

const BulletPoint = styled.div`
  background-color: ${theme.colors.textGrey};
  height: 4px;
  width: 4px;
  border-radius: 4px;
  margin: 0 5px;
`;

const PlacementName = styled.div`
  font-size: 15px;
  font-weight: 400;
`;

const NoData = styled.div`
  text-align: center;
  font-size: ${theme.fontSizes.small}px;
  color: #6f6f6f;
`;

const AddPlacementButton = styled.div`
  padding: 5px 8px;
  border-radius: 9px;
  cursor: pointer;
  transition: all 0.2s;
  &:hover {
    background: #ebeef1;
  }
`;

const ApprovedIcon = styled.img`
  width: 16px;
`;

export type Placement = {
  name: string;
  id?: string;
  clientName: string;
  startDate?: string;
  endDate?: string;
  approved?: boolean;
  orgSRANumber?:string;
  supervisorName:string;
  supervisorEmail:string;
  email?: string;
  fullTime: boolean;
  partTimeDays?: Weekday[];
  daysMissed: number;
  qweDocURL?: string;
  qweDocFileName?: string;
};

type Props = {
  placements?: Placement[];
  selected?: Placement;
  setSelected: (placement: Placement) => void;
  onAdd: () => void;
  deletePlacement: (id: string | undefined) => void;
  placementToDelete?: any;
  setPlacementToDelete?: any;
  isOpen?: any;
  setIsOpen?: any;
};

function PlacementCard({ placements, selected, setSelected, onAdd, deletePlacement, placementToDelete, setPlacementToDelete, isOpen, setIsOpen}: Props) {
  return (
    <Container>
      <TopRow>
        <Title>PLACEMENTS</Title>
        <AddPlacementButton onClick={onAdd}>
          <FontAwesomeIcon icon={faPlus} color="#6f6f6f" />
        </AddPlacementButton>
      </TopRow>
      <Placements>
        {placements ? (
          placements.map(
            ({
              id,
              name,
              clientName,
              startDate,
              endDate,
              approved,
              orgSRANumber,
              supervisorName,
              supervisorEmail,
              email,
              fullTime,
              partTimeDays,
              daysMissed,
              qweDocURL,
              qweDocFileName
            }: Placement) => (
              <Placement
                selected={selected && selected.id === id}
                onClick={() =>
                  setSelected({ id, name, clientName, startDate, endDate, orgSRANumber,
                    supervisorName,
                    supervisorEmail,
                    email,
                    fullTime, partTimeDays, daysMissed, qweDocURL, qweDocFileName })
                }
              >
                <PlacementRow>
                  <Tab selected={selected && selected.id === id} />
                  <PlacementDetails>
                    <PlacementDate>
                      {endDate && startDate && monthsLeft(startDate, endDate)}
                    </PlacementDate>
                    <PlacementName>{name}</PlacementName>
                  </PlacementDetails>
                </PlacementRow>
                {approved && (
                  <ApprovedIcon
                    src={require('../../assets/ApprovedIcon.svg')}
                  />
                )}
                 
                 <IconButton
                    onClick={() => {
                      setPlacementToDelete({id, name});
                      setIsOpen((prev: boolean) => !prev);
                    }}
                  >
                    <FontAwesomeIcon icon={faTrash}
            color={'#a4acb5'}
            style={{height: 15}}/> 
                  </IconButton>
              </Placement>
            )
          )
        ) : (
          <NoData>No placements yet</NoData>
        )}
      </Placements>
      <DeletePopup
        open={isOpen}
        name={placementToDelete.name}
        onClose={() => setIsOpen((prev: boolean) => !prev)}
        onDelete={() => {
          deletePlacement(placementToDelete.id);
          setIsOpen((prev: boolean) => !prev);
        }}
        text={`Warning: if you delete this placement, all portfolio entries related to this placement will be permanently deleted. Are you sure you want to delete?`}
      />
    </Container>
  );
}

export default PlacementCard;
