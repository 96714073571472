/* tslint:disable */
/* eslint-disable */
/* @relayHash b0ea7ede8386e28499a5d5b2476352a7 */

import { ConcreteRequest } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type App_Certificate_QueryVariables = {
    token: string;
};
export type App_Certificate_QueryResponse = {
    readonly certificateInfo: {
        readonly " $fragmentRefs": FragmentRefs<"CertGenerator_certificateInfo">;
    };
};
export type App_Certificate_Query = {
    readonly response: App_Certificate_QueryResponse;
    readonly variables: App_Certificate_QueryVariables;
};



/*
query App_Certificate_Query(
  $token: String!
) {
  certificateInfo(token: $token) {
    ...CertGenerator_certificateInfo
  }
}

fragment CertGenerator_certificateInfo on CertificateInfo {
  courseTitle
  courseName
  expiryDate
  completionStartDate
  completionEndDate
  clientName
  takerFirstName
  takerLastName
  certificateBodyURL
  footerText
  regulationText
  title
  instructorName
  instructorSignatureURL
  certificateNumber
  trainingRecord {
    type
    name
  }
  modulesDelivered
  certificateSubTitle
  showSignature
  showModulesDelivered
  trainingCompanyName
  trainingCompanyWebsite
  trainingCompanyEmail
  trainingCompanyTelephone
  trainingCompanyPostcode
}
*/

const node: ConcreteRequest = (function () {
    var v0 = [
        ({
            "kind": "LocalArgument",
            "name": "token",
            "type": "String!",
            "defaultValue": null
        } as any)
    ], v1 = [
        ({
            "kind": "Variable",
            "name": "token",
            "variableName": "token"
        } as any)
    ];
    return {
        "kind": "Request",
        "fragment": {
            "kind": "Fragment",
            "name": "App_Certificate_Query",
            "type": "Query",
            "metadata": null,
            "argumentDefinitions": (v0 /*: any*/),
            "selections": [
                {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "certificateInfo",
                    "storageKey": null,
                    "args": (v1 /*: any*/),
                    "concreteType": "CertificateInfo",
                    "plural": false,
                    "selections": [
                        {
                            "kind": "FragmentSpread",
                            "name": "CertGenerator_certificateInfo",
                            "args": null
                        }
                    ]
                }
            ]
        },
        "operation": {
            "kind": "Operation",
            "name": "App_Certificate_Query",
            "argumentDefinitions": (v0 /*: any*/),
            "selections": [
                {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "certificateInfo",
                    "storageKey": null,
                    "args": (v1 /*: any*/),
                    "concreteType": "CertificateInfo",
                    "plural": false,
                    "selections": [
                        {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "courseTitle",
                            "args": null,
                            "storageKey": null
                        },
                        {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "courseName",
                            "args": null,
                            "storageKey": null
                        },
                        {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "expiryDate",
                            "args": null,
                            "storageKey": null
                        },
                        {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "completionStartDate",
                            "args": null,
                            "storageKey": null
                        },
                        {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "completionEndDate",
                            "args": null,
                            "storageKey": null
                        },
                        {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "clientName",
                            "args": null,
                            "storageKey": null
                        },
                        {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "takerFirstName",
                            "args": null,
                            "storageKey": null
                        },
                        {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "takerLastName",
                            "args": null,
                            "storageKey": null
                        },
                        {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "certificateBodyURL",
                            "args": null,
                            "storageKey": null
                        },
                        {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "footerText",
                            "args": null,
                            "storageKey": null
                        },
                        {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "regulationText",
                            "args": null,
                            "storageKey": null
                        },
                        {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "title",
                            "args": null,
                            "storageKey": null
                        },
                        {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "instructorName",
                            "args": null,
                            "storageKey": null
                        },
                        {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "instructorSignatureURL",
                            "args": null,
                            "storageKey": null
                        },
                        {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "certificateNumber",
                            "args": null,
                            "storageKey": null
                        },
                        {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "trainingRecord",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "TrainingRecordItem",
                            "plural": true,
                            "selections": [
                                {
                                    "kind": "ScalarField",
                                    "alias": null,
                                    "name": "type",
                                    "args": null,
                                    "storageKey": null
                                },
                                {
                                    "kind": "ScalarField",
                                    "alias": null,
                                    "name": "name",
                                    "args": null,
                                    "storageKey": null
                                }
                            ]
                        },
                        {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "modulesDelivered",
                            "args": null,
                            "storageKey": null
                        },
                        {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "certificateSubTitle",
                            "args": null,
                            "storageKey": null
                        },
                        {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "showSignature",
                            "args": null,
                            "storageKey": null
                        },
                        {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "showModulesDelivered",
                            "args": null,
                            "storageKey": null
                        },
                        {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "trainingCompanyName",
                            "args": null,
                            "storageKey": null
                        },
                        {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "trainingCompanyWebsite",
                            "args": null,
                            "storageKey": null
                        },
                        {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "trainingCompanyEmail",
                            "args": null,
                            "storageKey": null
                        },
                        {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "trainingCompanyTelephone",
                            "args": null,
                            "storageKey": null
                        },
                        {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "trainingCompanyPostcode",
                            "args": null,
                            "storageKey": null
                        }
                    ]
                }
            ]
        },
        "params": {
            "operationKind": "query",
            "name": "App_Certificate_Query",
            "id": null,
            "text": "query App_Certificate_Query(\n  $token: String!\n) {\n  certificateInfo(token: $token) {\n    ...CertGenerator_certificateInfo\n  }\n}\n\nfragment CertGenerator_certificateInfo on CertificateInfo {\n  courseTitle\n  courseName\n  expiryDate\n  completionStartDate\n  completionEndDate\n  clientName\n  takerFirstName\n  takerLastName\n  certificateBodyURL\n  footerText\n  regulationText\n  title\n  instructorName\n  instructorSignatureURL\n  certificateNumber\n  trainingRecord {\n    type\n    name\n  }\n  modulesDelivered\n  certificateSubTitle\n  showSignature\n  showModulesDelivered\n  trainingCompanyName\n  trainingCompanyWebsite\n  trainingCompanyEmail\n  trainingCompanyTelephone\n  trainingCompanyPostcode\n}\n",
            "metadata": {}
        }
    } as any;
})();
(node as any).hash = '1857166aae02536f4c536cfe21484a30';
export default node;
