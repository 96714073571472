import { Competency } from "views/Entry/DropdownCompetencyQueryRenderer";

export type PracticeSubSkills = ReadonlyArray<{
  readonly practiceSkillGroup: {
      readonly practiceSkill: {
          readonly id: string;
          readonly name: string;
      } | null;
      readonly name: string;
  } | null;
  readonly id: string;
  readonly name: string;
}> | null;

export function subSkillToCompetencies(subSkills: PracticeSubSkills){
  let competencies: { [id: string]: Competency } = {}; 
  subSkills?.forEach(subSkill => {
    const skill = subSkill.practiceSkillGroup;
    const competency = skill?.practiceSkill;
    if (skill && competency){
      const newSkill = {
        name: skill.name,
        options: [{
          value: subSkill.id,
          name: subSkill.name
        }]
      };
      if (!(competency.id in competencies)){
        competencies[competency.id] = {
          id: competency.id,
          title: competency.name,
          skills: [newSkill]
        };
        return;
      }
      const comp = competencies[competency.id];
      const group = comp.skills.find(s => s.name === skill.name);
      if (group){
        competencies[competency.id] = {
          ...comp,
          skills: [
            ...comp.skills.filter(s => s.name !== group.name),
            {
              name: group.name,
              options: [...group.options, newSkill.options[0]]
            }
          ]
        }
      }else{
        competencies[competency.id] = {
          ...comp,
          skills: [...comp.skills, newSkill]
        };
      }
    }
  });
  return Object.values(competencies);
}
