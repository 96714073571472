import React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import Spacer from 'components/core/Spacers/Spacer';
import { Grid } from '@material-ui/core';
import Paginator, { Page as _Page } from 'components/Pagination/Paginator';
import { createUseStyles, useTheme } from 'react-jss';
import { Theme } from 'helpers/theme';
import Page from 'components/Page';
import { useRouter } from 'found';
import PageTitle from 'components/PageTitle';
import { Advices_advices } from './__generated__/Advices_advices.graphql';
import { Advices_user } from './__generated__/Advices_user.graphql';
import AdviceCard from 'components/AdviceCard';
import styled from 'styled-components';
import theme from 'helpers/theme';

const useStyles = createUseStyles((theme: Theme) => ({
  pagination: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  }
}));

const SubTitle = styled.div`
  font-weight: 300;
  font-size: 14px;
  color: ${theme.colors.textGrey};
  max-width: 500px;
  margin-top: 15px;
`;

const NoneFoundMessage = styled.div`
  color: ${theme.colors.textGrey};
  text-align: center;
  font-size: 20px;
`;

type Props = {
  user: Advices_user;
  advices: Advices_advices;
};

function Advices({ user, advices }: Props) {
  const theme = useTheme();
  const classes = useStyles({ theme });

  const { router, match } = useRouter();

  const pageInfo = {
    total: advices.pageInfo?.total ?? 0,
    limit: advices.pageInfo?.limit ?? 10,
    offset: advices.pageInfo?.offset ?? 0
  };
  const page = {
    currentPage: Math.ceil(pageInfo.offset / pageInfo.limit) + 1,
    totalPages: Math.ceil(pageInfo.total / pageInfo.limit)
  };

  const onUpdatePage = ({ offset, limit }: _Page) => {
    if (offset <= 0) {
      offset = 1;
    }

    router.push(`/app/advices?offset=${(offset - 1) * limit}&limit=${limit}`);
  };

  return (
    <Page>
      <PageTitle title="Competency Plus" />
      <SubTitle>
        {user?.firstName}, here are some exercises, journals, courses, articles
        and other amazing content to help you further develop your skills.
      </SubTitle>
      <Spacer vertical spacing={2} />
      {advices && advices.edges && advices.edges.length === 0 && (
        <NoneFoundMessage>No advices found.</NoneFoundMessage>
      )}
      <Grid container>
        {advices.edges?.map(
          (advice) =>
            advice && (
              <AdviceCard
                advice={{
                  ...advice
                }}
                onClick={() => {
                  router.push(`/app/advices/${advice.id}`);
                }}
              />
            )
        )}
      </Grid>
      {advices && advices.edges && advices.edges.length > 0 && (
        <>
          <div className={classes.pagination}>
            <Paginator
              currentPage={page.currentPage}
              updatePage={onUpdatePage}
              numPages={page.totalPages}
              itemsPerPage={pageInfo.limit}
              showRange={page.totalPages} //{page.totalPages > 4 ? 4 : page.totalPages}
            />
          </div>
          <Spacer vertical spacing={3} />
        </>
      )}
    </Page>
  );
}

export default createFragmentContainer(Advices, {
  user: graphql`
    fragment Advices_user on User {
      firstName
    }
  `,
  advices: graphql`
    fragment Advices_advices on AdvicePage {
      edges {
        id
        ...AdviceCard_advice
      }
      pageInfo {
        total
        offset
        limit
        given
      }
    }
  `
});
