/* tslint:disable */
/* eslint-disable */

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type CandidateModal_candidate = {
    readonly id: string;
    readonly firstName: string;
    readonly lastName: string;
    readonly email: string;
    readonly jobTitle: string;
    readonly telephone: string | null;
    readonly profileImageUrl: string | null;
    readonly skillGroupType: string | null;
    readonly " $refType": "CandidateModal_candidate";
};
export type CandidateModal_candidate$data = CandidateModal_candidate;
export type CandidateModal_candidate$key = {
    readonly " $data"?: CandidateModal_candidate$data;
    readonly " $fragmentRefs": FragmentRefs<"CandidateModal_candidate">;
};



const node: ReaderFragment = ({
    "kind": "Fragment",
    "name": "CandidateModal_candidate",
    "type": "Candidate",
    "metadata": null,
    "argumentDefinitions": [],
    "selections": [
        {
            "kind": "ScalarField",
            "alias": null,
            "name": "id",
            "args": null,
            "storageKey": null
        },
        {
            "kind": "ScalarField",
            "alias": null,
            "name": "firstName",
            "args": null,
            "storageKey": null
        },
        {
            "kind": "ScalarField",
            "alias": null,
            "name": "lastName",
            "args": null,
            "storageKey": null
        },
        {
            "kind": "ScalarField",
            "alias": null,
            "name": "email",
            "args": null,
            "storageKey": null
        },
        {
            "kind": "ScalarField",
            "alias": null,
            "name": "jobTitle",
            "args": null,
            "storageKey": null
        },
        {
            "kind": "ScalarField",
            "alias": null,
            "name": "telephone",
            "args": null,
            "storageKey": null
        },
        {
            "kind": "ScalarField",
            "alias": null,
            "name": "profileImageUrl",
            "args": null,
            "storageKey": null
        },
        {
            "kind": "ScalarField",
            "alias": null,
            "name": "skillGroupType",
            "args": null,
            "storageKey": null
        }
    ]
} as any);
(node as any).hash = '77f06f9184573ec551a6b2aba5697400';
export default node;
