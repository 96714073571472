import * as React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import CourseSyllabusCard, { ModulesEntity } from './CourseSyllabusCard';
import { CourseSyllabusCardFrag_course } from './__generated__/CourseSyllabusCardFrag_course.graphql';

type Props = {
  course?: CourseSyllabusCardFrag_course;
  upTo?: String;
  current?: String;
  completePercent?: number;
};

function SyllabusCard({
  course,
  upTo,
  current = '',
  completePercent = 0
}: Props) {
  const syllabusSections: ModulesEntity[] = (course?.syllabus ?? []).map(
    (courseElement) => {
      if (courseElement.type == 'module') {
        return {
          sections: [
            {
              name: courseElement.name ?? '',
              uuid: courseElement.uuid ?? '',
              complete: false
            },
            ...(courseElement?.syllabus ?? []).map((moduleItem) => {
              return {
                name: moduleItem.name ?? '',
                uuid: moduleItem.uuid ?? '',
                complete: false
              };
            })
          ]
        };
      } else {
        return {
          sections: [
            {
              name: courseElement.name ?? '',
              uuid: courseElement.uuid ?? '',
              complete: false
            }
          ]
        };
      }
    }
  );

  var revSyllabus = syllabusSections.reverse();
  var found = false; // Used to find the progress
  var totalSections = 0;

  for (const i in revSyllabus) {
    var revSections = syllabusSections[i].sections.reverse();

    var foundCurrent = false;
    for (const item in revSections) {
      totalSections += 1;

      if (revSections[item].uuid == upTo) {
        found = true;
      }
      if (found) {
        revSections[item] = { ...revSections[item], complete: true };
      }

      if (revSections[item].uuid == current) {
        foundCurrent = true;
      }
    }

    if (foundCurrent) {
      revSyllabus[i].current = true;
    }
    revSyllabus[i].sections = revSections.reverse();
  }

  const syllabus = revSyllabus.reverse();

  const syllabusProp = {
    completePercentage: completePercent,
    modules: syllabus
  };
  return <CourseSyllabusCard courseSyllabus={syllabusProp} />;
}

export default createFragmentContainer(SyllabusCard, {
  course: graphql`
    fragment CourseSyllabusCardFrag_course on Course {
      syllabus {
        name
        type
        uuid
        complete
        ... on Module {
          syllabus {
            name
            type
            uuid
            complete
          }
        }
      }
    }
  `
});
