export const qualificationOptions = [
  {
    text: 'A-Level',
    value: 'a_level',
    tooltipText: 'Upload here your A-level certificates'
  },
  {
    text: 'Degree or Equivalent',
    value: 'degree',
    tooltipText: 'Upload here your degree or equivalent qualification certificates'
  },
  {
    text: 'GDL',
    value: 'gdl',
    tooltipText: 'Graduate Diploma in Law'
  },
  {
    text: 'LPC',
    value: 'lpc',
    tooltipText: 'Legal Practice Course'
  },
  {
    text: 'PSC',
    value: 'professional_skills',
    tooltipText: 'Professional Skills Course'
  },
  {
    text: 'SQE 1',
    value: 'sqe1',
    tooltipText: 'Solicitors Qualifying Examination 1 - Functioning Legal Knowledge'
  },
  {
    text: 'SQE 2',
    value: 'sqe2',
    tooltipText: 'Solicitors Qualifying Examination 2 - Practical Legal Skills Assessments'
  },
  {
    text: 'Qualification Certificate',
    value: 'qualification_cert',
    tooltipText: 'Add here your qualification certificates'
  },
  {
    text: 'Practising Certificate',
    value: 'practicing_cert',
    tooltipText: 'Upon qualification, you will be able to apply for a Practising Certificate. You can upload yours here'
  },
  {
    text: 'Other',
    value: 'other',
    tooltipText: 'Here you can upload all your documents, certificates etc.'
  }
];
