/* tslint:disable */
/* eslint-disable */

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type Lesson_user = {
    readonly firstName: string;
    readonly lastName: string;
    readonly " $refType": "Lesson_user";
};
export type Lesson_user$data = Lesson_user;
export type Lesson_user$key = {
    readonly " $data"?: Lesson_user$data;
    readonly " $fragmentRefs": FragmentRefs<"Lesson_user">;
};



const node: ReaderFragment = ({
    "kind": "Fragment",
    "name": "Lesson_user",
    "type": "User",
    "metadata": null,
    "argumentDefinitions": [],
    "selections": [
        {
            "kind": "ScalarField",
            "alias": null,
            "name": "firstName",
            "args": null,
            "storageKey": null
        },
        {
            "kind": "ScalarField",
            "alias": null,
            "name": "lastName",
            "args": null,
            "storageKey": null
        }
    ]
} as any);
(node as any).hash = '383a54afa5279d46f5e2c7869ad317ba';
export default node;
