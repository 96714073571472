import React from 'react';
import { useRouter } from 'found';
import styled from 'styled-components';
import moment from 'moment';
import { makeMutation } from 'api/helpers';
import Spacer from 'components/core/Spacers/Spacer';
import { QWE_candidate } from './__generated__/QWE_candidate.graphql';
import { QWE_clients } from './__generated__/QWE_clients.graphql';
import PlacementCard from 'components/PlacementCard';
import theme from 'helpers/theme';
import { monthsLeft } from 'helpers/monthsLeft';
import SignoffPage from './SignoffPage';
import SupervisorCard from 'components/SupervisorCard';
import { useSideModalDispatch } from '../../views/SideModalProvider';
import QWECard from 'components/QWECard';
import { RefetchOptions } from 'react-relay';
import { DeletePlacement } from 'api/mutations';
import { RecordSourceSelectorProxy } from 'relay-runtime';

const SubTitle = styled.div`
  font-weight: 300;
  font-size: 14px;
  color: ${theme.colors.textGrey};
  max-width: 550px;
`;

const Container = styled.div`
  display: grid;
  grid-gap: 50px;
  grid-template-columns: 300px 1fr;
  @media (max-width: 1100px) {
    grid-template-columns: 1fr;
    grid-template-rows: 225px 1fr;
    grid-gap: 125px;
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
`;

const SupervisorRow = styled.div`
  display: grid;
  grid-gap: 20px;
  grid-template-columns: repeat(auto-fill, 268px);
  @media (max-width: 1300px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media (max-width: 550px) {
    grid-template-columns: repeat(1, 1fr);
  }
`;

const ClientName = styled.div`
  font-weight: 800;
  font-size: ${theme.fontSizes.heading}px;
  color: ${theme.colors.secondaryOrange};
`;

const PlacementName = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: 800;
  font-size: ${theme.fontSizes.heading}px;
  margin: 5px 0;
`;

const PlacementDate = styled.div`
  display: flex;
  align-items: center;
  font-size: ${theme.fontSizes.xSmall}px;
  color: ${theme.colors.textGrey};
`;

const BulletPoint = styled.div`
  background-color: ${theme.colors.textGrey};
  height: 4px;
  width: 4px;
  border-radius: 4px;
  margin: 0 5px;
`;

const SubHeading = styled(PlacementName)`
  font-size: ${theme.fontSizes.extraLarge}px;
  margin: 15px 0;
`;

const Red = styled(PlacementName)`
  margin: 15px 0;
  font-size: 14px;
  color: ${theme.colors.textGrey};
  font-weight: 700;
`;

const Button = styled.div`
  font-weight: 700;
  font-size: ${theme.fontSizes.xSmall}px;
  background-color: ${theme.colors.borderGrey};
  padding: 10px 25px;
  border-radius: 6px;
  cursor: pointer;
`;

const NoData = styled.div`
  text-align: center;
  font-weight: 800;
  font-size: ${theme.fontSizes.extraLarge}px;
  color: ${theme.colors.secondaryGrey};
  margin-top: 30px;
`;

type Props = {
  candidate: QWE_candidate;
  clients: QWE_clients;
  refetch?: (
    refetchVariables: Object | ((prevVars: Object) => void),
    renderVariables: Object,
    callback: (error?: Error) => void,
    options?: RefetchOptions
  ) => void;
};

function PlacementsPage({ candidate, clients, refetch }: Props) {
  const [selected, setSelected] = React.useState<any>();
  const [isOpen, setIsOpen] = React.useState(false);
  const [placementToDelete, setPlacementToDelete] = React.useState({
    name: '',
    id: ''
  });
  const dispatch = useSideModalDispatch();
  const { router } = useRouter();

  const deletePlacement = async (id: string) => {
    try {
      makeMutation(
        DeletePlacement,
        {
          id: id
        },
        (store: RecordSourceSelectorProxy<{}>) => {
          const root = store.get('client:root');
          if (!root) {
            console.error('could not get root');
            return;
          }
          const user = root.getLinkedRecord('candidate');
          if (!user) {
            console.log('Could not get user');
            return;
          }
        }
      ).then(() => refetch && refetch({}, {}
          , (err) => err && console.error(err.message), {
          force: true,
          fetchPolicy: 'network-only',
        })
      );
    } catch (err) {
      console.log('Unable to delete placement', err);
    }
  };
  const placements = candidate.placements?.edges?.map((placement) => ({
    id: placement?.id,
    name: placement?.name ?? '',
    clientName: placement?.client?.name ?? 'N/A',
    startDate: placement?.startDate,
    endDate: placement?.endDate ?? undefined,
    qweIndividual: placement?.qweIndividual ?? '',
    orgPosition: placement?.orgPosition ?? '',
    sraNumber: placement?.sraNumber ?? '',
    approved: placement?.approved ?? false,
    orgSRANumber: placement?.orgSRANumber || '',
    supervisorName: placement?.supervisorName || '',
    supervisorEmail: placement?.supervisorEmail || '',
    email: placement?.email || '',
    fullTime: placement?.fullTime ?? true,
    partTimeDays: placement?.partTimeDays?.map(d => d) ?? [],
    daysMissed: placement?.daysMissed || 0,
    qweDocURL: placement?.qweDocURL ?? undefined,
    qweDocFileName: placement?.qweDocFilename ?? undefined,
    supervisors:
      placement?.client?.managers?.edges?.map((supervisor: any) => ({
        id: supervisor?.id,
        name: `${supervisor?.firstName} ${supervisor.lastName}`,
        email: supervisor.email,
        imageUrl: supervisor.profileImageUrl
      })) ?? []
  }));

  var mentor = {
    id: candidate?.mentor?.id,
    name: `${candidate?.mentor?.firstName} ${candidate?.mentor?.lastName}`,
    email: candidate?.mentor?.email || '',
    imageUrl: ''
  };

  const clientOptions = clients?.edges?.map((client) => ({
    id: client?.id ?? '',
    title: client?.name ?? ''
  }));

  React.useEffect(() => {
    placements && placements.length > 0 && setSelected(placements[0]);
  }, [candidate]);

  // console.log('skillgroup', candidate.skillGroupType)
  return (
    <>
      <SubTitle>
        {candidate.skillGroupType === "TC" ? 'Please add details of each of your seats during your period of recognised training.'
        : (
          <span>
            Please add details of each of the placements you would like to use towards your two years' QWE. <strong style={{ color: 'red' }}>
              Remember, you can't have more than four different organisations.
            </strong>
          </span>
        )}
      </SubTitle>
      <Spacer vertical spacing={3} />
      <Container>
        <Content>
          <PlacementCard
            placements={placements}
            selected={selected}
            setSelected={(placement) => {
              const fullP = placements?.find((p) => p.id == placement.id);
              setSelected(fullP);
            }}
            onAdd={() =>
              dispatch({
                modal: 'placement',
                candidateID: candidate.id,
                clients: clientOptions,
                isCandidateTC: candidate.skillGroupType === 'TC',
                onClose: () => refetch && refetch({}, {}
                  , (err) => err && console.error(err.message), {
                  force: false,
                  fetchPolicy: 'store-or-network'
                })
              })
            }
            deletePlacement={deletePlacement}
            placementToDelete={placementToDelete}
            setPlacementToDelete = {setPlacementToDelete}
            isOpen={isOpen}
            setIsOpen={setIsOpen}
          />
          <Spacer vertical spacing={2} />
        </Content>
        {selected ? (
          <Content>
            <PlacementName>
              <ClientName>{selected.name}</ClientName>
              <Button
                onClick={() =>
                  dispatch({
                    modal: 'placement',
                    candidateID: candidate.id,
                    clients: clientOptions,
                    prefillPlacement: selected,
                    isCandidateTC: candidate.skillGroupType === 'TC',
                    onClose: () => refetch && refetch({}, {}
                      , (err) => err && console.error(err.message), {
                      force: false,
                      fetchPolicy: 'store-or-network'
                    })
                  })
                }
              >
                Edit Placement
              </Button>
            </PlacementName>
            <PlacementDate>
              {selected.startDate
                ? moment(selected.startDate).format('DD/MM/YYYY')
                : '-'}{' '}
              to{' '}
              {selected.endDate
                ? moment(selected.endDate).format('DD/MM/YYYY')
                : 'No End Date'}
              {selected.endDate && selected.startDate && (
                <>
                  <BulletPoint />
                  {monthsLeft(selected.startDate, selected.endDate)}
                </>
              )}
              {selected.qweIndividual != '' && (
                <>
                  <BulletPoint />
                  {selected.qweIndividual}
                </>
              )}
              {selected.orgPosition != '' && (
                <>
                  <BulletPoint />
                  {selected.orgPosition}
                </>
              )}
              {selected.sraNumber != '' && (
                <>
                  <BulletPoint />
                  {selected.sraNumber}
                </>
              )}
            </PlacementDate>
            <Spacer vertical spacing={1} />
            <SubTitle>
              <strong>
              Please make sure all your portfolio entries have been approved by your supervisor before the end of your secondment/placement.
              </strong>
            </SubTitle>
            <Spacer vertical spacing={2} />
            <SubHeading>Supervisors &amp; Mentors</SubHeading><Red>Please do not send more than 20 entries at a time.</Red>
            <Spacer vertical spacing={1} />
            {selected.supervisors.length <= 0 && !candidate.mentor ? (
              <>
                <NoData>No Supervisors yet</NoData>
                <NoData>No Mentors yet</NoData>
              </>
            ) : (
              <SupervisorRow>
                {selected.supervisors.map(
                  ({ id, name, email, imageUrl }: any) => (
                    <SupervisorCard
                      key={id}
                      name={name}
                      email={email}
                      role="Supervisor"
                      imageUrl={imageUrl}
                      onClick={() => {
                        dispatch({
                          modal: 'sendentries',
                          supervisorID: id,
                          placementID: selected.id
                        });
                      }}
                    />
                  )
                )}
                {candidate.mentor && (
                  <SupervisorCard
                    key={mentor.id}
                    name={mentor.name}
                    email={mentor.email}
                    role="Mentor"
                    imageUrl={mentor.imageUrl}
                    onClick={() => {
                      dispatch({
                        modal: 'sendentries',
                        mentorID: candidate?.mentor?.id || '',
                        placementID: selected.id
                      });
                    }}
                  />
                )}
              </SupervisorRow>
            )}
            <Spacer vertical spacing={4} />
            <SubHeading>Signoff Requests</SubHeading>
            <Spacer vertical spacing={2} />
            <SignoffPage placementID={selected.id} />
            <Spacer vertical spacing={3} />
          </Content>
        ) : (
          <Content>
            <NoData>Add a placement to view it here</NoData>
          </Content>
        )}
      </Container>
    </>
  );
}

export default PlacementsPage;
