/* tslint:disable */
/* eslint-disable */
/* @relayHash cadef07f58e6e9389c18e042071b9a66 */

import { ConcreteRequest } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type App_Mentoring_QueryVariables = {};
export type App_Mentoring_QueryResponse = {
    readonly candidate: {
        readonly " $fragmentRefs": FragmentRefs<"Mentoring_candidate">;
    } | null;
};
export type App_Mentoring_Query = {
    readonly response: App_Mentoring_QueryResponse;
    readonly variables: App_Mentoring_QueryVariables;
};



/*
query App_Mentoring_Query {
  candidate {
    ...Mentoring_candidate
    id
  }
}

fragment Mentoring_candidate on Candidate {
  firstName
  mentor {
    id
    firstName
    lastName
    email
  }
}
*/

const node: ConcreteRequest = (function () {
    var v0 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "firstName",
        "args": null,
        "storageKey": null
    } as any), v1 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "id",
        "args": null,
        "storageKey": null
    } as any);
    return {
        "kind": "Request",
        "fragment": {
            "kind": "Fragment",
            "name": "App_Mentoring_Query",
            "type": "Query",
            "metadata": null,
            "argumentDefinitions": [],
            "selections": [
                {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "candidate",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "Candidate",
                    "plural": false,
                    "selections": [
                        {
                            "kind": "FragmentSpread",
                            "name": "Mentoring_candidate",
                            "args": null
                        }
                    ]
                }
            ]
        },
        "operation": {
            "kind": "Operation",
            "name": "App_Mentoring_Query",
            "argumentDefinitions": [],
            "selections": [
                {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "candidate",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "Candidate",
                    "plural": false,
                    "selections": [
                        (v0 /*: any*/),
                        {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "mentor",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "Mentor",
                            "plural": false,
                            "selections": [
                                (v1 /*: any*/),
                                (v0 /*: any*/),
                                {
                                    "kind": "ScalarField",
                                    "alias": null,
                                    "name": "lastName",
                                    "args": null,
                                    "storageKey": null
                                },
                                {
                                    "kind": "ScalarField",
                                    "alias": null,
                                    "name": "email",
                                    "args": null,
                                    "storageKey": null
                                }
                            ]
                        },
                        (v1 /*: any*/)
                    ]
                }
            ]
        },
        "params": {
            "operationKind": "query",
            "name": "App_Mentoring_Query",
            "id": null,
            "text": "query App_Mentoring_Query {\n  candidate {\n    ...Mentoring_candidate\n    id\n  }\n}\n\nfragment Mentoring_candidate on Candidate {\n  firstName\n  mentor {\n    id\n    firstName\n    lastName\n    email\n  }\n}\n",
            "metadata": {}
        }
    } as any;
})();
(node as any).hash = 'fccf9b4f6178dd9569f5a6e1d32196e1';
export default node;
