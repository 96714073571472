/* tslint:disable */
/* eslint-disable */

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type StructureElement = "lesson" | "module" | "test" | "%future added value";
export type CertGenerator_certificateInfo = {
    readonly courseTitle: string;
    readonly courseName: string;
    readonly expiryDate: string | null;
    readonly completionStartDate: string | null;
    readonly completionEndDate: string;
    readonly clientName: string | null;
    readonly takerFirstName: string;
    readonly takerLastName: string;
    readonly certificateBodyURL: string | null;
    readonly footerText: string;
    readonly regulationText: string;
    readonly title: string;
    readonly instructorName: string;
    readonly instructorSignatureURL: string | null;
    readonly certificateNumber: string;
    readonly trainingRecord: ReadonlyArray<{
        readonly type: StructureElement;
        readonly name: string;
    }> | null;
    readonly modulesDelivered: number | null;
    readonly certificateSubTitle: string | null;
    readonly showSignature: boolean;
    readonly showModulesDelivered: boolean;
    readonly trainingCompanyName: string;
    readonly trainingCompanyWebsite: string;
    readonly trainingCompanyEmail: string;
    readonly trainingCompanyTelephone: string;
    readonly trainingCompanyPostcode: string;
    readonly " $refType": "CertGenerator_certificateInfo";
};
export type CertGenerator_certificateInfo$data = CertGenerator_certificateInfo;
export type CertGenerator_certificateInfo$key = {
    readonly " $data"?: CertGenerator_certificateInfo$data;
    readonly " $fragmentRefs": FragmentRefs<"CertGenerator_certificateInfo">;
};



const node: ReaderFragment = ({
    "kind": "Fragment",
    "name": "CertGenerator_certificateInfo",
    "type": "CertificateInfo",
    "metadata": null,
    "argumentDefinitions": [],
    "selections": [
        {
            "kind": "ScalarField",
            "alias": null,
            "name": "courseTitle",
            "args": null,
            "storageKey": null
        },
        {
            "kind": "ScalarField",
            "alias": null,
            "name": "courseName",
            "args": null,
            "storageKey": null
        },
        {
            "kind": "ScalarField",
            "alias": null,
            "name": "expiryDate",
            "args": null,
            "storageKey": null
        },
        {
            "kind": "ScalarField",
            "alias": null,
            "name": "completionStartDate",
            "args": null,
            "storageKey": null
        },
        {
            "kind": "ScalarField",
            "alias": null,
            "name": "completionEndDate",
            "args": null,
            "storageKey": null
        },
        {
            "kind": "ScalarField",
            "alias": null,
            "name": "clientName",
            "args": null,
            "storageKey": null
        },
        {
            "kind": "ScalarField",
            "alias": null,
            "name": "takerFirstName",
            "args": null,
            "storageKey": null
        },
        {
            "kind": "ScalarField",
            "alias": null,
            "name": "takerLastName",
            "args": null,
            "storageKey": null
        },
        {
            "kind": "ScalarField",
            "alias": null,
            "name": "certificateBodyURL",
            "args": null,
            "storageKey": null
        },
        {
            "kind": "ScalarField",
            "alias": null,
            "name": "footerText",
            "args": null,
            "storageKey": null
        },
        {
            "kind": "ScalarField",
            "alias": null,
            "name": "regulationText",
            "args": null,
            "storageKey": null
        },
        {
            "kind": "ScalarField",
            "alias": null,
            "name": "title",
            "args": null,
            "storageKey": null
        },
        {
            "kind": "ScalarField",
            "alias": null,
            "name": "instructorName",
            "args": null,
            "storageKey": null
        },
        {
            "kind": "ScalarField",
            "alias": null,
            "name": "instructorSignatureURL",
            "args": null,
            "storageKey": null
        },
        {
            "kind": "ScalarField",
            "alias": null,
            "name": "certificateNumber",
            "args": null,
            "storageKey": null
        },
        {
            "kind": "LinkedField",
            "alias": null,
            "name": "trainingRecord",
            "storageKey": null,
            "args": null,
            "concreteType": "TrainingRecordItem",
            "plural": true,
            "selections": [
                {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "type",
                    "args": null,
                    "storageKey": null
                },
                {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "name",
                    "args": null,
                    "storageKey": null
                }
            ]
        },
        {
            "kind": "ScalarField",
            "alias": null,
            "name": "modulesDelivered",
            "args": null,
            "storageKey": null
        },
        {
            "kind": "ScalarField",
            "alias": null,
            "name": "certificateSubTitle",
            "args": null,
            "storageKey": null
        },
        {
            "kind": "ScalarField",
            "alias": null,
            "name": "showSignature",
            "args": null,
            "storageKey": null
        },
        {
            "kind": "ScalarField",
            "alias": null,
            "name": "showModulesDelivered",
            "args": null,
            "storageKey": null
        },
        {
            "kind": "ScalarField",
            "alias": null,
            "name": "trainingCompanyName",
            "args": null,
            "storageKey": null
        },
        {
            "kind": "ScalarField",
            "alias": null,
            "name": "trainingCompanyWebsite",
            "args": null,
            "storageKey": null
        },
        {
            "kind": "ScalarField",
            "alias": null,
            "name": "trainingCompanyEmail",
            "args": null,
            "storageKey": null
        },
        {
            "kind": "ScalarField",
            "alias": null,
            "name": "trainingCompanyTelephone",
            "args": null,
            "storageKey": null
        },
        {
            "kind": "ScalarField",
            "alias": null,
            "name": "trainingCompanyPostcode",
            "args": null,
            "storageKey": null
        }
    ]
} as any);
(node as any).hash = '63c788dfb9e08228424ce77846082ae9';
export default node;
