import * as React from 'react';
import { createUseStyles, useTheme } from 'react-jss';
import { Theme } from 'helpers/theme';
import classnames from 'classnames';
import CoreInput, { InputTypes } from '../CoreInput';

const useStyles = createUseStyles((theme: Theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start'
  },
  label: {
    flex: 1,
    fontSize: theme.fontSizes.default,
    color: theme.colors.primaryBlack,
    marginBottom: theme.spacing(1)
  },
  input: {
    flex: 2,
    border: `1px solid #08080814`,
    borderRadius: 5,
    padding: '15px 10px'
  }
}));

type Props = {
  label?: string;
  labelClassName?: string;
  placeholder?: string;
  value?: string;
  type?: InputTypes;
  onChange?: (text: string) => string | void;
  disabled?: boolean;
};

const EasyInput = ({
  label,
  labelClassName,
  placeholder = '',
  value = '',
  type = 'text',
  disabled,
  onChange = () => {}
}: Props) => {
  const theme = useTheme();
  const classes = useStyles({ theme });

  const onTextChange = (text: string) => {
    return onChange(text);
  };

  return (
    <div className={classes.container}>
      {label && (
        <span className={classnames(classes.label, labelClassName)}>
          {label}
        </span>
      )}
      <CoreInput
        placeholder={placeholder}
        type={type}
        onChange={onTextChange}
        value={value}
        className={classes.input}
        disabled={disabled || false}
      />
    </div>
  );
};

export default EasyInput;
