import theme from 'helpers/theme';
import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  background: white;
  border-radius: 8px;
  border: 1px solid ${theme.colors.borderGrey};
  padding: 20px;
  box-shadow: 0px 3px 18px #0000000d;
`;

const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`;

const Title = styled.div`
  font-weight: 700;
  font-size: 18px;
  margin-bottom: 10px;
`;

const Subtitle = styled.div`
  font-size: 12px;
  color: ${theme.colors.textGrey};
  margin-bottom: 25px;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const Button = styled.a`
  background-color: #f37a21;
  color: ${theme.colors.hoverOrange};
  text-decoration: none;
  border-radius: 6px;
  padding: 11px;
  font-size: 13px;
  font-weight: 600;
  justify-content: center;
  align-items: center;
  display: flex;
  cursor: pointer;
  width: 50%;
  height: 32px;
`;

type Props = {
  title: string;
  subtitle: string;
  linkUrl: string;
};

export default function LinkCard({ title, subtitle, linkUrl }: Props) {
  return (
    <Container>
      <InfoContainer>
        <Title>{title}</Title>
        <Subtitle>{subtitle}</Subtitle>
      </InfoContainer>
      <Button href={linkUrl}>View</Button>
    </Container>
  );
}
