import * as React from 'react';
import { createUseStyles, useTheme } from 'react-jss';
import { Theme } from 'helpers/theme';
import GenericPopup from 'components/CoursePopups/GenericPopup';
import Button from 'components/core/Input/ButtonBase';
import Spacer from 'components/core/Spacers/Spacer';
import { useMediaQuery } from 'react-responsive';


const useStyles = createUseStyles((theme: Theme) => ({
  deleteRoot: {
    padding: '20px 30px'
  },
  body: {
    marginTop: theme.spacing(2),
    fontWeight: 600,
    fontSize: theme.fontSizes.large,
    color: theme.colors.secondaryBlack
  },
  buttons: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    alignSelf: 'flex-end',
    marginTop: theme.spacing(3)
  }
}));

type Props = {
  open: boolean;
  name: string;
  onClose: () => void;
  onDelete: () => void;
  text?: string;
};

function DeletePopup({ open, name, onClose, onDelete, text }: Props) {
  const theme = useTheme();
  const classes = useStyles({ theme });

  if (!open) {
    return null;
  }
  return (
    <GenericPopup
      open={open}
      onClose={onClose}
      title={`Delete ${name}?`}
      className={classes.deleteRoot}
    >
      <div className={classes.body}>
        {text ? text : `Are you sure you want to delete ${name}?`}
      </div>
      <div className={classes.buttons}>
        <Button archetype="default" onClick={onClose}>
          Cancel
        </Button>
        <Button archetype="submit" onClick={onDelete}>
          Delete
        </Button>
      </div>
    </GenericPopup>
  );
}

export default DeletePopup;
