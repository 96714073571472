/* tslint:disable */
/* eslint-disable */
/* @relayHash eba567e00cd0fe04e5bd4a9a8db5a817 */

import { ConcreteRequest } from "relay-runtime";
export type ContentiousType = "both" | "contentious" | "non_contentious" | "%future added value";
export type FileUpload = {
    fileBase64: string;
    fileType: string;
    fileName?: string | null;
};
export type mutationsUpdateQWEEntryMutationVariables = {
    title: string;
    startDate: string;
    endDate: string;
    subSkills: Array<string>;
    experience: string;
    evidence: string;
    learnt: string;
    moreExperience: string;
    needMoreExperience?: string | null;
    id: string;
    placementID: string;
    contentious: ContentiousType;
    file?: FileUpload | null;
    practiceAreas?: Array<number> | null;
    taskedBy?: string | null;
};
export type mutationsUpdateQWEEntryMutationResponse = {
    readonly updateQWEEntry: {
        readonly id: string;
    } | null;
};
export type mutationsUpdateQWEEntryMutation = {
    readonly response: mutationsUpdateQWEEntryMutationResponse;
    readonly variables: mutationsUpdateQWEEntryMutationVariables;
};



/*
mutation mutationsUpdateQWEEntryMutation(
  $title: String!
  $startDate: Time!
  $endDate: Time!
  $subSkills: [ID!]!
  $experience: String!
  $evidence: String!
  $learnt: String!
  $moreExperience: String!
  $needMoreExperience: String
  $id: ID!
  $placementID: ID!
  $contentious: ContentiousType!
  $file: FileUpload
  $practiceAreas: [Int!]
  $taskedBy: String
) {
  updateQWEEntry(input: {id: $id, placementID: $placementID, title: $title, startDate: $startDate, endDate: $endDate, practiceSubSkills: $subSkills, experience: $experience, evidence: $evidence, learnt: $learnt, moreExperience: $moreExperience, needMoreExperience: $needMoreExperience, contentious: $contentious, file: $file, practiceAreas: $practiceAreas, taskedBy: $taskedBy}) {
    id
  }
}
*/

const node: ConcreteRequest = (function () {
    var v0 = [
        ({
            "kind": "LocalArgument",
            "name": "title",
            "type": "String!",
            "defaultValue": null
        } as any),
        ({
            "kind": "LocalArgument",
            "name": "startDate",
            "type": "Time!",
            "defaultValue": null
        } as any),
        ({
            "kind": "LocalArgument",
            "name": "endDate",
            "type": "Time!",
            "defaultValue": null
        } as any),
        ({
            "kind": "LocalArgument",
            "name": "subSkills",
            "type": "[ID!]!",
            "defaultValue": null
        } as any),
        ({
            "kind": "LocalArgument",
            "name": "experience",
            "type": "String!",
            "defaultValue": null
        } as any),
        ({
            "kind": "LocalArgument",
            "name": "evidence",
            "type": "String!",
            "defaultValue": null
        } as any),
        ({
            "kind": "LocalArgument",
            "name": "learnt",
            "type": "String!",
            "defaultValue": null
        } as any),
        ({
            "kind": "LocalArgument",
            "name": "moreExperience",
            "type": "String!",
            "defaultValue": null
        } as any),
        ({
            "kind": "LocalArgument",
            "name": "needMoreExperience",
            "type": "String",
            "defaultValue": null
        } as any),
        ({
            "kind": "LocalArgument",
            "name": "id",
            "type": "ID!",
            "defaultValue": null
        } as any),
        ({
            "kind": "LocalArgument",
            "name": "placementID",
            "type": "ID!",
            "defaultValue": null
        } as any),
        ({
            "kind": "LocalArgument",
            "name": "contentious",
            "type": "ContentiousType!",
            "defaultValue": null
        } as any),
        ({
            "kind": "LocalArgument",
            "name": "file",
            "type": "FileUpload",
            "defaultValue": null
        } as any),
        ({
            "kind": "LocalArgument",
            "name": "practiceAreas",
            "type": "[Int!]",
            "defaultValue": null
        } as any),
        ({
            "kind": "LocalArgument",
            "name": "taskedBy",
            "type": "String",
            "defaultValue": null
        } as any)
    ], v1 = [
        ({
            "kind": "LinkedField",
            "alias": null,
            "name": "updateQWEEntry",
            "storageKey": null,
            "args": [
                {
                    "kind": "ObjectValue",
                    "name": "input",
                    "fields": [
                        {
                            "kind": "Variable",
                            "name": "contentious",
                            "variableName": "contentious"
                        },
                        {
                            "kind": "Variable",
                            "name": "endDate",
                            "variableName": "endDate"
                        },
                        {
                            "kind": "Variable",
                            "name": "evidence",
                            "variableName": "evidence"
                        },
                        {
                            "kind": "Variable",
                            "name": "experience",
                            "variableName": "experience"
                        },
                        {
                            "kind": "Variable",
                            "name": "file",
                            "variableName": "file"
                        },
                        {
                            "kind": "Variable",
                            "name": "id",
                            "variableName": "id"
                        },
                        {
                            "kind": "Variable",
                            "name": "learnt",
                            "variableName": "learnt"
                        },
                        {
                            "kind": "Variable",
                            "name": "moreExperience",
                            "variableName": "moreExperience"
                        },
                        {
                            "kind": "Variable",
                            "name": "needMoreExperience",
                            "variableName": "needMoreExperience"
                        },
                        {
                            "kind": "Variable",
                            "name": "placementID",
                            "variableName": "placementID"
                        },
                        {
                            "kind": "Variable",
                            "name": "practiceAreas",
                            "variableName": "practiceAreas"
                        },
                        {
                            "kind": "Variable",
                            "name": "practiceSubSkills",
                            "variableName": "subSkills"
                        },
                        {
                            "kind": "Variable",
                            "name": "startDate",
                            "variableName": "startDate"
                        },
                        {
                            "kind": "Variable",
                            "name": "taskedBy",
                            "variableName": "taskedBy"
                        },
                        {
                            "kind": "Variable",
                            "name": "title",
                            "variableName": "title"
                        }
                    ]
                }
            ],
            "concreteType": "QWEEntry",
            "plural": false,
            "selections": [
                {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "id",
                    "args": null,
                    "storageKey": null
                }
            ]
        } as any)
    ];
    return {
        "kind": "Request",
        "fragment": {
            "kind": "Fragment",
            "name": "mutationsUpdateQWEEntryMutation",
            "type": "Mutation",
            "metadata": null,
            "argumentDefinitions": (v0 /*: any*/),
            "selections": (v1 /*: any*/)
        },
        "operation": {
            "kind": "Operation",
            "name": "mutationsUpdateQWEEntryMutation",
            "argumentDefinitions": (v0 /*: any*/),
            "selections": (v1 /*: any*/)
        },
        "params": {
            "operationKind": "mutation",
            "name": "mutationsUpdateQWEEntryMutation",
            "id": null,
            "text": "mutation mutationsUpdateQWEEntryMutation(\n  $title: String!\n  $startDate: Time!\n  $endDate: Time!\n  $subSkills: [ID!]!\n  $experience: String!\n  $evidence: String!\n  $learnt: String!\n  $moreExperience: String!\n  $needMoreExperience: String\n  $id: ID!\n  $placementID: ID!\n  $contentious: ContentiousType!\n  $file: FileUpload\n  $practiceAreas: [Int!]\n  $taskedBy: String\n) {\n  updateQWEEntry(input: {id: $id, placementID: $placementID, title: $title, startDate: $startDate, endDate: $endDate, practiceSubSkills: $subSkills, experience: $experience, evidence: $evidence, learnt: $learnt, moreExperience: $moreExperience, needMoreExperience: $needMoreExperience, contentious: $contentious, file: $file, practiceAreas: $practiceAreas, taskedBy: $taskedBy}) {\n    id\n  }\n}\n",
            "metadata": {}
        }
    } as any;
})();
(node as any).hash = 'f5c419389afc24a4ff25d9f9ba35ec12';
export default node;
