import * as React from 'react';
import { createUseStyles } from 'react-jss';
import { Theme } from 'helpers/theme';
import classnames from 'classnames';

const useStyles = createUseStyles((theme: Theme) => ({
  certInfoRow: {
    margin: 0,
    fontSize: '1.3rem',

    '& span': {
      fontSize: '1.1rem',
      float: 'left'
    }
  },
  certInfoLabel: {
    fontWeight: 900,
    textAlign: 'right',
    display: 'block',
    float: 'left',
    marginRight: '10px',
    fontSize: '1.7rem'
  },
  setWidth: {
    width: '150px'
  }
}));

type Props = {
  label: String;
  text: String;
  setWidth?: Boolean;
};

function Label({ label, text, setWidth = true }: Props) {
  const classes = useStyles();

  return (
    <p className={classes.certInfoRow}>
      <span
        className={classnames(
          classes.certInfoLabel,
          setWidth ? classes.setWidth : ''
        )}
      >
        {label}
      </span>
      <span>{text}</span>
    </p>
  );
}

export default Label;
