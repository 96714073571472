import React from 'react';
import Spacer from 'components/core/Spacers/Spacer';
import theme from 'helpers/theme';
import { Divider } from '@material-ui/core';
import { createFragmentContainer, graphql } from 'react-relay';
import styled from 'styled-components';
import { buildURL } from 'react-imgix';
import { AdviceCard_advice } from './__generated__/AdviceCard_advice.graphql';

const Container = styled.div`
  width: 280px;
  margin: 10px 20px 20px 0;
  border: 1px solid ${theme.colors.borderGrey};
  box-shadow: 0px 3px 18px #0000000d;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  background: white;
  cursor: pointer;
  transition: all 0.2s;
  &:hover {
    box-shadow: 0px 7px 18px #0000001f;
  }
`;

const Content = styled.div`
  padding: 23px;
`;

const NameContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  background: rgba(94, 94, 94, 0.6);
`;

const Name = styled.div`
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 10px 5px;
  padding-left: 5px;
  font-weight: 600;
  font-size: 19px;
  color: white;
`;

const Introduction = styled.div`
  display: -webkit-box;
  -webkit-line-clamp: 6;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: 600;
  font-size: 13px;
  //color: ${theme.colors.textGrey};
`;

const Description = styled.div`
  line-height: 24px;
  margin: 0;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  height: 96px;
  font-size: 14px;
  font-weight: 400;
`;

const Date = styled.div`
  font-size: 13px;
  font-weight: 800;
  color: ${theme.colors.textGrey};
`;

const Image = styled.div<{ imageUrl: string }>`
  width: 100%;
  height: 174px;
  background-image: url(${(props) => props.imageUrl});
  background-size: cover;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
`;

type Props = {
  advice: AdviceCard_advice;
  onClick: () => void;
};

function AdviceCard({ advice, onClick }: Props) {
  const { name, introduction, description, postedAt, bannerImageURL, links } = advice;

  return (
    <Container onClick={onClick}>
      <Image
        imageUrl={
          bannerImageURL
            ? buildURL(bannerImageURL, { bri: -5 })
            : require(`../../assets/defaultImage.svg`)
        }
      >
        <NameContainer>
          <Name>{name}</Name>
        </NameContainer>
      </Image>
      <Divider />
      <Content>
        <Spacer vertical spacing={1} />
        <Introduction style={{ whiteSpace: 'pre-line' }}>
          {introduction}
        </Introduction>

        {/*
        <Spacer vertical spacing={2} />
        <Description style={{ whiteSpace: 'pre-line' }}>
          {description}
        </Description>
        <Spacer vertical spacing={2} />
        <Date>Posted: {moment(postedAt).format('MMM DD, YYYY ')}</Date>
        */}
      </Content>
    </Container>
  );
}

export default createFragmentContainer(AdviceCard, {
  advice: graphql`
    fragment AdviceCard_advice on Advice {
      id
      name
      introduction
      description
      postedAt
      bannerImageURL
      links
      voiceoverURL
		  videoURL
    }
  `
});
