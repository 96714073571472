/* tslint:disable */
/* eslint-disable */

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type CourseCard_course = {
    readonly ident: number;
    readonly name: string;
    readonly bannerImageURL: string | null;
    readonly externalLink: string | null;
    readonly excerpt: string | null;
    readonly category: {
        readonly color: string;
        readonly name: string;
    } | null;
    readonly " $refType": "CourseCard_course";
};
export type CourseCard_course$data = CourseCard_course;
export type CourseCard_course$key = {
    readonly " $data"?: CourseCard_course$data;
    readonly " $fragmentRefs": FragmentRefs<"CourseCard_course">;
};



const node: ReaderFragment = (function () {
    var v0 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "name",
        "args": null,
        "storageKey": null
    } as any);
    return {
        "kind": "Fragment",
        "name": "CourseCard_course",
        "type": "Course",
        "metadata": null,
        "argumentDefinitions": [],
        "selections": [
            {
                "kind": "ScalarField",
                "alias": "ident",
                "name": "id",
                "args": null,
                "storageKey": null
            },
            (v0 /*: any*/),
            {
                "kind": "ScalarField",
                "alias": null,
                "name": "bannerImageURL",
                "args": null,
                "storageKey": null
            },
            {
                "kind": "ScalarField",
                "alias": null,
                "name": "externalLink",
                "args": null,
                "storageKey": null
            },
            {
                "kind": "ScalarField",
                "alias": null,
                "name": "excerpt",
                "args": null,
                "storageKey": null
            },
            {
                "kind": "LinkedField",
                "alias": null,
                "name": "category",
                "storageKey": null,
                "args": null,
                "concreteType": "Category",
                "plural": false,
                "selections": [
                    {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "color",
                        "args": null,
                        "storageKey": null
                    },
                    (v0 /*: any*/)
                ]
            }
        ]
    } as any;
})();
(node as any).hash = 'cba264fec04b6fcb8150b03aa870cf9f';
export default node;
