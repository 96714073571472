import { graphql } from 'react-relay';

export const CreateQualification = graphql`
  mutation mutationsCreateQualificationMutation(
    $qualificationType: QualificationType!
    $name: String!
    $file: FileUpload!
    $startDate: Time
    $endDate: Time
    $grade: String
  ) {
    createQualification(
      input: {
        qualificationType: $qualificationType
        name: $name
        startDate: $startDate
        endDate: $endDate
        grade: $grade
        file: $file
      }
    ) {
      id
      qualificationType
      name
      fileUrl
      startDate
      endDate
      grade
    }
  }
`;

export const UpdateQualification = graphql`
  mutation mutationsUpdateQualificationMutation(
    $id: ID!
    $name: String
    $qualificationType: QualificationType
    $file: FileUpload
    $startDate: Time
    $endDate: Time
    $grade: String
  ) {
    updateQualification(
      input: {
        id: $id
        name: $name
        qualificationType: $qualificationType
        startDate: $startDate
        endDate: $endDate
        grade: $grade
        file: $file
      }
    ) {
      id
      qualificationType
      name
      fileUrl
      startDate
      endDate
      grade
    }
  }
`;

export const DeleteQualification = graphql`
  mutation mutationsDeleteQualificationMutation($id: ID!) {
    deleteQualification(input: { id: $id })
  }
`;

export const CreatePlacement = graphql`
  mutation mutationsCreatePlacementMutation(
    $candidateID: ID!
    $name: String!
    $startDate: Time!
    $endDate: Time
    $qweIndividual: String
    $orgPosition: String
    $sraNumber: String
    $orgSRANumber:    String
    $supervisorName:  String!
    $supervisorEmail: String!
    $email:          String
    $fullTime:       Boolean!
    $partTimeDays: [Weekday!]
    $daysMissed: Int!
    $qweDocFile: FileUpload
  ) {
    createPlacement(
      input: {
        candidateID: $candidateID
        name: $name
        startDate: $startDate
        endDate: $endDate
        qweIndividual: $qweIndividual
        orgPosition: $orgPosition
        sraNumber: $sraNumber
        orgSRANumber: $orgSRANumber
        supervisorName: $supervisorName
        supervisorEmail: $supervisorEmail
        email: $email
        fullTime: $fullTime
        partTimeDays: $partTimeDays
        daysMissed: $daysMissed
        qweDocFile: $qweDocFile
      }
    ) {
      id
      name
      startDate
      endDate
      qweIndividual
      orgPosition
      sraNumber
      orgSRANumber
      supervisorName
      supervisorEmail
      email
      fullTime
      partTimeDays
      qweDocURL
      client {
        name
        managers {
          edges {
            id
            firstName
            lastName
            email
            profileImageUrl
          }
        }
      }
    }
  }
`;

export const UpdatePlacement = graphql`
  mutation mutationsUpdatePlacementMutation(
    $id: ID!
    $name: String
    $startDate: Time
    $endDate: Time
    $qweIndividual: String
    $orgPosition: String
    $sraNumber: String
    $orgSRANumber:    String
    $supervisorName:  String
    $supervisorEmail: String
    $email:          String
    $fullTime:       Boolean
    $partTimeDays: [Weekday!]
    $daysMissed: Int
    $qweDocFile: FileUpload
  ) {
    updatePlacement(
      input: {
        id: $id
        name: $name
        startDate: $startDate
        endDate: $endDate
        qweIndividual: $qweIndividual
        orgPosition: $orgPosition
        sraNumber: $sraNumber
        orgSRANumber: $orgSRANumber
        supervisorName: $supervisorName
        supervisorEmail: $supervisorEmail
        email: $email
        fullTime: $fullTime
        partTimeDays: $partTimeDays
        daysMissed: $daysMissed
        qweDocFile: $qweDocFile
      }
    ) {
      id
      name
      startDate
      endDate
      qweIndividual
      orgPosition
      sraNumber
      orgSRANumber
      supervisorName
      supervisorEmail
      email
      fullTime
      qweDocURL
      client {
        name
        managers {
          edges {
            id
            firstName
            lastName
            email
            profileImageUrl
          }
        }
      }
    }
  }
`;

export const CreateQWEEntry = graphql`
  mutation mutationsCreateQWEEntryMutation(
    $title: String!
    $startDate: Time!
    $endDate: Time!
    $subSkills: [ID!]!
    $experience: String!
    $evidence: String!
    $learnt: String!
    $moreExperience: String!
    $needMoreExperience: String!
    $placementID: ID!
    $contentious: ContentiousType!
    $file: FileUpload
    $practiceAreas: [Int!]
	  $taskedBy: String
  ) {
    createQWEEntry(
      input: {
        placementID: $placementID
        title: $title
        startDate: $startDate
        endDate: $endDate
        practiceSubSkills: $subSkills
        experience: $experience
        evidence: $evidence
        learnt: $learnt
        moreExperience: $moreExperience
        needMoreExperience: $needMoreExperience
        contentious: $contentious
        file: $file
        practiceAreas: $practiceAreas
        taskedBy: $taskedBy
      }
    ) {
      id
    }
  }
`;

export const SubmitEntries = graphql`
  mutation mutationsSubmitEntriesMutation(
    $supervisorID: ID
    $mentorID: ID
    $entries: [ID!]!
    $title: String
  ) {
    submitEntries(
      input: {
        supervisorID: $supervisorID
        mentorID: $mentorID
        entries: $entries
        title: $title
      }
    )
  }
`;

export const SubmitMeetingRequest = graphql`
  mutation mutationsSubmitMeetingRequestMutation(
    $mentorID: ID!
    $availabilitySlotID: ID
    $availableTime: Time
    $type: MeetingRequestType!
  ) {
    submitMeetingRequest(
      input: {
        mentorID: $mentorID
        availabilitySlotID: $availabilitySlotID
        availableTime: $availableTime
        type: $type
      }
    )
  }
`;

export const CreateOtherQWE = graphql`
  mutation mutationsCreateOtherQWEMutation(
    $candidateID: ID!
    $name: String!
    $completionDate: Time!
    $file: FileUpload!
  ) {
    createOtherQWE(
      input: {
        candidateID: $candidateID
        name: $name
        completionDate: $completionDate
        file: $file
      }
    ) {
      id
      name
      completionDate
      fileUrl
    }
  }
`;

export const UpdateQWEEntry = graphql`
  mutation mutationsUpdateQWEEntryMutation(
    $title: String!
    $startDate: Time!
    $endDate: Time!
    $subSkills: [ID!]!
    $experience: String!
    $evidence: String!
    $learnt: String!
    $moreExperience: String!
    $needMoreExperience: String
    $id: ID!
    $placementID: ID!
    $contentious: ContentiousType!
    $file: FileUpload
    $practiceAreas: [Int!]
    $taskedBy: String
  ) {
    updateQWEEntry(
      input: {
        id: $id
        placementID: $placementID
        title: $title
        startDate: $startDate
        endDate: $endDate
        practiceSubSkills: $subSkills
        experience: $experience
        evidence: $evidence
        learnt: $learnt
        moreExperience: $moreExperience
        needMoreExperience: $needMoreExperience
        contentious: $contentious
        file: $file
        practiceAreas: $practiceAreas
        taskedBy: $taskedBy
      }
    ) {
      id
    }
  }
`;

export const DeleteEntry = graphql`
  mutation mutationsDeleteEntryMutation($id: ID!) {
    deleteQWEEntry(input: { id: $id })
  }
`;

export const SubmitCharacterSuitabilityCompletion = graphql`
  mutation mutationsSubmitCharacterSuitabilityCompletionMutation {
    submitCharacterSuitabilityCompletion
  }
`;


export const CreateRobertQuestion = graphql`
  mutation mutationsCreateRobertQuestionMutation(
    $questionId: String!
    $answerId: Int!
    $chatHistory: String!
  ) {
    createRobertQuestion(
      input: {
        questionId: $questionId
        answerId: $answerId
        chatHistory: $chatHistory
      }
    ) {
      id
      question
      answers
    }
  }
`;

export const SetCandidateEventAttendance = graphql`
  mutation mutationsSetCandidateEventAttendanceMutation(
    $eventID: ID!
    $status: AttendanceStatus!
  ){
    setCandidateEventAttendance(input: {
      eventID: $eventID
      status: $status
    })
  }
`;

export const DeletePlacement = graphql`
  mutation mutationsDeletePlacementMutation($id: ID!) {
    deletePlacement(input: { id: $id })
  }
`;