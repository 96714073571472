/* tslint:disable */
/* eslint-disable */
/* @relayHash 056d5b28c599ad7b9766237653f9594e */

import { ConcreteRequest } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type App_Signoff_QueryVariables = {
    signoffID: string;
};
export type App_Signoff_QueryResponse = {
    readonly node: {
        readonly " $fragmentRefs": FragmentRefs<"SignoffGenerator_node">;
    } | null;
};
export type App_Signoff_Query = {
    readonly response: App_Signoff_QueryResponse;
    readonly variables: App_Signoff_QueryVariables;
};



/*
query App_Signoff_Query(
  $signoffID: ID!
) {
  node(id: $signoffID) {
    __typename
    ...SignoffGenerator_node
    id
  }
}

fragment SignoffGenerator_node on SignoffRequest {
  candidate {
    firstName
    lastName
    id
  }
  entries {
    edges {
      id
      title
      startDate
      endDate
      experience
      evidence
      learnt
      moreExperience
      taskedBy
      contentious
      needMoreExperience
      fileUrl
      practiceAreas
      practiceSubSkills {
        practiceSkillGroup {
          practiceSkill {
            id
            name
          }
          name
          id
        }
        id
        name
      }
    }
  }
}
*/

const node: ConcreteRequest = (function () {
    var v0 = [
        ({
            "kind": "LocalArgument",
            "name": "signoffID",
            "type": "ID!",
            "defaultValue": null
        } as any)
    ], v1 = [
        ({
            "kind": "Variable",
            "name": "id",
            "variableName": "signoffID"
        } as any)
    ], v2 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "id",
        "args": null,
        "storageKey": null
    } as any), v3 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "name",
        "args": null,
        "storageKey": null
    } as any);
    return {
        "kind": "Request",
        "fragment": {
            "kind": "Fragment",
            "name": "App_Signoff_Query",
            "type": "Query",
            "metadata": null,
            "argumentDefinitions": (v0 /*: any*/),
            "selections": [
                {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "node",
                    "storageKey": null,
                    "args": (v1 /*: any*/),
                    "concreteType": null,
                    "plural": false,
                    "selections": [
                        {
                            "kind": "FragmentSpread",
                            "name": "SignoffGenerator_node",
                            "args": null
                        }
                    ]
                }
            ]
        },
        "operation": {
            "kind": "Operation",
            "name": "App_Signoff_Query",
            "argumentDefinitions": (v0 /*: any*/),
            "selections": [
                {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "node",
                    "storageKey": null,
                    "args": (v1 /*: any*/),
                    "concreteType": null,
                    "plural": false,
                    "selections": [
                        {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "__typename",
                            "args": null,
                            "storageKey": null
                        },
                        (v2 /*: any*/),
                        {
                            "kind": "InlineFragment",
                            "type": "SignoffRequest",
                            "selections": [
                                {
                                    "kind": "LinkedField",
                                    "alias": null,
                                    "name": "candidate",
                                    "storageKey": null,
                                    "args": null,
                                    "concreteType": "Candidate",
                                    "plural": false,
                                    "selections": [
                                        {
                                            "kind": "ScalarField",
                                            "alias": null,
                                            "name": "firstName",
                                            "args": null,
                                            "storageKey": null
                                        },
                                        {
                                            "kind": "ScalarField",
                                            "alias": null,
                                            "name": "lastName",
                                            "args": null,
                                            "storageKey": null
                                        },
                                        (v2 /*: any*/)
                                    ]
                                },
                                {
                                    "kind": "LinkedField",
                                    "alias": null,
                                    "name": "entries",
                                    "storageKey": null,
                                    "args": null,
                                    "concreteType": "EntryPage",
                                    "plural": false,
                                    "selections": [
                                        {
                                            "kind": "LinkedField",
                                            "alias": null,
                                            "name": "edges",
                                            "storageKey": null,
                                            "args": null,
                                            "concreteType": "QWEEntry",
                                            "plural": true,
                                            "selections": [
                                                (v2 /*: any*/),
                                                {
                                                    "kind": "ScalarField",
                                                    "alias": null,
                                                    "name": "title",
                                                    "args": null,
                                                    "storageKey": null
                                                },
                                                {
                                                    "kind": "ScalarField",
                                                    "alias": null,
                                                    "name": "startDate",
                                                    "args": null,
                                                    "storageKey": null
                                                },
                                                {
                                                    "kind": "ScalarField",
                                                    "alias": null,
                                                    "name": "endDate",
                                                    "args": null,
                                                    "storageKey": null
                                                },
                                                {
                                                    "kind": "ScalarField",
                                                    "alias": null,
                                                    "name": "experience",
                                                    "args": null,
                                                    "storageKey": null
                                                },
                                                {
                                                    "kind": "ScalarField",
                                                    "alias": null,
                                                    "name": "evidence",
                                                    "args": null,
                                                    "storageKey": null
                                                },
                                                {
                                                    "kind": "ScalarField",
                                                    "alias": null,
                                                    "name": "learnt",
                                                    "args": null,
                                                    "storageKey": null
                                                },
                                                {
                                                    "kind": "ScalarField",
                                                    "alias": null,
                                                    "name": "moreExperience",
                                                    "args": null,
                                                    "storageKey": null
                                                },
                                                {
                                                    "kind": "ScalarField",
                                                    "alias": null,
                                                    "name": "taskedBy",
                                                    "args": null,
                                                    "storageKey": null
                                                },
                                                {
                                                    "kind": "ScalarField",
                                                    "alias": null,
                                                    "name": "contentious",
                                                    "args": null,
                                                    "storageKey": null
                                                },
                                                {
                                                    "kind": "ScalarField",
                                                    "alias": null,
                                                    "name": "needMoreExperience",
                                                    "args": null,
                                                    "storageKey": null
                                                },
                                                {
                                                    "kind": "ScalarField",
                                                    "alias": null,
                                                    "name": "fileUrl",
                                                    "args": null,
                                                    "storageKey": null
                                                },
                                                {
                                                    "kind": "ScalarField",
                                                    "alias": null,
                                                    "name": "practiceAreas",
                                                    "args": null,
                                                    "storageKey": null
                                                },
                                                {
                                                    "kind": "LinkedField",
                                                    "alias": null,
                                                    "name": "practiceSubSkills",
                                                    "storageKey": null,
                                                    "args": null,
                                                    "concreteType": "PracticeSubSkill",
                                                    "plural": true,
                                                    "selections": [
                                                        {
                                                            "kind": "LinkedField",
                                                            "alias": null,
                                                            "name": "practiceSkillGroup",
                                                            "storageKey": null,
                                                            "args": null,
                                                            "concreteType": "PracticeSkillGroup",
                                                            "plural": false,
                                                            "selections": [
                                                                {
                                                                    "kind": "LinkedField",
                                                                    "alias": null,
                                                                    "name": "practiceSkill",
                                                                    "storageKey": null,
                                                                    "args": null,
                                                                    "concreteType": "PracticeSkill",
                                                                    "plural": false,
                                                                    "selections": [
                                                                        (v2 /*: any*/),
                                                                        (v3 /*: any*/)
                                                                    ]
                                                                },
                                                                (v3 /*: any*/),
                                                                (v2 /*: any*/)
                                                            ]
                                                        },
                                                        (v2 /*: any*/),
                                                        (v3 /*: any*/)
                                                    ]
                                                }
                                            ]
                                        }
                                    ]
                                }
                            ]
                        }
                    ]
                }
            ]
        },
        "params": {
            "operationKind": "query",
            "name": "App_Signoff_Query",
            "id": null,
            "text": "query App_Signoff_Query(\n  $signoffID: ID!\n) {\n  node(id: $signoffID) {\n    __typename\n    ...SignoffGenerator_node\n    id\n  }\n}\n\nfragment SignoffGenerator_node on SignoffRequest {\n  candidate {\n    firstName\n    lastName\n    id\n  }\n  entries {\n    edges {\n      id\n      title\n      startDate\n      endDate\n      experience\n      evidence\n      learnt\n      moreExperience\n      taskedBy\n      contentious\n      needMoreExperience\n      fileUrl\n      practiceAreas\n      practiceSubSkills {\n        practiceSkillGroup {\n          practiceSkill {\n            id\n            name\n          }\n          name\n          id\n        }\n        id\n        name\n      }\n    }\n  }\n}\n",
            "metadata": {}
        }
    } as any;
})();
(node as any).hash = '34022d0b19a81cb8061f076dcdd88749';
export default node;
