import React from 'react';
import { buildURL } from 'react-imgix';
import styled from 'styled-components';

const Container = styled.div`
  background: white;
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  box-shadow: 0px 5px 15px #0000000f;
  width: 300px;
  overflow: hidden;
  position: relative;
`;

const BackgroundImage = styled.div<{ src: string }>`
  display: flex;
  align-items: center;
  cursor: pointer;
  height: 150px;
  background-image: url(${(props) => props.src});
  background-size: cover;
  background-position: center;
`;

const Title = styled.span`
  margin-left: 22px;
  margin-right: 22px;
  margin-top: 10px;
  margin-bottom: 10px;
  font-weight: 700;
  font-size: 20px;
`;

const Content = styled.div`
  padding: 22px;
  display: flex;
  flex-direction: column;
`;

type Props = {
  title: string;
  thumbnail?: string;
  onClick?: () => void;
};

function VideoCard({ title, thumbnail, onClick }: Props){
  return (
    <Container onClick={onClick}>
      <BackgroundImage
        src={
          thumbnail 
            ? buildURL(thumbnail, { w: 300, h: 150 })
            :  require(`../../assets/defaultImage.svg`)
        }
      />
      <Title>{title}</Title>
    </Container>
  )
}

export default VideoCard;