import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CardMedia from '@material-ui/core/CardMedia';
import Spacer from 'components/core/Spacers/Spacer';
import theme from 'helpers/theme';
import { Grid } from '@material-ui/core';
import { Page as _Page } from 'components/Pagination/Paginator';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { useRouter } from 'found';
import { Advice_advice } from 'views/Advice/__generated__/Advice_advice.graphql';
import { buildURL } from 'react-imgix';

const useStyles = makeStyles({
  media: {
    height: '280px',
    borderRadius: '5px'
  },
  mediaTitle: {
    margin: '10px 0 10px 0'
  },
  mediaContainer: {
    width: '-webkit-fill-available'
  }
});

const Container = styled.div`
  @media (max-width: 1300px) {
    width: 700px;
  }
  @media (max-width: 1100px) {
    width: 600px;
  }
  @media (max-width: 1000px) {
    width: 400px;
  }
  @media (max-width: 750px) {
    width: 100vw;
  }

  width: 1000px;
  background: white;
  min-height: 100vh;
  border: 1px solid ${theme.colors.borderGrey};
`;

const Content = styled.div`
  padding: 42px 60px;
`;

const Heading = styled.div`
  font-weight: 700;
  font-size: 32px;
  color: ${theme.colors.primaryBlack};
  margin-bottom: 10px;
`;

const Introduction = styled.div`
  font-weight: 400;
  font-size: 16px;
  color: ${theme.colors.textGrey};
`;

const MediaContainer = styled.div`
  margin: 10px 10px 10px 0;
  border: 1px solid ${theme.colors.borderGrey};
  box-shadow: 0px 3px 18px #0000000d;
  border-radius: 5px;
`;

const Date = styled.div`
  font-weight: 400;
  font-size: 14px;
  color: ${theme.colors.textGrey};
`;

const Description = styled.div`
  font-weight: 300;
  font-size: 16px;
`;

const BackHeading = styled.div`
  border-bottom: 1px solid ${theme.colors.borderGrey};
  padding: 25px 60px;
  color: ${theme.colors.textGrey};
  cursor: pointer;
  font-size: 14px;
  font-weight: 600;
  display: flex;
  align-items: center;
  transition: all 0.2s;
  background: white;
  &:hover {
    color: #444750;
    background: #fbfbfb;
  }
`;

type Props = {
  advice: Advice_advice;
};

function AdvicePage({ advice }: Props) {
  const classes = useStyles();
  console.log(advice)
  const { name, introduction, description, postedAt, bannerImageURL, links, voiceoverURL, videoURL } = advice;

  const { router } = useRouter();
  return (
    <Container>
      <BackHeading
        onClick={() => {
          router.push('/app/advices');
        }}
      >
        <FontAwesomeIcon icon={faArrowLeft} />
        <Spacer horizontal spacing={2} />
        <span>Back to Competency Plus</span>
      </BackHeading>
      <Content>
        <Heading>{name}</Heading>
        <Introduction style={{ whiteSpace: 'pre-line' }}>
          {introduction}
        </Introduction>

        <Spacer vertical spacing={2} />
        <Grid>
          <MediaContainer>
            <CardMedia
              className={classes.media}
              image={
                bannerImageURL
                  ? buildURL(bannerImageURL, { bri: -5 })
                  : require(`../../assets/defaultImage.svg`)
              }
              title="Advice image"
            />
          </MediaContainer>
          <Spacer vertical spacing={2} />
          {/*<Date>{moment(postedAt).format('MMM DD, YYYY ')}</Date>
          <Spacer vertical spacing={2} />*/}
          <Description style={{ whiteSpace: 'pre-line' }}>
            {' '}
            <span dangerouslySetInnerHTML={{__html: description}} />
          </Description>
          <Spacer vertical spacing={2} />
          <div>
            <div>Additional Resources and Links</div>
              {links?.map(
                (link) =>
                  link && (
                  <p><a href={link}>{link}</a></p>)
              )}
          </div>
          <div>
            {voiceoverURL && <div>
                <div className={classes.mediaTitle}>Audio Voiceover</div>
                <audio className={classes.mediaContainer} controls>
                  <source src={voiceoverURL} type="audio/mp3" />
                  Your browser does not support the audio element.
                </audio>
              </div>}
          </div>
          <div>
            
            {videoURL && <div>
                <div className={classes.mediaTitle}>Video Source</div>
                <video className={classes.mediaContainer} controls>
                  <source src={videoURL} type="video/mp4" />
                  Your browser does not support the video element.
                </video>
              </div>}
          </div>
        </Grid>
      </Content>
    </Container>
  );
}

export default AdvicePage;
