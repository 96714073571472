import React from 'react';

import LoginDialogue from 'components/LoginDialogue';
import { Theme } from 'helpers/theme';
import environment from 'api/environment';
import { createUseStyles, useTheme } from 'react-jss';
import { commitMutation, graphql, createFragmentContainer } from 'react-relay';
import { GraphError } from 'types/general';
import { Router, useRouter } from 'found';
import Card from '../../components/core/Cards/Card';
import * as moment from 'moment-timezone';

const useStyles = createUseStyles((theme: Theme) => ({
  root: {
    background: theme.colors.backgroundGrey,
    width: '100%',
    height: '100%',
    position: 'absolute',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  contentContainer: {
    backgroundColor: theme.colors.primaryWhite,
    width: 320,
    padding: 20,
  },
  timeText: {
    fontWeight: 'bold',
    fontSize: '1.3em',
    marginTop: 5,
  }
}));

const CheckTimestamp = () => {
  const theme = useTheme();
  const classes = useStyles({ theme });
  const { match } = useRouter();
  const { timestamp } = match?.params;
  const momentTime = moment(+timestamp);
  const momentTimeLocal = moment(+timestamp);
  const isValid = momentTime.isValid();

  return (
    <>
      <div className={classes.root}>
        <Card padding="medium" className={classes.contentContainer}>
          {!isValid ? (
            <p>Your timestamp is not valid</p>
          ) : (
            <>
              <p>Hi, the Europe/London timestamp is: <br/><span className={classes.timeText}>{momentTime.tz('Europe/London').format('MM/DD/YYYY HH:mm:ss')}</span></p>
              <p>converting to your local time: <br /><span className={classes.timeText}>{momentTimeLocal.format('MM/DD/YYYY HH:mm:ss')}</span></p>
            </>
          )}
        </Card>
      </div>
    </>
  );
};

export default CheckTimestamp;
