import classNames from 'classnames';
import { Theme } from 'helpers/theme';
import * as React from 'react';
import { createUseStyles } from 'react-jss';
import { useMediaQuery } from 'react-responsive';

const useStyles = createUseStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    borderTop: [1, 'solid', theme.colors.borderGrey],
    zIndex: 10,
    gridArea: '1 / 3',
    background: theme.colors.primaryWhite,
    paddingRight: 10,
    paddingTop: theme.spacing(0)
  },
  menu: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    alignContent: 'center',
    padding: '0 20px',
    marginTop: theme.spacing(1)
  },
  logo: {
    cursor: 'pointer',
    height: 'auto',
    width: '125px',
    marginBottom: theme.spacing(0),
    '@media (max-width: 750px)': {
      height: '40px',
      width: '40px'
    }
  },
  terms: {
    fontWeight: 800,
    fontSize: theme.fontSizes.large,
    color: theme.colors.textGrey,
    '@media (max-width: 750px)': {
      display: 'none'
    }
  },
  links: {
    textDecoration: 'none',
    color: theme.colors.textGrey,
  }
}));

type Props = {
  className?: string;
  onLogoClick?: () => void;
}

function FooterMenu({
  className,
  onLogoClick
}: Props){
  const classes = useStyles();
  const isMobile = useMediaQuery({ maxWidth: 750 });

  return (
  <div className={classNames(classes.root, className)}>
    <div className={classes.menu}>
      <img
        className={classes.logo}
        onClick={() => onLogoClick && onLogoClick()}
        src={require(`../../../assets/logo/pathways-${isMobile ? 'icon.svg' : 'logo-pad.png'
            }`)}
        alt="Logo"
      />
      <span className={classes.terms}>
        <a className={classes.links} href={'https://accutrainee.com/terms-of-use/'} target={'_blank'}>Terms &amp; Conditions</a>
        {' '}/{' '} 
        <a className={classes.links} href={'https://accutrainee.com/privacy-policy/'} target={'_blank'}>Privacy Policy</a>
      </span>
    </div>
  </div>
  );
}

export default FooterMenu;