import environment from 'api/environment';
import React from 'react';
import { graphql, QueryRenderer } from 'react-relay';
import LoadMoreDropdown from './LoadMoreDropdown';

type Props = {
  mentorID: string;
  selected: string;
  setSelected: (sel: string) => void;
};

const PAGE_SIZE = 10;

function AvailabilityDropdown({ mentorID, selected, setSelected }: Props) {
  const [limit, setLimit] = React.useState(PAGE_SIZE);

  return (
    <QueryRenderer
      environment={environment}
      query={graphql`
        query AvailabilityDropdown_Query(
          $mentorID: ID!
          $booked: Boolean!
          $page: Page!
          $orderBy: OrderBy
        ) {
          availability(
            filter: { mentorID: $mentorID, booked: $booked }
            page: $page
            orderBy: $orderBy
          ) {
            edges {
              interviewType
              id
              startTime
              endTime
            }
            pageInfo {
              total
              offset
              limit
              given
            }
          }
        }
      `}
      variables={{
        mentorID,
        booked: false,
        page: {
          offset: 0,
          limit: limit
        },
        orderBy: {
          ascending: true,
          field: 'start_time'
        }
      }}
      render={({ error, props }) => {
        let availability = null;

        if (props) {
          const resp = props as any;
          availability = resp.availability;
          console.log(availability);
        }

        return (
          <LoadMoreDropdown
            options={
              availability?.edges?.map((slot: any) => ({
                interviewType: slot?.interviewType ?? '',
                startTime: slot?.startTime ?? '',
                endTime: slot?.endTime ?? '',
                value: slot?.id ?? ''
              })) ?? []
            }
            placeholder={
              selected
                ? 'Availability slot selected'
                : 'Select availability slot'
            }
            onChange={(ids) => {
              setSelected(ids);
            }}
            onLoadMore={() => {
              setLimit(limit + PAGE_SIZE);
            }}
            selected={selected}
            hasMore={
              availability?.pageInfo?.total !== availability?.pageInfo?.given
            }
          />
        );
      }}
    />
  );
}

export default AvailabilityDropdown;
