import theme from 'helpers/theme';
import React from 'react';
import styled from 'styled-components';
import { getSignoffPDF } from 'views/helpers';
import { Button } from '@material-ui/core';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  background: white;
  border-radius: 12px;
  border: 1px solid ${theme.colors.borderGrey};
  padding: 20px;
  box-shadow: 0px 3px 18px #0000000d;
`;

const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`;

const Title = styled.div`
  font-weight: 700;
  font-size: ${theme.fontSizes.default}px;
  margin-bottom: 3px;
`;

const CreatedAt = styled.div`
  font-size: 12px;
  color: ${theme.colors.textGrey};
`;

const Pending = styled.div`
  font-size: 12px;
  font-weight: 600;
  margin-top: 40px;
  margin-bottom: 15px;
  justify-content: center;
  align-items: center;
  display: flex;
`;

const Approved = styled(Pending)`
  color: #84ea47;
`;

// const Button = styled.button`
//   background-color: #c6c7ca;
//   color: ${theme.colors.primaryWhite};
//   text-decoration: none;
//   border-radius: 6px;
//   padding: 11px;
//   font-size: 13px;
//   font-weight: 600;
//   justify-content: center;
//   align-items: center;
//   display: flex;
//   cursor: pointer;
// `;

type Props = {
  entriesLength: string;
  approval: boolean;
  createdAt: string;
  title: string;
  PDFURL: string;
  signoffRequestID: string;
};

export default function SignoffRequestCard({
  entriesLength,
  approval,
  createdAt,
  title,
  PDFURL,
  signoffRequestID
}: Props) {
  const [loading, setLoading] = React.useState(false);

  const fetchPDF = (resolve: (res: string | null) => void) => {
    setLoading(true);
    getSignoffPDF(signoffRequestID).then((res) => {
      setLoading(false);
      resolve(res); 
    });
  };

  return (
    <Container>
      <InfoContainer>
        <Title>
          {title}
        </Title>
        <Title>
          {entriesLength}{' '}
          {parseInt(entriesLength, 10) === 1 ? 'Entry' : 'Entries'}
        </Title>
        <CreatedAt>Sent {createdAt}</CreatedAt>
      </InfoContainer>
      {approval ? <Approved>APPROVED</Approved> : <Pending>PENDING</Pending>}
      <Button
        color="default"
        variant="contained"
        disabled={loading}
        onClick={
          () => fetchPDF((url) => url && window.open(url, '_blank'))
        }
      >
        Download Entries
      </Button>
    </Container>
  );
}
