import Button from 'components/core/Input/ButtonBase';
import theme from 'helpers/theme';
import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  background: white;
  border-radius: 12px;
  border: 1px solid ${theme.colors.borderGrey};
  padding: 20px;
  box-shadow: 0px 3px 18px #0000000d;
`;

const ProfileIcon = styled.div<{ imageUrl?: string }>`
  height: 100px;
  width: 100px;
  border-radius: 100%;
  align-self: center;
  margin: 15px;
  background-size: cover;
  background-image: url(${(props) => props.imageUrl});
`;

const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-bottom: 15px;
`;

const Name = styled.div`
  font-weight: 700;
  font-size: ${theme.fontSizes.default}px;
  margin-bottom: 3px;
`;

const Email = styled.div`
  font-size: 12px;
  color: ${theme.colors.textGrey};
  margin-bottom: 3px;
`;

const Role = styled.div`
  font-weight: 800;
  font-size: 13px;
`;

type Props = {
  name: string;
  email: string;
  imageUrl: string;
  role?: string;
  onClick: () => void;
};

export default function SupervisorCard({
  name,
  email,
  imageUrl,
  role,
  onClick
}: Props) {
  return (
    <Container>
      <Role>{role}</Role>
      <ProfileIcon imageUrl={imageUrl} />
      <InfoContainer>
        <Name>{name}</Name>
        <Email>{email}</Email>
      </InfoContainer>
      <Button archetype="submit" onClick={onClick} style={{ height: 30 }}>
        Send Entries
      </Button>
    </Container>
  );
}
