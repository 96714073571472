/* tslint:disable */
/* eslint-disable */
/* @relayHash 3b287eae404c125e7d53f723bc68c050 */

import { ConcreteRequest } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type App_EntryUpdate_QueryVariables = {
    id: string;
};
export type App_EntryUpdate_QueryResponse = {
    readonly candidate: {
        readonly " $fragmentRefs": FragmentRefs<"EntryUpdate_candidate">;
    } | null;
    readonly node: {
        readonly " $fragmentRefs": FragmentRefs<"EntryUpdate_node">;
    } | null;
};
export type App_EntryUpdate_Query = {
    readonly response: App_EntryUpdate_QueryResponse;
    readonly variables: App_EntryUpdate_QueryVariables;
};



/*
query App_EntryUpdate_Query(
  $id: ID!
) {
  candidate {
    ...EntryUpdate_candidate
    id
  }
  node(id: $id) {
    __typename
    ...EntryUpdate_node
    id
  }
}

fragment EntryUpdate_candidate on Candidate {
  skillGroupType
  competency {
    practiceAreas {
      edges {
        id
        name
        createdAt
      }
    }
    id
  }
  placements {
    edges {
      id
      name
    }
  }
}

fragment EntryUpdate_node on QWEEntry {
  id
  title
  startDate
  endDate
  placement {
    id
    name
  }
  practiceSubSkills {
    practiceSkillGroup {
      practiceSkill {
        practiceArea {
          id
          name
        }
        id
        name
      }
      name
      id
    }
    id
    name
  }
  experience
  evidence
  learnt
  moreExperience
  needMoreExperience
  contentious
  fileUrl
  practiceAreas
  taskedBy
}
*/

const node: ConcreteRequest = (function () {
    var v0 = [
        ({
            "kind": "LocalArgument",
            "name": "id",
            "type": "ID!",
            "defaultValue": null
        } as any)
    ], v1 = [
        ({
            "kind": "Variable",
            "name": "id",
            "variableName": "id"
        } as any)
    ], v2 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "id",
        "args": null,
        "storageKey": null
    } as any), v3 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "name",
        "args": null,
        "storageKey": null
    } as any), v4 = [
        (v2 /*: any*/),
        (v3 /*: any*/)
    ];
    return {
        "kind": "Request",
        "fragment": {
            "kind": "Fragment",
            "name": "App_EntryUpdate_Query",
            "type": "Query",
            "metadata": null,
            "argumentDefinitions": (v0 /*: any*/),
            "selections": [
                {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "candidate",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "Candidate",
                    "plural": false,
                    "selections": [
                        {
                            "kind": "FragmentSpread",
                            "name": "EntryUpdate_candidate",
                            "args": null
                        }
                    ]
                },
                {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "node",
                    "storageKey": null,
                    "args": (v1 /*: any*/),
                    "concreteType": null,
                    "plural": false,
                    "selections": [
                        {
                            "kind": "FragmentSpread",
                            "name": "EntryUpdate_node",
                            "args": null
                        }
                    ]
                }
            ]
        },
        "operation": {
            "kind": "Operation",
            "name": "App_EntryUpdate_Query",
            "argumentDefinitions": (v0 /*: any*/),
            "selections": [
                {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "candidate",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "Candidate",
                    "plural": false,
                    "selections": [
                        {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "skillGroupType",
                            "args": null,
                            "storageKey": null
                        },
                        {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "competency",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "Competency",
                            "plural": false,
                            "selections": [
                                {
                                    "kind": "LinkedField",
                                    "alias": null,
                                    "name": "practiceAreas",
                                    "storageKey": null,
                                    "args": null,
                                    "concreteType": "PracticeAreaPage",
                                    "plural": false,
                                    "selections": [
                                        {
                                            "kind": "LinkedField",
                                            "alias": null,
                                            "name": "edges",
                                            "storageKey": null,
                                            "args": null,
                                            "concreteType": "PracticeArea",
                                            "plural": true,
                                            "selections": [
                                                (v2 /*: any*/),
                                                (v3 /*: any*/),
                                                {
                                                    "kind": "ScalarField",
                                                    "alias": null,
                                                    "name": "createdAt",
                                                    "args": null,
                                                    "storageKey": null
                                                }
                                            ]
                                        }
                                    ]
                                },
                                (v2 /*: any*/)
                            ]
                        },
                        {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "placements",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "PlacementPage",
                            "plural": false,
                            "selections": [
                                {
                                    "kind": "LinkedField",
                                    "alias": null,
                                    "name": "edges",
                                    "storageKey": null,
                                    "args": null,
                                    "concreteType": "Placement",
                                    "plural": true,
                                    "selections": (v4 /*: any*/)
                                }
                            ]
                        },
                        (v2 /*: any*/)
                    ]
                },
                {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "node",
                    "storageKey": null,
                    "args": (v1 /*: any*/),
                    "concreteType": null,
                    "plural": false,
                    "selections": [
                        {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "__typename",
                            "args": null,
                            "storageKey": null
                        },
                        (v2 /*: any*/),
                        {
                            "kind": "InlineFragment",
                            "type": "QWEEntry",
                            "selections": [
                                {
                                    "kind": "ScalarField",
                                    "alias": null,
                                    "name": "title",
                                    "args": null,
                                    "storageKey": null
                                },
                                {
                                    "kind": "ScalarField",
                                    "alias": null,
                                    "name": "startDate",
                                    "args": null,
                                    "storageKey": null
                                },
                                {
                                    "kind": "ScalarField",
                                    "alias": null,
                                    "name": "endDate",
                                    "args": null,
                                    "storageKey": null
                                },
                                {
                                    "kind": "LinkedField",
                                    "alias": null,
                                    "name": "placement",
                                    "storageKey": null,
                                    "args": null,
                                    "concreteType": "Placement",
                                    "plural": false,
                                    "selections": (v4 /*: any*/)
                                },
                                {
                                    "kind": "LinkedField",
                                    "alias": null,
                                    "name": "practiceSubSkills",
                                    "storageKey": null,
                                    "args": null,
                                    "concreteType": "PracticeSubSkill",
                                    "plural": true,
                                    "selections": [
                                        {
                                            "kind": "LinkedField",
                                            "alias": null,
                                            "name": "practiceSkillGroup",
                                            "storageKey": null,
                                            "args": null,
                                            "concreteType": "PracticeSkillGroup",
                                            "plural": false,
                                            "selections": [
                                                {
                                                    "kind": "LinkedField",
                                                    "alias": null,
                                                    "name": "practiceSkill",
                                                    "storageKey": null,
                                                    "args": null,
                                                    "concreteType": "PracticeSkill",
                                                    "plural": false,
                                                    "selections": [
                                                        {
                                                            "kind": "LinkedField",
                                                            "alias": null,
                                                            "name": "practiceArea",
                                                            "storageKey": null,
                                                            "args": null,
                                                            "concreteType": "PracticeArea",
                                                            "plural": false,
                                                            "selections": (v4 /*: any*/)
                                                        },
                                                        (v2 /*: any*/),
                                                        (v3 /*: any*/)
                                                    ]
                                                },
                                                (v3 /*: any*/),
                                                (v2 /*: any*/)
                                            ]
                                        },
                                        (v2 /*: any*/),
                                        (v3 /*: any*/)
                                    ]
                                },
                                {
                                    "kind": "ScalarField",
                                    "alias": null,
                                    "name": "experience",
                                    "args": null,
                                    "storageKey": null
                                },
                                {
                                    "kind": "ScalarField",
                                    "alias": null,
                                    "name": "evidence",
                                    "args": null,
                                    "storageKey": null
                                },
                                {
                                    "kind": "ScalarField",
                                    "alias": null,
                                    "name": "learnt",
                                    "args": null,
                                    "storageKey": null
                                },
                                {
                                    "kind": "ScalarField",
                                    "alias": null,
                                    "name": "moreExperience",
                                    "args": null,
                                    "storageKey": null
                                },
                                {
                                    "kind": "ScalarField",
                                    "alias": null,
                                    "name": "needMoreExperience",
                                    "args": null,
                                    "storageKey": null
                                },
                                {
                                    "kind": "ScalarField",
                                    "alias": null,
                                    "name": "contentious",
                                    "args": null,
                                    "storageKey": null
                                },
                                {
                                    "kind": "ScalarField",
                                    "alias": null,
                                    "name": "fileUrl",
                                    "args": null,
                                    "storageKey": null
                                },
                                {
                                    "kind": "ScalarField",
                                    "alias": null,
                                    "name": "practiceAreas",
                                    "args": null,
                                    "storageKey": null
                                },
                                {
                                    "kind": "ScalarField",
                                    "alias": null,
                                    "name": "taskedBy",
                                    "args": null,
                                    "storageKey": null
                                }
                            ]
                        }
                    ]
                }
            ]
        },
        "params": {
            "operationKind": "query",
            "name": "App_EntryUpdate_Query",
            "id": null,
            "text": "query App_EntryUpdate_Query(\n  $id: ID!\n) {\n  candidate {\n    ...EntryUpdate_candidate\n    id\n  }\n  node(id: $id) {\n    __typename\n    ...EntryUpdate_node\n    id\n  }\n}\n\nfragment EntryUpdate_candidate on Candidate {\n  skillGroupType\n  competency {\n    practiceAreas {\n      edges {\n        id\n        name\n        createdAt\n      }\n    }\n    id\n  }\n  placements {\n    edges {\n      id\n      name\n    }\n  }\n}\n\nfragment EntryUpdate_node on QWEEntry {\n  id\n  title\n  startDate\n  endDate\n  placement {\n    id\n    name\n  }\n  practiceSubSkills {\n    practiceSkillGroup {\n      practiceSkill {\n        practiceArea {\n          id\n          name\n        }\n        id\n        name\n      }\n      name\n      id\n    }\n    id\n    name\n  }\n  experience\n  evidence\n  learnt\n  moreExperience\n  needMoreExperience\n  contentious\n  fileUrl\n  practiceAreas\n  taskedBy\n}\n",
            "metadata": {}
        }
    } as any;
})();
(node as any).hash = '04a8467e5b72b959f74c262f894d1304';
export default node;
