import React from 'react';
import { useRouter } from 'found';
import Entry, { dropDownContentiousOptions, EntryState } from './Entry';
import { createFragmentContainer, graphql } from 'react-relay';
import { EntryCreate_candidate } from './__generated__/EntryCreate_candidate.graphql';
import { useToastDispatch } from 'components/Toast/ToastProvider';
import { makeMutation } from 'api/helpers';
import { CreateQWEEntry } from 'api/mutations';

type Props = {
  candidate: EntryCreate_candidate;
};

function EntryCreate({ candidate }: Props) {
  const { match, router } = useRouter();
  const dispatch = useToastDispatch();
  const onSave = async (props: EntryState) => {
    try {
      let subSkills: string[] = [];
      props.selectedCompetencies.forEach(comp => comp.skills.forEach(skill => subSkills = [...subSkills, ...skill.options.map(op => op.value)]));

      await makeMutation(CreateQWEEntry, {
        placementID: props.selectedPlacement?.id,
        title: props.title,
        startDate: new Date(props.startDate).toISOString(),
        endDate: new Date(props.endDate).toISOString(),
        subSkills: subSkills,
        experience: props.experience,
        evidence: props.evidence,
        learnt: props.whatWasLearned,
        moreExperience: props.moreExperience,
        needMoreExperience: props.needMoreExperience,
        contentious: props.selectedContentiousOption?.id,
        file: props.file,
        practiceAreas: props.selectedStaticAreas.map(p => p.id),
        taskedBy: props.taskedBy
      });
      dispatch({
        text: 'Entry submitted successfully',
        alert: 'success',
        stayFor: 4000
      });
      router.push('/app/qwe/portfolio');
    } catch (err) {
      console.log('Unable to submit entry', err);
      dispatch({
        text: "Please enter a placement",
        alert: 'error',
        stayFor: 6000
      });
    }
  };

  return (
    <Entry
      candidate={candidate}
      onSave={onSave}
      initialState={{
        new: true,
        startDate: new Date().toISOString(),
        endDate: new Date().toISOString(),
        experience: '',
        evidence: '',
        moreExperience: '',
        needMoreExperience: '',
        whatWasLearned: '',
        title: '',
        selectedCompetencies: [],
        confirmed: false,
        selectedContentiousOption: dropDownContentiousOptions[1],
        selectedStaticAreas: [],
        file: undefined,
      }}
    />
  );
}

export default createFragmentContainer(EntryCreate, {
  candidate: graphql`
    fragment EntryCreate_candidate on Candidate {
      skillGroupType
      competency {
        practiceAreas {
          edges {
            id
            name
            createdAt
          }
        }
      }
      placements {
        edges {
          id
          name
        }
      }
    }
  `
});
