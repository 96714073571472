import React from 'react';
import styled from 'styled-components';
import { makeMutation } from 'api/helpers';
import { SubmitMeetingRequest } from 'api/mutations';
import Button from 'components/core/Input/ButtonBase';
import Spacer from 'components/core/Spacers/Spacer';
import { Body, Footer } from 'components/SideModal/Tabs';
import { useToastDispatch } from 'components/Toast/ToastProvider';
import { ScaleLoader } from 'react-spinners';
import { createUseStyles, useTheme } from 'react-jss';
import { Theme } from 'helpers/theme';
import { State } from './Content';
import { ThemeProvider } from '@material-ui/core/styles';
import themeCustom from '../../helpers/themeCustom';
import  'react-date-range-picker/dist/main.css'
import dayjs from 'dayjs';
import OverrideModal from 'components/OverrideModal';
import { formatDateString } from 'utils/getTimeFromString';

const useStyles = createUseStyles((theme: Theme) => ({
  dateInput: {
    padding: '10px 10px !important',
    border: '1px solid #08080814 !important',
    borderRadius: 5,
    '& div': {
      '&:before': { borderBottom: 'none !important' },
      '&:after': { borderBottom: 'none !important' }
    },
    caretColor: 'transparent'
  }
}));

const SectionTitle = styled.h1`
  color: #271507;
  font-size: 20px;
  font-weight: 700;
`;

const Text = styled.span`
  margin-top: 10px;
  & > a {
    color: black;
  }
`;

type Props = {
  state: State;
  setState: (newState: any) => void;
  closeModal: () => void;
};

function ContentTPMAppraisal({ state, setState, closeModal }: Props) {
  const theme = useTheme();
  const classes = useStyles({ theme });
  const dispatch = useToastDispatch();
  const [isOpenOverrideModal, setIsOpenOverrideModal] = React.useState(false);

  const onSave = async () => {
    if (state.saving) return;

    if (!state.availableTime) {
      dispatch({
        text: 'Please enter an available date and time',
        alert: 'warning',
        stayFor: 6000
      });
      return;
    }

    try {
      setState((s: State) => ({ ...s, saving: true }));
      await makeMutation(SubmitMeetingRequest, {
        mentorID: state.mentorID,
        availableTime: state.availableTime,
        type: state.meetingRequestType
      });
      dispatch({
        text: 'Meeting request sent successfully',
        alert: 'success',
        stayFor: 4000
      });
      closeModal();
    } catch (err) {
      console.log('Unable to send meeting request', err);
      dispatch({
        text: err.message,
        alert: 'error',
        stayFor: 6000
      });
    }
    setState((s: State) => ({ ...s, saving: false }));
  };

  const handleCloseOverrideModal = () => {
    setIsOpenOverrideModal(false);
  };

  const CustomDatePicker = () => {
    return (
      <div className="react-google-flight-datepicker">
        <div className="date-picker">
          <div className="date-picker-input-wrap">
            <div className="date-picker-input" onClick={() => setIsOpenOverrideModal(true)}>
              <div className="date-picker-date-group">
                <div className="date is-focus" role="button" id="start-date-input-button">
                  <div className="selected-date">{state?.availableTime ? formatDateString(state?.availableTime) : ''}</div>
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" className="icon-calendar">
                    <path d="M9 11H7v2h2v-2zm4 0h-2v2h2v-2zm4 0h-2v2h2v-2zm2-7h-1V2h-2v2H8V2H6v2H5c-1.11 0-1.99.9-1.99 2L3 20a2 2 0 002 2h14c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm0 16H5V9h14v11z"></path>
                    </svg>
                    <label></label>
                </div>
              </div>
            </div>            
          </div>
        </div>
      </div>
    )
  }

  return (
    <>
      <Body>
        <SectionTitle>Please propose a date &amp; time (Europe/London)</SectionTitle>
        <ThemeProvider theme={themeCustom}>
          <CustomDatePicker />
        </ThemeProvider>
        <Text>*Download appraisal form <a target='_blank' href='https://pathways-dev-uploads.s3.eu-west-1.amazonaws.com/manualUploads/appraisal_form.docx'>here</a></Text>
        <Text>*Download end of training contract form <a target='_blank' href='https://pathways-dev-uploads.s3.eu-west-1.amazonaws.com/manualUploads/tc_checklist.docx'>here</a></Text>
        <Spacer vertical spacing={1} />
        <OverrideModal
          isEdit={false}
          isOpen={isOpenOverrideModal}
          onClose={handleCloseOverrideModal}
          highlightToday={true}
          onChange={(data:any)=>{
            console.log('OverrideModal onChange', data);
            setState((s: State) => ({
              ...s,
              availableTime: dayjs(data.startTime).format("YYYY-MM-DDTHH:mm:ssZ")
            }));
          }} 
          data={ 
            { 
              id: '',
              endTime: new Date(),
              selectedTimeRange: {
                id: '',
                start: '',
                end: ''
              },
              startTime : state.availableTime ? state.availableTime : new Date()}
          }
          isTime={true}
        />
      </Body>
      <Footer>
        <div style={{ display: 'flex' }}>
          <Button archetype={'default'} onClick={closeModal}>
            Cancel
          </Button>
          <Spacer horizontal spacing={2} />
          <Button archetype="submit" onClick={state.saving ? () => {} : onSave}>
            {state.saving ? (
              <ScaleLoader color="white" height={10} />
            ) : state.meetingRequestType === 'tpm' ? (
              'Send TPM Request'
            ) : (
              'Send Appraisal Request'
            )}
          </Button>
        </div>
      </Footer>
    </>
  );
}

export default ContentTPMAppraisal;
