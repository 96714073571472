/* tslint:disable */
/* eslint-disable */
/* @relayHash 4be30525cab88d988b535e9989ecb97a */

import { ConcreteRequest } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type Page = {
    offset?: number | null;
    limit?: number | null;
};
export type AdviceFilter = {
    name?: string | null;
};
export type App_Advices_QueryVariables = {
    page?: Page | null;
    filter?: AdviceFilter | null;
};
export type App_Advices_QueryResponse = {
    readonly user: {
        readonly " $fragmentRefs": FragmentRefs<"Advices_user">;
    } | null;
    readonly advices: {
        readonly " $fragmentRefs": FragmentRefs<"Advices_advices">;
    } | null;
};
export type App_Advices_Query = {
    readonly response: App_Advices_QueryResponse;
    readonly variables: App_Advices_QueryVariables;
};



/*
query App_Advices_Query(
  $page: Page
  $filter: AdviceFilter
) {
  user {
    ...Advices_user
  }
  advices(page: $page, filter: $filter) {
    ...Advices_advices
  }
}

fragment AdviceCard_advice on Advice {
  id
  name
  introduction
  description
  postedAt
  bannerImageURL
  links
}

fragment Advices_advices on AdvicePage {
  edges {
    id
    ...AdviceCard_advice
  }
  pageInfo {
    total
    offset
    limit
    given
  }
}

fragment Advices_user on User {
  firstName
}
*/

const node: ConcreteRequest = (function () {
    var v0 = [
        ({
            "kind": "LocalArgument",
            "name": "page",
            "type": "Page",
            "defaultValue": null
        } as any),
        ({
            "kind": "LocalArgument",
            "name": "filter",
            "type": "AdviceFilter",
            "defaultValue": null
        } as any)
    ], v1 = [
        ({
            "kind": "Variable",
            "name": "filter",
            "variableName": "filter"
        } as any),
        ({
            "kind": "Variable",
            "name": "page",
            "variableName": "page"
        } as any)
    ];
    return {
        "kind": "Request",
        "fragment": {
            "kind": "Fragment",
            "name": "App_Advices_Query",
            "type": "Query",
            "metadata": null,
            "argumentDefinitions": (v0 /*: any*/),
            "selections": [
                {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "user",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "User",
                    "plural": false,
                    "selections": [
                        {
                            "kind": "FragmentSpread",
                            "name": "Advices_user",
                            "args": null
                        }
                    ]
                },
                {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "advices",
                    "storageKey": null,
                    "args": (v1 /*: any*/),
                    "concreteType": "AdvicePage",
                    "plural": false,
                    "selections": [
                        {
                            "kind": "FragmentSpread",
                            "name": "Advices_advices",
                            "args": null
                        }
                    ]
                }
            ]
        },
        "operation": {
            "kind": "Operation",
            "name": "App_Advices_Query",
            "argumentDefinitions": (v0 /*: any*/),
            "selections": [
                {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "user",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "User",
                    "plural": false,
                    "selections": [
                        {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "firstName",
                            "args": null,
                            "storageKey": null
                        }
                    ]
                },
                {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "advices",
                    "storageKey": null,
                    "args": (v1 /*: any*/),
                    "concreteType": "AdvicePage",
                    "plural": false,
                    "selections": [
                        {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "edges",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "Advice",
                            "plural": true,
                            "selections": [
                                {
                                    "kind": "ScalarField",
                                    "alias": null,
                                    "name": "id",
                                    "args": null,
                                    "storageKey": null
                                },
                                {
                                    "kind": "ScalarField",
                                    "alias": null,
                                    "name": "name",
                                    "args": null,
                                    "storageKey": null
                                },
                                {
                                    "kind": "ScalarField",
                                    "alias": null,
                                    "name": "introduction",
                                    "args": null,
                                    "storageKey": null
                                },
                                {
                                    "kind": "ScalarField",
                                    "alias": null,
                                    "name": "description",
                                    "args": null,
                                    "storageKey": null
                                },
                                {
                                    "kind": "ScalarField",
                                    "alias": null,
                                    "name": "postedAt",
                                    "args": null,
                                    "storageKey": null
                                },
                                {
                                    "kind": "ScalarField",
                                    "alias": null,
                                    "name": "bannerImageURL",
                                    "args": null,
                                    "storageKey": null
                                },
                                {
                                    "kind": "ScalarField",
                                    "alias": null,
                                    "name": "links",
                                    "args": null,
                                    "storageKey": null
                                },
                                {
                                    "kind": "ScalarField",
                                    "alias": null,
                                    "name": "voiceoverURL",
                                    "args": null,
                                    "storageKey": null
                                },
                                {
                                    "kind": "ScalarField",
                                    "alias": null,
                                    "name": "videoURL",
                                    "args": null,
                                    "storageKey": null
                                }
                            ]
                        },
                        {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "pageInfo",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "PageInfo",
                            "plural": false,
                            "selections": [
                                {
                                    "kind": "ScalarField",
                                    "alias": null,
                                    "name": "total",
                                    "args": null,
                                    "storageKey": null
                                },
                                {
                                    "kind": "ScalarField",
                                    "alias": null,
                                    "name": "offset",
                                    "args": null,
                                    "storageKey": null
                                },
                                {
                                    "kind": "ScalarField",
                                    "alias": null,
                                    "name": "limit",
                                    "args": null,
                                    "storageKey": null
                                },
                                {
                                    "kind": "ScalarField",
                                    "alias": null,
                                    "name": "given",
                                    "args": null,
                                    "storageKey": null
                                }
                            ]
                        }
                    ]
                }
            ]
        },
        "params": {
            "operationKind": "query",
            "name": "App_Advices_Query",
            "id": null,
            "text": "query App_Advices_Query(\n  $page: Page\n  $filter: AdviceFilter\n) {\n  user {\n    ...Advices_user\n  }\n  advices(page: $page, filter: $filter) {\n    ...Advices_advices\n  }\n}\n\nfragment AdviceCard_advice on Advice {\n  id\n  name\n  introduction\n  description\n  postedAt\n  bannerImageURL\n  links\n  voiceoverURL\n  videoURL\n}\n\nfragment Advices_advices on AdvicePage {\n  edges {\n    id\n    ...AdviceCard_advice\n  }\n  pageInfo {\n    total\n    offset\n    limit\n    given\n  }\n}\n\nfragment Advices_user on User {\n  firstName\n}\n",
            "metadata": {}
        }
    } as any;
})();
(node as any).hash = 'fc95fac0dab57eb935ddb307ec3b2b6c';
export default node;
