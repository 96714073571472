/* tslint:disable */
/* eslint-disable */
/* @relayHash 300c0b9652b8f08b181b3a7921344ca9 */

import { ConcreteRequest } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type App_Advice_QueryVariables = {
    id: string;
};
export type App_Advice_QueryResponse = {
    readonly advice: {
        readonly " $fragmentRefs": FragmentRefs<"Advice_advice">;
    } | null;
};
export type App_Advice_Query = {
    readonly response: App_Advice_QueryResponse;
    readonly variables: App_Advice_QueryVariables;
};



/*
query App_Advice_Query(
  $id: ID!
) {
  advice(id: $id) {
    ...Advice_advice
    id
  }
}

fragment Advice_advice on Advice {
  id
  name
  introduction
  description
  postedAt
  bannerImageURL
  links
}
*/

const node: ConcreteRequest = (function () {
    var v0 = [
        ({
            "kind": "LocalArgument",
            "name": "id",
            "type": "ID!",
            "defaultValue": null
        } as any)
    ], v1 = [
        ({
            "kind": "Variable",
            "name": "id",
            "variableName": "id"
        } as any)
    ];
    return {
        "kind": "Request",
        "fragment": {
            "kind": "Fragment",
            "name": "App_Advice_Query",
            "type": "Query",
            "metadata": null,
            "argumentDefinitions": (v0 /*: any*/),
            "selections": [
                {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "advice",
                    "storageKey": null,
                    "args": (v1 /*: any*/),
                    "concreteType": "Advice",
                    "plural": false,
                    "selections": [
                        {
                            "kind": "FragmentSpread",
                            "name": "Advice_advice",
                            "args": null
                        }
                    ]
                }
            ]
        },
        "operation": {
            "kind": "Operation",
            "name": "App_Advice_Query",
            "argumentDefinitions": (v0 /*: any*/),
            "selections": [
                {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "advice",
                    "storageKey": null,
                    "args": (v1 /*: any*/),
                    "concreteType": "Advice",
                    "plural": false,
                    "selections": [
                        {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "id",
                            "args": null,
                            "storageKey": null
                        },
                        {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "name",
                            "args": null,
                            "storageKey": null
                        },
                        {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "introduction",
                            "args": null,
                            "storageKey": null
                        },
                        {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "description",
                            "args": null,
                            "storageKey": null
                        },
                        {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "postedAt",
                            "args": null,
                            "storageKey": null
                        },
                        {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "bannerImageURL",
                            "args": null,
                            "storageKey": null
                        },
                        {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "links",
                            "args": null,
                            "storageKey": null
                        },
                        {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "voiceoverURL",
                            "args": null,
                            "storageKey": null
                        },
                        {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "videoURL",
                            "args": null,
                            "storageKey": null
                        }
                    ]
                }
            ]
        },
        "params": {
            "operationKind": "query",
            "name": "App_Advice_Query",
            "id": null,
            "text": "query App_Advice_Query(\n  $id: ID!\n) {\n  advice(id: $id) {\n    ...Advice_advice\n    id\n  }\n}\n\nfragment Advice_advice on Advice {\n  id\n  name\n  introduction\n  description\n  postedAt\n  bannerImageURL\n  links\n  voiceoverURL\n  videoURL\n}\n",
            "metadata": {}
        }
    } as any;
})();
(node as any).hash = '8465c914f253a3f1204ea41c9a76b9ed';
export default node;
