import { Chip, Grid, TextField } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { Theme } from 'helpers/theme';
import React from 'react';
import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles((theme: Theme) => ({
  textField: {
    '& label.Mui-focused': {
      color: theme.colors.primaryBlack,
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: theme.colors.textOrange,
    },
    '& .MuiOutlinedInput-root': {
      // '& fieldset': {
      //   borderColor: 'white',
      // },
      // '&:hover fieldset': {
      //   borderColor: 'white',
      // },
      '&.Mui-focused fieldset': {
        borderColor: theme.colors.textOrange,
      }
    }
  }
}));

function capitalizeFirstLetter(x: string) {
  return x.charAt(0).toUpperCase() + x.slice(1);
}

type Props = {
  chips: string[];
  selectedChips: string[];
  onChange: (newChips: string[]) => void; 
}

function ChipAutocomplete({ chips, selectedChips, onChange }: Props){
  const classes = useStyles();

  const [options, setOptions] = React.useState(chips);
  const [input, setInput] = React.useState('');
  const [inputs, setInputs] = React.useState(selectedChips);
  const onChipAdded = (newValue: string[]) => {
    setInputs(newValue);
    onChange(newValue);
  };

  React.useEffect(() => {
    setOptions(
      chips.filter(x => x.toLowerCase().includes(input.toLowerCase()))
    );
  }, [input]);

  return (
    <Grid container spacing={1} alignContent="center">
      <Grid item xs={12}>
        <Autocomplete
          multiple
          options={options}
          value={inputs}
          onChange={(_, newValue) => {
            onChipAdded(newValue);
          }}
          getOptionLabel={option => capitalizeFirstLetter(option)}
          renderTags={(value, getTagProps) => 
            value.map((option, index) => (
              <Chip
                label={capitalizeFirstLetter(option)}
                color={'default'}
                {...getTagProps({ index })}
              />
            ))
          }
          renderInput={params => (
            <TextField
              {...params}
              label="Weekdays"
              variant="outlined"
              onChange={inp => setInput(inp.target.value)}
              className={classes.textField}
            />
          )}
        />
      </Grid>
    </Grid>
  );
}

export default ChipAutocomplete;