import * as React from 'react';
import { createUseStyles } from 'react-jss';
import styled from 'styled-components';
import { Theme } from 'helpers/theme';
import { Background } from 'react-imgix';

const useStyles = createUseStyles((theme: Theme) => ({
    container: {
        display: 'flex',
        boxSizing: 'border-box',
        flexDirection: 'column',
        padding: '10px 8px 10px 16px',
        height: '100%',
        justifyContent: 'space-between'
    },
    row: {
        display: 'flex',
        width: '100%',
        alignItems: 'center'
    },
    label: {
        width: '42px',
        fontSize: '13px',
        color: '#737987'
    },
    progress: {
        width: '136px',
        height: '12px',
        border: '#e5e5e5 solid 1px',
        margin: '0px 6px 0px 6px',
        background: 'white',
        borderRadius: '6px'
    },
    value: {
        width: '80px',
        fontSize: '11px',
        textAlign: 'center',
        color: '#7c818e'
    }
}));

const ProgressBar = styled.div<{ percent: number }>`
  width: ${(props) => props.percent}%;
  height: 10px;
  background-image: linear-gradient(to right, #f27b31 , #f9e1b4);
  border-radius: 5px;
`;

type Props = {
    maxTime: number;
    maxSkills: number;
    curTime: number;
    curSkills: number;
}

function ProgressBox({ maxTime, maxSkills, curTime, curSkills }: Props) {
    const classes = useStyles();

    const days = () => {
        var days = curTime * 100 / maxTime;
        return days >= 100 ? 100 : days;
    }

    const skills = () => {
        if (curSkills == 0) return 0;
        var skills = curSkills * 100 / maxSkills;
        return skills >= 100 ? 100 : skills;
    }

    return (
        <div className={classes.container}>
            <div className={classes.row}>
                <div className={classes.label}>Days</div>
                <div className={classes.progress}>
                    <ProgressBar percent={days()} />
                </div>
                <div className={classes.value}>{`${curTime >= maxTime ? maxTime : curTime}`}/{maxTime}</div>
            </div>
            <div className={classes.row}>
                <div className={classes.label}>Skills</div>
                <div className={classes.progress}>
                    <ProgressBar percent={skills()} />
                </div>
                <div className={classes.value}>{`${curSkills >= maxSkills ? maxSkills : curSkills}`}/{maxSkills}</div>
            </div>
        </div>
    );
}

export default ProgressBox;