import * as React from 'react';
import styled from 'styled-components';
import Text from 'components/core/Table/Text';
import { createFragmentContainer, graphql } from 'react-relay';
import Spacer from 'components/core/Spacers/Spacer';
import { CompetencySkillsPage_candidate } from './__generated__/CompetencySkillsPage_candidate.graphql';
import CompetencySkillsProgress from './CompetencySkillsProgress';
import CompetencySkillsTable from 'components/CompetencySkillsTable/CompetencySkillsTable';
import Paginator, { Page } from 'components/Pagination/Paginator';
import theme from 'helpers/theme';

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const SubTitleAndButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const SubTitle = styled.div`
  font-weight: 300;
  font-size: 14px;
  color: ${theme.colors.textGrey};
  max-width: 600px;
`;

const Button = styled.div`
  background-color: #f37a21;
  color: white;
  text-decoration: none;
  border-radius: 6px;
  padding: 10px;
  font-size: 12px;
  font-weight: 500;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin-right: 15px;
  width: 150px;
`;

const PaginationContainer = styled.div`
  margin-left: 40px;
  margin-bottom: 60px;
`;

const NoneFoundMessage = styled.div`
  color: ${theme.colors.textGrey};
  text-align: center;
`;

const tableSkillColumns = [
  { headerName: 'COMPETENCIES', field: 'name' },
  {
    headerName: 'PROGRESS',
    component: (item: any) => (
      <CompetencySkillsProgress
        doneEntryCount={item.doneEntryCount}
        minSuggestedEntryCount={item.minSuggestedEntryCount}
      />
    )
  },
  {
    headerName: '',
    component: (item: any) => (
      <Text text={`${item.doneEntryCount}/${item.minSuggestedEntryCount}`} />
    )
  }
];

const tableSkillGroupColumns = [
  {
    headerName: 'SKILLS',
    component: (item: any) => <Text text={item.name} />
  }
];

const tableSubSkillColumns = [
  { headerName: '', field: 'name' },
  {
    headerName: '',
    component: (item: any) => (
      <CompetencySkillsProgress
        doneEntryCount={item.doneEntryCount}
        minSuggestedEntryCount={item.minSuggestedEntryCount}
      />
    )
  },
  {
    headerName: '',
    component: (item: any) => (
      <Text text={`${item.doneEntryCount}/${item.minSuggestedEntryCount}`} />
    )
  }
];

type Props = {
  candidate: CompetencySkillsPage_candidate;
  onButtonClick: Function;
};

const OFFSET = 0;
const LIMIT = 10;

// We show them as "Competency" and "Skill" on the pages according to the new design.
// Since it is used in many places, the use of old names in the code continued.
// Old design: Practice area -> Practice skill -> Practice subskill
// New design: Practice area -> Competency -> Skill group -> Skill
function CompetencySkillsPage({ candidate, onButtonClick }: Props) {
  const [offset, setOffset] = React.useState(OFFSET);
  const [limit, setLimit] = React.useState(LIMIT);

  React.useEffect(() => {
    setOffset(OFFSET);
    setLimit(LIMIT);
  }, [candidate]);

  const onUpdatePage = ({ offset, limit }: Page) => {
    if (offset <= 0) {
      offset = 1;
    }

    setLimit(limit);
    setOffset((offset - 1) * limit);
  };

  let practiceSkillComponents: any = []; // includes all data

  const areaProgress = candidate?.areaProgress ?? [];
  areaProgress.map((practiceArea: any) => {
    // prepare all skills (called 'competency' according to the new ui design) of the practice area
    if (practiceArea.practiceArea.name == candidate.skillGroupType) {
      practiceArea.practiceArea.practiceSkills.edges.map(
        (practiceSkill: any) => {
          let totalMinSuggestedEntryCount = 0;
          let totalDoneEntryCount = 0;
          const practiceSkillGroupComponents: any = [];

          // prepare all skill groups of the practice skill
          practiceSkill.practiceSkillGroups.edges.map(
            (practiceSkillGroup: any) => {
              // prepare all sub-skills (called 'skills' according to the new ui design) of the skill group
              const practiceSubSkillComponents: any = [];
              practiceSkillGroup.practiceSubSkills.edges.map(
                (practiceSubSkill: any) => {
                  const practiceSubSkillComponent = {
                    id: practiceSubSkill.id,
                    name: practiceSubSkill.name,
                    minSuggestedEntryCount:
                      practiceSubSkill.minSuggestedEntryCount || 0,
                    doneEntryCount: practiceSubSkill.doneEntryCount || 0,
                    showChildrenTableHeaders: false
                  };

                  totalMinSuggestedEntryCount +=
                    practiceSubSkill.minSuggestedEntryCount || 0;
                  totalDoneEntryCount += practiceSubSkill.doneEntryCount || 0;
                  practiceSubSkillComponents.push(practiceSubSkillComponent);
                }
              );

              const practiceSkillGroupComponent = {
                id: practiceSkillGroup.id,
                name: practiceSkillGroup.name,
                children: practiceSubSkillComponents,
                childrenColumns: tableSubSkillColumns,
                open: false,
                showChildrenTableHeaders: false
              };

              practiceSkillGroupComponents.push(practiceSkillGroupComponent);
            }
          );

          // quang 15/07/2023 - check if totalDoneEntryCount > totalMinSuggestedEntryCount
          // then set totalDoneEntryCount = totalMinSuggestedEntryCount
          if(totalDoneEntryCount > totalMinSuggestedEntryCount){
            totalDoneEntryCount = totalMinSuggestedEntryCount;
          }
          
          practiceSkillComponents.push({
            id: practiceSkill.id,
            name: practiceSkill.name,
            children: practiceSkillGroupComponents,
            childrenColumns: tableSkillGroupColumns,
            minSuggestedEntryCount: totalMinSuggestedEntryCount || 0,
            doneEntryCount: totalDoneEntryCount || 0,
            open: false,
            showChildrenTableHeaders: true
          });
        }
      );
    }
  });

  if (practiceSkillComponents.length === 0) {
    return <NoneFoundMessage>No competencies found.</NoneFoundMessage>;
  }

  const page = {
    currentPage: Math.ceil(offset / limit) + 1,
    numPages: Math.ceil(practiceSkillComponents.length / limit)
  };

  const subtitle = () => {
    if (candidate.skillGroupType === "TC") {
      return "Easily see exactly how much exposure you're had to each of the Practice Skill Standards. And crucially, where there might be any gaps!";
    }
    return "Easily see exactly how much exposure you've had to each of the competencies set out in the Statement of Solicitors Competence. And crucially, where there might be any gaps!";
  }

  return (
    <Container>
      <SubTitleAndButtonContainer>
        <SubTitle>
          {subtitle()}
        </SubTitle>
        {candidate.candidateType === 'level3' && (
          <Button onClick={() => onButtonClick()}>View Entries</Button>
        )}
      </SubTitleAndButtonContainer>
      {candidate.candidateType === 'level3' && (
        <div>
          <Spacer vertical spacing={3} />
          <CompetencySkillsTable
            columns={tableSkillColumns}
            rows={practiceSkillComponents.slice(offset, offset + limit)}
          />
          <Spacer spacing={2} vertical />
          <PaginationContainer>
            <Paginator
              currentPage={page.currentPage}
              updatePage={onUpdatePage}
              numPages={page.numPages ?? 1}
              itemsPerPage={limit}
              showRange={(page.numPages ?? 1) > 4 ? 4 : page.numPages ?? 1}
            />
          </PaginationContainer>
          <Spacer vertical spacing={3} />
        </div>
      )}
    </Container>
  );
}

export default createFragmentContainer(CompetencySkillsPage, {
  candidate: graphql`
    fragment CompetencySkillsPage_candidate on Candidate {
      id
      firstName
      lastName
      skillGroupType
      candidateType
      areaProgress {
        practiceArea {
          id
          name
          practiceSkills {
            edges {
              id
              name
              practiceSkillGroups {
                edges {
                  id
                  name
                  practiceSubSkills {
                    edges {
                      id
                      name
                      practiceSubSkillType
                      minSuggestedEntryCount
                      doneEntryCount
                      order
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `
});
