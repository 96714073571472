import Button from 'components/core/Input/ButtonBase';
import Dropdown, { DropdownOption } from 'components/core/Input/Dropdown';
import EasyInput from 'components/core/Input/EasyInput';
import Spacer from 'components/core/Spacers/Spacer';
import { Body, Footer } from 'components/SideModal/Tabs';
import { qualificationOptions } from 'helpers/qualificationOptions';
import React, { useRef } from 'react';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { createUseStyles, useTheme } from 'react-jss';
import { Theme } from 'helpers/theme';
import styled from 'styled-components';
import { toBase64 } from 'helpers/upload';
import { useToastDispatch } from 'components/Toast/ToastProvider';
import { FileUpload } from 'api/__generated__/mutationsCreateQualificationMutation.graphql';
import { makeMutation } from 'api/helpers';
import { CreateQualification, UpdateQualification } from 'api/mutations';
import { ScaleLoader } from 'react-spinners';
import { RecordSourceSelectorProxy } from 'relay-runtime';
import { ThemeProvider } from '@material-ui/core/styles';
import themeCustom from '../../helpers/themeCustom';

const dropdownOptions: DropdownOption[] = qualificationOptions.map(
  (option) => ({ id: option.value, title: option.text })
);

const useStyles = createUseStyles((theme: Theme) => ({
  root: {
    padding: '10px 10px !important',
    border: '1px solid #08080814 !important',
    borderRadius: 5,
    '& div': {
      '&:before': { borderBottom: 'none !important' },
      '&:after': { borderBottom: 'none !important' }
    }
  },
  certificateButton: {
    marginLeft: '5px',
    height: '32px'
  },
}));

const SectionTitle = styled.h1`
  color: #271507;
  font-size: 20px;
  font-weight: 700;
`;

const FileInput = styled.input`
  display: none;
`;

const UploadContainer = styled.label`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
`;

const UploadButton = styled.div`
  display: flex;
  padding: 10px 5px;
  justify-content: center;
  background: #e8e8e8;
  align-items: center;
  color: #585858;
  border-radius: 4px;
  font-size: 13px;
  flex: 1;
  cursor: pointer;
  min-width: 200px;
`;

const UploadWarning = styled.div`
  font-size: 14px;
  color: #757575;
`;

export type State = {
  qualificationType?: DropdownOption;
  name: string;
  id?: string;
  startDate?: string;
  endDate?: string;
  grade?: string;
  fileUrl?: string;
  fileURL?: string;
  fileName?: string;
  file?: FileUpload;
  uploading?: boolean;
  editing?: boolean;
};

type Props = {
  state: State;
  setState: (newState: State) => void;
  setTab: (tab: string) => void;
  closeModal: () => void;
};

function UploadContent({ state, setState, setTab, closeModal }: Props) {
  const theme = useTheme();
  const classes = useStyles({ theme });
  const dispatch = useToastDispatch();

  const inputRef = useRef<HTMLInputElement>(null);

  const onUpload = async (evt: React.ChangeEvent<HTMLInputElement>) => {
    const files = evt?.target?.files;
    if (files && files.length > 0) {
      const file = files[0];
      const split = file.name.split('.');
      const fType = split[split.length - 1].toLowerCase();
      if (
        fType !== 'jpg' &&
        fType !== 'jpeg' &&
        fType !== 'png' &&
        fType !== 'pdf' &&
        fType !== 'doc' &&
        fType !== 'docx'
      ) {
        // TODO: alert users
        dispatch({
          text: 'Pick a png, jpg, pdf, jpeg, doc or docs file',
          alert: 'error',
          stayFor: 6000
        });
        return;
      }

      try {
        const base64 = await toBase64(file);
        if (!base64) {
          dispatch({
            text: 'Unable to upload file, please try again',
            alert: 'error',
            stayFor: 6000
          });
          throw new Error('Base64 is undefined');
        }
        setState((s: State) => ({
          ...s,
          fileURL: URL.createObjectURL(file),
          file: {
            fileBase64: base64,
            fileType: fType
          },
          fileName: file.name
        }));

        // To fix issue where input doesn't work twice in a row
        // https://stackoverflow.com/questions/12030686/html-input-file-selection-event-not-firing-upon-selecting-the-same-file
        if (inputRef && inputRef.current) {
          inputRef.current.value = '';
        }
      } catch (err) {
        dispatch({
          text: 'Unable to upload file, please try again',
          alert: 'error',
          stayFor: 6000
        });
        console.error('Unable to base64 file', err);
      }
    }
  };

  const onEdit = async () => {
    if (!state.name) {
      dispatch({
        text: 'Please enter a qualification name',
        alert: 'warning',
        stayFor: 6000
      });
      return;
    }
    if (!state.qualificationType) {
      dispatch({
        text: 'Please select a qualification type',
        alert: 'warning',
        stayFor: 6000
      });
      return;
    }
    setState((s: State) => ({ ...s, uploading: true }));
    try {
      await makeMutation(UpdateQualification, {
        id: state.id,
        name: state.name,
        qualificationType: state.qualificationType?.id,
        startDate: state.startDate,
        endDate: state.endDate,
        grade: state.grade,
        file: state.file
      });
      setState((s: State) => ({ ...s, uploading: false }));
      dispatch({
        text: 'Qualification uploaded successfully',
        alert: 'success',
        stayFor: 4000
      });
      closeModal();
    } catch (err) {
      console.log('Unable to upload file', err);
      dispatch({
        text: err.message,
        alert: 'error',
        stayFor: 6000
      });
      setState((s: State) => ({ ...s, uploading: false }));
    }
  };

  const onSave = async () => {
    if (!state.file) {
      dispatch({
        text: 'Please upload a qualification',
        alert: 'warning',
        stayFor: 6000
      });
      return;
    }
    if (!state.name) {
      dispatch({
        text: 'Please enter a qualification name',
        alert: 'warning',
        stayFor: 6000
      });
      return;
    }
    if (!state.qualificationType) {
      dispatch({
        text: 'Please select a qualification type',
        alert: 'warning',
        stayFor: 6000
      });
      return;
    }
    setState((s: State) => ({ ...s, uploading: true }));
    try {
      await makeMutation(
        CreateQualification,
        {
          qualificationType: state.qualificationType?.id,
          name: state.name,
          startDate: state.startDate,
          endDate: state.endDate,
          grade: state.grade,
          file: state.file
        },
        (store: RecordSourceSelectorProxy<{}>, data: any) => {
          const root = store.get('client:root');

          if (!root) {
            console.error('could not get root');
            return;
          }
          const user = root.getLinkedRecord('candidate');
          if (!user) {
            console.log('Could not get user');
            return;
          }

          const qualifs = user.getLinkedRecords('qualifications', {
            qualificationType: state.qualificationType?.id
          });
          if (!qualifs) {
            console.error('could not get qualifs');
            return;
          }
          const newQualif = store.get(data.createQualification.id);
          if (!newQualif) {
            console.error('could not find qualif', data.createQualification.id);
            return;
          }

          const newRecords = [...qualifs, newQualif];
          user.setLinkedRecords(newRecords, 'qualifications', {
            qualificationType: state.qualificationType?.id
          });
        }
      );
      setState((s: State) => ({ ...s, uploading: false }));
      dispatch({
        text: 'Qualification uploaded successfully',
        alert: 'success',
        stayFor: 4000
      });
      closeModal();
    } catch (err) {
      console.log('Unable to upload file', err);
      dispatch({
        text: err.message,
        alert: 'error',
        stayFor: 6000
      });
      setState((s: State) => ({ ...s, uploading: false }));
    }
  };

  return (
    <>
      <Body>
        <SectionTitle>Qualification Type*</SectionTitle>
        <Dropdown
          placeholder="Select a qualification"
          options={dropdownOptions}
          setSelected={(drop) => {
            setState((s: State) => ({ ...s, qualificationType: drop }));
          }}
          selected={state.qualificationType}
        />
        <Spacer vertical spacing={1} />
        <SectionTitle>Qualification Name*</SectionTitle>
        <EasyInput
          placeholder="Name"
          type="text"
          value={state.name}
          onChange={(text) => {
            setState((s: State) => ({ ...s, name: text }));
          }}
        />
        <Spacer vertical spacing={1} />
        <SectionTitle>Month and Year Completed</SectionTitle>
        <ThemeProvider theme={themeCustom}>
          <KeyboardDatePicker
            views={["year", "month"]}
            value={state.startDate ? state.startDate : null}
            placeholder="10/2018"
            onChange={(date) => {
              setState((s: State) => ({ ...s, startDate: date?.format() }));
            }}
            format="MM/YYYY"
            className={classes.root}
            inputProps={{ style: { font: "12px 'Muli', sans-serif" } }}
            autoOk={true}
          />
        </ThemeProvider>
        {/*
        <Spacer vertical spacing={1} />
        <SectionTitle>End Date</SectionTitle>
        <ThemeProvider theme={themeCustom}>
          <KeyboardDatePicker
            value={state.endDate ? state.e
              ndDate : null}
            placeholder="10/2018"
            onChange={(date) => {
              setState((s: State) => ({ ...s, endDate: date?.format() }));
            }}
            format="MM/YYYY"
            className={classes.root}
            inputProps={{ style: { font: "12px 'Muli', sans-serif" } }}
          />
        </ThemeProvider>
          */}
        <Spacer vertical spacing={1} />
        <SectionTitle>Grade</SectionTitle>
        <EasyInput
          placeholder="Grade"
          type="text"
          value={state.grade}
          onChange={(text) => {
            setState((s: State) => ({ ...s, grade: text }));
          }}
        />
        <Spacer vertical spacing={1} />
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <SectionTitle>Upload a Qualification*</SectionTitle>
          {state.fileUrl ? <Button
            className={classes.certificateButton}
            archetype="submit"
            onClick={() => window.open(state.fileUrl, '_blank')}
          >
            View Qualification
          </Button> : <></>}
        </div>
        <FileInput
          type="file"
          name="file"
          id={'logo-upload'}
          onChange={onUpload}
          ref={inputRef}
        />
        <UploadContainer htmlFor={'logo-upload'}>
          <UploadButton onClick={(evt) => { }}>
            {state.file ? state.fileName : 'Upload Qualification'}
          </UploadButton>
          <Spacer horizontal spacing={2} />
          <UploadWarning>
            Supported files: PDF, JPG, PNG (max 20MB)
          </UploadWarning>
        </UploadContainer>
      </Body>
      <Footer>
        <div style={{ display: 'flex' }}>
          <Button archetype={'default'} onClick={closeModal}>
            Cancel
          </Button>
          <Spacer horizontal spacing={2} />
          <Button
            archetype="submit"
            onClick={() => {
              if (state.uploading) {
                return;
              }
              if (state.editing) {
                onEdit();
                return;
              }

              onSave();
            }}
          >
            {state.uploading ? (
              <ScaleLoader color="white" height={10} />
            ) : (
              `${state.editing ? 'Save' : 'Add Qualification'}`
            )}
          </Button>
        </div>
      </Footer>
    </>
  );
}

export default UploadContent;
