import * as React from 'react';
import moment from 'moment';
import { createUseStyles } from 'react-jss';
import styled from 'styled-components';
import { Theme } from 'helpers/theme';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import Spacer from 'components/core/Spacers/Spacer';
import { configureScope } from '@sentry/react';

const Container = styled.div`
  position: relative;
  outline: none;
`;
const CurrentOption = styled.div`
  background: white;
  padding: 14px 17px;
  display: flex;
  font-weight: 300;
  border-radius: 6px;
  font-size: 13px;
  border: 1px solid #e9ebeb;
  justify-content: space-between;
  cursor: pointer;
`;

const Placeholder = styled.div``;

const Dropdown = styled.div`
  padding: 15px;
  background: white;
  position: absolute;
  width: 100%;
  top: 50px;
  border-radius: 6px;
  border: 1px solid #e9ebeb;
  box-shadow: 0px 4px 9px #0000000f;
  max-height: 500px;
  overflow-y: scroll;
`;

const DropdownOption = styled.div`
  display: flex;
  padding: 7px 12px;
  align-items: center;
  border-radius: 6px;
  cursor: pointer;
  &:hover {
    background: #f8f9fb;
  }
`;

const Selector = styled.div`
  border-radius: 100%;
  border: 1px solid gainsboro;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 20px;
  width: 20px;
`;

const OptionText = styled.div`
  font-size: 13px;
  margin-left: 17px;
`;

const OptionTextWithBold = styled.div`
  font-size: 13px;
  font-weight: bold;
  margin-left: 5px;
`;

const NothingToShow = styled.div`
  font-weight: 400;
  font-size: 13px;
  color: #78797b;
`;

const Tick = styled.img`
  width: 9px;
`;

const ShowMoreButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background: #e8e9ec;
  border-radius: 6px;
  padding: 9px;
  font-size: 13px;
  color: #5c5d5f;
  cursor: pointer;
  &:hover {
    background: #dee0e4;
  }
`;

const interviewTypes = [
  {
    value: 'virtual',
    text: 'Virtual'
  },
  {
    value: 'in_person',
    text: 'In person'
  }
];

export type Option = {
  startTime: string;
  endTime: string;
  interviewType: string;
  value: string;
};

type Props = {
  options: Option[];
  placeholder: string;
  onChange: (selected: string) => void;
  hasMore?: boolean;
  onLoadMore?: () => void;
  selected: string;
  emptyText?: string;
};

function LoadMoreDropdown({
  options,
  onChange,
  selected,
  hasMore,
  onLoadMore,
  placeholder,
  emptyText
}: Props) {
  const [isOpen, setOpen] = React.useState<boolean>(false);

  console.log(options);

  return (
    <Container
      onFocus={() => setOpen(true)}
      onBlur={() => setOpen(false)}
      tabIndex={-1}
    >
      <CurrentOption>
        <Placeholder>{placeholder}</Placeholder>
        <FontAwesomeIcon icon={faChevronDown} color={'#CFD1D1'} />
      </CurrentOption>
      {isOpen && (
        <Dropdown>
          {options.length == 0 && (
            <NothingToShow>{emptyText || 'Nothing to show'}</NothingToShow>
          )}
          {options.map((option) => (
            <DropdownOption
              onClick={() => {
                onChange(option.value);
              }}
            >
              <Selector>
                {selected == option.value && (
                  <Tick src={require('../../assets/orangeTick.svg')} />
                )}
              </Selector>
              <OptionText>
                {moment(option.startTime).format('LLL')} -{' '}
                {moment(option.endTime).format('LLL')} {option.interviewType ? '-' : ''}{' '}
              </OptionText>

              <OptionTextWithBold>
                  {interviewTypes.find(type => type.value === option.interviewType)?.text??''}
              </OptionTextWithBold>
              
            </DropdownOption>
          ))}
          {hasMore && (
            <>
              <Spacer vertical spacing={2} />
              <ShowMoreButton onClick={onLoadMore}>Show More</ShowMoreButton>
            </>
          )}
        </Dropdown>
      )}
    </Container>
  );
}

export default LoadMoreDropdown;
