import SideModal from 'components/SideModal/SideModal';
import Tabs from 'components/SideModal/Tabs';
import React from 'react';
import Content, { State } from './Content';

type Props = {
  isOpen: boolean;
  candidateID: string;
  onClose: () => void;
  page: { offset:number, limit: number };
};

function OtherQWEModal({ isOpen, candidateID, page, onClose }: Props) {
  const initialState: State = {
    name: '',
    page,
    candidateID,
  };

  return (
    <SideModal isOpen={isOpen} title="Create Other QWE" closeModal={onClose}>
      <Tabs
        content={[
          {
            key: 'Create Other QWE',
            component: Content
          }
        ]}
        closeModal={onClose}
        initialState={initialState}
      />
    </SideModal>
  );
}

export default OtherQWEModal;
