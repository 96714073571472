/* tslint:disable */
/* eslint-disable */
/* @relayHash f24c114eaa051c3480cc31b8146d9ebe */

import { ConcreteRequest } from "relay-runtime";
export type mutationsCreateRobertQuestionMutationVariables = {
    questionId: string;
    answerId: number;
    chatHistory: string;
};
export type mutationsCreateRobertQuestionMutationResponse = {
    readonly createRobertQuestion: {
        readonly id: string;
        readonly question: string;
        readonly answers: ReadonlyArray<string> | null;
    };
};
export type mutationsCreateRobertQuestionMutation = {
    readonly response: mutationsCreateRobertQuestionMutationResponse;
    readonly variables: mutationsCreateRobertQuestionMutationVariables;
};



/*
mutation mutationsCreateRobertQuestionMutation(
  $questionId: String!
  $answerId: Int!
  $chatHistory: String!
) {
  createRobertQuestion(input: {questionId: $questionId, answerId: $answerId, chatHistory: $chatHistory}) {
    id
    question
    answers
  }
}
*/

const node: ConcreteRequest = (function () {
    var v0 = [
        ({
            "kind": "LocalArgument",
            "name": "questionId",
            "type": "String!",
            "defaultValue": null
        } as any),
        ({
            "kind": "LocalArgument",
            "name": "answerId",
            "type": "Int!",
            "defaultValue": null
        } as any),
        ({
            "kind": "LocalArgument",
            "name": "chatHistory",
            "type": "String!",
            "defaultValue": null
        } as any)
    ], v1 = [
        ({
            "kind": "LinkedField",
            "alias": null,
            "name": "createRobertQuestion",
            "storageKey": null,
            "args": [
                {
                    "kind": "ObjectValue",
                    "name": "input",
                    "fields": [
                        {
                            "kind": "Variable",
                            "name": "answerId",
                            "variableName": "answerId"
                        },
                        {
                            "kind": "Variable",
                            "name": "chatHistory",
                            "variableName": "chatHistory"
                        },
                        {
                            "kind": "Variable",
                            "name": "questionId",
                            "variableName": "questionId"
                        }
                    ]
                }
            ],
            "concreteType": "RobertQuestion",
            "plural": false,
            "selections": [
                {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "id",
                    "args": null,
                    "storageKey": null
                },
                {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "question",
                    "args": null,
                    "storageKey": null
                },
                {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "answers",
                    "args": null,
                    "storageKey": null
                }
            ]
        } as any)
    ];
    return {
        "kind": "Request",
        "fragment": {
            "kind": "Fragment",
            "name": "mutationsCreateRobertQuestionMutation",
            "type": "Mutation",
            "metadata": null,
            "argumentDefinitions": (v0 /*: any*/),
            "selections": (v1 /*: any*/)
        },
        "operation": {
            "kind": "Operation",
            "name": "mutationsCreateRobertQuestionMutation",
            "argumentDefinitions": (v0 /*: any*/),
            "selections": (v1 /*: any*/)
        },
        "params": {
            "operationKind": "mutation",
            "name": "mutationsCreateRobertQuestionMutation",
            "id": null,
            "text": "mutation mutationsCreateRobertQuestionMutation(\n  $questionId: String!\n  $answerId: Int!\n  $chatHistory: String!\n) {\n  createRobertQuestion(input: {questionId: $questionId, answerId: $answerId, chatHistory: $chatHistory}) {\n    id\n    question\n    answers\n  }\n}\n",
            "metadata": {}
        }
    } as any;
})();
(node as any).hash = '99c7f1dfa0c7943c3551244bf6efa095';
export default node;
