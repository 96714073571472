import React from 'react';
import Spacer from 'components/core/Spacers/Spacer';
import moment from 'moment';
import theme from 'helpers/theme';
import { Divider } from '@material-ui/core';
import { createFragmentContainer, graphql } from 'react-relay';
import { EventCard_event } from './__generated__/EventCard_event.graphql';
import styled from 'styled-components';
import { buildURL } from 'react-imgix';
import Icon from 'components/core/Icon';

const Container = styled.div`
  width: 360px;
  margin: 10px 20px 20px 0;
  border: 1px solid ${theme.colors.borderGrey};
  box-shadow: 0px 3px 18px #0000000d;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  background: white;
  cursor: pointer;
  transition: all 0.2s;
  &:hover {
    box-shadow: 0px 7px 18px #0000001f;
  }
`;

const Content = styled.div`
  padding: 23px;
`;

const Name = styled.div`
  margin: 0;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-bottom: 5;
  font-weight: 800;
  font-size: 17px;
  color: black;
`;

const Title = styled.div`
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: 600;
  font-size: 15px;
  color: ${theme.colors.textGrey};
`;

const BodyText = styled.div`
  line-height: 24px;
  margin: 0;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  height: 72px;
  font-size: 14px;
  font-weight: 400;
`;

const Date = styled.div`
  font-size: 13px;
  color: ${theme.colors.textGrey};
`;

const Address = styled.div`
  margin: 0;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 13px;
  color: ${theme.colors.textGrey};
`;

const Image = styled.div<{ imageUrl: string }>`
  width: 100%;
  height: 174px;
  background-image: url(${(props) => props.imageUrl});
  background-size: cover;
`;

const ViewText = styled.span`
  float: right;
  font-size: ${theme.fontSizes.large}px;
  color: ${theme.colors.textOrange};
  border-radius: ${theme.buttonBorderRadius}px;
  border: 1px solid ${theme.colors.secondaryOrange};
  padding: ${theme.spacing(0)}px ${theme.spacing(1)}px;
`;

type Props = {
  event: EventCard_event;
  onClick: () => void;
};

function EventCard({ event, onClick }: Props) {
  const {
    name,
    title,
    startDate,
    endDate,
    addressLine1,
    addressLine2,
    postCode,
    bodyText,
    imageURL,
    userAttendanceStatus
  } = event;
  const address = addressLine2
    ? addressLine1?.concat('\n', addressLine2).concat('\n', postCode || "")
    : addressLine1?.concat('\n', postCode || "", '\n\n');

  return (
    <Container onClick={onClick}>
      <Image
        imageUrl={
          imageURL
            ? buildURL(imageURL, { bri: -5 })
            : require(`../../assets/defaultImage.svg`)
        }
      />
      <Divider />
      <Content>
        <Name>{name}</Name>
        <Spacer vertical spacing={1} />
        <Title>{title}</Title>
        <Spacer vertical spacing={2} />
        <BodyText style={{ whiteSpace: 'pre-line' }}>{bodyText}</BodyText>
        <Spacer vertical spacing={2} />
        <Date>{moment(startDate).format('hh:mmA - MMM DD, YYYY ')} - {moment(endDate).format('hh:mmA - MMM DD, YYYY ')}</Date>
        <Spacer vertical spacing={1} />
        <Address style={{ whiteSpace: 'pre-line' }}> {address}</Address>
        <Spacer vertical spacing={1} />
        {userAttendanceStatus === 'going' ? (
          <Icon name="Tick" style={{ float: 'right' }} />
        ): (
          <ViewText>VIEW</ViewText>
        )}
      </Content>
    </Container>
  );
}

export default createFragmentContainer(EventCard, {
  event: graphql`
    fragment EventCard_event on SocialEvent {
      id
      name
      title
      startDate
      endDate
      addressLine1
      addressLine2
      postCode
      bodyText
      imageURL
      userAttendanceStatus
    }
  `
});
