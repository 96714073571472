/* tslint:disable */
/* eslint-disable */
/* @relayHash 091fc6bc5ebab8156161db6711a23ec1 */

import { ConcreteRequest } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type App_SocialEvent_QueryVariables = {
    id: string;
};
export type App_SocialEvent_QueryResponse = {
    readonly socialEvent: {
        readonly " $fragmentRefs": FragmentRefs<"SocialEvent_socialEvent">;
    } | null;
};
export type App_SocialEvent_Query = {
    readonly response: App_SocialEvent_QueryResponse;
    readonly variables: App_SocialEvent_QueryVariables;
};



/*
query App_SocialEvent_Query(
  $id: ID!
) {
  socialEvent(id: $id) {
    ...SocialEvent_socialEvent
    id
  }
}

fragment SocialEvent_socialEvent on SocialEvent {
  id
  name
  title
  startDate
  endDate
  addressLine1
  addressLine2
  postCode
  bodyText
  imageURL
  userAttendanceStatus
}
*/

const node: ConcreteRequest = (function () {
    var v0 = [
        ({
            "kind": "LocalArgument",
            "name": "id",
            "type": "ID!",
            "defaultValue": null
        } as any)
    ], v1 = [
        ({
            "kind": "Variable",
            "name": "id",
            "variableName": "id"
        } as any)
    ];
    return {
        "kind": "Request",
        "fragment": {
            "kind": "Fragment",
            "name": "App_SocialEvent_Query",
            "type": "Query",
            "metadata": null,
            "argumentDefinitions": (v0 /*: any*/),
            "selections": [
                {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "socialEvent",
                    "storageKey": null,
                    "args": (v1 /*: any*/),
                    "concreteType": "SocialEvent",
                    "plural": false,
                    "selections": [
                        {
                            "kind": "FragmentSpread",
                            "name": "SocialEvent_socialEvent",
                            "args": null
                        }
                    ]
                }
            ]
        },
        "operation": {
            "kind": "Operation",
            "name": "App_SocialEvent_Query",
            "argumentDefinitions": (v0 /*: any*/),
            "selections": [
                {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "socialEvent",
                    "storageKey": null,
                    "args": (v1 /*: any*/),
                    "concreteType": "SocialEvent",
                    "plural": false,
                    "selections": [
                        {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "id",
                            "args": null,
                            "storageKey": null
                        },
                        {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "name",
                            "args": null,
                            "storageKey": null
                        },
                        {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "title",
                            "args": null,
                            "storageKey": null
                        },
                        {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "startDate",
                            "args": null,
                            "storageKey": null
                        },
                        {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "endDate",
                            "args": null,
                            "storageKey": null
                        },
                        {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "addressLine1",
                            "args": null,
                            "storageKey": null
                        },
                        {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "addressLine2",
                            "args": null,
                            "storageKey": null
                        },
                        {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "postCode",
                            "args": null,
                            "storageKey": null
                        },
                        {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "bodyText",
                            "args": null,
                            "storageKey": null
                        },
                        {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "imageURL",
                            "args": null,
                            "storageKey": null
                        },
                        {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "userAttendanceStatus",
                            "args": null,
                            "storageKey": null
                        }
                    ]
                }
            ]
        },
        "params": {
            "operationKind": "query",
            "name": "App_SocialEvent_Query",
            "id": null,
            "text": "query App_SocialEvent_Query(\n  $id: ID!\n) {\n  socialEvent(id: $id) {\n    ...SocialEvent_socialEvent\n    id\n  }\n}\n\nfragment SocialEvent_socialEvent on SocialEvent {\n  id\n  name\n  title\n  startDate\n  endDate\n  addressLine1\n  addressLine2\n  postCode\n  bodyText\n  imageURL\n  userAttendanceStatus\n}\n",
            "metadata": {}
        }
    } as any;
})();
(node as any).hash = '3b58bced7c12bef19470ad5e900a8e13';
export default node;
