/* tslint:disable */
/* eslint-disable */
/* @relayHash 3dc731a9cb57e8ec2431ffa57a61e56c */

import { ConcreteRequest } from "relay-runtime";
export type QWEEntryStatus = "draft" | "signedoff" | "submitted" | "%future added value";
export type OrderBy = {
    ascending?: boolean | null;
    field: string;
};
export type PortfolioQueryRendererQueryVariables = {
    searchText?: string | null;
    order?: OrderBy | null;
};
export type PortfolioQueryRendererQueryResponse = {
    readonly candidate: {
        readonly id: string;
        readonly firstName: string;
        readonly lastName: string;
        readonly placements: {
            readonly edges: ReadonlyArray<{
                readonly id: string;
                readonly name: string;
                readonly entries: {
                    readonly edges: ReadonlyArray<{
                        readonly id: string;
                        readonly title: string;
                        readonly startDate: string;
                        readonly endDate: string;
                        readonly status: QWEEntryStatus | null;
                        readonly placement: {
                            readonly name: string;
                        } | null;
                        readonly fileUrl: string | null;
                    } | null> | null;
                } | null;
            } | null> | null;
        } | null;
    } | null;
};
export type PortfolioQueryRendererQuery = {
    readonly response: PortfolioQueryRendererQueryResponse;
    readonly variables: PortfolioQueryRendererQueryVariables;
};



/*
query PortfolioQueryRendererQuery(
  $searchText: String
  $order: OrderBy
) {
  candidate {
    id
    firstName
    lastName
    placements {
      edges {
        id
        name
        entries(filter: {title: $searchText}, orderBy: $order) {
          edges {
            id
            title
            startDate
            endDate
            status
            placement {
              name
              id
            }
            fileUrl
          }
        }
      }
    }
  }
}
*/

const node: ConcreteRequest = (function () {
    var v0 = [
        ({
            "kind": "LocalArgument",
            "name": "searchText",
            "type": "String",
            "defaultValue": null
        } as any),
        ({
            "kind": "LocalArgument",
            "name": "order",
            "type": "OrderBy",
            "defaultValue": null
        } as any)
    ], v1 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "id",
        "args": null,
        "storageKey": null
    } as any), v2 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "firstName",
        "args": null,
        "storageKey": null
    } as any), v3 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "lastName",
        "args": null,
        "storageKey": null
    } as any), v4 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "name",
        "args": null,
        "storageKey": null
    } as any), v5 = [
        ({
            "kind": "ObjectValue",
            "name": "filter",
            "fields": [
                {
                    "kind": "Variable",
                    "name": "title",
                    "variableName": "searchText"
                }
            ]
        } as any),
        ({
            "kind": "Variable",
            "name": "orderBy",
            "variableName": "order"
        } as any)
    ], v6 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "title",
        "args": null,
        "storageKey": null
    } as any), v7 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "startDate",
        "args": null,
        "storageKey": null
    } as any), v8 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "endDate",
        "args": null,
        "storageKey": null
    } as any), v9 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "status",
        "args": null,
        "storageKey": null
    } as any), v10 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "fileUrl",
        "args": null,
        "storageKey": null
    } as any);
    return {
        "kind": "Request",
        "fragment": {
            "kind": "Fragment",
            "name": "PortfolioQueryRendererQuery",
            "type": "Query",
            "metadata": null,
            "argumentDefinitions": (v0 /*: any*/),
            "selections": [
                {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "candidate",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "Candidate",
                    "plural": false,
                    "selections": [
                        (v1 /*: any*/),
                        (v2 /*: any*/),
                        (v3 /*: any*/),
                        {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "placements",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "PlacementPage",
                            "plural": false,
                            "selections": [
                                {
                                    "kind": "LinkedField",
                                    "alias": null,
                                    "name": "edges",
                                    "storageKey": null,
                                    "args": null,
                                    "concreteType": "Placement",
                                    "plural": true,
                                    "selections": [
                                        (v1 /*: any*/),
                                        (v4 /*: any*/),
                                        {
                                            "kind": "LinkedField",
                                            "alias": null,
                                            "name": "entries",
                                            "storageKey": null,
                                            "args": (v5 /*: any*/),
                                            "concreteType": "EntryPage",
                                            "plural": false,
                                            "selections": [
                                                {
                                                    "kind": "LinkedField",
                                                    "alias": null,
                                                    "name": "edges",
                                                    "storageKey": null,
                                                    "args": null,
                                                    "concreteType": "QWEEntry",
                                                    "plural": true,
                                                    "selections": [
                                                        (v1 /*: any*/),
                                                        (v6 /*: any*/),
                                                        (v7 /*: any*/),
                                                        (v8 /*: any*/),
                                                        (v9 /*: any*/),
                                                        {
                                                            "kind": "LinkedField",
                                                            "alias": null,
                                                            "name": "placement",
                                                            "storageKey": null,
                                                            "args": null,
                                                            "concreteType": "Placement",
                                                            "plural": false,
                                                            "selections": [
                                                                (v4 /*: any*/)
                                                            ]
                                                        },
                                                        (v10 /*: any*/)
                                                    ]
                                                }
                                            ]
                                        }
                                    ]
                                }
                            ]
                        }
                    ]
                }
            ]
        },
        "operation": {
            "kind": "Operation",
            "name": "PortfolioQueryRendererQuery",
            "argumentDefinitions": (v0 /*: any*/),
            "selections": [
                {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "candidate",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "Candidate",
                    "plural": false,
                    "selections": [
                        (v1 /*: any*/),
                        (v2 /*: any*/),
                        (v3 /*: any*/),
                        {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "placements",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "PlacementPage",
                            "plural": false,
                            "selections": [
                                {
                                    "kind": "LinkedField",
                                    "alias": null,
                                    "name": "edges",
                                    "storageKey": null,
                                    "args": null,
                                    "concreteType": "Placement",
                                    "plural": true,
                                    "selections": [
                                        (v1 /*: any*/),
                                        (v4 /*: any*/),
                                        {
                                            "kind": "LinkedField",
                                            "alias": null,
                                            "name": "entries",
                                            "storageKey": null,
                                            "args": (v5 /*: any*/),
                                            "concreteType": "EntryPage",
                                            "plural": false,
                                            "selections": [
                                                {
                                                    "kind": "LinkedField",
                                                    "alias": null,
                                                    "name": "edges",
                                                    "storageKey": null,
                                                    "args": null,
                                                    "concreteType": "QWEEntry",
                                                    "plural": true,
                                                    "selections": [
                                                        (v1 /*: any*/),
                                                        (v6 /*: any*/),
                                                        (v7 /*: any*/),
                                                        (v8 /*: any*/),
                                                        (v9 /*: any*/),
                                                        {
                                                            "kind": "LinkedField",
                                                            "alias": null,
                                                            "name": "placement",
                                                            "storageKey": null,
                                                            "args": null,
                                                            "concreteType": "Placement",
                                                            "plural": false,
                                                            "selections": [
                                                                (v4 /*: any*/),
                                                                (v1 /*: any*/)
                                                            ]
                                                        },
                                                        (v10 /*: any*/)
                                                    ]
                                                }
                                            ]
                                        }
                                    ]
                                }
                            ]
                        }
                    ]
                }
            ]
        },
        "params": {
            "operationKind": "query",
            "name": "PortfolioQueryRendererQuery",
            "id": null,
            "text": "query PortfolioQueryRendererQuery(\n  $searchText: String\n  $order: OrderBy\n) {\n  candidate {\n    id\n    firstName\n    lastName\n    placements {\n      edges {\n        id\n        name\n        entries(filter: {title: $searchText}, orderBy: $order) {\n          edges {\n            id\n            title\n            startDate\n            endDate\n            status\n            placement {\n              name\n              id\n            }\n            fileUrl\n          }\n        }\n      }\n    }\n  }\n}\n",
            "metadata": {}
        }
    } as any;
})();
(node as any).hash = '70f9cbbe42063d457ce7ee2c1bb31fbc';
export default node;
