/* tslint:disable */
/* eslint-disable */
/* @relayHash afbbc580f6d482972e5193a642228918 */

import { ConcreteRequest } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type App_Tutorials_QueryVariables = {};
export type App_Tutorials_QueryResponse = {
    readonly user: {
        readonly " $fragmentRefs": FragmentRefs<"Tutorials_user">;
    } | null;
};
export type App_Tutorials_Query = {
    readonly response: App_Tutorials_QueryResponse;
    readonly variables: App_Tutorials_QueryVariables;
};



/*
query App_Tutorials_Query {
  user {
    ...Tutorials_user
  }
}

fragment Tutorials_user on User {
  firstName
}
*/

const node: ConcreteRequest = ({
    "kind": "Request",
    "fragment": {
        "kind": "Fragment",
        "name": "App_Tutorials_Query",
        "type": "Query",
        "metadata": null,
        "argumentDefinitions": [],
        "selections": [
            {
                "kind": "LinkedField",
                "alias": null,
                "name": "user",
                "storageKey": null,
                "args": null,
                "concreteType": "User",
                "plural": false,
                "selections": [
                    {
                        "kind": "FragmentSpread",
                        "name": "Tutorials_user",
                        "args": null
                    }
                ]
            }
        ]
    },
    "operation": {
        "kind": "Operation",
        "name": "App_Tutorials_Query",
        "argumentDefinitions": [],
        "selections": [
            {
                "kind": "LinkedField",
                "alias": null,
                "name": "user",
                "storageKey": null,
                "args": null,
                "concreteType": "User",
                "plural": false,
                "selections": [
                    {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "firstName",
                        "args": null,
                        "storageKey": null
                    }
                ]
            }
        ]
    },
    "params": {
        "operationKind": "query",
        "name": "App_Tutorials_Query",
        "id": null,
        "text": "query App_Tutorials_Query {\n  user {\n    ...Tutorials_user\n  }\n}\n\nfragment Tutorials_user on User {\n  firstName\n}\n",
        "metadata": {}
    }
} as any);
(node as any).hash = '2df1157e0885ec5a6f3e5cb059de9b78';
export default node;
