/* tslint:disable */
/* eslint-disable */

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type RobertChat_candidate = {
    readonly firstName: string;
    readonly skillGroupType: string | null;
    readonly skillGroupTypeConfirmed: boolean;
    readonly hiddenQualifications: number;
    readonly chatHistory: string | null;
    readonly " $refType": "RobertChat_candidate";
};
export type RobertChat_candidate$data = RobertChat_candidate;
export type RobertChat_candidate$key = {
    readonly " $data"?: RobertChat_candidate$data;
    readonly " $fragmentRefs": FragmentRefs<"RobertChat_candidate">;
};



const node: ReaderFragment = ({
    "kind": "Fragment",
    "name": "RobertChat_candidate",
    "type": "Candidate",
    "metadata": null,
    "argumentDefinitions": [],
    "selections": [
        {
            "kind": "ScalarField",
            "alias": null,
            "name": "firstName",
            "args": null,
            "storageKey": null
        },
        {
            "kind": "ScalarField",
            "alias": null,
            "name": "skillGroupType",
            "args": null,
            "storageKey": null
        },
        {
            "kind": "ScalarField",
            "alias": null,
            "name": "skillGroupTypeConfirmed",
            "args": null,
            "storageKey": null
        },
        {
            "kind": "ScalarField",
            "alias": null,
            "name": "hiddenQualifications",
            "args": null,
            "storageKey": null
        },
        {
            "kind": "ScalarField",
            "alias": null,
            "name": "chatHistory",
            "args": null,
            "storageKey": null
        }
    ]
} as any);
(node as any).hash = '905fc25e62a22f0fb9da729790399710';
export default node;
