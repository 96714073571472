import * as React from 'react';
import { QueryRenderer, graphql } from 'react-relay';
import environment from 'api/environment';
import Dropdown, { DropdownOption } from 'components/core/Input/Dropdown';
import { createUseStyles, useTheme } from 'react-jss';
import { Theme } from 'helpers/theme';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { DropdownCompetencyQueryRendererQueryResponse } from './__generated__/DropdownCompetencyQueryRendererQuery.graphql';
import ExtraFancyDropdown from 'components/core/Input/ExtraFancyDropdown';
import DropdownQueryRenderer from './DropdownQueryRenderer';
import { Category } from 'components/core/Input/FancyDropdown';

const useStyles = createUseStyles((theme: Theme) => ({
  practiceSkills: {
    width: '100%',
    height: '45px'
  },
  boxStyle: {
    height: '45px'
  },
  fontStyle: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    width: '250px',
    '@media (max-width: 1100px)': {
      width: '400px'
    }
  },
  fontStyleOption: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap'
  },
}));

export type Competency = {
  id: string;
  title: string;
  skills: Category[];
};

type Props = {
  practiceAreaID: number | string;
  selected: DropdownOption | undefined;
  setSelected: (item: DropdownOption) => void;
  selectedCompetencies: Competency[];
  setSelectedCompetencies: (items: Competency[]) => void;
  hint?: string;
};

// 'Practice skills' are called 'competency' according to the new design
function DropdownCompetencyQueryRenderer({
  practiceAreaID,
  selected,
  setSelected,
  selectedCompetencies,
  setSelectedCompetencies,
  hint
}: Props) {
  const theme = useTheme();
  const classes = useStyles({ theme });
  const [hovered, setHovered] = React.useState<DropdownOption>();

  const dropdownComponent = (
    placeholder: string,
    options: Competency[],
    setSelected: (item: DropdownOption) => void,
    selected?: DropdownOption
  ) => {
    let selectedSubskills: string[] = [];
    selectedCompetencies.forEach(
      comp => comp.skills.forEach(
        skill => selectedSubskills = [...selectedSubskills, ...skill.options.map(op => op.value)]
      )
    );
    return (
      <ExtraFancyDropdown
        options={options}
        setSelected={(item) => {
          // setSubSelected([]);
          setSelected(item);
        }}
        title={selected ? selected.title : placeholder}
        selected={selected}
        hovered={hovered}
        setHovered={setHovered}
        hint={hint}
      >
        {hovered && options.find(op => op.id === (selected?.id || hovered.id))?.skills.map((skill, i) => (
          <div key={i}>
            <ExtraFancyDropdown.FancyItem
              category={skill}
              selected={selectedSubskills}
              onChange={items => {
                let selectedComps: Competency[] = [];
                for (const comp of options){
                  const selectedCats = comp.skills.filter(
                    cat => cat.options.find(op => new Set(items).has(op.value))
                  ).map(cat => ({
                    name: cat.name,
                    options: cat.options.filter(op => new Set(items).has(op.value))
                  }));
                  selectedComps.push({
                    ...comp,
                    skills: selectedCats
                  });
                }
                const oldComps = selectedCompetencies.filter(comp => !selectedComps.find(c => c.id === comp.id));
                setSelectedCompetencies([...oldComps, ...selectedComps]);
              }}
            />
          </div>
        ))}
      </ExtraFancyDropdown>
    );
  };
  
  return practiceAreaID ? (
    <QueryRenderer
      environment={environment}
      query={graphql`
        query DropdownCompetencyQueryRendererQuery($practiceAreaID: ID!) {
          node(id: $practiceAreaID) {
            ... on PracticeArea {
              practiceSkills {
                edges {
                  id
                  name
                  practiceSkillGroups {
                    edges {
                      id
                      name
                      practiceSubSkills {
                        edges {
                          id
                          name
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      `}
      variables={{
        practiceAreaID: practiceAreaID
      }}
      render={({ error, props }) => {
        let dropdownSkillOptions: Competency[] = [];
        if (props) {
          const {
            node
          } = props as DropdownCompetencyQueryRendererQueryResponse;

          dropdownSkillOptions =
            node?.practiceSkills?.edges?.map((option) => ({
              id: option?.id ?? '',
              title: option?.name ?? '',
              skills: option?.practiceSkillGroups?.edges?.map(skill => ({
                name: skill?.name ?? '',
                options: skill?.practiceSubSkills?.edges?.map(subskill => ({
                  value: subskill?.id ?? '',
                  name: subskill?.name ?? ''
                })) ?? []
              })) ?? []
            })) ?? [];
        }
        return dropdownSkillOptions.length > 0
          ? dropdownComponent(
              'Competencies',
              dropdownSkillOptions,
              (val) => setSelected(val),
              selected
            )
          : dropdownComponent(
              'Competencies',
              [{ id: '', title: `No competencies`, skills: [] }],
              () => {}
            );
      }}
    />
  ) : (
    dropdownComponent(
      'Competencies',
      [{ id: '', title: `Please select 'Practice Area' first`, skills: [] }],
      () => {}
    )
  );
}

export default DropdownCompetencyQueryRenderer;
