import * as React from 'react';
import SideModal from 'components/SideModal/SideModal';
import Tabs from 'components/SideModal/Tabs';
import { createFragmentContainer, graphql } from 'react-relay';
import Content from './Content';
import { CandidateModal_candidate } from './__generated__/CandidateModal_candidate.graphql';
import { FileUpload } from './__generated__/mutations_UpdateCandidateMutation.graphql';

export type CandidateInfo = {
  id: string;
  firstName?: string;
  lastName?: string;
  jobTitle?: string;
  email?: string;
  skillGroupType?: string;
  telephone?: string;
  profileImageUrl?: string;
  profileImage?: FileUpload;
};

type Props = {
  isOpen: boolean;
  candidate: CandidateModal_candidate;
  refetch: () => void;
  onClose: () => void;
};

const CandidateSlideIn = ({ isOpen, candidate, refetch, onClose }: Props) => {
  return (
    <SideModal
      title={`${candidate.firstName} ${candidate.lastName}`}
      closeModal={onClose}
      isOpen={isOpen}
    >
      <Tabs
        content={[
          {
            key: 'Your details',
            component: Content
          }
        ]}
        closeModal={onClose}
        initialState={{
          // Candidate Details
          profileImageUrl: candidate.profileImageUrl,
          id: candidate.id,
          firstName: candidate.firstName,
          lastName: candidate.lastName,
          jobTitle: candidate.jobTitle,
          email: candidate.email,
          skillGroupType: candidate.skillGroupType,
          telephone: candidate.telephone,
        }}
      />
    </SideModal>
  );
};

export default createFragmentContainer(CandidateSlideIn, {
  candidate: graphql`
    fragment CandidateModal_candidate on Candidate {
      id
      firstName
      lastName
      email
      jobTitle
      telephone
      profileImageUrl
      skillGroupType
    }
  `
});

