/* tslint:disable */
/* eslint-disable */
/* @relayHash 63af86c1abbf244c391284d8f5123be8 */

import { ConcreteRequest } from "relay-runtime";
export type AttendanceStatus = "going" | "invited" | "notGoing" | "%future added value";
export type mutationsSetCandidateEventAttendanceMutationVariables = {
    eventID: string;
    status: AttendanceStatus;
};
export type mutationsSetCandidateEventAttendanceMutationResponse = {
    readonly setCandidateEventAttendance: boolean;
};
export type mutationsSetCandidateEventAttendanceMutation = {
    readonly response: mutationsSetCandidateEventAttendanceMutationResponse;
    readonly variables: mutationsSetCandidateEventAttendanceMutationVariables;
};



/*
mutation mutationsSetCandidateEventAttendanceMutation(
  $eventID: ID!
  $status: AttendanceStatus!
) {
  setCandidateEventAttendance(input: {eventID: $eventID, status: $status})
}
*/

const node: ConcreteRequest = (function () {
    var v0 = [
        ({
            "kind": "LocalArgument",
            "name": "eventID",
            "type": "ID!",
            "defaultValue": null
        } as any),
        ({
            "kind": "LocalArgument",
            "name": "status",
            "type": "AttendanceStatus!",
            "defaultValue": null
        } as any)
    ], v1 = [
        ({
            "kind": "ScalarField",
            "alias": null,
            "name": "setCandidateEventAttendance",
            "args": [
                {
                    "kind": "ObjectValue",
                    "name": "input",
                    "fields": [
                        {
                            "kind": "Variable",
                            "name": "eventID",
                            "variableName": "eventID"
                        },
                        {
                            "kind": "Variable",
                            "name": "status",
                            "variableName": "status"
                        }
                    ]
                }
            ],
            "storageKey": null
        } as any)
    ];
    return {
        "kind": "Request",
        "fragment": {
            "kind": "Fragment",
            "name": "mutationsSetCandidateEventAttendanceMutation",
            "type": "Mutation",
            "metadata": null,
            "argumentDefinitions": (v0 /*: any*/),
            "selections": (v1 /*: any*/)
        },
        "operation": {
            "kind": "Operation",
            "name": "mutationsSetCandidateEventAttendanceMutation",
            "argumentDefinitions": (v0 /*: any*/),
            "selections": (v1 /*: any*/)
        },
        "params": {
            "operationKind": "mutation",
            "name": "mutationsSetCandidateEventAttendanceMutation",
            "id": null,
            "text": "mutation mutationsSetCandidateEventAttendanceMutation(\n  $eventID: ID!\n  $status: AttendanceStatus!\n) {\n  setCandidateEventAttendance(input: {eventID: $eventID, status: $status})\n}\n",
            "metadata": {}
        }
    } as any;
})();
(node as any).hash = '6440b7a6e8fdea99b2a8b8c5b238aeae';
export default node;
