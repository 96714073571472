import React from 'react';
import { useRouter } from 'found';
import Entry, { dropDownContentiousOptions, EntryState } from './Entry';
import { createFragmentContainer, graphql } from 'react-relay';
import { EntryUpdate_candidate } from './__generated__/EntryUpdate_candidate.graphql';
import { EntryUpdate_node } from './__generated__/EntryUpdate_node.graphql';
import { useToastDispatch } from 'components/Toast/ToastProvider';
import { makeMutation } from 'api/helpers';
import { UpdateQWEEntry } from 'api/mutations';
import { Category } from 'components/core/Input/FancyDropdown';
import { Competency } from './DropdownCompetencyQueryRenderer';
import { subSkillToCompetencies } from 'helpers/practiceSubSkills';
import { practiceAreaOptions } from 'helpers/practiceAreaOptions';

type Props = {
  candidate: EntryUpdate_candidate;
  node: EntryUpdate_node;
};

function EntryUpdate({ candidate, node }: Props) {
  const { match, router } = useRouter();
  const dispatch = useToastDispatch();
  const onSave = async (props: EntryState) => {
    try {
      let subSkills: string[] = [];
      props.selectedCompetencies.forEach(comp => comp.skills.forEach(skill => subSkills = [...subSkills, ...skill.options.map(op => op.value)]));

      await makeMutation(UpdateQWEEntry, {
        id: match.params.id,
        placementID: props.selectedPlacement?.id,
        title: props.title,
        startDate: new Date(props.startDate).toISOString(),
        endDate: new Date(props.endDate).toISOString(),
        subSkills: subSkills,
        experience: props.experience,
        evidence: props.evidence,
        learnt: props.whatWasLearned,
        moreExperience: props.moreExperience,
        needMoreExperience: props.needMoreExperience,
        contentious: props.selectedContentiousOption?.id,
        file: props.file,
        practiceAreas: props.selectedStaticAreas.map(p => p.id),
        taskedBy: props.taskedBy
      });
      dispatch({
        text: 'Entry updated successfully',
        alert: 'success',
        stayFor: 4000
      });
      router.push('/app/qwe/portfolio');
    } catch (err) {
      console.log('Unable to updated entry', err);
      dispatch({
        text: err.message,
        alert: 'error',
        stayFor: 6000
      });
    }
  };

  let area;
  if (
    node.practiceSubSkills?.[0]?.practiceSkillGroup?.practiceSkill?.practiceArea
  ) {
    area = {
      id:
        node.practiceSubSkills?.[0]?.practiceSkillGroup?.practiceSkill
          ?.practiceArea?.id ?? '',
      title:
        node.practiceSubSkills?.[0]?.practiceSkillGroup?.practiceSkill
          ?.practiceArea?.name
    };
  }

  let competency; // formerly 'practice skill'
  if (node.practiceSubSkills?.[0]?.practiceSkillGroup?.practiceSkill) {
    competency = {
      id:
        node.practiceSubSkills?.[0]?.practiceSkillGroup?.practiceSkill?.id ??
        '',
      title:
        node.practiceSubSkills?.[0]?.practiceSkillGroup?.practiceSkill?.name
    };
  }

  let selectedContentiousOption;
  if (dropDownContentiousOptions.length > 1) {
    selectedContentiousOption =
      dropDownContentiousOptions.find(d => d.id === node.contentious);
  }

  let placement;
  if (node.placement) {
    placement = {
      id: node.placement?.id ?? '',
      title: node.placement?.name ?? ''
    };
  }

  const state = {
    new: false,
    startDate: node.startDate,
    endDate: node.endDate,
    experience: node.experience,
    evidence: node.evidence,
    moreExperience: node.moreExperience,
    needMoreExperience: node.needMoreExperience,
    whatWasLearned: node.learnt,
    title: node.title,
    selectedCompetencies: subSkillToCompetencies(node.practiceSubSkills),
    selectedSkill: competency,
    selectedArea: area,
    confirmed: node?.fileUrl ? true : false,
    selectedContentiousOption: selectedContentiousOption,
    selectedPlacement: placement,
    file: undefined,
    fileURL: node.fileUrl ?? undefined,
    taskedBy: node.taskedBy ?? undefined,
    selectedStaticAreas: practiceAreaOptions.filter(p => node.practiceAreas?.includes(p.id)),
  };

  return <Entry candidate={candidate} onSave={onSave} initialState={state} />;
}

export default createFragmentContainer(EntryUpdate, {
  candidate: graphql`
    fragment EntryUpdate_candidate on Candidate {
      skillGroupType
      competency {
        practiceAreas {
          edges {
            id
            name
            createdAt
          }
        }
      }
      placements {
        edges {
          id
          name
        }
      }
    }
  `,
  node: graphql`
    fragment EntryUpdate_node on QWEEntry {
      id
      title
      startDate
      endDate
      placement {
        id
        name
      }
      practiceSubSkills {
        practiceSkillGroup {
          practiceSkill {
            practiceArea {
              id
              name
            }
            id
            name
          }
          name
        }
        id
        name
      }
      experience
      evidence
      learnt
      moreExperience
      needMoreExperience
      contentious
      fileUrl
      practiceAreas
      taskedBy
    }
  `
});
