import * as React from 'react';
import { createUseStyles, useTheme } from 'react-jss';
import { Theme } from 'helpers/theme';
import GenericPopup from './GenericPopup';
import Button from 'components/core/Input/ButtonBase';
import Spacer from 'components/core/Spacers/Spacer';

const useStyles = createUseStyles((theme: Theme) => ({
  tryAgainRoot: {},
  bodyText: {
    fontSize: 22,
    fontWeight: 300
  },
  buttons: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: theme.spacing(4)
  },
  heading: {
    fontWeight: 900,
    fontSize: 38,
    color: theme.colors.primaryBlack
  },
  coursename: {
    fontWeight: 600,
    fontSize: 21,
    margin: 0,
    color: '#1081AA'
  }
}));

type Props = {
  open: boolean;
  firstName: string;
  courseName: string;
  onClose: () => void;
};

function Congratulations({ open, firstName, courseName, onClose }: Props) {
  const theme = useTheme();
  const classes = useStyles({ theme });

  if (!open) {
    return null;
  }
  return (
    <GenericPopup
      open={open}
      onClose={onClose}
      title={`Congratulations, ${firstName}...`}
    >
      <h1 className={classes.heading}>Congratulations...</h1>
      <p className={classes.bodyText}>
        {firstName} - congratulations on passing the
      </p>
      <p className={classes.coursename}>{courseName} Course </p>
      <div className={classes.buttons}>
        <Button archetype="submit" onClick={onClose}>
          Return to Dashboard
        </Button>
      </div>
    </GenericPopup>
  );
}

export default Congratulations;
