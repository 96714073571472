import React, {
  useState
} from 'react';
import Item from './Item';
import Divider from './Divider';
import FancyItem from './FancyItem';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronRight, faChevronUp, faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { DropdownOption } from '../Dropdown';
import classNames from 'classnames';
import { createUseStyles } from 'react-jss';
import { Theme } from 'helpers/theme';
import Tooltip from 'components/core/Tooltip';

const Container = styled.div`
  position: relative;
  display: inline-flex;
  align-items: stretch;
`;

const TitleContainer = styled.div`
  outline: none;
  user-select: none;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  white-space: nowrap;
  cursor: pointer;
`;

const Title = styled.p`
  text-overflow: ellipsis;
  overflow: hidden;
`;

const IconArea = styled.div`
`;
  
const SubmenuTitle = styled.p`
  align-items: left;
  padding: 0 10px;
  min-width: 50%;
`;

const DropdownContainer = styled.div`
  display: flex;
  flex-direction: row;
  position: absolute;
  top: 40px;
  max-height: 450px;
  border: 1px solid #E9EBEB;
  background: white;
  z-index: 10;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
`;

const DropdownResults = styled.ul`
  list-style: none;
  list-style-position: inside;
  padding-left: 0;
  margin-top: 12px;
  margin-bottom: 12px;
  overflow: auto;
`;

const HoverItem = styled.div`
  &:hover {
    background: #f8f9fb;
  }
`;

const useStyles = createUseStyles((theme: Theme) => ({
  dropdownHeader: {
    border: '1px solid #E9EBEB',
    height: 45,
    transition: 'filter 160ms ease-out',
    background: 'white',
    borderRadius: theme.buttonBorderRadius,
    fontWeight: 300,
    fontSize: theme.fontSizes.small,
    padding: '0 15px'
  },
  fullWidth: {
    width: '100%'
  },
  halfMinWidth: {
    width: '50%',
    minWidth: '50%'
  },
  backgroundHider: {
    position: 'fixed',
    width: '100%',
    height: '100%',
    top: 0,
    left: 0,
  },
  submenuContainer: {
    overflow: 'hidden',
    overflowY: 'scroll'
  },
  hoverItem: {
    '&:hover': {
      background: '#f8f9fb'
    }
  },
  selectedItem: {
    backgroundColor: '#EEF3FF' ,
    fontWeight: 'bold',
  }
}));

type Props = {
  title: string;
  options: DropdownOption[];
  selected?: DropdownOption;
  setSelected: (item: DropdownOption) => void;
  hovered?: DropdownOption;
  setHovered: (item: DropdownOption) => void;
  children?: React.ReactNode;
  hint?: string;
};

function ExtraFancyDropdown({
  title,
  options,
  selected,
  setSelected,
  setHovered,
  hint,
  children
}: Props) {
  const [isOpen, setOpen] = useState(false);
  const [isSubMenuOpen, setSubMenuOpen] = useState(false);
  const classes = useStyles();

  return (
    <>
      {isOpen && <div className={classes.backgroundHider} onClick={() => {
        setOpen(false);
        setSubMenuOpen(false);
      }} />}
      <Container>
        <div className={classNames(
          classes.dropdownHeader
          , classes.fullWidth)}>
          <TitleContainer onClick={() => {
            setOpen(!isOpen);
            setSubMenuOpen(false);
          }}>
            <Title style={{ width: '90%' }}>
              {title}
            </Title>
            <IconArea>
              {hint && (
                <Tooltip text={hint} element={<FontAwesomeIcon icon={faInfoCircle} />} />
              )}
              <FontAwesomeIcon icon={!isOpen ? faChevronDown : faChevronUp} color={'#CFD1D1'} />
            </IconArea>
          </TitleContainer>
        </div>
        {isOpen && (
          <DropdownContainer className={classNames(
            isSubMenuOpen ? classes.fullWidth : classes.halfMinWidth
          )}
            onMouseLeave={() => {
              // setOpen(false);
              setSubMenuOpen(false);
            }}
          >
            <DropdownResults
              className={classNames(
                isSubMenuOpen ? classes.halfMinWidth : classes.fullWidth
              )}
            >
              {options.map((op, i) => op.component ?
                op.component :
                <div
                  key={i}
                  onMouseEnter={() => {
                    setHovered(op);
                    setSubMenuOpen(true);
                  }}
                >
                  <Item
                    onClick={() => setSelected(op)}
                    key={op.id}
                    className={classNames(classes.hoverItem, selected?.id === op.id ? classes.selectedItem: undefined)}
                  >
                    {/* <HoverItem> */}
                      <Title>
                        {op.title}
                      </Title>
                      <FontAwesomeIcon
                        icon={faChevronRight}
                        color={'#CFD1D1'}
                      />
                    {/* </HoverItem> */}
                  </Item>
                </div>
              )}
            </DropdownResults>
            {isSubMenuOpen && (
              <nav
                // onMouseLeave={() => setSubMenuOpen(false)}
                className={classes.submenuContainer}
              >
                <DropdownResults
                  onMouseEnter={() => setSubMenuOpen(true)}
                >
                  {children}
                </DropdownResults>
              </nav>
            )}
          </DropdownContainer>
        )}
      </Container>
    </>
  );
}

ExtraFancyDropdown.Item = Item;
ExtraFancyDropdown.Divider = Divider;
ExtraFancyDropdown.FancyItem = FancyItem;

export default ExtraFancyDropdown;