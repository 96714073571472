import Spacer from 'components/core/Spacers/Spacer';
import Table from 'components/core/Table';
import Text from 'components/core/Table/Text';
import Page from 'components/Page';
import PageTitle from 'components/PageTitle';
import Button from 'components/core/Input/ButtonBase';
import React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import { useSideModalDispatch } from '../SideModalProvider';
import styled from 'styled-components';
import theme from 'helpers/theme';
import { Appraisals_mentors } from './__generated__/Appraisals_mentors.graphql';

const SubTitle = styled.div`
  font-weight: 300;
  font-size: 14px;
  color: ${theme.colors.textGrey};
  margin-top: 15px;
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
`;

type Props = {
  mentors: Appraisals_mentors;
};

function Appraisals({ mentors }: Props) {
  const dispatch = useSideModalDispatch();
  const columns = [
    {
      headerName: 'TRAINING PRINCIPAL',
      component: (item: any) => (
        <Text text={`${item?.firstName} ${item?.lastName}`} />
      )
    },
    {
      headerName: 'EMAIL',
      field: 'email'
    },
    {
      headerName: '',
      component: (item: any) => (
        <ButtonContainer>
          {/*
          <Button
            onClick={() => {
              dispatch({
                modal: 'sendmeetingrequest',
                mentorID: item?.id || '',
                meetingRequestType: 'tpm'
              });
            }}
            style={{ minWidth: '160px' }}
          >
            Book a TPM
          </Button>
          <Spacer horizontal spacing={1} />
          */}
          <Button
            onClick={() => {
              dispatch({
                modal: 'sendmeetingrequest',
                mentorID: item?.id || '',
                meetingRequestType: 'appraisal'
              });
            }}
            style={{ /*height: 30, */ minWidth: '160px' }}
          >
            Book an Appraisal
          </Button>
        </ButtonContainer>
      )
    }
  ];

  const rows =
    mentors?.edges?.map((mentor) => ({
      id: mentor?.id ?? '',
      firstName: mentor?.firstName ?? '',
      lastName: mentor?.lastName ?? '',
      email: mentor?.email ?? '',
      isTP: mentor?.isTP ?? false
    })) ?? [];

  return (
    <Page>
      <PageTitle title="Appraisals" />
      <SubTitle>
      Approximately every six months, you need to schedule an appraisal with your supervisor and your training principal to discuss your development and progress to date. Once you have a time that works for you and your supervisor, click the 'Book an appraisal' button to schedule the meeting with your Training Principal. Remember to circulate your draft appraisal form to both of them a couple of days before your appraisal meeting.
      </SubTitle>
      <Spacer vertical spacing={3} />
      <Table columns={columns} rows={rows} />
    </Page>
  );
}
export default createFragmentContainer(Appraisals, {
  mentors: graphql`
    fragment Appraisals_mentors on MentorPage {
      edges {
        id
        email
        firstName
        lastName
        isTP
      }
      pageInfo {
        total
        offset
        limit
        given
      }
    }
  `
});
